<div [formGroup]="locationFormGroup" class="companyForm">
    <div class="left-column">
        <div id="map">
            <custom-map
                (mapInitialized)="mapInit()"
                [boundingBox]="userCoverage"
                [mapOptions]="mapOptions"
                [markerOptions]="mapMarker"
                (markerDragEnd)="onMarkerDrag($any($event[0]), $event[1])"></custom-map>
        </div>
    </div>
    <div class="right-column">
        <div>
            <mat-form-field appearance="outline" class="full-width-form-field">
                <mat-label i18n="@@description">Description</mat-label>
                <input
                    formControlName="name"
                    i18n-placeholder="@@companyPlaceholder"
                    matInput
                    placeholder="Company XYZ"
                    required
                    type="string" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width-form-field">
                <mat-label i18n="@@industry">Industry</mat-label>
                <mat-select formControlName="industry" multiple>
                    @for (industry of industries; track industry) {
                        <mat-option [value]="industry">
                            {{ industry }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
        <mat-tab-group
            (focusChange)="onTabFocusChange()"
            (selectedTabChange)="onEntrancesTabChanged(selectedEntranceTab)"
            [(selectedIndex)]="selectedEntranceTab">
            @for (
                entrance of this._entrancesFormGroup.controls.entrances.value;
                track index;
                let index = $index
            ) {
                <mat-tab>
                    <ng-template mat-tab-label>
                        @if (index === 0) {
                            <ng-container i18n="@@mainLocation">Main Location</ng-container>
                        } @else if (index > 0) {
                            <ng-container i18n="@@locationWithId">
                                Location {{ index + 1 }}</ng-container
                            >
                            <mat-icon (click)="removeEntranceTab(index)">close</mat-icon>
                        }
                    </ng-template>
                </mat-tab>
            }
        </mat-tab-group>
        <div class="mt-m" [formGroup]="entrances.controls[selectedEntranceTab]">
            <google-places-input
                [entranceIndex]="selectedEntranceTab"
                [label]="searchText"
                [locationRestriction]="userCoverage"
                [parentFormGroup]="placeResultFormGroup"
                i18n-placeholder="@@searchPlaceholder"
                placeholder="Company XYZ, Peter-Behrens-Platz 10, Linz, Austria">
            </google-places-input>
            <mat-form-field appearance="outline" class="full-width-form-field">
                <mat-label i18n="@@streetAndHouseNo">Street + House Number</mat-label>
                <input
                    formControlName="street"
                    matInput
                    placeholder="Peter-Behrens-Platz 10"
                    required
                    type="string" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
                <mat-label i18n="@@country">Country</mat-label>
                <input
                    [matAutocomplete]="auto"
                    aria-label="Country"
                    formControlName="country"
                    matInput
                    placeholder="Select Country"
                    required
                    type="text" />
                <mat-autocomplete #auto="matAutocomplete">
                    @for (option of filteredCountryOptions$ | async; track option) {
                        <mat-option [value]="option.name">
                            {{ option.name }}
                        </mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
            <div class="city-info">
                <mat-form-field appearance="outline">
                    <mat-label i18n="@@city">City</mat-label>
                    <input
                        formControlName="city"
                        matInput
                        placeholder="Linz"
                        required
                        type="string" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label i18n="@@postalCode">Postal Code</mat-label>
                    <input
                        formControlName="zipcode"
                        matInput
                        placeholder="4020"
                        required
                        type="string" />
                </mat-form-field>
            </div>
            <div class="city-info">
                <mat-form-field appearance="outline">
                    <mat-label i18n="@@latitude">Latitude</mat-label>
                    <input
                        #latitude
                        (change)="
                            entranceCoordinateChange(
                                latitude.value,
                                longitude.value,
                                selectedEntranceTab
                            )
                        "
                        formControlName="latitude"
                        matInput
                        placeholder="48.23456"
                        required
                        type="number"
                        step=".001" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label i18n="@@longitude">Longitude</mat-label>
                    <input
                        #longitude
                        (change)="
                            entranceCoordinateChange(
                                latitude.value,
                                longitude.value,
                                selectedEntranceTab
                            )
                        "
                        formControlName="longitude"
                        matInput
                        placeholder="16.2345"
                        required
                        type="number"
                        step=".001" />
                </mat-form-field>
            </div>
            <button (click)="addEntranceTab()" mat-button i18n="@@addAnotherLocation">
                Add another location <mat-icon>add_circle</mat-icon>
            </button>
        </div>
        <div class="section">
            <!-- Section Header -->
            <expandable-section
                (expandSection)="onExpandSection($event)"
                (hideSection)="onHideSection($event)"
                [disabled]="sectionHeader.disabled"
                [isExpandable]="sectionHeader.isExpandable"
                [isExpanded]="sectionHeader.isExpanded"
                [isHidden]="sectionHeader.isHidden"
                [sectionName]="sectionHeader.label"></expandable-section>

            <!-- Section Content -->
            <div class="full-width">
                <!-- Hidden component for css --->
                @if (sectionHeader.isHidden) {
                    <mat-form-field appearance="outline" style="visibility: hidden">
                        <input matInput />
                    </mat-form-field>
                } @else {
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Timezone</mat-label>
                        <input
                            [matAutocomplete]="auto"
                            aria-label="Timezone"
                            formControlName="timeZone"
                            matInput
                            placeholder="Select Timezone"
                            type="text" />
                        <mat-autocomplete #auto="matAutocomplete">
                            @for (option of filteredTimezoneOptions$ | async; track option) {
                                <mat-option [value]="option">
                                    {{ option }}
                                </mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                }
            </div>
        </div>
    </div>
</div>
