import { Injectable } from '@angular/core';
import { Observable, combineLatest, of, pipe, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import {
    CarpoolingImprovementDetails,
    CompanyCostLine,
    CompanyLocation,
    KpisService,
    ScenarioResultToSave,
    SmallScoreResponse,
} from '@upscore-mobility-audit/api';
import { AuditInputDataService } from '@upscore-mobility-audit/core/data-services/audit-input-data.service';
import { CacheResetBase } from '@upscore-mobility-audit/core/data-services/cache-reset-base.service';
import { CompanyLocationDataService } from '@upscore-mobility-audit/core/data-services/company-location-data.service';
import { ScoreDataService } from '@upscore-mobility-audit/core/data-services/score-data.service';
import { calculateMobilityScore } from '@upscore-mobility-audit/core/functions/calculate-mobility-stats/calculate-mobility-stats';
import { CarpoolPassengers } from '@upscore-mobility-audit/core/interfaces/CarpoolPassengers';
import { MobilityScenario } from '@upscore-mobility-audit/shared/interfaces/mobility-scenario.interface';

export interface ScenarioKpi {
    idAndScore: { scenarioRef: number; score: SmallScoreResponse };
    results: ScenarioResultToSave;
}

@Injectable({
    providedIn: 'root',
})
export class ScenarioKpiService extends CacheResetBase {
    public selectedScenarioKpi$ = this.scoreDataService.selectedScenarioAndScore$.pipe(
        switchMap(scenarioAndScore => {
            if (scenarioAndScore.scenarioRef != null && scenarioAndScore.score != null) {
                const companyLocation = this.companyDataService.companyLocation();

                return this.loadScenarioKpi(scenarioAndScore.scenarioRef, companyLocation).pipe(
                    map(scenarioResults => {
                        return {
                            idAndScore: scenarioAndScore,
                            results: scenarioResults,
                        } as ScenarioKpi;
                    }),
                );
            }

            return of(null);
        }),
    );

    public baselineScenarioKpi$ = this.scoreDataService.baselineScore$.pipe(
        switchMap(score => {
            if (score != null) {
                const companyLocation = this.companyDataService.companyLocation();

                return this.loadScenarioKpi(-1, companyLocation).pipe(
                    map(scenarioResults => {
                        return {
                            idAndScore: {
                                scenarioRef: -1,
                                score: score,
                            },
                            results: scenarioResults,
                        } as ScenarioKpi;
                    }),
                );
            }

            return of(null);
        }),
    );

    public scenarioToScore = map(([scenarioResult, department]: [ScenarioKpi | null, string]) => {
        if (
            scenarioResult == null ||
            scenarioResult.results == null ||
            scenarioResult.idAndScore.score == null
        ) {
            return null;
        }

        let employeeScenarioResults = scenarioResult.results.employeeScenarioResults;
        let employeeInterventionResults = scenarioResult.results.employeeInterventionResults;

        if (department !== 'All') {
            employeeScenarioResults = employeeScenarioResults.filter(
                employeeScenarioResult => employeeScenarioResult.department === department,
            );
            const employeeIdsInDepartment = new Set(
                employeeScenarioResults.map(it => it.employeeId),
            );
            employeeInterventionResults = employeeInterventionResults.filter(it =>
                it.employeeId != undefined ? employeeIdsInDepartment.has(it.employeeId) : false,
            );
        }

        const kpiCalculationParams =
            scenarioResult.results.scenarioDescription.kpiCalculationParameters;

        const calculatedKpis = calculateMobilityScore(
            employeeScenarioResults,
            kpiCalculationParams,
        );

        if (department !== 'All') {
            return {
                ...calculatedKpis,
                interventions: scenarioResult.results.scenarioDescription.interventions,
                employeeInterventionResults: employeeInterventionResults,
                scoreType: 'DepartmentKpi',
            } as MobilityScenario;
        }

        const employerCosts = Object.values(scenarioResult.results.employerCosts)
            .map((line: CompanyCostLine | undefined | null) => line?.costs)
            .reduce((a, b) => (a ?? 0) + (b ?? 0), 0);

        return {
            ...scenarioResult.idAndScore.score,
            ...calculatedKpis,
            interventionsImpact: scenarioResult.results.interventionsImpact,
            infrastructureKpis: scenarioResult.results.infrastructureKpis,
            scoreType: 'MobilityScore',
            yearlyEmployerCosts: employerCosts,
            dailyEmployerCosts:
                ((employerCosts ?? 0) /
                    calculatedKpis.maxPresenceDaysPerYear /
                    calculatedKpis.defaultPresenceDaysPerWeek) *
                calculatedKpis.maxPresenceDaysPerWeek,
            employerCosts: scenarioResult.results.employerCosts,
            interventions: scenarioResult.results.scenarioDescription.interventions,
            employeeInterventionResults: employeeInterventionResults,
            disableMobilityScore: scenarioResult.results.disablesMobilityScore,
        } as MobilityScenario;
    });

    public baselineEmployeeScenarioResults$ = combineLatest([
        this.baselineScenarioKpi$,
        this.companyFilterInputDataService.selectedDepartment$,
    ]).pipe(
        map(([scenarioResults, department]) =>
            this.filterEmployeeScenarioResultsByDepartment(
                scenarioResults?.results ?? null,
                department,
            ),
        ),
    );

    public selectedEmployeeScenarioResults$ = combineLatest([
        this.selectedScenarioKpi$,
        this.companyFilterInputDataService.selectedDepartment$,
    ]).pipe(
        map(([scenarioResults, department]) =>
            this.filterEmployeeScenarioResultsByDepartment(
                scenarioResults?.results ?? null,
                department,
            ),
        ),
    );

    public selectedMobilityScenario$: Observable<MobilityScenario | null> = combineLatest([
        this.selectedScenarioKpi$,
        this.companyFilterInputDataService.selectedDepartment$,
    ]).pipe(this.scenarioToScore, shareReplay(1));

    public carpools$ = this.selectedScenarioKpi$.pipe(
        map(scenarioResults => {
            const carpools: CarpoolPassengers = {};
            if (scenarioResults) {
                const carpoolingInterventionId =
                    scenarioResults.results.scenarioDescription.interventions.find(
                        intervention => intervention.type === 'CarpoolingIntervention',
                    )?.id;
                scenarioResults.results.employeeInterventionResults
                    .filter(res => res.interventionId === carpoolingInterventionId)
                    .forEach(res => {
                        const carpoolPassenger = res.details as CarpoolingImprovementDetails;
                        if (res.employeeId != undefined) {
                            carpools[res.employeeId] = carpoolPassenger.carpoolDriverId!;
                        }
                        carpools[carpoolPassenger.carpoolDriverId!] =
                            carpoolPassenger.carpoolDriverId!;
                    });
            }

            return carpools;
        }),
    );

    public baselineMobilityScenario$: Observable<MobilityScenario | null> = combineLatest([
        this.baselineScenarioKpi$,
        this.companyFilterInputDataService.selectedDepartment$,
    ]).pipe(this.scenarioToScore, pipe(shareReplay(1)));

    private scenarioKpiStore = new Map<number, Observable<ScenarioResultToSave>>();

    constructor(
        private readonly companyFilterInputDataService: AuditInputDataService,
        private readonly companyDataService: CompanyLocationDataService,
        private readonly kpisService: KpisService,
        private readonly scoreDataService: ScoreDataService,
    ) {
        super();
    }

    public loadScenarioKpi(scenarioRef: number, companyLocation: CompanyLocation) {
        if (!this.scenarioKpiStore.has(scenarioRef)) {
            this.scenarioKpiStore.set(
                scenarioRef,
                this.kpisService
                    .getScenario({
                        scenarioReference: scenarioRef,
                        companyLocationId: companyLocation.id,
                    })
                    .pipe(shareReplay(1)),
            );
        }

        return this.scenarioKpiStore.get(scenarioRef) as Observable<ScenarioResultToSave>;
    }

    protected override auditClosed(): void {
        this.scenarioKpiStore.clear();
    }

    protected override scoreSaved(packageId: number): void {
        this.scenarioKpiStore.delete(packageId);
    }

    private filterEmployeeScenarioResultsByDepartment(
        results: ScenarioResultToSave | null,
        department: string,
    ) {
        if (results == null) {
            return [];
        }

        let employeeScenarioResults = results.employeeScenarioResults;

        if (department !== 'All') {
            employeeScenarioResults = employeeScenarioResults.filter(
                employeeScenarioResult => employeeScenarioResult.department === department,
            );
        }

        return employeeScenarioResults;
    }
}
