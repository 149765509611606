<div
    class="rounded flex flex-col justify-between items-end gap-l text-light box-border p-m w-[var(--measure-card-width)], h-[var(--measure-card-height)] bg-beta">
    <div class="flex gap-l w-full items-center">
        <measure-icon class="flex-none" [measureType]="'__OTHER__'" size="large"></measure-icon>
        <div class="shrink min-w-0 flex flex-col items-start">
            <div
                class="text-h4 min-w-0 max-w-full font-semibold truncate"
                i18n="@@measure.otherHeader">
                Other Measures
            </div>
            <div class="text-b2" i18n="@@measure.otherDescription">
                Couldn't find what you were looking for? Let us know your ideas for further
                measures.
            </div>
        </div>
    </div>
    <a
        href="https://survey.triply.at/beta-feedback?source=measure&username={{
            (user$ | async)?.email
        }}"
        target="_blank"
        class="after:content-['_→'] text-xs cursor-pointer text-white"
        ><span class="underline uppercase font-semibold" i18n="@@measure.leaveFeedback"
            >Leave Feedback</span
        ></a
    >
</div>
