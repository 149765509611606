import { CdkStepper } from '@angular/cdk/stepper';
import { Component, EventEmitter, HostBinding, Input, Output, QueryList } from '@angular/core';

import { UpscoreStepComponent } from '../upscore-step/upscore-step.component';

@Component({
    selector: 'upscore-stepper',
    templateUrl: './upscore-stepper.component.html',
    styleUrls: ['./upscore-stepper.component.scss'],
    providers: [{ provide: CdkStepper, useExisting: UpscoreStepperComponent }],
})
export class UpscoreStepperComponent extends CdkStepper {
    @Input() public finalStepButtonDisabled = false;
    @Input() public backwardStepDisabled = false;
    @Input() public allowReset = false;
    @Output() public stepsCompleted: EventEmitter<void> = new EventEmitter();
    @Output() public stepsReset: EventEmitter<void> = new EventEmitter();
    @HostBinding('style.pointer-events') private pointerEvents = 'auto';

    public get upscoreSteps(): QueryList<UpscoreStepComponent> {
        return this.steps as QueryList<UpscoreStepComponent>;
    }

    public get upscoreSelected(): UpscoreStepComponent {
        return this.selected as UpscoreStepComponent;
    }

    @Input()
    public set disabled(value: boolean) {
        if (value) {
            this.pointerEvents = 'none !important';
        } else {
            this.pointerEvents = 'auto';
        }
    }

    public preCallCheck(skipNext = false): void {
        let nextStep = this.upscoreSteps.get(this.selectedIndex + 1);

        if (nextStep == null) {
            return;
        }

        if (skipNext) {
            this.next();
            nextStep = this.upscoreSteps.get(this.selectedIndex + 1);
        } else {
            nextStep.mandatory = true;
        }

        if (nextStep == null) {
            return;
        }

        if (!nextStep.preCall) {
            this.next();
            this.disabled = false;
        } else {
            nextStep.preCall().subscribe(
                () => {
                    this.next();
                    this.disabled = false;
                },
                error => {
                    this.next();
                    this.disabled = false;
                },
            );
        }
    }

    public previousStep(): void {
        let nextStep = this.upscoreSteps.get(this.selectedIndex - 1);

        if (nextStep == null) {
            return;
        }

        let nextIndex: number = this.selectedIndex - 1;
        while (nextStep && !nextStep.mandatory) {
            nextStep = this.upscoreSteps.get(nextIndex - 1);
            nextIndex--;
        }
        if (nextStep == null) {
            return;
        }

        if (this.upscoreSteps.get(nextIndex)?.preCall) {
            this.upscoreSteps
                .get(nextIndex)
                ?.preCall()
                .subscribe({
                    next: () => {
                        this.disabled = false;
                        this.selectedIndex = nextIndex;
                    },
                    error: () => {
                        this.disabled = false;
                    },
                });
        } else {
            this.selectedIndex = nextIndex;
        }
    }

    public nextStepWithCheck(skipNext = false): void {
        if (!this.upscoreSelected.postCall) {
            this.preCallCheck(skipNext);
        } else {
            this.disabled = true;
            this.upscoreSelected.postCall().subscribe({
                next: value => {
                    if (value) {
                        this.preCallCheck(skipNext);
                    }
                },
                error: () => {
                    this.disabled = false;
                },
            });
        }
    }

    /**
     * Jump to step
     * @param index
     */
    public jumpToStep(index: number): void {
        if (
            this.selected == null ||
            (this.selected.stepControl.valid === false && this.selectedIndex < index)
        ) {
            return;
        }

        if (!this.upscoreSelected.postCall) {
            this._jumpToStep(index);
        } else {
            this.disabled = true;
            this.upscoreSelected.postCall().subscribe({
                next: value => {
                    if (value) {
                        this._jumpToStep(index);
                    } else {
                        this.disabled = false;
                    }
                },
                error: () => {
                    this.disabled = false;
                },
            });
        }
    }

    /**
     * Reset steps
     */
    public resetSteps(): void {
        this.reset();
        this.stepsReset.emit();
    }

    private _jumpToStep(index: number): void {
        const step = this.upscoreSteps.get(index);

        if (step == null) {
            return;
        }

        if (!step.preCall) {
            this.disabled = false;
            this.selectedIndex = index;
        } else {
            this.disabled = true;
            step.preCall().subscribe({
                next: () => {
                    this.disabled = false;
                    this.selectedIndex = index;
                },
                error: () => {
                    this.next();
                    this.disabled = false;
                },
            });
        }
    }
}
