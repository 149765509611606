import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

import { NumberUnit } from '@shared/utils';

import { DistributionTableComponentEntry } from '../../interfaces/distribution-table-component-entry.interface';
import { TopicTableComponentEntry } from '../../interfaces/topic-table-component-entry.interface';
import { BaseKpiCardComponent } from '../../models/base-kpi-card.model';

@Component({
    selector: 'data-card',
    templateUrl: './data-card.component.html',
    styleUrls: ['./data-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataCardComponent extends BaseKpiCardComponent implements OnChanges {
    @Input() public yearly!: number;
    @Input() public daily?: number;
    @Input() public baselineYearly?: number | null;
    @Input() public baselineDaily?: number | null;
    @Input() public title!: string;
    @Input() public barText!: string;
    @Input() public unit!: NumberUnit | string;
    @Input() public digitsInfo?: string;
    @Input() public distributionTableComponentEntries?: DistributionTableComponentEntry[];
    @Input() public topicTableComponentEntries?: TopicTableComponentEntry[];
    @Input() public showUnavailable = false;
    @Input() public panelOpenState = false;
    @Input() public hasMenu = true;
    @Input() public moreInfoText?: string;
    @Input() public moreInfoLink?: string;
    @Input() public chartData: {
        labels: string[];
        datasets: { data: number[] }[];
    };

    @Output() public panelOpenStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public barDistribution?: Array<{ width: number; color: string }>;
    public distributionByMode = true;

    @Input()
    public set barDistributionInput(distributions: Array<{ width: number; color: string }>) {
        this.barDistribution = distributions.filter(distribution => {
            return distribution.width === 0 ? undefined : distribution;
        });
    }

    /**
     * Angular Lifecycle
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes['distributionTableComponentEntries'] &&
                !changes['distributionTableComponentEntries'].isFirstChange()) ||
            (changes['barDistribution'] && !changes['barDistribution'].isFirstChange())
        ) {
            this.changes.next();
        }
    }

    /**
     * Method to toggle topic table component
     */
    public toggleTopicTableComponent(): void {
        this.changes.next();
        this.panelOpenStateChange.emit(this.panelOpenState);
    }
}
