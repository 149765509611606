/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuditProgress } from '../models/audit-progress';
import { EmailInfo } from '../models/email-info';
import { EmployeeUploadProgress } from '../models/employee-upload-progress';
import { EmployeeUploadTask } from '../models/employee-upload-task';
import { MobilityAuditTask } from '../models/mobility-audit-task';
import { ModeEstimationTask } from '../models/mode-estimation-task';
import { PackageTask } from '../models/package-task';
import { TaskDeleted } from '../models/task-deleted';
import { TaskInfo } from '../models/task-info';
import { TestTaskDefinition } from '../models/test-task-definition';
import { TestTaskProgress } from '../models/test-task-progress';


/**
 * Operations related to tasks. See external documentation for information on async APIs provided over websockets.
 */
@Injectable({
  providedIn: 'root',
})
export class TasksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation notifyOnFinish
   */
  static readonly NotifyOnFinishPath = '/api/v1/tasks/notify/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notifyOnFinish()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notifyOnFinish$Response(params: {
    taskId: string;
    context?: HttpContext
    body: EmailInfo
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.NotifyOnFinishPath, 'post');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notifyOnFinish$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notifyOnFinish(params: {
    taskId: string;
    context?: HttpContext
    body: EmailInfo
  }
): Observable<void> {

    return this.notifyOnFinish$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTaskInfo
   */
  static readonly GetTaskInfoPath = '/api/v1/tasks/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskInfo$Response(params: {
    taskId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TaskInfo>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTaskInfoPath, 'get');
    if (params) {
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaskInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskInfo(params: {
    taskId: string;
    context?: HttpContext
  }
): Observable<TaskInfo> {

    return this.getTaskInfo$Response(params).pipe(
      map((r: StrictHttpResponse<TaskInfo>) => r.body as TaskInfo)
    );
  }

  /**
   * Path part for operation cancelTask
   */
  static readonly CancelTaskPath = '/api/v1/tasks/{taskId}';

  /**
   * Remove a task and cancel it if necessary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelTask$Response(params: {
    taskId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.CancelTaskPath, 'delete');
    if (params) {
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Remove a task and cancel it if necessary.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancelTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelTask(params: {
    taskId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.cancelTask$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getMyTasks
   */
  static readonly GetMyTasksPath = '/api/v1/tasks/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyTasks$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<TaskInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetMyTasksPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TaskInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMyTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyTasks(params?: {
    context?: HttpContext
  }
): Observable<Array<TaskInfo>> {

    return this.getMyTasks$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TaskInfo>>) => r.body as Array<TaskInfo>)
    );
  }

  /**
   * Path part for operation getTaskResult
   */
  static readonly GetTaskResultPath = '/api/v1/tasks/result/{taskId}';

  /**
   * Get the result returned from the task or an error.
   *
   * If the task is completed successfully, the result of it is returned.
   *             |
   *             |If the task failed, this response will be the same error as if the task failed in a synchronous calculation. 
   *             |If the task is still ongoing a 400 response with a TaskResultUnavailable exception is thrown, where the `reason` is `IN_PROGRESS`.
   *             |If the task was cancelled a 400 response with a TaskResultUnavailable exception is thrown, where the `reason` is `CANCELLED`
   *             |If the task does not exist, a 404 response with a TaskNotFound exception is thrown.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskResult$Response(params: {
    taskId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTaskResultPath, 'get');
    if (params) {
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get the result returned from the task or an error.
   *
   * If the task is completed successfully, the result of it is returned.
   *             |
   *             |If the task failed, this response will be the same error as if the task failed in a synchronous calculation. 
   *             |If the task is still ongoing a 400 response with a TaskResultUnavailable exception is thrown, where the `reason` is `IN_PROGRESS`.
   *             |If the task was cancelled a 400 response with a TaskResultUnavailable exception is thrown, where the `reason` is `CANCELLED`
   *             |If the task does not exist, a 404 response with a TaskNotFound exception is thrown.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaskResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskResult(params: {
    taskId: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getTaskResult$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getTaskProgress
   */
  static readonly GetTaskProgressPath = '/api/v1/tasks/progress/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskProgress()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskProgress$Response(params: {
    taskId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<(AuditProgress | EmployeeUploadProgress | TaskDeleted | TestTaskProgress)>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTaskProgressPath, 'get');
    if (params) {
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(AuditProgress | EmployeeUploadProgress | TaskDeleted | TestTaskProgress)>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaskProgress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskProgress(params: {
    taskId: string;
    context?: HttpContext
  }
): Observable<(AuditProgress | EmployeeUploadProgress | TaskDeleted | TestTaskProgress)> {

    return this.getTaskProgress$Response(params).pipe(
      map((r: StrictHttpResponse<(AuditProgress | EmployeeUploadProgress | TaskDeleted | TestTaskProgress)>) => r.body as (AuditProgress | EmployeeUploadProgress | TaskDeleted | TestTaskProgress))
    );
  }

  /**
   * Path part for operation getTasksForLocation
   */
  static readonly GetTasksForLocationPath = '/api/v1/tasks/location/{locationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTasksForLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTasksForLocation$Response(params: {
    locationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<TaskInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTasksForLocationPath, 'get');
    if (params) {
      rb.path('locationId', params.locationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TaskInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTasksForLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTasksForLocation(params: {
    locationId: number;
    context?: HttpContext
  }
): Observable<Array<TaskInfo>> {

    return this.getTasksForLocation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TaskInfo>>) => r.body as Array<TaskInfo>)
    );
  }

  /**
   * Path part for operation getTaskDefinition
   */
  static readonly GetTaskDefinitionPath = '/api/v1/tasks/definition/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskDefinition()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskDefinition$Response(params: {
    taskId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<(EmployeeUploadTask | MobilityAuditTask | ModeEstimationTask | PackageTask | TestTaskDefinition)>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTaskDefinitionPath, 'get');
    if (params) {
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(EmployeeUploadTask | MobilityAuditTask | ModeEstimationTask | PackageTask | TestTaskDefinition)>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaskDefinition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskDefinition(params: {
    taskId: string;
    context?: HttpContext
  }
): Observable<(EmployeeUploadTask | MobilityAuditTask | ModeEstimationTask | PackageTask | TestTaskDefinition)> {

    return this.getTaskDefinition$Response(params).pipe(
      map((r: StrictHttpResponse<(EmployeeUploadTask | MobilityAuditTask | ModeEstimationTask | PackageTask | TestTaskDefinition)>) => r.body as (EmployeeUploadTask | MobilityAuditTask | ModeEstimationTask | PackageTask | TestTaskDefinition))
    );
  }

}
