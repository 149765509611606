import { Pipe, PipeTransform } from '@angular/core';

import { TaskDefinition } from '@upscore-mobility-audit/api';

@Pipe({
    name: 'taskDefinitionHeader',
    standalone: true,
})
export class TaskDefinitionHeaderPipe implements PipeTransform {
    transform(value: TaskDefinition): string {
        switch (value.type) {
            case 'MobilityAuditTask':
                return $localize`:@@calcAudit:Calculating Mobility Audit`;
            case 'EmployeeUploadTask':
                return $localize`:@@uploadingEmployees:Uploading Employees`;
            case 'ModeEstimationTask':
                return $localize`:@@estimatingModes:Estimating Modes`;
            case 'PackageTask':
                return $localize`:@@calculatingPackages:Calculating Packages`;
            default:
                return $localize`:@@taskInProgress:Task in Progress`;
        }
    }
}
