import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { Entrance } from '@upscore-mobility-audit/api';
import { UploadFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/upload-form-group.interface';

@Component({
    selector: 'estimate-employee-selection',
    templateUrl: './estimate-employee-selection.component.html',
    styleUrls: ['./estimate-employee-selection.component.scss'],
})
export class EstimateEmployeeSelectionComponent implements OnInit, OnDestroy {
    @Input() public parentFormGroup: FormGroup<UploadFormGroup>;
    @Input() public entrances: Entrance[];

    public ngOnInit(): void {
        this.parentFormGroup.controls.employeeCount.setValidators(
            Validators.compose([Validators.min(10), Validators.max(10000)]),
        );
        this.parentFormGroup.controls.employeeCount.updateValueAndValidity();
    }

    public ngOnDestroy(): void {
        this.parentFormGroup.controls.employeeCount.clearValidators();
        this.parentFormGroup.controls.employeeCount.setValue(null);
        this.parentFormGroup.controls.employeeCount.updateValueAndValidity();
    }
}
