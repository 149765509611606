<div [formGroup]="parentFormGroup">
    <mat-form-field appearance="outline">
        <mat-label>{{ label }}</mat-label>
        <input #addressSearch [placeholder]="placeholder" [required]="required" matInput />

        @if (addressSearch.value) {
            <button (click)="addressSearch.value = ''" mat-icon-button matSuffix type="button">
                <mat-icon>close</mat-icon>
            </button>
        }
    </mat-form-field>
</div>
