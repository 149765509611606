<ng-container *ngIf="scenarioKpiService.selectedMobilityScenario$ | async as mobilityScore">
    <ng-container *ngIf="optimumScore | async as optimumMobilityScore">
        <ng-container *ngIf="currentScore | async as currentMobilityScore">
            <div class="row">
                <export-card-wrapper
                    (delete)="deleteCard(rowElement.card.id)"
                    (positionChanged)="positionChanged($event)"
                    *ngFor="let rowElement of rows[0]"
                    [cardId]="rowElement.card.id"
                    [currentPage]="currentPage"
                    [deleteButton]="rowElement.card.type === 'Text'"
                    [pageLength]="pages[currentPage].length"
                    [position]="rowElement.index">
                    <ng-container [ngSwitch]="rowElement.card.type" body>
                        <export-text-card
                            (cardChanged)="textCardChanged($event, rowElement.card)"
                            *ngSwitchCase="translations.text"
                            [heading]="rowElement.card.heading ?? ''"
                            [text]="rowElement.card.text ?? ''">
                        </export-text-card>
                        <ng-container *ngIf="userDataService.isUserType$('PREMIUM') | async">
                            <ng-container
                                *ngIf="currentMobilityScore.scoreType === 'MobilityScore'">
                                <mobility-score-card
                                    *ngSwitchCase="kpiCard.MOBILITY_SCORE.title"
                                    [hasMenu]="false"
                                    [mobilityScore]="currentMobilityScore"></mobility-score-card>
                            </ng-container>
                            <single-value-card
                                *ngSwitchCase="kpiCard.CAR_OCCUPANCY_STATUS_QUO.title"
                                [current]="
                                    ((currentScore | async)?.mobilityStats?.carPassenger || 0) /
                                        ((currentScore | async)?.mobilityStats?.carDriver || 1) +
                                        1 || 0
                                "
                                [hasMenu]="false"
                                [text]="statusQuo.translations.avgPerVehicleText"
                                [title]="kpiCard.CAR_OCCUPANCY_STATUS_QUO.title"
                                [unit]="statusQuo.translations.people"></single-value-card>
                            <modal-split-card
                                *ngSwitchCase="kpiCard.MODAL_SPLIT_STATUS_QUO.title"
                                [employeeCount]="
                                    (employeesDataService.employees$ | async)?.length ?? 0
                                "
                                [hasMenu]="false"
                                [mobilityStats]="(currentScore | async)?.mobilityStats"
                                [title]="kpiCard.MODAL_SPLIT_STATUS_QUO.title"></modal-split-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.CO2_EMISSIONS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.CO2_EMISSIONS_STATUS_QUO.title"
                                [barDistributionInput]="statusQuo.co2BarDistribution"
                                [barText]="statusQuo.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    statusQuo.co2DistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.CO2_EMISSIONS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.CO2_EMISSIONS_STATUS_QUO.title"
                                [topicTableComponentEntries]="statusQuo.co2TopicEntries"
                                [unit]="numberUnit.WEIGHT"
                                [yearly]="
                                    (scenarioKpiService.selectedMobilityScenario$ | async)
                                        ?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYER_COSTS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYER_COSTS_STATUS_QUO.title"
                                [barText]="statusQuo.translations.modalSplitText"
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYER_COSTS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.EMPLOYER_COSTS_STATUS_QUO.title"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (currentScore | async)?.yearlyEmployerCosts ?? 0
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title"
                                [barDistributionInput]="statusQuo.employeeCostsBarDistribution"
                                [barText]="statusQuo.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    statusQuo.employeeCostsDistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title"
                                [topicTableComponentEntries]="statusQuo.employeeCostsTopicEntries"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (scenarioKpiService.selectedMobilityScenario$ | async)
                                        ?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.TRAVEL_DISTANCE_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.TRAVEL_DISTANCE_STATUS_QUO.title"
                                [barDistributionInput]="statusQuo.tripDistanceBarDistribution"
                                [barText]="statusQuo.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    statusQuo.tripDistanceDistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.TRAVEL_DISTANCE_STATUS_QUO.title)
                                        ?.panelOpen === true
                                "
                                [title]="kpiCard.TRAVEL_DISTANCE_STATUS_QUO.title"
                                [topicTableComponentEntries]="statusQuo.tripDistanceTopicEntries"
                                [unit]="numberUnit.DISTANCE"
                                [yearly]="
                                    (scenarioKpiService.selectedMobilityScenario$ | async)
                                        ?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.TRAVEL_TIME_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.TRAVEL_TIME_STATUS_QUO.title"
                                [barDistributionInput]="statusQuo.tripDurationBarDistribution"
                                [barText]="statusQuo.translations.modalSplitText"
                                [digitsInfo]="'1.0-0'"
                                [distributionTableComponentEntries]="
                                    statusQuo.tripDurationDistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.TRAVEL_TIME_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.TRAVEL_TIME_STATUS_QUO.title"
                                [topicTableComponentEntries]="statusQuo.tripDurationTopicEntries"
                                [unit]="'h'"
                                [yearly]="
                                    (scenarioKpiService.selectedMobilityScenario$ | async)
                                        ?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                            <topic-table-card
                                *ngSwitchCase="kpiCard.AVG_PER_EMPLOYEE_STATUS_QUO.title"
                                [entries]="statusQuo.averageEmployeeTopicEntries"
                                [hasMenu]="false"
                                [title]="
                                    kpiCard.AVG_PER_EMPLOYEE_STATUS_QUO.title
                                "></topic-table-card>
                            <topic-table-card
                                *ngSwitchCase="kpiCard.CARPOOLING_STATUS_QUO.title"
                                [entries]="statusQuo.carpoolingTopicEntries"
                                [hasMenu]="false"
                                [title]="kpiCard.CARPOOLING_STATUS_QUO.title"></topic-table-card>
                            <modal-split-card
                                *ngSwitchCase="kpiCard.MODAL_SPLIT_OPTIMUM.title"
                                [employeeCount]="
                                    (employeesDataService.employees$ | async)?.length ?? 0
                                "
                                [hasMenu]="false"
                                [mobilityStats]="(optimumScore | async)?.mobilityStats"
                                [baselineMobilityStats]="(currentScore | async)?.mobilityStats"
                                [title]="kpiCard.MODAL_SPLIT_OPTIMUM.title"></modal-split-card>
                            <single-value-card
                                *ngSwitchCase="kpiCard.CAR_OCCUPANCY_OPTIMUM.title"
                                [current]="
                                    ((currentScore | async)?.mobilityStats?.carPassenger || 0) /
                                        ((currentScore | async)?.mobilityStats?.carDriver || 1) +
                                        1 || 0
                                "
                                [hasMenu]="false"
                                [baseline]="
                                    ((scenarioKpiService.baselineMobilityScenario$ | async)
                                        ?.mobilityStats?.carPassenger || 0) /
                                        ((scenarioKpiService.baselineMobilityScenario$ | async)
                                            ?.mobilityStats?.carDriver || 1) +
                                        1 || 0
                                "
                                [text]="optimum.translations.avgPerVehicleText"
                                [title]="kpiCard.CAR_OCCUPANCY_OPTIMUM.title"
                                [unit]="optimum.translations.people"></single-value-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.CO2_EMISSIONS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.CO2_EMISSIONS_OPTIMUM.title"
                                [barDistributionInput]="optimum.co2BarDistribution"
                                [barText]="optimum.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    optimum.co2DistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.CO2_EMISSIONS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="
                                    (scenarioKpiService.baselineMobilityScenario$ | async)
                                        ?.yearlyTripDistance | arraySum
                                "
                                [title]="kpiCard.CO2_EMISSIONS_OPTIMUM.title"
                                [topicTableComponentEntries]="optimum.co2TopicEntries"
                                [unit]="numberUnit.WEIGHT"
                                [yearly]="
                                    (scenarioKpiService.selectedMobilityScenario$ | async)
                                        ?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYER_COSTS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYER_COSTS_OPTIMUM.title"
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYER_COSTS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="(currentScore | async)?.yearlyEmployerCosts"
                                [title]="kpiCard.EMPLOYER_COSTS_OPTIMUM.title"
                                [unit]="numberUnit.MONEY"
                                [yearly]="(optimumScore | async)?.yearlyEmployerCosts"></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYEE_COSTS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYEE_COSTS_OPTIMUM.title"
                                [barDistributionInput]="optimum.employeeCostsBarDistribution"
                                [distributionTableComponentEntries]="
                                    optimum.employeeCostsDistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYEE_COSTS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="
                                    (scenarioKpiService.baselineMobilityScenario$ | async)
                                        ?.yearlyEmployeeCosts | arraySum
                                "
                                [title]="kpiCard.EMPLOYEE_COSTS_OPTIMUM.title"
                                [topicTableComponentEntries]="optimum.employeeCostsTopicEntries"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (currentScore | async)?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                            <ng-container *ngIf="mobilityScore.scoreType === 'MobilityScore'">
                                <data-card
                                    *ngSwitchCase="kpiCard.TRAVEL_DISTANCE_OPTIMUM.title"
                                    (panelOpenStateChange)="
                                        panelOpenStateChanged(
                                            $event,
                                            kpiCard.TRAVEL_DISTANCE_OPTIMUM.title
                                        )
                                    "
                                    [barDistributionInput]="optimum.tripDistanceBarDistribution"
                                    [distributionTableComponentEntries]="
                                        optimum.tripDistanceDistributionTableComponentEntries
                                    "
                                    [hasMenu]="false"
                                    [panelOpenState]="
                                        findCard(kpiCard.TRAVEL_DISTANCE_OPTIMUM.title)
                                            ?.panelOpen === true
                                    "
                                    [baselineYearly]="
                                        (scenarioKpiService.baselineMobilityScenario$ | async)
                                            ?.yearlyTripDistance | arraySum
                                    "
                                    [title]="kpiCard.TRAVEL_DISTANCE_OPTIMUM.title"
                                    [topicTableComponentEntries]="optimum.tripDistanceTopicEntries"
                                    [unit]="numberUnit.DISTANCE"
                                    [yearly]="
                                        mobilityScore.yearlyTripDistance | arraySum
                                    "></data-card>
                            </ng-container>

                            <ng-container
                                *ngIf="optimumMobilityScore.scoreType === 'MobilityScore'">
                                <data-card
                                    (panelOpenStateChange)="
                                        panelOpenStateChanged(
                                            $event,
                                            kpiCard.TRAVEL_TIME_OPTIMUM.title
                                        )
                                    "
                                    *ngSwitchCase="kpiCard.TRAVEL_TIME_OPTIMUM.title"
                                    [barDistributionInput]="optimum.tripDurationBarDistribution"
                                    [digitsInfo]="'1.0-0'"
                                    [distributionTableComponentEntries]="
                                        optimum.tripDurationDistributionTableComponentEntries
                                    "
                                    [hasMenu]="false"
                                    [panelOpenState]="
                                        findCard(kpiCard.TRAVEL_TIME_OPTIMUM.title)?.panelOpen ===
                                        true
                                    "
                                    [baselineYearly]="
                                        (scenarioKpiService.baselineMobilityScenario$ | async)
                                            ?.yearlyTripDuration | arraySum
                                    "
                                    [title]="kpiCard.TRAVEL_TIME_OPTIMUM.title"
                                    [topicTableComponentEntries]="optimum.tripDurationTopicEntries"
                                    [unit]="'h'"
                                    [yearly]="
                                        optimumMobilityScore.yearlyTripDuration | arraySum
                                    "></data-card>
                            </ng-container>
                            <topic-table-card
                                *ngSwitchCase="kpiCard.AVG_PER_EMPLOYEE_OPTIMUM.title"
                                [entries]="optimum.averageEmployeeTopicEntries"
                                [hasMenu]="false"
                                [title]="kpiCard.AVG_PER_EMPLOYEE_OPTIMUM.title"></topic-table-card>
                            <topic-table-card
                                *ngSwitchCase="kpiCard.CARPOOLING_OPTIMUM.title"
                                [entries]="optimum.carpoolingTopicEntries"
                                [hasMenu]="false"
                                [title]="kpiCard.CARPOOLING_OPTIMUM.title"></topic-table-card>
                        </ng-container>
                        <ng-container *ngIf="userDataService.isUserType$('LIGHT') | async">
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title"
                                [barDistributionInput]="statusQuo.employeeCostsBarDistribution"
                                [barText]="statusQuo.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    statusQuo.employeeCostsDistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title"
                                [topicTableComponentEntries]="statusQuo.employeeCostsTopicEntries"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (currentScore | async)?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.CO2_EMISSIONS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.CO2_EMISSIONS_STATUS_QUO.title"
                                [barDistributionInput]="statusQuo.co2BarDistribution"
                                [barText]="statusQuo.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    statusQuo.co2DistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.CO2_EMISSIONS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.CO2_EMISSIONS_STATUS_QUO.title"
                                [topicTableComponentEntries]="statusQuo.co2TopicEntries"
                                [unit]="numberUnit.WEIGHT"
                                [yearly]="
                                    (currentScore | async)?.yearlyEmissions | arraySum
                                "></data-card>
                            <modal-split-card
                                *ngSwitchCase="kpiCard.MODAL_SPLIT_STATUS_QUO.title"
                                [employeeCount]="
                                    (employeesDataService.employees$ | async)?.length ?? 0
                                "
                                [hasMenu]="false"
                                [mobilityStats]="(currentScore | async)?.mobilityStats"
                                [title]="kpiCard.MODAL_SPLIT_STATUS_QUO.title"></modal-split-card>
                            <single-value-card
                                *ngSwitchCase="kpiCard.CAR_OCCUPANCY_STATUS_QUO.title"
                                [current]="
                                    ((currentScore | async)?.mobilityStats?.carPassenger || 0) /
                                        ((currentScore | async)?.mobilityStats?.carDriver || 1) +
                                        1 || 0
                                "
                                [hasMenu]="false"
                                [text]="statusQuo.translations.avgPerVehicleText"
                                [title]="kpiCard.CAR_OCCUPANCY_STATUS_QUO.title"
                                [unit]="statusQuo.translations.people"></single-value-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYER_COSTS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYER_COSTS_STATUS_QUO.title"
                                [barText]="statusQuo.translations.modalSplitText"
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYER_COSTS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.EMPLOYER_COSTS_STATUS_QUO.title"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (this.currentScore | async)?.yearlyEmployerCosts
                                "></data-card>
                            <modal-split-card
                                *ngSwitchCase="kpiCard.MODAL_SPLIT_OPTIMUM.title"
                                [employeeCount]="
                                    (employeesDataService.employees$ | async)?.length ?? 0
                                "
                                [hasMenu]="false"
                                [mobilityStats]="(optimumScore | async)?.mobilityStats"
                                [baselineMobilityStats]="(currentScore | async)?.mobilityStats"
                                [title]="kpiCard.MODAL_SPLIT_OPTIMUM.title"></modal-split-card>
                            <single-value-card
                                *ngSwitchCase="kpiCard.CAR_OCCUPANCY_OPTIMUM.title"
                                [current]="
                                    ((optimumScore | async)?.mobilityStats?.carPassenger || 0) /
                                        ((optimumScore | async)?.mobilityStats?.carDriver || 1) +
                                        1 || 0
                                "
                                [hasMenu]="false"
                                [baseline]="
                                    (companyLocationDataService.companyLocation().mobilityStats
                                        ?.carPassenger || 0) /
                                        (companyLocationDataService.companyLocation().mobilityStats
                                            ?.carDriver || 1) +
                                        1 || 0
                                "
                                [text]="optimum.translations.carOccupancyAverageText"
                                [title]="kpiCard.CAR_OCCUPANCY_OPTIMUM.title"
                                [unit]="optimum.translations.people"></single-value-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.CO2_EMISSIONS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.CO2_EMISSIONS_OPTIMUM.title"
                                [barDistributionInput]="optimum.co2BarDistribution"
                                [barText]="optimum.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    optimum.co2DistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.CO2_EMISSIONS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="
                                    (currentScore | async)?.yearlyEmissions | arraySum
                                "
                                [title]="kpiCard.CO2_EMISSIONS_OPTIMUM.title"
                                [topicTableComponentEntries]="optimum.co2TopicEntries"
                                [unit]="numberUnit.WEIGHT"
                                [yearly]="
                                    (optimumScore | async)?.yearlyEmissions | arraySum
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYER_COSTS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYER_COSTS_OPTIMUM.title"
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYER_COSTS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="(currentScore | async)?.yearlyEmployerCosts"
                                [title]="kpiCard.EMPLOYER_COSTS_OPTIMUM.title"
                                [unit]="numberUnit.MONEY"
                                [yearly]="(optimumScore | async)?.yearlyEmployerCosts"></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYEE_COSTS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYEE_COSTS_OPTIMUM.title"
                                [barDistributionInput]="optimum.employeeCostsBarDistribution"
                                [distributionTableComponentEntries]="
                                    optimum.employeeCostsDistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYEE_COSTS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="
                                    (currentScore | async)?.yearlyEmployeeCosts | arraySum
                                "
                                [title]="kpiCard.EMPLOYEE_COSTS_OPTIMUM.title"
                                [topicTableComponentEntries]="optimum.employeeCostsTopicEntries"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (optimumScore | async)?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                        </ng-container>
                    </ng-container>
                </export-card-wrapper>
            </div>
            <div class="row">
                <export-card-wrapper
                    (delete)="deleteCard(rowElement.card.id)"
                    (positionChanged)="positionChanged($event)"
                    *ngFor="let rowElement of rows[1]"
                    [cardId]="rowElement.card.id"
                    [currentPage]="currentPage"
                    [deleteButton]="rowElement.card.type === 'Text'"
                    [pageLength]="pages[currentPage].length"
                    [position]="rowElement.index">
                    <ng-container [ngSwitch]="rowElement.card.type" body>
                        <export-text-card
                            (cardChanged)="textCardChanged($event, rowElement.card)"
                            *ngSwitchCase="translations.text"
                            [heading]="rowElement.card.heading"
                            [text]="rowElement.card.text">
                        </export-text-card>
                        <ng-container *ngIf="userDataService.isUserType$('PREMIUM') | async">
                            <ng-container
                                *ngIf="currentMobilityScore.scoreType === 'MobilityScore'">
                                <mobility-score-card
                                    *ngSwitchCase="kpiCard.MOBILITY_SCORE.title"
                                    [hasMenu]="false"
                                    [mobilityScore]="currentMobilityScore"></mobility-score-card>
                            </ng-container>
                            <single-value-card
                                *ngSwitchCase="kpiCard.CAR_OCCUPANCY_STATUS_QUO.title"
                                [current]="
                                    ((currentScore | async)?.mobilityStats?.carPassenger || 0) /
                                        ((currentScore | async)?.mobilityStats?.carDriver || 1) +
                                        1 || 0
                                "
                                [hasMenu]="false"
                                [text]="statusQuo.translations.avgPerVehicleText"
                                [title]="kpiCard.CAR_OCCUPANCY_STATUS_QUO.title"
                                [unit]="statusQuo.translations.people"></single-value-card>
                            <modal-split-card
                                *ngSwitchCase="kpiCard.MODAL_SPLIT_STATUS_QUO.title"
                                [employeeCount]="(employeesDataService.employees$ | async)?.length"
                                [hasMenu]="false"
                                [mobilityStats]="(currentScore | async)?.mobilityStats"
                                [title]="kpiCard.MODAL_SPLIT_STATUS_QUO.title"></modal-split-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.CO2_EMISSIONS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.CO2_EMISSIONS_STATUS_QUO.title"
                                [barDistributionInput]="statusQuo.co2BarDistribution"
                                [barText]="statusQuo.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    statusQuo.co2DistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.CO2_EMISSIONS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.CO2_EMISSIONS_STATUS_QUO.title"
                                [topicTableComponentEntries]="statusQuo.co2TopicEntries"
                                [unit]="numberUnit.WEIGHT"
                                [yearly]="
                                    (currentScore | async)?.yearlyEmissions | arraySum
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYER_COSTS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYER_COSTS_STATUS_QUO.title"
                                [barText]="statusQuo.translations.modalSplitText"
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYER_COSTS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.EMPLOYER_COSTS_STATUS_QUO.title"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (this.currentScore | async)?.yearlyEmployerCosts
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title"
                                [barDistributionInput]="statusQuo.employeeCostsBarDistribution"
                                [barText]="statusQuo.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    statusQuo.employeeCostsDistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title"
                                [topicTableComponentEntries]="statusQuo.employeeCostsTopicEntries"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (currentScore | async)?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                            <ng-container
                                *ngIf="currentMobilityScore.scoreType === 'MobilityScore'">
                                <data-card
                                    (panelOpenStateChange)="
                                        panelOpenStateChanged(
                                            $event,
                                            kpiCard.TRAVEL_DISTANCE_STATUS_QUO.title
                                        )
                                    "
                                    *ngSwitchCase="kpiCard.TRAVEL_DISTANCE_STATUS_QUO.title"
                                    [barDistributionInput]="statusQuo.tripDistanceBarDistribution"
                                    [barText]="statusQuo.translations.modalSplitText"
                                    [distributionTableComponentEntries]="
                                        statusQuo.tripDistanceDistributionTableComponentEntries
                                    "
                                    [hasMenu]="false"
                                    [panelOpenState]="
                                        findCard(kpiCard.TRAVEL_DISTANCE_STATUS_QUO.title)
                                            ?.panelOpen === true
                                    "
                                    [title]="kpiCard.TRAVEL_DISTANCE_STATUS_QUO.title"
                                    [topicTableComponentEntries]="
                                        statusQuo.tripDistanceTopicEntries
                                    "
                                    [unit]="numberUnit.DISTANCE"
                                    [yearly]="
                                        currentMobilityScore.yearlyTripDistance | arraySum
                                    "></data-card>
                                <data-card
                                    (panelOpenStateChange)="
                                        panelOpenStateChanged(
                                            $event,
                                            kpiCard.TRAVEL_TIME_STATUS_QUO.title
                                        )
                                    "
                                    *ngSwitchCase="kpiCard.TRAVEL_TIME_STATUS_QUO.title"
                                    [barDistributionInput]="statusQuo.tripDurationBarDistribution"
                                    [barText]="statusQuo.translations.modalSplitText"
                                    [digitsInfo]="'1.0-0'"
                                    [distributionTableComponentEntries]="
                                        statusQuo.tripDurationDistributionTableComponentEntries
                                    "
                                    [hasMenu]="false"
                                    [panelOpenState]="
                                        findCard(kpiCard.TRAVEL_TIME_STATUS_QUO.title)
                                            ?.panelOpen === true
                                    "
                                    [title]="kpiCard.TRAVEL_TIME_STATUS_QUO.title"
                                    [topicTableComponentEntries]="
                                        statusQuo.tripDurationTopicEntries
                                    "
                                    [unit]="'h'"
                                    [yearly]="
                                        currentMobilityScore?.yearlyTripDuration | arraySum
                                    "></data-card>
                            </ng-container>
                            <topic-table-card
                                *ngSwitchCase="kpiCard.AVG_PER_EMPLOYEE_STATUS_QUO.title"
                                [entries]="statusQuo.averageEmployeeTopicEntries"
                                [hasMenu]="false"
                                [title]="
                                    kpiCard.AVG_PER_EMPLOYEE_STATUS_QUO.title
                                "></topic-table-card>
                            <topic-table-card
                                *ngSwitchCase="kpiCard.CARPOOLING_STATUS_QUO.title"
                                [entries]="statusQuo.carpoolingTopicEntries"
                                [hasMenu]="false"
                                [title]="kpiCard.CARPOOLING_STATUS_QUO.title"></topic-table-card>
                            <modal-split-card
                                *ngSwitchCase="kpiCard.MODAL_SPLIT_OPTIMUM.title"
                                [employeeCount]="(employeesDataService.employees$ | async)?.length"
                                [hasMenu]="false"
                                [mobilityStats]="(optimumScore | async)?.mobilityStats"
                                [baselineMobilityStats]="(currentScore | async)?.mobilityStats"
                                [title]="kpiCard.MODAL_SPLIT_OPTIMUM.title"></modal-split-card>
                            <single-value-card
                                *ngSwitchCase="kpiCard.CAR_OCCUPANCY_OPTIMUM.title"
                                [current]="
                                    ((optimumScore | async)?.mobilityStats?.carPassenger || 0) /
                                        ((optimumScore | async)?.mobilityStats?.carDriver || 1) +
                                        1 || 0
                                "
                                [hasMenu]="false"
                                [baseline]="
                                    (companyLocationDataService.companyLocation().mobilityStats
                                        ?.carPassenger || 0) /
                                        (companyLocationDataService.companyLocation().mobilityStats
                                            ?.carDriver || 1) +
                                        1 || 0
                                "
                                [text]="optimum.translations.avgPerVehicleText"
                                [title]="kpiCard.CAR_OCCUPANCY_OPTIMUM.title"
                                [unit]="optimum.translations.people"></single-value-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.CO2_EMISSIONS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.CO2_EMISSIONS_OPTIMUM.title"
                                [barDistributionInput]="optimum.co2BarDistribution"
                                [barText]="optimum.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    optimum.co2DistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.CO2_EMISSIONS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="
                                    (currentScore | async)?.yearlyEmissions | arraySum
                                "
                                [title]="kpiCard.CO2_EMISSIONS_OPTIMUM.title"
                                [topicTableComponentEntries]="optimum.co2TopicEntries"
                                [unit]="numberUnit.WEIGHT"
                                [yearly]="
                                    (optimumScore | async)?.yearlyEmissions | arraySum
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYER_COSTS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYER_COSTS_OPTIMUM.title"
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYER_COSTS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="(currentScore | async)?.yearlyEmployerCosts"
                                [title]="kpiCard.EMPLOYER_COSTS_OPTIMUM.title"
                                [unit]="numberUnit.MONEY"
                                [yearly]="(optimumScore | async)?.yearlyEmployerCosts"></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYEE_COSTS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYEE_COSTS_OPTIMUM.title"
                                [barDistributionInput]="optimum.employeeCostsBarDistribution"
                                [distributionTableComponentEntries]="
                                    optimum.employeeCostsDistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYEE_COSTS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="
                                    (currentScore | async)?.yearlyEmployeeCosts | arraySum
                                "
                                [title]="kpiCard.EMPLOYEE_COSTS_OPTIMUM.title"
                                [topicTableComponentEntries]="optimum.employeeCostsTopicEntries"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (optimumScore | async)?.yearlyEmployeeCosts | arraySum
                                "></data-card>

                            <ng-container
                                *ngIf="
                                    currentMobilityScore.scoreType === 'MobilityScore' &&
                                    optimumMobilityScore.scoreType === 'MobilityScore'
                                ">
                                <data-card
                                    (panelOpenStateChange)="
                                        panelOpenStateChanged(
                                            $event,
                                            kpiCard.TRAVEL_DISTANCE_OPTIMUM.title
                                        )
                                    "
                                    *ngSwitchCase="kpiCard.TRAVEL_DISTANCE_OPTIMUM.title"
                                    [barDistributionInput]="optimum.tripDistanceBarDistribution"
                                    [distributionTableComponentEntries]="
                                        optimum.tripDistanceDistributionTableComponentEntries
                                    "
                                    [hasMenu]="false"
                                    [panelOpenState]="
                                        findCard(kpiCard.TRAVEL_DISTANCE_OPTIMUM.title)
                                            ?.panelOpen === true
                                    "
                                    [baselineYearly]="
                                        currentMobilityScore?.yearlyTripDistance | arraySum
                                    "
                                    [title]="kpiCard.TRAVEL_DISTANCE_OPTIMUM.title"
                                    [topicTableComponentEntries]="optimum.tripDistanceTopicEntries"
                                    [unit]="numberUnit.DISTANCE"
                                    [yearly]="
                                        optimumMobilityScore?.yearlyTripDistance | arraySum
                                    "></data-card>
                                <data-card
                                    (panelOpenStateChange)="
                                        panelOpenStateChanged(
                                            $event,
                                            kpiCard.TRAVEL_TIME_OPTIMUM.title
                                        )
                                    "
                                    *ngSwitchCase="kpiCard.TRAVEL_TIME_OPTIMUM.title"
                                    [barDistributionInput]="optimum.tripDurationBarDistribution"
                                    [digitsInfo]="'1.0-0'"
                                    [distributionTableComponentEntries]="
                                        optimum.tripDurationDistributionTableComponentEntries
                                    "
                                    [hasMenu]="false"
                                    [panelOpenState]="
                                        findCard(kpiCard.TRAVEL_TIME_OPTIMUM.title)?.panelOpen ===
                                        true
                                    "
                                    [baselineYearly]="
                                        currentMobilityScore?.yearlyTripDuration | arraySum
                                    "
                                    [title]="kpiCard.TRAVEL_TIME_OPTIMUM.title"
                                    [topicTableComponentEntries]="optimum.tripDurationTopicEntries"
                                    [unit]="'h'"
                                    [yearly]="
                                        optimumMobilityScore?.yearlyTripDuration | arraySum
                                    "></data-card>
                            </ng-container>
                            <topic-table-card
                                *ngSwitchCase="kpiCard.AVG_PER_EMPLOYEE_OPTIMUM.title"
                                [entries]="optimum.averageEmployeeTopicEntries"
                                [hasMenu]="false"
                                [title]="kpiCard.AVG_PER_EMPLOYEE_OPTIMUM.title"></topic-table-card>
                            <topic-table-card
                                *ngSwitchCase="kpiCard.CARPOOLING_OPTIMUM.title"
                                [entries]="optimum.carpoolingTopicEntries"
                                [hasMenu]="false"
                                [title]="kpiCard.CARPOOLING_OPTIMUM.title"></topic-table-card>
                        </ng-container>
                        <ng-container *ngIf="userDataService.isUserType$('LIGHT') | async">
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title"
                                [barDistributionInput]="statusQuo.employeeCostsBarDistribution"
                                [barText]="statusQuo.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    statusQuo.employeeCostsDistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.EMPLOYEE_COSTS_STATUS_QUO.title"
                                [topicTableComponentEntries]="statusQuo.employeeCostsTopicEntries"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (currentScore | async)?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.CO2_EMISSIONS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.CO2_EMISSIONS_STATUS_QUO.title"
                                [barDistributionInput]="statusQuo.co2BarDistribution"
                                [barText]="statusQuo.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    statusQuo.co2DistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.CO2_EMISSIONS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.CO2_EMISSIONS_STATUS_QUO.title"
                                [topicTableComponentEntries]="statusQuo.co2TopicEntries"
                                [unit]="numberUnit.WEIGHT"
                                [yearly]="
                                    (currentScore | async)?.yearlyEmissions | arraySum
                                "></data-card>
                            <modal-split-card
                                *ngSwitchCase="kpiCard.MODAL_SPLIT_STATUS_QUO.title"
                                [employeeCount]="(employeesDataService.employees$ | async)?.length"
                                [hasMenu]="false"
                                [mobilityStats]="(currentScore | async)?.mobilityStats"
                                [title]="kpiCard.MODAL_SPLIT_STATUS_QUO.title"></modal-split-card>
                            <single-value-card
                                *ngSwitchCase="kpiCard.CAR_OCCUPANCY_STATUS_QUO.title"
                                [current]="
                                    ((currentScore | async)?.mobilityStats?.carPassenger || 0) /
                                        ((currentScore | async)?.mobilityStats?.carDriver || 1) +
                                        1 || 0
                                "
                                [hasMenu]="false"
                                [text]="statusQuo.translations.avgPerVehicleText"
                                [title]="kpiCard.CAR_OCCUPANCY_STATUS_QUO.title"
                                [unit]="statusQuo.translations.people"></single-value-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYER_COSTS_STATUS_QUO.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYER_COSTS_STATUS_QUO.title"
                                [barText]="statusQuo.translations.modalSplitText"
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYER_COSTS_STATUS_QUO.title)?.panelOpen ===
                                    true
                                "
                                [title]="kpiCard.EMPLOYER_COSTS_STATUS_QUO.title"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (this.currentScore | async)?.yearlyEmployerCosts
                                "></data-card>
                            <modal-split-card
                                *ngSwitchCase="kpiCard.MODAL_SPLIT_OPTIMUM.title"
                                [employeeCount]="(employeesDataService.employees$ | async)?.length"
                                [hasMenu]="false"
                                [mobilityStats]="(optimumScore | async)?.mobilityStats"
                                [baselineMobilityStats]="(currentScore | async)?.mobilityStats"
                                [title]="kpiCard.MODAL_SPLIT_OPTIMUM.title"></modal-split-card>
                            <single-value-card
                                *ngSwitchCase="kpiCard.CAR_OCCUPANCY_OPTIMUM.title"
                                [current]="
                                    ((optimumScore | async)?.mobilityStats?.carPassenger || 0) /
                                        ((optimumScore | async)?.mobilityStats?.carDriver || 1) +
                                        1 || 0
                                "
                                [hasMenu]="false"
                                [baseline]="
                                    (companyLocationDataService.companyLocation().mobilityStats
                                        ?.carPassenger || 0) /
                                        (companyLocationDataService.companyLocation().mobilityStats
                                            ?.carDriver || 1) +
                                        1 || 0
                                "
                                [text]="optimum.translations.carOccupancyAverageText"
                                [title]="kpiCard.CAR_OCCUPANCY_OPTIMUM.title"
                                [unit]="optimum.translations.people"></single-value-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.CO2_EMISSIONS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.CO2_EMISSIONS_OPTIMUM.title"
                                [barDistributionInput]="optimum.co2BarDistribution"
                                [barText]="optimum.translations.modalSplitText"
                                [distributionTableComponentEntries]="
                                    optimum.co2DistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.CO2_EMISSIONS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="
                                    (currentScore | async)?.yearlyEmissions | arraySum
                                "
                                [title]="kpiCard.CO2_EMISSIONS_OPTIMUM.title"
                                [topicTableComponentEntries]="optimum.co2TopicEntries"
                                [unit]="numberUnit.WEIGHT"
                                [yearly]="
                                    (optimumScore | async)?.yearlyEmissions | arraySum
                                "></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYER_COSTS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYER_COSTS_OPTIMUM.title"
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYER_COSTS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="(currentScore | async)?.yearlyEmployerCosts"
                                [title]="kpiCard.EMPLOYER_COSTS_OPTIMUM.title"
                                [unit]="numberUnit.MONEY"
                                [yearly]="(optimumScore | async)?.yearlyEmployerCosts"></data-card>
                            <data-card
                                (panelOpenStateChange)="
                                    panelOpenStateChanged(
                                        $event,
                                        kpiCard.EMPLOYEE_COSTS_OPTIMUM.title
                                    )
                                "
                                *ngSwitchCase="kpiCard.EMPLOYEE_COSTS_OPTIMUM.title"
                                [barDistributionInput]="optimum.employeeCostsBarDistribution"
                                [distributionTableComponentEntries]="
                                    optimum.employeeCostsDistributionTableComponentEntries
                                "
                                [hasMenu]="false"
                                [panelOpenState]="
                                    findCard(kpiCard.EMPLOYEE_COSTS_OPTIMUM.title)?.panelOpen ===
                                    true
                                "
                                [baselineYearly]="
                                    (currentScore | async)?.yearlyEmployeeCosts | arraySum
                                "
                                [title]="kpiCard.EMPLOYEE_COSTS_OPTIMUM.title"
                                [topicTableComponentEntries]="optimum.employeeCostsTopicEntries"
                                [unit]="numberUnit.MONEY"
                                [yearly]="
                                    (optimumScore | async)?.yearlyEmployeeCosts | arraySum
                                "></data-card>
                        </ng-container>
                    </ng-container>
                </export-card-wrapper>
            </div>
            <div
                (click)="emptyPageClicked.emit()"
                *ngIf="pages[currentPage].length === 0"
                class="empty-page"
                data-html2canvas-ignore>
                <h2 class="empty-page" i18n="@@addKpis">Add KPIs</h2>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
