import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { take, withLatestFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CompanyLocationDataService } from '@upscore-mobility-audit/core/data-services/company-location-data.service';
import { EmployeesDataService } from '@upscore-mobility-audit/core/data-services/employees-data.service';
import { SentryReportService } from '@upscore-mobility-audit/shared/services/sentry-report.service';
import { FeedbackDialogTranslations } from '@upscore-mobility-audit/shared/translations/feedback-dialog-translations';
import { DialogService } from '@upscore-mobility-audit/shared-ui/services/dialog.service';

@Component({
    selector: '[feedback]',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({ opacity: 0 })),
            state('active', style({ opacity: 1 })),
            state('removed', style({ opacity: 0 })),
            transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
            transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
        ]),
    ],
    preserveWhitespaces: false,
})
export class FeedbackComponent extends Toast {
    public informationLink: string;

    public rawMessage:
        | string
        | { [data: string]: unknown }
        | { [data: string]: unknown; code: string };

    public sentryEventId?: string;

    public rawMessageIsJson: boolean;

    public expandRawMessage = false;

    public translations: typeof FeedbackDialogTranslations = FeedbackDialogTranslations;

    public enableReport = false;

    public showDashboardButton: boolean;

    public httpError: HttpErrorResponse | null;

    public closeDialog: () => void;

    public custom?: {
        problematicEmployeeIds: number[];
    };

    constructor(
        protected override toastrService: ToastrService,
        public override toastPackage: ToastPackage,
        private readonly sentryReportService: SentryReportService,
        private router: Router,
        private dialogService: DialogService,
        private employeesDataService: EmployeesDataService,
        private companyLocationDataService: CompanyLocationDataService,
    ) {
        super(toastrService, toastPackage);

        // @ts-expect-error: informationLink not in default ToastPackage
        this.informationLink = toastPackage.config.informationLink;
        // @ts-expect-error: rawMessage not in default ToastPackage
        this.rawMessage = toastPackage.config.rawMessage;
        // @ts-expect-error: rawMessage not in default ToastPackage
        this.enableReport = toastPackage.config.enableReport;
        // @ts-expect-error: showDashboardButton not in default ToastPackage
        this.showDashboardButton = toastPackage.config.showDashboardButton;
        // @ts-expect-error: httpError not in default ToastPackage
        this.httpError = toastPackage.config.httpError;
        // @ts-expect-error: showDashboardButton not in default ToastPackage
        this.closeDialog = toastPackage.config.closeDialog;
        // @ts-expect-error: handleClick not in default ToastPackage
        this.custom = toastPackage.config.custom;

        this.rawMessageIsJson = !(
            typeof this.rawMessage === 'string' || this.rawMessage instanceof String
        );
    }

    @HostListener('mouseenter')
    public onMouseEnter(): void {
        if (this.enableReport) {
            this.expandRawMessage = true;
            this.toastPackage.config.closeButton = true;
            this.toastPackage.config.tapToDismiss = false;
            this.toastPackage.config.disableTimeOut = true;
        }
    }

    public reportError(): void {
        // event_id is added to HTTPErrorResponse in app.module.ts
        if (this.httpError && 'event_id' in this.httpError) {
            this.sentryEventId = this.httpError['event_id'] as string;
        }
        if (!this.sentryEventId) {
            this.sentryEventId = this.sentryReportService.reportError(
                this.httpError != null
                    ? this.httpError
                    : !this.rawMessage
                      ? this.message ?? ''
                      : this.rawMessage,
            );
        }
        this.sentryReportService.reportUserError(this.sentryEventId);
    }

    public onGoToDashboardClick(): void {
        if (this.closeDialog) {
            this.closeDialog();
        }
        void this.router.navigate(['/audits']);
    }

    public openEmployeeEdit(ids: number[]) {
        this.employeesDataService.allEmployees$
            .notNull()
            .pipe(
                filter(emps => emps.length > 0),
                withLatestFrom(this.companyLocationDataService.companyLocation$),
                take(1),
            )
            .subscribe(([employees, companyLocation]) => {
                const employeesToEdit = employees.filter(employee => {
                    return ids.includes(employee.id);
                });

                if (!employeesToEdit || !companyLocation) {
                    return;
                }

                this.dialogService
                    .openViewEditGeoCodedEmployeeDialog(
                        companyLocation,
                        employeesToEdit,
                        this.employeesDataService.departments$.value,
                        undefined,
                        undefined,
                        undefined,
                        true,
                    )
                    .subscribe(data => {
                        if (data.hasChanges) {
                            // TODO retrigger audit reload
                        }
                    });
            });
    }
}
