<div class="dialog-wrapper">
    <div style="margin-bottom: 10px">
        <span class="title">Share Mobility Audit</span>
    </div>
    <p style="margin-bottom: 10px" i18n="@@sharingInfo">
        Sharing a mobility audit creates a snapshot of the current version of the mobility audit.
        The sharing user can login to <a [href]="baseURL" class="url">{{ baseURL }}</a> with his
        account details and view the company. The sharing user can not edit the company or start a
        new calculation, and changes to the company will not be reflected in the shared company
    </p>
    @if (!showAddForm && !showPasswordResetForm) {
        <button (click)="onAddNewUser()" color="primary" mat-raised-button>
            <mat-icon>add</mat-icon>
            Add New User
        </button>
    } @else {
        <button (click)="onCancelAdd()" color="warn" mat-raised-button>
            <mat-icon>close</mat-icon>
            Cancel
        </button>
    }
    @if (showAddForm) {
        <form [formGroup]="newUserFormGroup" style="margin-top: 10px">
            <div class="grid-container flex items-center">
                <mat-form-field
                    appearance="outline"
                    class="full-width-form-field"
                    subscriptSizing="dynamic">
                    <mat-label>Username</mat-label>
                    <input
                        formControlName="username"
                        matInput
                        placeholder="Username"
                        required
                        type="string" />
                </mat-form-field>
                <mat-form-field
                    appearance="outline"
                    class="full-width-form-field"
                    subscriptSizing="dynamic">
                    <mat-label>Name</mat-label>
                    <input formControlName="name" matInput placeholder="Name" type="string" />
                </mat-form-field>
                <mat-form-field
                    appearance="outline"
                    class="full-width-form-field"
                    subscriptSizing="dynamic">
                    <mat-label>Password</mat-label>
                    <input
                        formControlName="password"
                        matInput
                        placeholder="Password"
                        type="string" />
                </mat-form-field>
                <div class="submit-btn">
                    <button
                        (click)="onCreateNewUser()"
                        [disabled]="!newUserFormGroup.valid"
                        color="primary"
                        mat-raised-button
                        style="height: 75%">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    }
    @if (showPasswordResetForm) {
        <form [formGroup]="resetPasswordFormGroup">
            <div class="grid-container-reset">
                <mat-form-field appearance="outline" class="full-width-form-field">
                    <mat-label>New Password</mat-label>
                    <input
                        formControlName="newpassword"
                        matInput
                        placeholder="New Password"
                        type="string" />
                </mat-form-field>
                <div class="submit-btn">
                    <button
                        (click)="onResetPassword()"
                        [disabled]="!resetPasswordFormGroup.valid"
                        color="primary"
                        mat-raised-button
                        style="height: 75%">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    }
    @if (showAddedUserDetails) {
        <div class="user-info-box">
            <div class="user-info-title">
                <span>New User Info</span>
            </div>
            <div>
                <div>
                    <span>Username/Email: {{ addedUserDetails.email }}</span>
                </div>
                <div>
                    <span>Name: {{ addedUserDetails.name }}</span>
                </div>
                <div>
                    <span>Password: {{ addedUserDetails.password }}</span>
                </div>
                <div>
                    <span>Viewable Company: {{ addedUserDetails.viewableCompanyLocationId }}</span>
                </div>
            </div>
        </div>
    }
    @if (tableData.length > 0) {
        <div>
            <upscore-table
                [dataSource]="tableData"
                [columns]="shareMobilityTableColumns"
                [upscoreTableModel]="shareMobilityAuditTableModel"
                (rowDelete)="onRowDelete($any($event))"
                (passwordReset)="onPasswordReset($any($event))"></upscore-table>
        </div>
    }
</div>
