import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { NumberUnit } from '@shared/utils';
import { PercentageChangeComponent } from '@upscore-mobility-audit/kpi/components/percentage-change/percentage-change.component';
import { KpiMeasureComponent } from '@upscore-mobility-audit/shared-ui/components/kpi-measure/kpi-measure.component';

@Component({
    selector: 'kpi-change-header',
    standalone: true,
    imports: [CommonModule, PercentageChangeComponent, KpiMeasureComponent],
    templateUrl: './kpi-change-header.component.html',
    styleUrl: './kpi-change-header.component.scss',
})
export class KpiChangeHeaderComponent {
    @Input() baselineYearly: number;
    @Input() baselineDaily: number;
    @Input() currentYearly: number;
    @Input() currentDaily: number;
    @Input() unit: NumberUnit | string;
    @Input() digitsInfo: string;

    @Input() invertColorScheme = false;

    public showYearly = true;
}
