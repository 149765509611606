/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// TODO fix this
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { EditLocationDialogComponent } from '@upscore-mobility-audit/data-collection/components/employee-details-table/edit-location-dialog/edit-location-dialog.component';
import { EmployeeTableData } from '@upscore-mobility-audit/data-collection/interfaces/employee-table-classes';
import { UpscoreTable } from '@upscore-mobility-audit/shared/interfaces/upscore-table.interface';

import { RowButtonData, RowData, RowDetails } from './upscore-table-classes';
import { UpscoreTableModel } from './upscore-table.model';

@Component({
    selector: 'upscore-table',
    templateUrl: './upscore-table.component.html',
    styleUrls: ['./upscore-table.component.scss'],
})
export class UpscoreTableComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;

    @Input() public columns: UpscoreTable[];

    @Input() public upscoreTableModel: UpscoreTableModel;

    @Input() public locationEditRowIndex: number;

    @Input() public searchEmployeeInfo: number;

    @Input() public dataSource: unknown[];

    @Output() public rowDetails: EventEmitter<RowDetails> = new EventEmitter();

    @Output() public rowButtonClick: EventEmitter<RowButtonData> = new EventEmitter();

    @Output() public readonly rowDelete: EventEmitter<unknown> = new EventEmitter();

    @Output() public readonly passwordReset: EventEmitter<unknown> = new EventEmitter();

    public _dataSource: MatTableDataSource<AbstractControl> =
        new MatTableDataSource<AbstractControl>([]);

    public displayedColumns: string[] = [];

    public searchDropdownVal = '';

    public searchDropdownOptions: UpscoreTable[] = [];

    public objFilterValue = '';

    public tableData: unknown[] = [];

    public originalRowData: RowData;

    public upscoreTableForm: UntypedFormGroup;

    public originalAllData: unknown = null;

    public autocompleteoptions: Observable<unknown[]> = new Observable();

    public startingIndexOfPage: number;

    public endingIndexOfPage: number;

    public editLocationDialogRef: MatDialogRef<EditLocationDialogComponent>;
    public rowButtonDialogData: unknown;

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly fb: UntypedFormBuilder,
        private readonly dialog: MatDialog,
    ) {
        this.upscoreTableForm = this._formBuilder.group({
            upscoreTableRows: this._formBuilder.array([]),
        });
    }

    public ngAfterViewInit(): void {
        this._dataSource.paginator = this.paginator;
        this.displayedColumns = this.upscoreTableModel.displayedColumns;
    }

    public ngOnChanges(): void {
        this.tableData = this.dataSource;
        this.displayedColumns = this.upscoreTableModel.displayedColumns;
        this.searchDropdownOptions = this.columns.filter(ele => ele.searchable);
        this.setDataSource(this.dataSource);

        if (this.tableData?.length > 0) {
            const keys = Object.keys(this.tableData[0]);
            const passarr: any[] = [];
            this.tableData.forEach((ele, rowIndex) => {
                const pushobj: { isEditable?: UntypedFormControl; isNewRow?: UntypedFormControl } =
                    {};
                keys.forEach(key => {
                    const colField = this.columns.find(item => item.field === key);
                    pushobj[key] = new UntypedFormControl(
                        { value: ele[key], disabled: colField?.isEditable },
                        {
                            validators: Validators.compose([
                                colField?.minNumber ? Validators.min(colField.minNumber) : null,
                                colField?.maxNumber ? Validators.max(colField.maxNumber) : null,
                            ]),
                        },
                    );
                });
                if (!this.locationEditRowIndex && this.locationEditRowIndex !== rowIndex) {
                    pushobj.isEditable = new UntypedFormControl(true);
                } else if (this.locationEditRowIndex !== rowIndex) {
                    pushobj.isEditable = new UntypedFormControl(true);
                } else {
                    pushobj.isEditable = new UntypedFormControl(false);
                }

                pushobj.isNewRow = new UntypedFormControl(false);
                const fbGroup: UntypedFormGroup = this.fb.group(pushobj);
                passarr.push(fbGroup);
            });
            this.upscoreTableForm = this.fb.group({
                upscoreTableRows: this.fb.array(passarr),
            });

            this._dataSource = new MatTableDataSource(
                (this.upscoreTableForm.get('upscoreTableRows') as UntypedFormArray).controls,
            );
            if (this.searchEmployeeInfo) {
                const tableInfo = this.tableData as EmployeeTableData[];
                const emp = tableInfo.find(ele => ele.id === this.searchEmployeeInfo);
                this.applyFilter(emp.address);
            } else if (this.objFilterValue !== '' && this.objFilterValue !== undefined) {
                this.applyFilter(this.objFilterValue);
            }

            this._dataSource.filterPredicate = (data: AbstractControl, filter) => {
                return data.getRawValue()[this.searchDropdownVal]
                    ? data
                          .getRawValue()
                          [this.searchDropdownVal].toLowerCase()
                          .includes(filter.toLowerCase())
                    : false;
            };
        }

        this._dataSource.paginator = this.paginator;
    }

    public ngOnInit(): void {
        if (this.upscoreTableModel.enableTableSearches) {
            this.searchDropdownVal = this.searchEmployeeInfo
                ? 'address'
                : this.searchDropdownOptions[0].field;
        }
    }

    public onContentChange(): void {
        this.startingIndexOfPage = this.paginator.pageIndex * this.paginator.pageSize;
        this.endingIndexOfPage =
            this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize;
    }

    public onSearchParameterChange(): void {
        if (this.objFilterValue !== '' && this.objFilterValue !== undefined) {
            this.applyFilter(this.objFilterValue);
        }
    }

    public setDataSource(data: unknown[]): void {
        this._dataSource = new MatTableDataSource<unknown>(data);
    }

    public onAutocompleteChange(evnt: Event, row: unknown, i: number): void {
        const inputValue = (evnt.target as HTMLInputElement).value;
        const obs: Observable<string> = of(inputValue);
        if (inputValue.length > 1) {
            this.autocompleteoptions = obs.pipe(
                startWith(''),
                debounceTime(1000),
                distinctUntilChanged((curr, prev) => {
                    return curr.toLowerCase() === prev.toLowerCase();
                }),
                map(() =>
                    this.upscoreTableModel.autocompleteoptions[0].options.filter(option =>
                        option.toLowerCase().includes(inputValue.toLowerCase()),
                    ),
                ),
            );
        }
    }

    public onAddAutocompleteOption(val: string): void {
        this.upscoreTableModel.autocompleteoptions[0].options.push(val);
        const obs: Observable<string> = of(val);
        this.autocompleteoptions = obs.pipe(
            startWith(''),
            map(() =>
                this.upscoreTableModel.autocompleteoptions[0].options.filter(option =>
                    option.toLowerCase().includes(val.toLowerCase()),
                ),
            ),
        );
    }

    public applyFilterFromInput(event: Event) {
        this.applyFilter((event.target as HTMLInputElement).value);
    }

    public applyFilter(filterValue: string | null): void {
        const len: number = (this.upscoreTableForm.get('upscoreTableRows') as UntypedFormArray)
            .value.length;

        for (let j = 0; j < len; j++) {
            (this.upscoreTableForm.get('upscoreTableRows') as UntypedFormArray)
                .at(j)
                .get('isEditable')
                ?.patchValue(true);
        }

        filterValue = String(filterValue);
        this.objFilterValue = filterValue;

        this._dataSource.filter = filterValue.trim().toLowerCase();
        if (this._dataSource.paginator) {
            this._dataSource.paginator.firstPage();
        }
    }

    public getItemToEditIndex(index: number): number {
        const tableInfo = this.tableData as EmployeeTableData[];
        const itemToEditId = tableInfo.filter(ele => {
            // @ts-expect-error: TS7053
            return ele[this.searchDropdownVal]
                ? // @ts-expect-error: TS7053
                  ele[this.searchDropdownVal]
                      .toLowerCase()
                      .includes(this.objFilterValue.toLowerCase())
                : false;
        })[index].id;

        return tableInfo.findIndex(ele => ele.id === itemToEditId);
    }

    // this function will enabled the select field for editd
    public editTableRow(formEle: UntypedFormGroup, i: number): void {
        this.rowButtonDialogData = null;
        const len = formEle.get('upscoreTableRows')?.value.length ?? 0;
        if (this.originalAllData) {
            for (let j = 0; j < len; j++) {
                if (
                    j !== i &&
                    (formEle.get('upscoreTableRows') as UntypedFormArray).at(j).get('isEditable')
                        ?.value === false
                ) {
                    this.cancelRow(formEle, j);
                }
            }
        }

        this.columns.forEach(col => {
            if (col.isEditable) {
                (formEle.get('upscoreTableRows') as FormArray).at(i).get(col.field).enable();
            }
        });
        this.originalAllData = formEle.get('upscoreTableRows')?.value;
        (formEle.get('upscoreTableRows') as UntypedFormArray)
            .at(i)
            .get('isEditable')
            ?.patchValue(false);
        const itemToEditIndex = this.objFilterValue ? this.getItemToEditIndex(i) : null;
        this.originalRowData = itemToEditIndex
            ? formEle.value.upscoreTableRows[itemToEditIndex]
            : formEle.value.upscoreTableRows[i];
    }

    // On click of correct button in table (after click on edit) this method will call
    public saveRow(formEle: UntypedFormGroup, i: number): void {
        const itemToEditIndex = this.objFilterValue ? this.getItemToEditIndex(i) : null;
        (formEle.get('upscoreTableRows') as UntypedFormArray)
            .at(itemToEditIndex ? itemToEditIndex : i)
            .get('isEditable')
            ?.patchValue(true);
        delete formEle.value.upscoreTableRows[itemToEditIndex ? itemToEditIndex : i].isEditable;
        const result = formEle.value.upscoreTableRows[itemToEditIndex ? itemToEditIndex : i];

        const mergedData = {
            ...result,
            ...(this.rowButtonDialogData as Record<string, unknown>),
        };

        const passobj: RowDetails = {
            id: this.originalRowData.id,
            editedData: mergedData,
        };
        this.rowDetails.emit(passobj);
    }

    // On click of cancel button in the table (after click on edit) this method will call and reset the previous data
    public cancelRow(formEle: UntypedFormGroup, i: number): void {
        this.columns.forEach(col => {
            if (col.isEditable) {
                (formEle.get('upscoreTableRows') as FormArray).at(i).get(col.field)?.disable();
            }
        });
        formEle.get('upscoreTableRows')?.patchValue(this.originalAllData);
        (formEle.get('upscoreTableRows') as UntypedFormArray)
            .at(i)
            .get('isEditable')
            ?.patchValue(true);
    }

    public onRowButtonClick(vals: unknown, index: number, buttonPurpose: string | undefined): void {
        const itemToEditIndex = this.objFilterValue ? this.getItemToEditIndex(index) : null;
        const rowData = itemToEditIndex ? this.tableData[itemToEditIndex] : vals;
        if (buttonPurpose === 'address') {
            this.editLocationDialogRef = this.dialog.open(EditLocationDialogComponent, {
                data: itemToEditIndex ? this.tableData[itemToEditIndex] : vals,
            });
            this.editLocationDialogRef.afterClosed().subscribe(res => {
                if (res) {
                    const tableInfo = this.tableData as EmployeeTableData[];
                    const rowInfo = rowData as EmployeeTableData;
                    const rowToEditIndex = tableInfo.findIndex(ele => ele.id === rowInfo.id);
                    const addr =
                        (res.data.street ? res.data.street : rowInfo.street) +
                        ',' +
                        (res.data.city ? res.data.city : rowInfo.city) +
                        ',' +
                        (res.data.country ? res.data.country : rowInfo.country) +
                        ',' +
                        (res.data.zipcode ? res.data.zipcode : rowInfo.postalCode);

                    (this.upscoreTableForm.get('upscoreTableRows') as UntypedFormArray)
                        .at(rowToEditIndex)
                        .get('address')
                        ?.setValue(addr);
                    this.rowButtonDialogData = res.data;
                }
            });
        }
    }

    public showUnsetBtn(row: UpscoreTable, index: number): boolean {
        const itemToEditIndex = this.objFilterValue ? this.getItemToEditIndex(index) : index;

        return (
            row.unset &&
            this.upscoreTableForm.get('upscoreTableRows')?.value[itemToEditIndex][row.field]
        );
    }

    public unsetField(index: number, field: string, event: MouseEvent): void {
        event.stopPropagation();
        const itemToEditIndex = this.objFilterValue ? this.getItemToEditIndex(index) : index;
        (this.upscoreTableForm.get('upscoreTableRows') as UntypedFormArray).controls[
            itemToEditIndex
        ]
            ?.get(field)
            ?.patchValue(null);
    }

    public onConfirmDeleteRow(formEle: UntypedFormGroup, i: number): void {
        this.rowDelete.emit(formEle.value.upscoreTableRows[i]);
    }

    public onResetPassword(formEle: UntypedFormGroup, i: number): void {
        this.passwordReset.emit(formEle.value.upscoreTableRows[i]);
    }
}
