<div class="dialog-wrapper">
    <div class="flex justify-between">
        <span class="title items-center" i18n="@@viewEditEmployeeHeader">EDIT EMPLOYEES</span>
        @if (data?.displayLimitedEmployeesInfo) {
            <div
                class="bg-warn border border-transport-subway text-white px-m py-m rounded relative"
                role="alert">
                <span class="block sm:inline" i18n="@@onlySelectedEmployeesShown"
                    >Only showing results for the selected employees.</span
                >
            </div>
        }
    </div>
    <employee-details-table
        [companyLocation]="data?.companyLocation"
        [employees]="data?.employees"
        [geocodingData]="geocodingData ? geocodingData() : null"
        [companyDepartments]="data?.companyDepartments"
        [searchEmployeeInfo]="data?.searchEmployeeInfo"
        [userProperties]="data?.userProperties"
        [companyProperties]="data?.companyProperties"
        (changes)="onChange()"></employee-details-table>
</div>
