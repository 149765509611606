// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Observable, Subject, combineLatest, of } from 'rxjs';

import { AbstractUserDataService, NumberUnit } from '@shared/utils';
import { ScenarioResultToSave, UserInfo } from '@upscore-mobility-audit/api';
import { CompanyLocationDataService } from '@upscore-mobility-audit/core/data-services/company-location-data.service';
import { EmployeesDataService } from '@upscore-mobility-audit/core/data-services/employees-data.service';
import { ScenarioKpiService } from '@upscore-mobility-audit/core/data-services/scenario-kpi.service';
import { DistributionTableComponentEntry } from '@upscore-mobility-audit/kpi/interfaces/distribution-table-component-entry.interface';
import { TopicTableComponentEntry } from '@upscore-mobility-audit/kpi/interfaces/topic-table-component-entry.interface';
import { KpiCard, KpiCardInfo } from '@upscore-mobility-audit/shared/enums/kpi-card.enum';
import {
    MobilityScenario,
    MobilityScoreScenario,
} from '@upscore-mobility-audit/shared/interfaces/mobility-scenario.interface';
import { UtilsService } from '@upscore-mobility-audit/shared/services/utils.service';
import {
    ExportService,
    SelectedCard,
} from '@upscore-mobility-audit/shared-ui/services/export.service';

import { KpiGridService } from '../../services/kpi-grid.service';
import { ExportDialogTranslations } from '../../translations/export-dialog-translations';
import { OptimumPageTranslations } from '../../translations/optimum-page-translations';
import { StatusQuoPageTranslations } from '../../translations/status-quo-page-translations';
import { MoveCardEvent, MoveDirection } from '../export-card-wrapper/export-card-wrapper.component';

@Component({
    selector: 'export-page',
    templateUrl: './export-page.component.html',
    styleUrls: ['./export-page.component.scss'],
})
export class ExportPageComponent implements OnInit, OnChanges {
    @Input() public currentPage: number;

    @Input() public kpiCardInfo: KpiCardInfo[];

    @Output() public cardPositionChanged: EventEmitter<[number, number]> = new EventEmitter<
        [number, number]
    >();

    @Output() public cardPageChanged: EventEmitter<[string, number, number]> = new EventEmitter<
        [string, number, number]
    >();

    @Output() public emptyPageClicked: EventEmitter<void> = new EventEmitter<void>();

    @Output() public layoutChanged: EventEmitter<void> = new EventEmitter<void>();

    public statusQuo: PageData<typeof StatusQuoPageTranslations> = {
        translations: StatusQuoPageTranslations,

        co2BarDistribution: new Array<{ width: number; color: string }>(),
        co2DistributionTableComponentEntries: new Array<DistributionTableComponentEntry>(),
        co2TopicEntries: new Array<TopicTableComponentEntry>(),

        employeeCostsBarDistribution: new Array<{ width: number; color: string }>(),
        employeeCostsDistributionTableComponentEntries:
            new Array<DistributionTableComponentEntry>(),
        employeeCostsTopicEntries: new Array<TopicTableComponentEntry>(),

        tripDistanceBarDistribution: new Array<{ width: number; color: string }>(),
        tripDistanceDistributionTableComponentEntries: new Array<DistributionTableComponentEntry>(),
        tripDistanceTopicEntries: new Array<TopicTableComponentEntry>(),

        tripDurationBarDistribution: new Array<{ width: number; color: string }>(),
        tripDurationDistributionTableComponentEntries: new Array<DistributionTableComponentEntry>(),
        tripDurationTopicEntries: new Array<TopicTableComponentEntry>(),

        averageEmployeeTopicEntries: new Array<TopicTableComponentEntry>(),
        carpoolingTopicEntries: new Array<TopicTableComponentEntry>(),
    };

    public optimum: PageData<typeof OptimumPageTranslations> = {
        translations: OptimumPageTranslations,

        co2DistributionTableComponentEntries: new Array<DistributionTableComponentEntry>(),
        co2BarDistribution: new Array<{ width: number; color: string }>(),
        co2TopicEntries: new Array<TopicTableComponentEntry>(),

        employeeCostsBarDistribution: new Array<{ width: number; color: string }>(),
        employeeCostsDistributionTableComponentEntries:
            new Array<DistributionTableComponentEntry>(),
        employeeCostsTopicEntries: new Array<TopicTableComponentEntry>(),

        tripDistanceBarDistribution: new Array<{ width: number; color: string }>(),
        tripDistanceDistributionTableComponentEntries: new Array<DistributionTableComponentEntry>(),
        tripDistanceTopicEntries: new Array<TopicTableComponentEntry>(),

        tripDurationBarDistribution: new Array<{ width: number; color: string }>(),
        tripDurationDistributionTableComponentEntries: new Array<DistributionTableComponentEntry>(),
        tripDurationTopicEntries: new Array<TopicTableComponentEntry>(),

        averageEmployeeTopicEntries: new Array<TopicTableComponentEntry>(),
        carpoolingTopicEntries: new Array<TopicTableComponentEntry>(),
    };

    public numberUnit: typeof NumberUnit = NumberUnit;

    public rows: [Row, Row];

    public pages: SelectedCard[][];

    public translations: typeof ExportDialogTranslations = ExportDialogTranslations;

    public kpiCard: typeof KpiCard = KpiCard;

    public currentScenarioKpis = new Subject<ScenarioResultToSave>();
    public optimumScenarioKpis = new Subject<ScenarioResultToSave>();

    public currentScore: Observable<MobilityScoreScenario> = combineLatest([
        this.currentScenarioKpis,
        this.companyLocationDataService.companyLocation$,
        of('All'),
    ]).pipe(this.scenarioKpiService.scenarioToScore);

    public optimumScore: Observable<MobilityScoreScenario> = combineLatest([
        this.optimumScenarioKpis,
        this.companyLocationDataService.companyLocation$,
        of('All'),
    ]).pipe(this.scenarioKpiService.scenarioToScore);

    constructor(
        public readonly userDataService: AbstractUserDataService<UserInfo>,
        public readonly companyLocationDataService: CompanyLocationDataService,
        public readonly employeesDataService: EmployeesDataService,
        public readonly scenarioKpiService: ScenarioKpiService,
        public readonly utilsService: UtilsService,
        public readonly exportService: ExportService,
        private readonly kpiGridService: KpiGridService,
    ) {}

    public ngOnInit(): void {
        this.scenarioKpiService.loadScenarioKpi(
            -1,
            this.companyLocationDataService.companyLocation(),
            this.currentScenarioKpis,
        );
        this.scenarioKpiService.loadScenarioKpi(
            -2,
            this.companyLocationDataService.companyLocation(),
            this.optimumScenarioKpis,
        );

        this.initChartsSubscription();

        this.exportService.pages.subscribe(p => {
            this.pages = p;
            this.updateRows();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.pages && changes.currentPage) {
            this.updateRows();
        }
    }

    public findCard(card: string): SelectedCard | null {
        return this.pages[this.currentPage].find(c => c.id === card) ?? null;
    }

    public panelOpenStateChanged($event: boolean, card: string): void {
        const selectedCard: SelectedCard = this.pages[this.currentPage].find(c => c.id === card);
        const kpiCardInfo: KpiCardInfo = this.kpiCardInfo.find(c => c.title === card);

        selectedCard.panelOpen = $event;
        selectedCard.size = $event ? kpiCardInfo.expandedSize : kpiCardInfo.size;

        this.layoutChanged.emit();
    }

    public positionChanged($event: MoveCardEvent): void {
        if ($event.direction === MoveDirection.UP || $event.direction === MoveDirection.DOWN) {
            const newPosition: number =
                $event.position + ($event.direction === MoveDirection.UP ? -1 : 1);
            this.cardPositionChanged.emit([$event.position, newPosition]);
        } else {
            const newPage: number =
                $event.page + ($event.direction === MoveDirection.LEFT ? -1 : 1);
            this.cardPageChanged.emit([$event.id, $event.page, newPage]);
        }
    }

    public textCardChanged($event: [string, string], card: SelectedCard): void {
        const selectedCard: SelectedCard = this.pages[this.currentPage].find(c => c.id === card.id);

        selectedCard.heading = $event[0];
        selectedCard.text = $event[1];

        this.exportService.pages.next(this.pages);
    }

    public deleteCard(id: string): void {
        this.pages[this.currentPage] = this.pages[this.currentPage].filter(c => c.id !== id);
        this.exportService.pages.next(this.pages);
        this.layoutChanged.emit();
    }

    /**
     * init charts subscription
     */
    private initChartsSubscription(): void {
        combineLatest([this.currentScore, this.employeesDataService.employees$]).subscribe(
            ([mobilityScore, employees]) => {
                this.initStatusQuoChartData(mobilityScore, employees);
            },
        );

        combineLatest([
            this.optimumScore,
            this.currentScore,
            this.employeesDataService.employees$,
        ]).subscribe(([optimumMobilityScore, mobilityScore, employees]) => {
            this.initOptimumChartData(optimumMobilityScore, mobilityScore, employees);
        });
    }

    /**
     * init status quo chart data
     * @param mobilityScore
     * @param employees
     */
    private initStatusQuoChartData(mobilityScore: MobilityScenario, employees: Employee[]): void {
        this.statusQuo.employeeCostsBarDistribution = this.kpiGridService.getBarDistribution(
            mobilityScore.yearlyEmployeeCosts,
        );
        this.statusQuo.employeeCostsDistributionTableComponentEntries =
            this.kpiGridService.initDistributionTable(
                mobilityScore.yearlyEmployeeCosts,
                mobilityScore.dailyEmployeeCosts,
            );
        this.statusQuo.employeeCostsTopicEntries = this.kpiGridService.initModalSplitTopicEntries(
            mobilityScore.yearlyEmployeeCosts,
            mobilityScore.dailyEmployeeCosts,
            NumberUnit.MONEY,
            employees.length,
            mobilityScore.mobilityStats,
        );

        this.statusQuo.co2BarDistribution = this.kpiGridService.getBarDistribution(
            mobilityScore.yearlyEmissions,
        );
        this.statusQuo.co2DistributionTableComponentEntries =
            this.kpiGridService.initDistributionTable(
                mobilityScore.yearlyEmissions,
                mobilityScore.dailyEmissions,
            );
        this.statusQuo.co2TopicEntries = this.kpiGridService.initModalSplitTopicEntries(
            mobilityScore.yearlyEmissions,
            mobilityScore.dailyEmissions,
            NumberUnit.WEIGHT,
            employees.length,
            mobilityScore.mobilityStats,
        );

        this.statusQuo.tripDistanceBarDistribution = this.kpiGridService.getBarDistribution(
            mobilityScore.yearlyTripDistance,
        );
        this.statusQuo.tripDistanceDistributionTableComponentEntries =
            this.kpiGridService.initDistributionTable(
                mobilityScore.yearlyTripDistance,
                mobilityScore.dailyTripDistance,
            );
        this.statusQuo.tripDistanceTopicEntries = this.kpiGridService.initModalSplitTopicEntries(
            mobilityScore.yearlyTripDistance,
            mobilityScore.dailyTripDistance,
            NumberUnit.DISTANCE,
            employees.length,
            mobilityScore.mobilityStats,
        );

        this.statusQuo.tripDurationBarDistribution = this.kpiGridService.getBarDistribution(
            mobilityScore.yearlyTripDuration,
        );
        this.statusQuo.tripDurationDistributionTableComponentEntries = this.kpiGridService
            .initDistributionTable(
                mobilityScore.yearlyTripDuration,
                mobilityScore.dailyTripDuration,
            )
            .map(entry => {
                entry.yearly = entry.yearly / 60;

                return entry;
            });

        this.statusQuo.tripDurationTopicEntries = this.kpiGridService.initModalSplitTopicEntries(
            mobilityScore.yearlyTripDuration,
            mobilityScore.dailyTripDuration,
            NumberUnit.TIME,
            employees.length,
            mobilityScore.mobilityStats,
        );

        this.statusQuo.averageEmployeeTopicEntries = [
            ...this.kpiGridService.initAverageEmployeeTopicEntries(mobilityScore, employees.length),
        ];

        this.statusQuo.carpoolingTopicEntries = [
            // ...this.kpiGridService.initCarpoolingTopicEntries(mobilityScore.carpoolingStats),
        ];
    }

    /**
     * init optimum chart data
     * @param optimumMobilityScore
     * @param currentMobilityScore
     * @param employees
     */
    private initOptimumChartData(
        optimumMobilityScore: MobilityScenario,
        currentMobilityScore: MobilityScenario,
        employees: Employee[],
    ): void {
        this.optimum.employeeCostsBarDistribution = this.kpiGridService.getBarDistribution(
            optimumMobilityScore.yearlyEmployeeCosts,
        );
        this.optimum.employeeCostsDistributionTableComponentEntries =
            this.kpiGridService.initDistributionTable(
                optimumMobilityScore.yearlyEmployeeCosts,
                optimumMobilityScore.dailyEmployeeCosts,
            );
        this.optimum.employeeCostsTopicEntries = this.kpiGridService.initModalSplitTopicEntries(
            optimumMobilityScore.yearlyEmployeeCosts,
            optimumMobilityScore.dailyEmployeeCosts,
            NumberUnit.MONEY,
            employees.length,
            optimumMobilityScore.mobilityStats,
            currentMobilityScore.yearlyEmployeeCosts,
            currentMobilityScore.dailyEmployeeCosts,
            currentMobilityScore.mobilityStats,
        );

        this.optimum.co2BarDistribution = this.kpiGridService.getBarDistribution(
            optimumMobilityScore.yearlyEmissions,
        );
        this.optimum.co2DistributionTableComponentEntries =
            this.kpiGridService.initDistributionTable(
                optimumMobilityScore.yearlyEmissions,
                optimumMobilityScore.dailyEmissions,
            );
        this.optimum.co2TopicEntries = this.kpiGridService.initModalSplitTopicEntries(
            optimumMobilityScore.yearlyEmissions,
            optimumMobilityScore.dailyEmissions,
            NumberUnit.WEIGHT,
            employees.length,
            optimumMobilityScore.mobilityStats,
            currentMobilityScore.yearlyEmissions,
            currentMobilityScore.dailyEmissions,
            currentMobilityScore.mobilityStats,
        );

        this.optimum.tripDistanceBarDistribution = this.kpiGridService.getBarDistribution(
            optimumMobilityScore.yearlyTripDistance,
        );
        this.optimum.tripDistanceDistributionTableComponentEntries =
            this.kpiGridService.initDistributionTable(
                optimumMobilityScore.yearlyTripDistance,
                optimumMobilityScore.dailyTripDistance,
            );
        this.optimum.tripDistanceTopicEntries = this.kpiGridService.initModalSplitTopicEntries(
            optimumMobilityScore.yearlyTripDistance,
            optimumMobilityScore.dailyTripDistance,
            NumberUnit.DISTANCE,
            employees.length,
            optimumMobilityScore.mobilityStats,
            currentMobilityScore.yearlyTripDistance,
            currentMobilityScore.dailyTripDistance,
            currentMobilityScore.mobilityStats,
        );

        this.optimum.tripDurationBarDistribution = this.kpiGridService.getBarDistribution(
            optimumMobilityScore.yearlyTripDuration,
        );
        this.optimum.tripDurationDistributionTableComponentEntries = this.kpiGridService
            .initDistributionTable(
                optimumMobilityScore.yearlyTripDuration,
                optimumMobilityScore.dailyTripDuration,
            )
            .map(entry => {
                entry.yearly = entry.yearly / 60;

                return entry;
            });

        this.optimum.tripDurationTopicEntries = this.kpiGridService.initModalSplitTopicEntries(
            optimumMobilityScore.yearlyTripDuration,
            optimumMobilityScore.dailyTripDuration,
            NumberUnit.TIME,
            employees.length,
            optimumMobilityScore.mobilityStats,
            currentMobilityScore.yearlyTripDuration,
            currentMobilityScore.dailyTripDuration,
            currentMobilityScore.mobilityStats,
        );

        this.optimum.averageEmployeeTopicEntries = [
            ...this.kpiGridService.initAverageEmployeeTopicEntries(
                optimumMobilityScore,
                employees.length,
                currentMobilityScore,
            ),
        ];

        this.optimum.carpoolingTopicEntries = [
            ...this.kpiGridService.initCarpoolingTopicEntries(
                optimumMobilityScore.carpoolingStats,
                currentMobilityScore.carpoolingStats,
            ),
        ];
    }

    private updateRows(): void {
        let sizeCounter = 0;

        const rows: [Row, Row] = [[], []];

        if (this.pages[this.currentPage]) {
            for (const [index, card] of this.pages[this.currentPage].entries()) {
                sizeCounter += card.size;

                if (sizeCounter <= 5) {
                    rows[0].push({ index, card });
                } else {
                    rows[1].push({ index, card });
                }
            }

            this.rows = rows;
        }
    }
}

export interface PageData<T> {
    translations: T;

    co2BarDistribution: { width: number; color: string }[];
    co2DistributionTableComponentEntries: DistributionTableComponentEntry[];
    co2TopicEntries: TopicTableComponentEntry[];

    employeeCostsBarDistribution: { width: number; color: string }[];
    employeeCostsDistributionTableComponentEntries: DistributionTableComponentEntry[];
    employeeCostsTopicEntries: TopicTableComponentEntry[];

    tripDistanceBarDistribution: { width: number; color: string }[];
    tripDistanceDistributionTableComponentEntries: DistributionTableComponentEntry[];
    tripDistanceTopicEntries: TopicTableComponentEntry[];

    tripDurationBarDistribution: { width: number; color: string }[];
    tripDurationDistributionTableComponentEntries: DistributionTableComponentEntry[];
    tripDurationTopicEntries: TopicTableComponentEntry[];

    averageEmployeeTopicEntries: TopicTableComponentEntry[];
    carpoolingTopicEntries: TopicTableComponentEntry[];
}

export type Row = { index: number; card: SelectedCard }[];
