import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, catchError, firstValueFrom, of, switchMap } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import {
    ImprovementsService,
    MobilityScenarioDto,
    PackageDefinition,
    PackageTask,
    PackagesService,
    PostedPackage,
    SmallScoreResponse,
    TaskInfo,
} from '@upscore-mobility-audit/api';
import { AuditInputDataService } from '@upscore-mobility-audit/core/data-services/audit-input-data.service';
import { CacheResetBase } from '@upscore-mobility-audit/core/data-services/cache-reset-base.service';
import { CompanyLocationDataService } from '@upscore-mobility-audit/core/data-services/company-location-data.service';
import { ScoreDataService } from '@upscore-mobility-audit/core/data-services/score-data.service';
import { TaskHandlerService, TaskNotificationService } from '@upscore-mobility-audit/tasks';

@Injectable({
    providedIn: 'root',
})
export class PackageDataService extends CacheResetBase {
    public definitions$ = new BehaviorSubject<PackageDefinition[]>([]);

    public get selectedDefinition() {
        return this.definitions$.value.find(
            d => d.packageId === this.auditInputDataService.selectedScenarioId$.value,
        );
    }

    private waitForTaskSwitchMap = switchMap((task: PackageTask) => {
        return this.taskHandlerService.waitForTaskCompletion<SmallScoreResponse>(task.id).pipe(
            tap(score => {
                this.scoreDataService.saveScore(task.packageId, score);
                this.scoreDataService.mobilityScoreError$.next(null);
                this.fixTaskCalculationTime(task.packageId);
            }),
            catchError(error => {
                this.scoreDataService.mobilityScoreError$.next(error as HttpErrorResponse);
                throw error;
            }),
            map(() => task.packageId),
            takeUntil(this.resetService.auditClosed$),
        );
    });

    constructor(
        private improvementsService: ImprovementsService,
        private taskHandlerService: TaskHandlerService,
        private packagesService: PackagesService,
        private scoreDataService: ScoreDataService,
        private auditInputDataService: AuditInputDataService,
        private taskNotificationService: TaskNotificationService,
        private companyLocationDataService: CompanyLocationDataService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        super();
    }

    public setDefinitions(packageDefs: PackageDefinition[]) {
        // also change array reference
        this.definitions$.next([...packageDefs.sort((a, b) => b.packageId - a.packageId)]);
    }

    public getPackageDefinitions(companyLocationId: number) {
        return this.packagesService.getPackageDefinitions({ companyLocationId }).pipe(
            takeUntil(this.resetService.auditClosed$),
            tap(definitions => {
                this.setDefinitions(definitions);
            }),
        );
    }

    public loadPackageDefinitionById(companyLocationId: number, packageId: number) {
        return this.packagesService.getPackageDefinition({ companyLocationId, packageId }).pipe(
            tap(definition => {
                const defs = this.definitions$.value;
                const index = defs.findIndex(d => d.packageId === packageId);

                if (index != -1) {
                    defs[index] = definition;
                } else {
                    defs.push(definition);
                }

                // change reference
                this.setDefinitions(defs);
            }),
        );
    }

    public async loadPackageScoreById(companyLocationId: number, packageId: number) {
        this.scoreDataService.mobilityScoreError$.next(null);
        try {
            const response = await this.taskNotificationService.createTaskIfNecessary(
                task =>
                    task.definition.locationId === companyLocationId &&
                    task.definition.type === 'PackageTask' &&
                    (task.definition as PackageTask).packageId === packageId,
                () => firstValueFrom(this.getScoreAsync(companyLocationId, packageId)),
            );

            if (!response) {
                return;
            }

            this.scoreDataService.saveScore(packageId, response);
            // optimum needs to be saved after current score
            this.scoreDataService.saveOptimumScore();
        } catch (error) {
            this.scoreDataService.mobilityScoreError$.next(error as HttpErrorResponse);
            this.scoreDataService.deleteScore(packageId);
        }
    }

    /**
     * A new base scenario is created.
     * The returning observable contains the new package id.
     * @param companyLocationId
     * @param packageToPost
     * @returns
     */
    public addBaseScenario(companyLocationId: number, packageToPost: PostedPackage) {
        return this.packagesService
            .createSmallPackageAsync1({
                companyLocationId: companyLocationId,
                body: packageToPost,
            })
            .pipe(this.loadDefinitionSwitchMap(companyLocationId), this.waitForTaskSwitchMap);
    }

    public updateBaseScenario(
        companyLocationId: number,
        packageId: number,
        packageToUpdate: PostedPackage,
    ) {
        return this.packagesService
            .updateSmallPackageAsync({
                packageId,
                body: packageToUpdate,
                companyLocationId: companyLocationId,
            })
            .pipe(
                tap(task => {
                    this.scoreDataService.deleteScore(task.packageId);
                }),
                this.loadDefinitionSwitchMap(companyLocationId),
                this.waitForTaskSwitchMap,
            );
    }

    public deleteScenario(packageId: number) {
        return this.packagesService
            .deletePackage({
                packageId,
            })
            .pipe(
                tap(() => {
                    this.scoreDataService.deleteScore(packageId);

                    const definitions = this.definitions$.value.filter(
                        d => d.packageId != packageId,
                    );
                    this.setDefinitions(definitions);
                    if (this.auditInputDataService.selectedScenarioId$.value == packageId) {
                        this.navigateToScenario(
                            definitions.length > 0 ? definitions[0].packageId : null,
                        );
                    }
                }),
            );
    }

    public addMeasure(companyLocationId: number, body: MobilityScenarioDto) {
        // when creating a measure we also want to select it
        return this.improvementsService
            .createSmallPackageAsync({
                companyLocationId,
                body,
            })
            .pipe(
                this.loadDefinitionSwitchMap(companyLocationId),
                tap(response => {
                    this.navigateToScenario(response.packageId);
                }),
                this.waitForTaskSwitchMap,
            );
    }

    public updateMeasure(companyLocationId: number, packageId: number, body: MobilityScenarioDto) {
        return this.improvementsService
            .editSmallPackageAsync({
                companyLocationId,
                packageId,
                body,
            })
            .pipe(
                tap(() => {
                    this.scoreDataService.deleteScore(packageId);
                    this.navigateToScenario(packageId);
                }),
                this.loadDefinitionSwitchMap(companyLocationId),
                this.waitForTaskSwitchMap,
            );
    }

    public recalculateScenario(companyLocationId: number, packageId: number) {
        return this.packagesService.recalculatePackageAsync({ companyLocationId, packageId }).pipe(
            tap(() => {
                this.scoreDataService.deleteScore(packageId);
            }),
            this.waitForTaskSwitchMap,
        );
    }

    protected override auditClosed(): void {
        this.definitions$.next([]);
    }

    protected override scoreDeleted(packageId: number) {
        const definition = this.selectedDefinition;
        if (definition && packageId == (definition.baseScenario ?? -1)) {
            const companyLocation = this.companyLocationDataService.companyLocation();
            void this.loadPackageScoreById(companyLocation.id, definition.packageId);
        }
    }

    protected override scoreSaved(packageId: number): void {
        const definition = this.selectedDefinition;
        if (
            definition &&
            packageId == (definition.baseScenario ?? -1) &&
            this.scoreDataService.hasScore(definition.baseScenario ?? -1)
        ) {
            const companyLocation = this.companyLocationDataService.companyLocation();
            void this.loadPackageScoreById(companyLocation.id, definition.packageId);
        }
    }

    private getScoreAsync(companyLocationId: number, packageId: number) {
        return this.packagesService.getPackageAsync$Response({ companyLocationId, packageId }).pipe(
            switchMap(response => {
                switch (response.status) {
                    case 200:
                        return of(response.body as SmallScoreResponse);
                    case 202:
                        return this.taskHandlerService.waitForTaskCompletion<SmallScoreResponse>(
                            (response.body as unknown as TaskInfo).id,
                        );
                    default:
                        return Promise.reject(response);
                }
            }),
        );
    }

    private loadDefinitionSwitchMap(companyLocationId: number) {
        return switchMap((task: PackageTask) =>
            this.loadPackageDefinitionById(companyLocationId, task.packageId).pipe(map(() => task)),
        );
    }

    /**
     * This hotfixes the calculation time of a package.
     * Is used in waitForTaskSwitchMap after a calculation is finished.
     *
     * This is needed as we reload a task definition before a calculation is finished.
     * @param packageId
     * @private
     */
    private fixTaskCalculationTime(packageId: number) {
        const package_ = this.definitions$.value.find(p => p.packageId === packageId);
        if (package_) {
            package_.calculationTime = new Date().toDateString();
        }
        this.setDefinitions(this.definitions$.value);
    }

    private navigateToScenario(scenario: number | null) {
        void this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                scenario: scenario ?? 'measures',
                skipCalculation: true,
            },
            queryParamsHandling: 'merge',
        });
    }
}
