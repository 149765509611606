import { Component, Input } from '@angular/core';

import { NumberUnit } from '@shared/utils';

import { DistributionTableComponentEntry } from '../../interfaces/distribution-table-component-entry.interface';

@Component({
    selector: 'distribution-table',
    templateUrl: './distribution-table.component.html',
    styleUrls: ['./distribution-table.component.scss'],
})
export class DistributionTableComponent {
    @Input() public distribution!: DistributionTableComponentEntry[];
    @Input() public unit!: NumberUnit | string;
    @Input() public digitsInfo?: string;

    public numberUnit: typeof NumberUnit = NumberUnit;
}
