<button
    class="!absolute right-[5px] top-[5px]"
    data-html2canvas-ignore
    mat-icon-button
    [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu>
    <button mat-menu-item exportButton [exportTriggerFor]="exportTriggerFor" format="png">
        <mat-icon>collections</mat-icon>
        <span i18n="@@exportPNG">Export PNG</span>
    </button>

    <button mat-menu-item exportButton [exportTriggerFor]="exportTriggerFor" format="pdf">
        <mat-icon>picture_as_pdf</mat-icon>
        <span i18n="@@exportPDF">Export PDF</span>
    </button>
</mat-menu>
