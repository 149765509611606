import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CompanyLocation } from '@upscore-mobility-audit/api';
import { UnsafeCompanyLocationDataService } from '@upscore-mobility-audit/core/data-services/unsafe-company-location-data.service';

/**
 * Service to load and store the company location data
 * that's used in the audit view only! Not for other pages like the dashboard.
 */
@Injectable({
    providedIn: 'root',
})
export class CompanyLocationDataService {
    public companyLocation$ = this.unsafeCompanyLocationDataService.companyLocation$.pipe(
        filter(location => location != null),
    ) as Observable<CompanyLocation>;

    public companyLocation = toSignal<CompanyLocation>(
        this.companyLocation$,
    ) as Signal<CompanyLocation>;

    constructor(private unsafeCompanyLocationDataService: UnsafeCompanyLocationDataService) {}
}
