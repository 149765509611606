<mat-dialog-content>
    <h3>{{ errorHeader }}</h3>
    <p>{{ errorText }}</p>
    @if (questionText) {
        <p>{{ questionText }}</p>
    }
    <div class="button-row">
        <button mat-raised-button (click)="onCloseDialog(true)">
            {{ cancelText }}
        </button>
        <button mat-raised-button (click)="onCloseDialog(false)" [color]="'warn'">
            {{ continueText }}
        </button>
    </div>
</mat-dialog-content>
