import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastService } from '@shared/utils';
import { UploadFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/upload-form-group.interface';

import { UploadAreaTranslations } from '../../../../translations/upload-area-translations';

@Component({
    selector: 'upload-area',
    templateUrl: './upload-area.component.html',
    styleUrls: ['./upload-area.component.scss'],
})
export class UploadAreaComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput', { static: false }) public fileInput: ElementRef;
    @Input() public parentFormGroup: FormGroup<UploadFormGroup>;
    @Output() public fileUploaded: EventEmitter<File> = new EventEmitter();

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private readonly toastService: ToastService) {}

    /**
     * Angular lifecycle hook
     */
    public ngOnInit(): void {
        this.parentFormGroup.controls.file.setValidators(Validators.required);
        this.parentFormGroup.controls.file.updateValueAndValidity();

        this.parentFormGroup.controls.file.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(value => {
                if (value == null) {
                    this.fileInput.nativeElement.value = '';
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.parentFormGroup.controls.file.clearValidators();
        this.parentFormGroup.controls.file.setValue(null);
        this.parentFormGroup.controls.file.updateValueAndValidity();
    }

    public buttonOnFileUpload(htmlInputElement: EventTarget | null): void {
        this.onFileUpload((htmlInputElement as HTMLInputElement)?.files);
    }

    /**
     * Method which is called when a new file is uploaded per button or drag and drop
     * @param files
     */
    public onFileUpload(files: FileList | null): void {
        if (files == null || files.length === 0) {
            return;
        }

        if (
            ![
                'text/csv',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ].includes(files[0].type)
        ) {
            this.toastService.showInfoEvent(UploadAreaTranslations.wrongFormatError, true);

            return;
        }

        if (this.parentFormGroup.value.file != null) {
            this.toastService.showInfoEvent(UploadAreaTranslations.singleFileError, true);
        }

        this.parentFormGroup.controls.file.setValue(files[0]);
        this.parentFormGroup.markAsDirty();
        this.fileUploaded.emit(files[0]);
    }

    /**
     * Method which is called when a new files gets uploaded
     */
    public onFileUploadButton(): void {
        this.fileInput.nativeElement.click();
    }
}
