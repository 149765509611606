import { ModeString } from '@shared/map';
import { SliderColorClass } from '@ui-library/enums/slider-color-class.enum';

export function modeToSliderColorClass(mode: ModeString) {
    switch (mode) {
        case 'WALK':
            return SliderColorClass.WALKING;
        case 'BIKE':
            return SliderColorClass.BIKING;
        case 'PUBLIC_TRANSPORT':
            return SliderColorClass.PUBLIC_TRANSPORT;
        case 'CAR_DRIVER':
            return SliderColorClass.CAR_DRIVER;
        case 'CAR_PASSENGER':
            return SliderColorClass.CAR_PASSENGER;
        default:
            return SliderColorClass.CAR_DRIVER;
    }
}
