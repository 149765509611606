import { StatsPercentageChange } from '@upscore-mobility-audit/shared/interfaces/stats-percentage-change.interface';

import { DefaultVehicleStatsSliderOptionsTranslations } from '../translations/default-vehicle-stats-slider-options-translations';

export const defaultVehicleStatsSliderOptions: StatsPercentageChange[] = [
    {
        identifier: 'smallVehicles',
        sliderOptions: {
            title: DefaultVehicleStatsSliderOptionsTranslations.dieselCars,
            value: 40,
            min: 0,
            max: 100,
            format: '%',
        },
    },
    {
        identifier: 'mediumVehicles',
        sliderOptions: {
            title: DefaultVehicleStatsSliderOptionsTranslations.petrolCars,
            value: 20,
            min: 0,
            max: 100,
            format: '%',
        },
    },
    {
        identifier: 'electricVehicles',
        sliderOptions: {
            title: DefaultVehicleStatsSliderOptionsTranslations.bevCars,
            value: 5,
            min: 0,
            max: 100,
            format: '%',
        },
    },
    {
        identifier: 'largeVehicles',
        sliderOptions: {
            title: DefaultVehicleStatsSliderOptionsTranslations.hybridCars,
            value: 35,
            min: 0,
            max: 100,
            format: '%',
        },
    },
];
