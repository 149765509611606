<notification-layout [standalone]="standalone">
    <h1 class="tps-heading-5" header>
        {{ definition() | taskDefinitionHeader }}
    </h1>

    <div
        actions
        class="flex flex-row absolute top-0 right-0"
        [style.transform]="
            standalone ? 'scale(0.85) !important' : 'scale(0.85) translate(25%, -25%) !important'
        ">
        @if (
            task.status === 'QUEUED' || task.status === 'STARTING' || task.status === 'IN_PROGRESS'
        ) {
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu>
                <button mat-menu-item (click)="openNotificationDialog()">
                    <mat-icon>mail</mat-icon>
                    <span i18n="@@notifyMe">Notify me when finished</span>
                </button>
                <button mat-menu-item (click)="cancelTask()">
                    <mat-icon>cancel</mat-icon>
                    <span i18n="@@cancelTask">Cancel Task</span>
                </button>
            </mat-menu>
        }

        @if (
            standalone ||
            task.status === 'FINISHED' ||
            task.status === 'FAILED' ||
            task.status === 'CANCELLED'
        ) {
            <button mat-icon-button (click)="deleteTask()">
                <mat-icon>close</mat-icon>
            </button>
        }
    </div>

    <p
        class="tps-body-1"
        body
        [innerHTML]="
            task
                | taskProgressBody
                    : definition()
                    : (definition().type === 'PackageTask' ? packageName() : companyLocationName)
        "></p>

    @if (task.status === 'STARTING' || task.status === 'IN_PROGRESS' || task.status === 'QUEUED') {
        <upscore-task-progress-bar
            footer
            [progress]="task | taskProgressPercentage"
            [progressText]="task | taskProgressFooter">
        </upscore-task-progress-bar>
    } @else if (task.status === 'FINISHED') {
        <div class="flex flex-row gap-m items-center cursor-pointer" (click)="viewTaskResult()">
            <mat-icon class="!text-action-success">check_circle</mat-icon>
            <a
                class="tps-text-small font-semibold underline !text-dark-grey after:content-['_→'] uppercase">
                {{ task | taskProgressFooter }}
            </a>
        </div>
    } @else if (task.status === 'FAILED' || task.status === 'CANCELLED') {
        <div class="flex flex-row gap-m items-center cursor-pointer" (click)="showError()">
            <mat-icon class="!text-action-danger">warning</mat-icon>
            <span
                class="tps-text-small font-semibold underline !text-dark-grey after:content-['_→'] uppercase">
                {{ task | taskProgressFooter }}
            </span>
        </div>
    }
</notification-layout>
