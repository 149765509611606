import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, tap } from 'rxjs';

import { CompanyLocation } from '@upscore-mobility-audit/api';
import { CacheResetBase } from '@upscore-mobility-audit/core/data-services/cache-reset-base.service';
import { CompanyLocationsWrapperService } from '@upscore-mobility-audit/shared/api-services/company-locations-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class UnsafeCompanyLocationDataService extends CacheResetBase {
    public _companyLocation$ = new BehaviorSubject<CompanyLocation | null>(null);

    public companyLocation$ = this._companyLocation$.asObservable();

    public companyLocation = toSignal(this._companyLocation$, {
        requireSync: true,
    });

    constructor(private companyLocationWrapper: CompanyLocationsWrapperService) {
        super();
    }

    public reset() {
        this._companyLocation$.next(null);
        this.resetService.companyChanged$.next(null);
    }

    public loadCompanyLocation(locationId: number) {
        return this.companyLocationWrapper.getCompanyLocation(locationId).pipe(
            tap(companyLocation => {
                this._companyLocation$.next(companyLocation);
                this.resetService.companyChanged$.next(companyLocation.id);
            }),
        );
    }

    // companyChanged function not needed as new companyLocation is loaded through resolver
    protected override auditClosed(): void {
        this.reset();
    }
}
