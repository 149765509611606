import { Pipe, PipeTransform } from '@angular/core';

import { MobilityIntervention } from '@upscore-mobility-audit/api';
import { InterventionType } from '@upscore-mobility-audit/core/interfaces/intervention-type.interface';

@Pipe({
    name: 'measureType',
    standalone: true,
})
export class MeasureTypePipe implements PipeTransform {
    transform(value: MobilityIntervention): InterventionType {
        return value.type as InterventionType;
    }
}
