<form [formGroup]="measureFormGroup">
    <div class="content-wrapper">
        <div class="first-column">
            <div class="section">
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                    <mat-label i18n="@@name">Name</mat-label>
                    <input
                        type="text"
                        matInput
                        placeholder="{{ translations.placeholderTextMeasurePackage }}"
                        formControlName="title"
                        required />
                    @if (measureFormGroup.controls['title'].value) {
                        <button
                            type="button"
                            matSuffix
                            mat-icon-button
                            (click)="measureFormGroup.controls['title'].setValue('')">
                            <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>
            </div>
            <div class="section">
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                    <mat-label i18n="@@attendancePerWeek"
                        >Average Days In Office Per Week</mat-label
                    >
                    <input
                        matInput
                        placeholder="4.3"
                        formControlName="presenceDays"
                        type="number" />
                    @if (measureFormGroup.controls['presenceDays'].value) {
                        <button
                            matSuffix
                            mat-icon-button
                            type="button"
                            (click)="measureFormGroup.controls['presenceDays'].setValue(undefined)">
                            <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>
            </div>
            <div class="section">
                <mat-slide-toggle
                    class="mat-form-field-wrapper"
                    formControlName="overrideFixedMode"
                    i18n="@@overrideFixedMode"
                    >Ignore Fixed Modes Of Employees
                </mat-slide-toggle>
                <mat-slide-toggle
                    class="mat-form-field-wrapper"
                    formControlName="isImprovementOnly"
                    i18n="@@improvementOnly"
                    >Improvement Only
                </mat-slide-toggle>
            </div>
        </div>
        <div class="second-column">
            <div class="section">
                <mobility-stats-input
                    parentFormControlName="mobilityStats"
                    [companyLocationId]="companyLocation.id"
                    [statsPercentageChange]="mobilityStatsSliderOptions"
                    [parentFormGroup]="measureFormGroup"
                    [formControlValidatorFn]="percentageChangeValidatorFn"
                    [allowDifferentType]="allowSwitchingMeasureType"></mobility-stats-input>
            </div>
            <div class="section">
                <basic-percentage-change-wrapper
                    [title]="translations.vehiclesUsedText"
                    [statsPercentageChange]="vehicleStatsSliderOptions"
                    parentFormControlName="vehicleStats"
                    [parentFormGroup]="measureFormGroup"
                    [formControlValidatorFn]="
                        percentageChangeValidatorFn
                    "></basic-percentage-change-wrapper>
            </div>
        </div>
    </div>
</form>
