import { Component, Input } from '@angular/core';

@Component({
    selector: 'help-popover',
    templateUrl: './help-popover.component.html',
    styleUrls: ['./help-popover.component.scss'],
})
export class HelpPopoverComponent {
    @Input() public content!: string;
    @Input() public link?: string;
    @Input() public type: 'help' | 'warn' = 'help';
    @Input() public linkText?: string;

    public onLinkClick(): void {
        if (this.link) {
            window.open(this.link, '_blank');
        }
    }
}
