import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    EventEmitter,
    OnInit,
    Output,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

import { UploadAreaWrapperComponent } from '../components/upload-area-wrapper/upload-area-wrapper.component';

@Directive({
    selector: '[appUploadAreaWrapper]',
})
export class UploadAreaWrapperDirective implements OnInit {
    @Output() public fileDropped: EventEmitter<FileList> = new EventEmitter<FileList>();
    private wrapperContainer: ComponentRef<UploadAreaWrapperComponent>;

    constructor(
        private templateRef: TemplateRef<UploadAreaWrapperComponent>,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {}

    public ngOnInit(): void {
        const containerFactory: ComponentFactory<UploadAreaWrapperComponent> =
            this.componentFactoryResolver.resolveComponentFactory(UploadAreaWrapperComponent);
        this.wrapperContainer = this.viewContainerRef.createComponent(containerFactory);
        this.wrapperContainer.instance.template = this.templateRef;

        this.wrapperContainer.location.nativeElement.addEventListener('drop', (evt: DragEvent) => {
            evt.preventDefault();
            evt.stopPropagation();
            const files = evt.dataTransfer?.files;
            if (files && files.length > 0) {
                this.fileDropped.emit(files);
            }
        });
    }
}
