<kpi-card
    [showMenu]="hasMenu"
    [showUnavailable]="showUnavailable"
    [title]="title"
    [moreInfoText]="moreInfoText"
    [moreInfoLink]="moreInfoLink">
    <div body class="wrapper">
        <!-- daily / yearly overview-->
        <kpi-change-header
            [digitsInfo]="digitsInfo"
            [baselineYearly]="baselineYearly"
            [baselineDaily]="baselineDaily"
            [currentDaily]="daily"
            [currentYearly]="yearly"
            [unit]="unit">
        </kpi-change-header>

        <div class="divider"></div>
        <div class="menu">
            <div
                class="item"
                [ngClass]="{ inactive: !distributionByMode }"
                (click)="distributionByMode = true; changes.next()"
                i18n="@@distributionByMode">
                Distribution by Mode
            </div>
            <div
                class="item"
                [ngClass]="{ inactive: distributionByMode }"
                (click)="distributionByMode = false; changes.next()"
                i18n="@@distributionByEmployee">
                Distribution by Employee
            </div>
        </div>
        @if (distributionByMode) {
            <!-- modal split distribution -->
            @if (barDistribution) {
                <div class="divider"></div>
                <distribution-bar
                    [distribution]="barDistribution"
                    [title]="barText"></distribution-bar>
                <div class="divider"></div>
                @if (distributionTableComponentEntries) {
                    <distribution-table
                        [digitsInfo]="digitsInfo"
                        [distribution]="distributionTableComponentEntries"
                        [unit]="unit"></distribution-table>
                }
            }
        } @else {
            <div class="divider"></div>
            <span class="title" i18n="@@employeeDistribution"> Employee Distribution </span>
            <bar-chart [data]="chartData"></bar-chart>
        }

        <!-- topic table card table (daily / yearly per modal type) -->
        @if (topicTableComponentEntries) {
            <div
                [attr.data-html2canvas-ignore]="!panelOpenState ? '' : null"
                [class.data-html2canvas-ignore]="!panelOpenState">
                <mat-expansion-panel
                    (afterCollapse)="toggleTopicTableComponent()"
                    (afterExpand)="toggleTopicTableComponent()"
                    [(expanded)]="panelOpenState">
                    <mat-expansion-panel-header>
                        <mat-panel-title i18n="@@kpiPerEmployee">
                            KPIs Per Employee</mat-panel-title
                        >
                    </mat-expansion-panel-header>
                    <topic-table [entries]="topicTableComponentEntries"></topic-table>
                </mat-expansion-panel>
            </div>
        }
    </div>
</kpi-card>
