// export enum SidebarSectionHeaderLabel {
//     'BIKE_SIMULATION' = 'RAD SIMULATION',
//     'WALK_SIMULATION' = 'FUSSWEG SIMULATION',
//     'CAR_SIMULATION' = 'MIV SIMULATION',
//     'PUBLIC_TRANSPORT_SIMULATION' = 'ÖV SIMULATION',

//     'HEATMAP' = 'HEATMAP',
//     'EMPLOYEE_LOCATION' = 'MITARBEITER STANDORTE',
//     'BIKE_REACHABILITY' = 'RADWEG ERREICHBARKEIT',
//     'WALK_REACHABILITY' = 'FUßWEG ERREICHBARKEIT',
//     'CAR_REACHABILITY' = 'MIV ERREICHBARKEIT',
//     'PUBLIC_TRANSPORT_REACHABILITY' = 'ÖV ERREICHBARKEIT',
// }

/* Converting enums into class as $localize is not yet supported in enums by Angular.
We can go back to using enums once the support for $ localize in string based enums is added. */

export class SidebarSectionHeaderLabel {
    public static readonly BIKE_SIMULATION: string = $localize`:@@bikeSimulation:BIKE SIMULATION`;
    public static readonly WALK_SIMULATION: string = $localize`:@@walkSimulation:WALK SIMULATION`;
    public static readonly CAR_SIMULATION: string = $localize`:@@carSimulation:CAR SIMULATION`;
    public static readonly PUBLIC_TRANSPORT_SIMULATION: string = $localize`:@@ptSimulation:PUBLIC TRANSPORT SIMULATION`;

    public static readonly EMPLOYEE_LOCATION: string = $localize`:@@employeeLocations:EMPLOYEE LOCATIONS`;
    public static readonly BIKE_REACHABILITY: string = $localize`:@@bikeReachability:ACCESSIBILITY BY BIKE`;
    public static readonly WALK_REACHABILITY: string = $localize`:@@walkReachability:ACCESSIBILITY ON FOOT`;
    public static readonly CAR_REACHABILITY: string = $localize`:@@carReachability:ACCESSIBILITY BY CAR`;
    public static readonly PUBLIC_TRANSPORT_REACHABILITY: string = $localize`:@@ptReachability:ACCESSIBILITY BY PUBLIC TRANSPORT`;
    public static readonly RADIUS_REACHABILITY: string = $localize`:@@radiusReachability:ACCESSIBILITY BY DISTANCE`;
}
