<mat-dialog-content>
    <form [formGroup]="locationFormGroup">
        <google-places-input
            [locationRestriction]="(userDataService.user$ | async)?.coverageBbox"
            [parentFormGroup]="placeResultFormGroup"
            i18n-placeholder="@@searchPlaceholder"
            label="Search"
            placeholder="Company XYZ, Peter-Behrens-Platz 10, Linz, Austria">
        </google-places-input>
        <mat-form-field appearance="outline" class="full-width-form-field">
            <mat-label i18n="@@streetAndHouseNo">Street + House Number</mat-label>
            <input
                formControlName="street"
                matInput
                placeholder="Peter-Behrens-Platz 10"
                required
                type="string" />
        </mat-form-field>
        <div>
            <mat-form-field appearance="outline" class="col-margin">
                <mat-label i18n="@@city">City</mat-label>
                <input formControlName="city" matInput placeholder="Linz" required type="string" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label i18n="@@postalCode">Postal Code</mat-label>
                <input
                    formControlName="zipcode"
                    matInput
                    placeholder="4020"
                    required
                    type="string" />
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="outline" class="col-margin">
                <mat-label i18n="@@latitude">Latitude</mat-label>
                <input
                    formControlName="latitude"
                    matInput
                    placeholder="48.23456"
                    required
                    type="number" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label i18n="@@longitude">Longitude</mat-label>
                <input
                    formControlName="longitude"
                    matInput
                    placeholder="16.2345"
                    required
                    type="number" />
            </mat-form-field>
        </div>

        <div class="footer-wrapper" mat-dialog-actions>
            <button
                (click)="onCancel()"
                class="cancel-button-filled cancel-button"
                mat-button
                type="button">
                <span i18n="@@cancel">Cancel</span>
            </button>
            <button
                (click)="onUpdateLocation()"
                [disabled]="locationFormGroup.invalid"
                class="primary-button-filled"
                mat-button
                type="button">
                <span i18n="@@update">Update</span>
            </button>
        </div>
    </form>
</mat-dialog-content>
