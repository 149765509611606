import { Component, HostListener, Inject, Signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AbstractUserDataService, FeatureFlag } from '@shared/utils';
import {
    CalculationProperties,
    CompanyLocation,
    Employee,
    UserInfo,
} from '@upscore-mobility-audit/api';
import { GeocodingData } from '@upscore-mobility-audit/shared/interfaces/geocoding-data.interface';
import { GeocodingEmployeeDataService } from '@upscore-mobility-audit/shared/services/geocoding-employee-data.service';

@Component({
    selector: 'view-edit-employees',
    templateUrl: './view-edit-employees.component.html',
    styleUrls: ['./view-edit-employees.component.scss'],
})
export class ViewEditEmployeesComponent {
    public allowGeoCodingQuality: boolean;
    public geocodingData?: Signal<GeocodingData>;

    public hasChanges = false;

    constructor(
        private userDataService: AbstractUserDataService<UserInfo>,
        private geocodingEmployeeDataService: GeocodingEmployeeDataService,
        private dialogRef: MatDialogRef<ViewEditEmployeesComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            companyLocation: CompanyLocation;
            employees: Employee[];
            companyDepartments?: string[];
            searchEmployeeInfo?: number;
            userProperties?: CalculationProperties;
            companyProperties?: CalculationProperties;
            displayLimitedEmployeesInfo: boolean;
        },
    ) {
        this.allowGeoCodingQuality = this.userDataService.showFeature(
            FeatureFlag.GEOCODING_QUALITY,
        );
        if (this.allowGeoCodingQuality) {
            this.geocodingData = this.geocodingEmployeeDataService.getGeocodingData(
                data.companyLocation.id,
            );
        }

        dialogRef.backdropClick().subscribe(() => {
            // Close the dialog
            dialogRef.close({ hasChanges: this.hasChanges });
        });
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.dialogRef.close({ hasChanges: this.hasChanges });
    }

    public onChange() {
        if (this.allowGeoCodingQuality) {
            this.geocodingEmployeeDataService.loadGeocodingData(this.data.companyLocation.id);
        }
        this.hasChanges = true;
    }
}
