import { Component, Input } from '@angular/core';

import { NumberUnit } from '@shared/utils';

@Component({
    selector: 'increase-header',
    templateUrl: './increase-header.component.html',
    styleUrls: ['./increase-header.component.scss'],
})
export class IncreaseHeaderComponent {
    @Input() public baselineValue: number;
    @Input() public baselineDaily: number;
    @Input() public currValue: number;
    @Input() public showDailyComparisonValue: boolean;
    @Input() public unit: NumberUnit | string;
    @Input() public digitsInfo?: string;
    @Input() public showTimePeriod = true;

    @Input() public showPercentageChangePrefix = true;
    @Input() public isPercentageChange = false;

    public numberUnit: typeof NumberUnit = NumberUnit;
}
