import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

import { TaskNotificationService } from '../../services';
import { NotificationListComponent } from '../notification-list/notification-list.component';

@Component({
    selector: 'upscore-user-notifications',
    standalone: true,
    imports: [
        CommonModule,
        MatDivider,
        MatIcon,
        MatIconButton,
        MatMenu,
        MatMenuItem,
        MatMenuTrigger,
        NotificationListComponent,
    ],
    templateUrl: './user-notifications.component.html',
    styleUrl: './user-notifications.component.scss',
})
export class UserNotificationsComponent {
    @Input() public tasks = 0;
    constructor(public taskNotificationService: TaskNotificationService) {}
}
