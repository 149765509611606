import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

import { ExportButtonDirective } from '@upscore-mobility-audit/shared-ui/directives/export-button.directive';
import { ExportDirective } from '@upscore-mobility-audit/shared-ui/directives/export.directive';

@Component({
    selector: 'export-menu',
    standalone: true,
    imports: [
        CommonModule,
        ExportButtonDirective,
        MatIcon,
        MatIconButton,
        MatMenu,
        MatMenuItem,
        MatMenuTrigger,
    ],
    templateUrl: './export-menu.component.html',
    styleUrl: './export-menu.component.scss',
})
export class ExportMenuComponent {
    @Input({ required: true }) exportTriggerFor: ExportDirective;
}
