<div [formGroup]="parentFormGroup" class="estimate-employee-wrapper">
    <h2 class="selected-page-headline" i18n="@@estimateEmployees">Estimate Employees</h2>
    <div class="form">
        <mat-form-field appearance="outline" class="full-width-form-field">
            <mat-label i18n="@@employeeCount">Employees</mat-label>
            <input
                [max]="10000"
                [min]="10"
                formControlName="employeeCount"
                matInput
                placeholder="760"
                required
                type="number" />
        </mat-form-field>
        @if (entrances.length > 1) {
            <mat-form-field appearance="outline">
                <mat-label i18n="@@location">Location</mat-label>
                <mat-select formControlName="entranceNumber">
                    @for (entrance of entrances; track entrance) {
                        <mat-option [value]="entrance.number">
                            {{ entrance.number }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
    </div>
    <callout
        type="info"
        header="How locations are estimated"
        i18n-header="@@employeesEstimatedHeader"
        i18n="@@employeesEstimatedInfo"
        >Employee locations are estimated based on population and commuter data available in your
        region.
        <br />
        Depending on the distance to the company, the number of presence days a week for estimated
        employees varies.
    </callout>
</div>
