import { Injectable } from '@angular/core';
import { Position } from '@turf/helpers';

import {
    defaultMapOptions,
    defaultMarkerOptions,
    defaultPolygonOptions,
    defaultPolylineOptions,
} from '../constants';
import { EmployeeMarkerImages } from '../enums';
import {
    Employee,
    EmployeeIconOptions,
    EmployeesModes,
    IdMarkerOptions,
    Location,
    ModeString,
    WeightedCoordinate,
} from '../interfaces';

import PolylineOptions = google.maps.PolylineOptions;

@Injectable({
    providedIn: 'root',
})
export class MapService {
    /**
     * Method to build map options
     * @param location
     */
    public buildMapOptions(
        location: Location | { latitude: number; longitude: number } | Location[],
    ): google.maps.MapOptions {
        if (Array.isArray(location)) {
            const bounds = new google.maps.LatLngBounds();
            location.forEach((loc: Location) => {
                bounds.extend(new google.maps.LatLng(loc.latitude, loc.longitude));
            });

            const center: google.maps.LatLng = bounds.getCenter();

            return {
                ...defaultMapOptions,
                center: center,
                zoom: location.length > 1 ? 8 : 10,
            };
        }

        return {
            ...defaultMapOptions,
            center: {
                lat: location.latitude,
                lng: location.longitude,
            },
        };
    }

    /**
     * Method to build heatmap data with weighted coordinates
     * @param weightedCoordinates
     */
    public buildHeatmapData(
        weightedCoordinates: WeightedCoordinate[],
    ): google.maps.visualization.WeightedLocation[] {
        const heatmapData: google.maps.visualization.WeightedLocation[] = [];
        weightedCoordinates.forEach(weightedCoordinate => {
            heatmapData.push({
                location: new google.maps.LatLng(weightedCoordinate.lat, weightedCoordinate.lng),
                weight: weightedCoordinate.weight,
            });
        });

        return heatmapData;
    }

    /**
     * Method to get weighted locations for array of locations
     * @param data
     */
    public getWeightedLocations(
        data: { lat: number; lng: number }[],
    ): google.maps.visualization.WeightedLocation[] {
        return data
            .filter((dataset, index, self) => {
                return (
                    index ===
                    self.findIndex(
                        dataset2 => dataset2.lat === dataset.lat && dataset2.lng === dataset.lng,
                    )
                );
            })
            .map(dataset => {
                return {
                    weight: data.filter(
                        dataset2 => dataset.lat === dataset2.lat && dataset.lng === dataset2.lng,
                    ).length,
                    location: new google.maps.LatLng(dataset.lat, dataset.lng),
                };
            });
    }

    /**
     * Method to build a new marker
     * @param markerOptions
     */
    public buildMarker(
        markerOptions: google.maps.marker.AdvancedMarkerElementOptions,
    ): google.maps.marker.AdvancedMarkerElement {
        return new google.maps.marker.AdvancedMarkerElement(markerOptions);
    }

    /**
     * Method to build new makers
     * @param markerOptions
     */
    public buildMarkers(
        markerOptions: google.maps.marker.AdvancedMarkerElementOptions[],
    ): google.maps.marker.AdvancedMarkerElement[] {
        const markers: google.maps.marker.AdvancedMarkerElement[] = [];
        for (const option of markerOptions) {
            markers.push(this.buildMarker(option));
        }

        return markers;
    }

    /**
     * Method to build the polygon options
     * @parma polygonOptions
     */
    public buildPolygonOptions(
        polygonOptions: google.maps.PolygonOptions,
    ): google.maps.PolygonOptions {
        return {
            ...defaultPolygonOptions,
            ...polygonOptions,
        };
    }

    /**
     * Method to build the polyline options
     * @parma polylineOptions
     */
    public buildPolylineOptions(
        polylineOptions: google.maps.PolylineOptions,
    ): google.maps.PolylineOptions {
        return {
            ...defaultPolylineOptions,
            ...polylineOptions,
        } as PolylineOptions;
    }

    /**
     * Method to toggle employee marker visibility
     * @param markerOptions
     * @param visibility
     */
    public toggleEmployeeMarkerVisibility(
        markerOptions: google.maps.MarkerOptions[],
        visibility: boolean,
    ): google.maps.MarkerOptions[] {
        return markerOptions.map(option => Object.assign(option, { visible: visibility }));
    }

    /**
     * Method to build the company location marker options
     * @param location
     */
    public buildCompanyLocationAdvancedMarkerOption(
        location: Location,
        entranceNumber: number,
    ): google.maps.marker.AdvancedMarkerElementOptions {
        const imgIcon = document.createElement('img');
        imgIcon.style.width = '110px';
        imgIcon.style.height = '110px';
        // aligns center to lat lng position
        imgIcon.style.transform = 'translateY(50%)';
        imgIcon.src = 'assets/images/map/map-company.png';

        return {
            position: {
                lat: location.latitude,
                lng: location.longitude,
            },
            zIndex: 99999999999,
            content: imgIcon,
        };
    }

    /**
     * Method to build the company location marker options
     * @param location
     */
    public buildCompanyLocationMarkerOptions(
        location: Location,
        entranceNumber: number,
    ): google.maps.MarkerOptions | { id: string } {
        return {
            ...defaultMarkerOptions,
            position: {
                lat: location.latitude,
                lng: location.longitude,
            },
            zIndex: 99999999999,
            icon: {
                url: 'assets/images/map/map-company.png',
                scaledSize: new google.maps.Size(110, 110, 'px', 'px'),
                anchor: new google.maps.Point(55, 55),
            },
            id: `loc-${entranceNumber}`,
        };
    }

    /**
     * Method to build the employees marker options
     *
     * The mode that specifies the marker image is defined as follows:
     * - If {@link isPackageMode} is set to `true`, then the package mode is significant
     * - Otherwise, if {@link showOptimal} is set to `true`, then {@link Employee.optimalMode} is significant
     * - Otherwise, {@link Employee.currentMode} is significant
     *
     * @param employeeToDisplay information about which employees to display
     * @param iconOptions
     * @param visibility
     * @param employeeMode
     * @param clickable
     */
    public buildEmployeesMarkersOptions(
        employeeToDisplay: Employee[],
        employeeMode: EmployeesModes,
        iconOptions: EmployeeIconOptions,
        visibility = true,
        clickable = false,
        map: google.maps.Map,
    ): IdMarkerOptions[] {
        return employeeToDisplay.map((employee: Employee) => {
            const id = employee.id;
            const newUrl: string = this.chooseIconUrl(employeeMode[id], iconOptions);

            // When adding new properties also add these to map.component.ts handleOldMarker
            return {
                ...defaultMarkerOptions,
                visible: visibility && newUrl.length > 0,
                clickable,
                position: {
                    lat: employee.latitude,
                    lng: employee.longitude,
                },
                icon: {
                    url: newUrl,
                    scaledSize: new google.maps.Size(30, 30, 'px', 'px'),
                },
                zIndex: 5,
                employee: employee,
                mode: employeeMode[id],
                id: `emp-${employee.id}`,
            };
        });
    }

    /**
     * Method to build the employee marker options
     *
     * The mode that specifies the marker image is defined as follows:
     * - If {@link isPackageMode} is set to `true`, then the package mode is significant
     * - Otherwise, if {@link showOptimal} is set to `true`, then {@link Employee.optimalMode} is significant
     * - Otherwise, {@link Employee.currentMode} is significant
     *
     * @param employee
     * @param mode
     * @param iconOptions
     * @param visibility
     * @param clickable
     * @param map
     */
    public buildEmployeeMarkersOptions(
        employee: Employee,
        mode: ModeString,
        iconOptions: EmployeeIconOptions,
        clickable = false,
    ): google.maps.marker.AdvancedMarkerElementOptions {
        const iconImg = document.createElement('img');
        iconImg.style.width = '30px';
        iconImg.style.height = '30px';
        iconImg.src = this.chooseIconUrl(mode, iconOptions);

        return {
            gmpClickable: clickable,
            position: {
                lat: employee.latitude,
                lng: employee.longitude,
            },
            content: iconImg,
            zIndex: 5,
        };
    }

    /**
     * Method to get marker options of a provided marker
     * @param marker
     */
    public getMarkerOptions(marker: google.maps.Marker): google.maps.MarkerOptions {
        return {
            animation: marker.getAnimation(),
            clickable: marker.getClickable(),
            cursor: marker.getCursor(),
            draggable: marker.getDraggable(),
            icon: marker.getIcon(),
            label: marker.getLabel(),
            opacity: marker.getOpacity(),
            position: marker.getPosition(),
            shape: marker.getShape(),
            visible: marker.getVisible(),
            zIndex: marker.getZIndex(),
            title: marker.getTitle(),
        };
    }

    /**
     * Method to build the polygon paths for provided coordinates from a feature
     * @param coordinates
     */
    public buildPolygonPathsForCoordinates(
        coordinates: number[][] | Position[][] | number[][][][],
    ): google.maps.LatLngLiteral[][] {
        const polygonPath: google.maps.LatLngLiteral[][] = [];
        this.scanFeatureGeometryCoordinateDataForPolygonPath(coordinates, polygonPath);

        return polygonPath;
    }

    public chooseIconUrl(modeType: string | undefined, iconOptions: EmployeeIconOptions): string {
        switch (modeType) {
            case 'BIKE':
                return iconOptions.bike as string;
            case 'WALK':
                return iconOptions.walk as string;
            case 'PUBLIC_TRANSPORT':
                return iconOptions.pt as string;
            case 'CAR_DRIVER':
                return iconOptions.carDriver as string;
            case 'CAR_PASSENGER':
                return iconOptions.carPassenger as string;
            default:
                // when mobility score hasn't been calculated, the optimal isn't set. So we use a default marker.
                return EmployeeMarkerImages.DEFAULT as string;
        }
    }

    public buildCircleMarkerOptions(
        position: google.maps.marker.AdvancedMarkerElement['position'] | null | undefined,
        color: any,
    ): google.maps.marker.AdvancedMarkerElementOptions {
        const circleDiv = document.createElement('div');
        circleDiv.style.width = '20px';
        circleDiv.style.height = '20px';
        circleDiv.style.borderRadius = '50%';
        circleDiv.style.backgroundColor = color;
        circleDiv.style.transform = 'translateY(25%)';
        circleDiv.style.opacity = '0.8';

        return {
            position: position,
            zIndex: -99,
            content: circleDiv,
            gmpDraggable: false,
        };
    }

    /**
     * Method to scan the feature geometry coordinates data array for a polygon path
     * This is needed because multi-polygons have a different structure than normal polygons
     * @param coordinates
     * @param polygonPath
     */
    private scanFeatureGeometryCoordinateDataForPolygonPath(
        coordinates: number[] | number[][] | Position[][] | number[][][][],
        polygonPath: google.maps.LatLngLiteral[][],
    ): void {
        coordinates?.forEach((coordinatesArray: number | number[] | number[][] | number[][][]) => {
            if (
                Array.isArray(coordinatesArray) &&
                Array.isArray(coordinatesArray[0]) &&
                typeof coordinatesArray[0][0] === 'number' &&
                typeof coordinatesArray[0][1] === 'number'
            ) {
                polygonPath.push(
                    (coordinatesArray as number[][]).map(coordinate => {
                        return { lat: coordinate[1], lng: coordinate[0] };
                    }),
                );

                return;
            } else {
                this.scanFeatureGeometryCoordinateDataForPolygonPath(
                    coordinatesArray as number[][],
                    polygonPath,
                );

                return;
            }
        });
    }
}
