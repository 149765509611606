import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, catchError, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { TasksService } from '@upscore-mobility-audit/api';

@Injectable({
    providedIn: 'root',
})
export class TasksWrapperService {
    public _taskDeleted = new Subject<string>();
    public taskDeleted = this._taskDeleted.pipe(delay(300));

    constructor(private tasksService: TasksService) {}

    public deleteTask(taskId: string) {
        this._taskDeleted.next(taskId);

        return this.tasksService
            .cancelTask({ taskId })
            .pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse && error.status === 404) {
                        return of();
                    }

                    throw error;
                }),
            )
            .subscribe();
    }
}
