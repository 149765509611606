@if (title) {
    <div [attr.aria-label]="title" [class]="options.titleClass">
        {{ title }}
        @if (duplicatesCount) {
            [{{ duplicatesCount + 1 }}]
        }
    </div>
}

@if (message && options.enableHtml) {
    <div
        [class]="options.messageClass"
        [innerHTML]="message"
        aria-live="polite"
        role="alertdialog"
        style="display: inline-block !important"></div>
} @else if (message && !options.enableHtml) {
    <div
        [attr.aria-label]="message"
        [class]="options.messageClass"
        aria-live="polite"
        role="alertdialog"
        style="display: inline-block !important">
        {{ message }}
    </div>
}
@if (rawMessage) {
    <div
        [attr.aria-label]="message"
        [class]="options.messageClass"
        aria-live="polite"
        id="messagebody"
        role="alertdialog">
        @if (!expandRawMessage) {
            <p i18n="@@fullErrorFeedback" style="flex: 1">Check The Full Error Message</p>
        }
        @if (rawMessageIsJson && expandRawMessage) {
            <pre
                [ngStyle]="
                    expandRawMessage
                        ? { 'white-space': 'pre-wrap' }
                        : { 'white-space': 'nowrap', overflow: 'hidden', flex: 1 }
                "
                >{{ rawMessage | json }}</pre
            >
        }
        @if (!rawMessageIsJson && expandRawMessage) {
            <p
                [ngStyle]="
                    expandRawMessage ? {} : { 'white-space': 'nowrap', overflow: 'hidden', flex: 1 }
                ">
                {{ message }}
            </p>
        }
        @if (!expandRawMessage) {
            <div style="display: inline-block; background-color: rgba(138, 47, 38, 1)">
                <mat-icon>expand_more</mat-icon>
            </div>
        }
    </div>
}

@if (informationLink || options.closeButton || showDashboardButton) {
    <div class="footer">
        @if (informationLink) {
            <span i18n="@@errorInformation">
                More Information: <a href="{{ informationLink }}">HELP</a>
            </span>
        } @else {
            <span></span>
        }
        <div class="buttons">
            @if (custom && custom.problematicEmployeeIds) {
                <button
                    (click)="openEmployeeEdit(custom.problematicEmployeeIds)"
                    class="report-button"
                    color="warn"
                    mat-flat-button>
                    <span i18n="@@editEmployees">Edit Employees</span>
                </button>
            }
            @if (enableReport) {
                <button (click)="reportError()" class="report-button" color="warn" mat-flat-button>
                    <span i18n="@@report">Report</span>
                </button>
            }
            @if (showDashboardButton) {
                <button
                    (click)="onGoToDashboardClick()"
                    class="report-button"
                    color="primary"
                    mat-flat-button>
                    <span i18n="@@returnToDashboard">Go To Dashboard</span>
                </button>
            }
            @if (options.closeButton) {
                <button (click)="remove()" class="close-button" color="warn" mat-flat-button>
                    <span i18n="@@close">Close</span>
                </button>
            }
        </div>
    </div>
}
@if (options.progressBar) {
    <div>
        <div [style.width]="width + '%'" class="toast-progress"></div>
    </div>
}
