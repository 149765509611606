export class OptimumStepTranslations {
    /**
     * Get Info Text
     */
    public static getInfoText(
        time: number,
        employeeCount: number,
        employeePercentage: number,
    ): string {
        return $localize`:@@reachableIn:Reachable in ${time} min(s), includes ${employeeCount} (${Math.round(
            employeePercentage,
        )}%) Employees`;
    }
}
