<div class="header-wrapper">
    <span class="text" i18n="@@area">KPI</span>
    <span class="text" i18n="Yearly@@annual">annual</span>
    <span class="text" i18n="@@dailyKpi">per day</span>
</div>

<div class="body-wrapper">
    @for (entry of entries; track entry; let last = $last) {
        <hr />
        <!-- first row -->
        <div class="row-wrapper">
            <div class="flex items-center gap-[11px]">
                @if (entry.color) {
                    <span [style.background-color]="entry.color" class="dot"></span>
                }
                <span class="tps-body-2 font-bold">{{ entry.title }}</span>
            </div>
            <div>
                <kpi-measure
                    [digitsInfo]="entry.digitsInfo"
                    [measureCssClass]="
                        'kpi-measure__measure--color-primary kpi-measure__measure--text-xs'
                    "
                    [unit]="entry.unit"
                    [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-sm'"
                    [value]="entry.yearly"></kpi-measure>
            </div>
            <div>
                <kpi-measure
                    [digitsInfo]="entry.digitsInfo"
                    [measureCssClass]="
                        'kpi-measure__measure--color-primary kpi-measure__measure--text-xs'
                    "
                    [unit]="entry.unit"
                    [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-sm'"
                    [value]="
                        useDailyPipe ? (entry.yearly | dailyKpi | async) : entry.daily
                    "></kpi-measure>
            </div>
        </div>
        <!--  second row -->
        @if (entry.baselineYearly; as baselineYearly) {
            <div class="row-wrapper">
                <div [style.paddingLeft.px]="entry.color ? '22' : ''">
                    <kpi-measure
                        [measureCssClass]="
                            'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                        "
                        [showChangePrefix]="true"
                        [unit]="numberUnit.PERCENTAGE"
                        [valueCssClass]="
                            'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                        "
                        [value]="entry.yearly | percentageChange: baselineYearly"></kpi-measure>
                </div>
                <kpi-measure
                    [digitsInfo]="entry.digitsInfo"
                    [measureCssClass]="
                        'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                    "
                    [unit]="entry.unit"
                    [valueCssClass]="
                        'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                    "
                    [value]="baselineYearly"></kpi-measure>
                <kpi-measure
                    [digitsInfo]="entry.digitsInfo"
                    [measureCssClass]="
                        'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                    "
                    [unit]="entry.unit"
                    [valueCssClass]="
                        'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                    "
                    [value]="
                        useDailyPipe ? (baselineYearly | dailyKpi | async) : entry.baselineDaily
                    "></kpi-measure>
            </div>
        }
        @if (!last) {
            <div class="divider"></div>
        }
    }
</div>
