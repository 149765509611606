import { EmployeeMarkerImages } from '@shared/map';
import { getMeasureSections } from '@upscore-mobility-audit/core/constants/measure-section.constant';
import { getOptimumSections } from '@upscore-mobility-audit/core/constants/optimum-section.constant';
import { getStatusQuoSections } from '@upscore-mobility-audit/core/constants/status-quo-section.constant';
import { MapType } from '@upscore-mobility-audit/core/enums/map-type.enum';
import { ReachabilityOptions } from '@upscore-mobility-audit/core/interfaces/reachability-options.interface';
import { SidebarMapOptions } from '@upscore-mobility-audit/core/interfaces/sidebar-map-options.interface';
import { ClusterOptions } from '@upscore-mobility-audit/map/interfaces/cluster-options.interface';
import { SidebarSection } from '@upscore-mobility-audit/shared/interfaces/sidebar-section.interface';

import { sidebarDistanceRadius } from './sidebar-distance-radius.constant';

function createMapOption(mapType: MapType, sections: SidebarSection[]): SidebarMapOptions {
    return {
        iconOptions: {
            walk: EmployeeMarkerImages.WALK,
            bike: EmployeeMarkerImages.BIKE,
            pt: EmployeeMarkerImages.PT,
            carDriver: EmployeeMarkerImages.DRIVER,
            carPassenger: EmployeeMarkerImages.PASSENGER,
        },
        reachabilityOptions: {
            walk: {
                show: false,
                value: null,
            },
            bike: {
                show: false,
                value: null,
            },
            car: {
                show: false,
                value: null,
            },
            transit: {
                show: false,
                value: null,
            },
            radius: {
                show: false,
                value: sidebarDistanceRadius,
            },
        } as ReachabilityOptions,
        showCarpooling: false,
        showHeatMap: false,
        showEmployees: false,
        clusterEmployees: false,
        clusterOptions: {
            algorithm: 'default',
            renderer: 'default',
        } as ClusterOptions,
        type: mapType,
        sections: sections,
        selectedTabIndex: 0,
    };
}

export function getSidebarMapOptions(): { [id in MapType]: SidebarMapOptions } {
    return {
        [MapType.CURRENT]: createMapOption(MapType.CURRENT, getStatusQuoSections()),
        [MapType.OPTIMUM]: createMapOption(MapType.OPTIMUM, getOptimumSections()),
        [MapType.PACKAGE]: createMapOption(MapType.PACKAGE, getMeasureSections()),
    };
}
