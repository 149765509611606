/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SmallScoreResponse } from '../models/small-score-response';

@Injectable({
  providedIn: 'root',
})
export class ScoreControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getScore
   */
  static readonly GetScorePath = '/api/v1/score/{companyLocationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScore()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScore$Response(params: {
    companyLocationId: number;
    forceDistanceRecalculation?: boolean;
    forceScoreRecalculation?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SmallScoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScoreControllerService.GetScorePath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('forceDistanceRecalculation', params.forceDistanceRecalculation, {});
      rb.query('forceScoreRecalculation', params.forceScoreRecalculation, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmallScoreResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScore(params: {
    companyLocationId: number;
    forceDistanceRecalculation?: boolean;
    forceScoreRecalculation?: boolean;
    context?: HttpContext
  }
): Observable<SmallScoreResponse> {

    return this.getScore$Response(params).pipe(
      map((r: StrictHttpResponse<SmallScoreResponse>) => r.body as SmallScoreResponse)
    );
  }

  /**
   * Path part for operation getScoreAsync
   */
  static readonly GetScoreAsyncPath = '/api/v1/async/score/{companyLocationId}';

  /**
   * Calculate the mobility score of a companyLocation.
   *
   * If results are already available this returns a score directly in a 200 response, otherwise an async calculation is started and the task definition is returned in a 202 response
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScoreAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoreAsync$Response(params: {
    companyLocationId: number;
    forceDistanceRecalculation?: boolean;
    forceScoreRecalculation?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SmallScoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScoreControllerService.GetScoreAsyncPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('forceDistanceRecalculation', params.forceDistanceRecalculation, {});
      rb.query('forceScoreRecalculation', params.forceScoreRecalculation, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmallScoreResponse>;
      })
    );
  }

  /**
   * Calculate the mobility score of a companyLocation.
   *
   * If results are already available this returns a score directly in a 200 response, otherwise an async calculation is started and the task definition is returned in a 202 response
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScoreAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoreAsync(params: {
    companyLocationId: number;
    forceDistanceRecalculation?: boolean;
    forceScoreRecalculation?: boolean;
    context?: HttpContext
  }
): Observable<SmallScoreResponse> {

    return this.getScoreAsync$Response(params).pipe(
      map((r: StrictHttpResponse<SmallScoreResponse>) => r.body as SmallScoreResponse)
    );
  }

}
