import {
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Chart, ChartData } from 'chart.js';

import { ThemeService } from '@shared/utils';
import { MobilityStats } from '@upscore-mobility-audit/api';

import { ModalSplitCardTranslations } from '../../translations/modal-split-card-translations';

@Component({
    selector: 'modal-split-change-chart',
    templateUrl: './modal-split-change-chart.component.html',
    styleUrls: ['./modal-split-change-chart.component.scss'],
})
export class ModalSplitChangeChartComponent implements OnInit, OnChanges {
    @Input() public mobilityStats: MobilityStats;
    @Input() public baselineMobilityStats: MobilityStats;

    @ViewChild('modalSplitChangeCanvas', { static: true })
    public modalSplitChangeCanvas: ElementRef<HTMLCanvasElement>;
    public modalSplitChangeChart: Chart;

    public translations: typeof ModalSplitCardTranslations = ModalSplitCardTranslations;

    private chartData: number[];
    private chartComparisonData: number[];
    private chartColor: string[];
    private chartComparisonColor: string[];
    private chartLabel: string[];

    constructor(
        private readonly zone: NgZone,
        private readonly themeService: ThemeService,
    ) {}

    public ngOnInit(): void {
        this.zone.runOutsideAngular(() => {
            this.initChart();
        });
        this.addChartData();
    }

    /**
     * Angular Lifecycle
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes['baselineMobilityStats'] &&
                !changes['baselineMobilityStats'].isFirstChange()) ||
            (changes['mobilityStats'] && !changes['mobilityStats'].isFirstChange())
        ) {
            this.addChartData();
        }
    }

    public createChartLabel(mode: string, increase: number) {
        return mode + (increase >= 0 ? ` (+${increase}%)` : ` (${increase}%)`);
    }

    private addValue(
        value: number,
        oldValue: number,
        colorVar: string,
        comparisonColorVar: string,
        translations: string,
    ): void {
        this.chartData.push(value * 100);
        this.chartColor.push(this.themeService.getHexFromCSSVariable(colorVar));

        this.chartComparisonData.push(oldValue * 100);
        this.chartComparisonColor.push(this.themeService.getHexFromCSSVariable(comparisonColorVar));

        this.chartLabel.push(
            this.createChartLabel(translations, this.calculatePercentageChange(oldValue, value)),
        );
    }

    /**
     * init chart data
     */
    private addChartData(): void {
        if (!this.baselineMobilityStats || !this.mobilityStats) {
            return;
        }

        this.chartLabel = [];
        this.chartData = [];
        this.chartColor = [];
        this.chartComparisonData = [];
        this.chartComparisonColor = [];

        this.addValue(
            this.mobilityStats.walk,
            this.baselineMobilityStats.walk,
            '--walk-color',
            '--light-walk-color',
            ModalSplitCardTranslations.walk,
        );
        this.addValue(
            this.mobilityStats.bike,
            this.baselineMobilityStats.bike,
            '--bike-color',
            '--light-bike-color',
            ModalSplitCardTranslations.bike,
        );
        this.addValue(
            this.mobilityStats.transit,
            this.baselineMobilityStats.transit,
            '--public-transport-color',
            '--light-public-transport-color',
            ModalSplitCardTranslations.pt,
        );
        this.addValue(
            this.mobilityStats.carDriver,
            this.baselineMobilityStats.carDriver,
            '--car-driver-color',
            '--light-car-driver-color',
            ModalSplitCardTranslations.carDriver,
        );
        this.addValue(
            this.mobilityStats.carPassenger,
            this.baselineMobilityStats.carPassenger,
            '--car-passenger-color',
            '--light-car-passenger-color',
            ModalSplitCardTranslations.carPassenger,
        );

        this.modalSplitChangeChart.data.datasets = [
            {
                data: this.chartData,
                backgroundColor: this.chartColor,
                borderRadius: 50,
                barThickness: 10,
            },
            {
                data: this.chartComparisonData,
                backgroundColor: this.chartComparisonColor,
                borderRadius: 50,
                barThickness: 10,
            },
        ];
        this.modalSplitChangeChart.data.labels = this.chartLabel;
        this.modalSplitChangeChart.update();
    }

    private initChart(): void {
        const ctx = this.modalSplitChangeCanvas.nativeElement.getContext('2d');

        if (ctx != null) {
            this.modalSplitChangeChart = new Chart(ctx, {
                type: 'bar',
                // TODO fix in future version
                data: null as unknown as ChartData<'bar', number[], string>,
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    devicePixelRatio: 2,
                    indexAxis: 'y',
                    responsive: true,
                    aspectRatio: 1.5,
                    scales: {
                        x: {
                            ticks: {
                                display: false,
                            },
                            border: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            ticks: {
                                color: '#1d1c2d',
                                font: {
                                    family: 'source-sans-pro-regular',
                                    size: 13,
                                    weight: 'normal',
                                },
                            },
                            border: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                        },
                    },
                },
            });
        }
    }

    private calculatePercentageChange(oldP: number, newP: number) {
        return Math.round(100 * newP) - Math.round(100 * oldP);
    }
}
