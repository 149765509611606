// tslint:disable-next-line:max-classes-per-file

import { ModeString } from '@shared/map';

import { CriteriaMetricTypeEnum } from '../enums/criteria-metric-type.enum';
import { CriteriaTypeEnum } from '../enums/criteria-type.enum';

enum Modes {
    'Walk' = 'Walk',
    'Bike' = 'Bike',
    'PublicTransport' = 'PublicTransport',
    'Car' = 'Car',
}

function modeFromModeString(mode: ModeString): string {
    switch (mode) {
        case 'WALK':
            return Modes.Walk;
        case 'BIKE':
            return Modes.Bike;
        case 'PUBLIC_TRANSPORT':
            return Modes.PublicTransport;
        case 'CAR_DRIVER':
        case 'CAR_DRIVER_TRAFFIC':
            return Modes.Car;
        default:
            throw Error(`Mode not found: ${mode}`);
    }
}

interface BasicCriteria {
    type: CriteriaTypeEnum;
    negate?: boolean;
}

interface NotExtension {
    not: boolean;
}

interface NumberOfChangesCriteria extends BasicCriteria {
    value: number;
    min: number;
    max: number;
    step: number;
}

interface MetricCriteria extends BasicCriteria, NotExtension {
    metric: { type: CriteriaMetricTypeEnum };
    value: number;
    min: number;
    max: number;
    step: number;
}

interface DiffCriteria extends MetricCriteria, NotExtension {
    toCompare: Modes;
}

type Criteria = DiffCriteria | MetricCriteria | NumberOfChangesCriteria | BasicCriteria;

interface NotCriteria extends BasicCriteria {
    criteria: Criteria;
}

interface AndCriteria extends BasicCriteria {
    first: Criteria | AndCriteria;
    second: Criteria | AndCriteria;
}

type LogicOperator = CriteriaTypeEnum.OrCriteria | CriteriaTypeEnum.AndCriteria;

export {
    Modes,
    Criteria,
    DiffCriteria,
    BasicCriteria,
    MetricCriteria,
    AndCriteria,
    LogicOperator,
    NotCriteria,
    NumberOfChangesCriteria,
    modeFromModeString,
};
