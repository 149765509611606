import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { CompanyLocationDataService } from '@upscore-mobility-audit/core/data-services/company-location-data.service';

import { UpscoreTaskData } from '../../interfaces/task-data.interface';
import { TaskNotificationComponent } from '../task-notification/task-notification.component';

@Component({
    selector: 'upscore-notification-list',
    standalone: true,
    imports: [CommonModule, TaskNotificationComponent],
    templateUrl: './notification-list.component.html',
    styleUrl: './notification-list.component.scss',
})
export class NotificationListComponent {
    @Input() public tasks: UpscoreTaskData[];

    constructor(public companyLocationDataService: CompanyLocationDataService) {}
}
