import { Pipe, PipeTransform } from '@angular/core';

import { UtilsService } from '@upscore-mobility-audit/shared/services/utils.service';

@Pipe({
    name: 'percentageChange',
})
export class PercentageChangePipe implements PipeTransform {
    constructor(private readonly utilsService: UtilsService) {}

    public transform(value: number, compareValue: number): number {
        return this.utilsService.calculatePercentageChange(compareValue, value);
    }
}
