import {
    AfterViewInit,
    ContentChildren,
    Directive,
    ElementRef,
    Input,
    QueryList,
} from '@angular/core';

import { ExportButtonDirective } from '@upscore-mobility-audit/shared-ui/directives/export-button.directive';
import {
    ContentType,
    ExportFormat,
    ExportService,
} from '@upscore-mobility-audit/shared-ui/services/export.service';

/**
 * Directive that marks an element that can be exported as an image or a pdf.
 *
 * You can either trigger the export programmatically by calling the `export` method, include
 * an `exportButton` as a descendent of the `export` directive or link the `exportButton` to the
 * `export` directive by setting the `exportTriggerFor` input.
 */
@Directive({
    standalone: true,
    selector: '[export]',
    exportAs: 'toExport',
})
export class ExportDirective implements AfterViewInit {
    @Input({ required: true }) exportTitle: string;
    @Input() contentType: ContentType = 'kpi';
    @ContentChildren(ExportButtonDirective, { descendants: true })
    exportButtons: QueryList<ExportButtonDirective>;

    constructor(
        private elementRef: ElementRef,
        private exportService: ExportService,
    ) {}

    ngAfterViewInit(): void {
        this.exportButtons.forEach(button => {
            button.export.subscribe(format => {
                this.export(format);
            });
        });
    }

    export(format: ExportFormat) {
        this.exportService.export({
            element: this.elementRef.nativeElement,
            format: format,
            contentType: this.contentType,
            title: this.exportTitle,
        });
    }
}
