import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { UpscoreTableInput } from '@upscore-mobility-audit/shared/interfaces/upscore-table-input.interface';

@Component({
    selector: 'upscore-simple-table',
    templateUrl: './upscore-simple-table.component.html',
    styleUrls: ['./upscore-simple-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpscoreSimpleTableComponent implements AfterViewInit {
    @Input() public editable = false;
    @Input() public searchable = false;
    @Input() public headerData: UpscoreTableInput[] = [];
    @Input() public pageSizes: number[] = [5, 10, 20];
    @Input() public pageSize = 5;
    @ViewChild(MatPaginator) public paginator!: MatPaginator;
    @ViewChild(MatSort) public sort!: MatSort;
    public dataSource: MatTableDataSource<unknown>;

    public get displayedColumns(): string[] {
        return this.headerData.map(header => header.display);
    }

    @Input()
    public set data(value: unknown[]) {
        this.dataSource = new MatTableDataSource(value);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(event: Event): void {
        const filterValue: string = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    public ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
}
