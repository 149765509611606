import { CriteriaMetricTypeEnum } from '../enums/criteria-metric-type.enum';
import { CriteriaTypeEnum } from '../enums/criteria-type.enum';
import { Modes } from '../interfaces/criteria-classes';
import { CriteriaConfig } from '../interfaces/criteria-config.interface';

import { plainCriteriaToCriteria } from './criteria-utils';

export function defaultCriteriaEstimates(): CriteriaConfig {
    return {
        Walk: [
            {
                ...plainCriteriaToCriteria({
                    type: CriteriaTypeEnum.CutoffCriteria,
                    metric: { type: CriteriaMetricTypeEnum.duration },
                }),
                value: 15,
            },
        ],
        Bike: [
            {
                ...plainCriteriaToCriteria({
                    type: CriteriaTypeEnum.CutoffCriteria,
                    metric: { type: CriteriaMetricTypeEnum.duration },
                }),
                value: 30,
            },
            {
                ...plainCriteriaToCriteria({
                    type: CriteriaTypeEnum.CutoffCriteria,
                    metric: { type: CriteriaMetricTypeEnum.distance },
                }),
                value: 15000,
            },
        ],
        Car: [
            plainCriteriaToCriteria({
                type: CriteriaTypeEnum.Always,
            }),
        ],
        PublicTransport: [
            {
                ...plainCriteriaToCriteria({
                    type: CriteriaTypeEnum.RelativeDiffCutoffCriteria,
                    metric: { type: CriteriaMetricTypeEnum.duration },
                }),
                value: 0.5,
                toCompare: Modes.Car,
            },
        ],
    };
}
