import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CalculationProperties, MobilityPropertiesService } from '@upscore-mobility-audit/api';

@Injectable({
    providedIn: 'root',
})
export class MobilityPropertiesWrapperService {
    constructor(private mobilityPropertiesService: MobilityPropertiesService) {}

    /**
     * get user mobility properties.
     */
    public getUserMobilityProperties(): Observable<CalculationProperties> {
        return this.mobilityPropertiesService.getUserMobilityProperties();
    }

    /**
     * set user mobility properties.
     */

    public setUserMobilityProperties(body: CalculationProperties): Observable<void> {
        return this.mobilityPropertiesService.setUserMobilityProperties({ body });
    }

    /**
     * Update user mobility properties.
     */
    public updateUserMobilityProperties(body: CalculationProperties): Observable<void> {
        return this.mobilityPropertiesService.updateUserMobilityProperties({ body });
    }

    /**
     * get company mobility properties.
     */
    public getLocationMobilityProperties(
        companyLocationId: number,
    ): Observable<CalculationProperties> {
        return this.mobilityPropertiesService.getLocationMobilityProperties({ companyLocationId });
    }

    /**
     * set user mobility properties.
     */

    public setLocationMobilityProperties(
        companyLocationId: number,
        body: CalculationProperties,
    ): Observable<void> {
        return this.mobilityPropertiesService.setLocationMobilityProperties({
            companyLocationId,
            body,
        });
    }

    /**
     * Update user mobility properties.
     */
    public updateLocationMobilityProperties(
        companyLocationId: number,
        body: CalculationProperties,
    ): Observable<void> {
        return this.mobilityPropertiesService.updateLocationMobilityProperties({
            companyLocationId,
            body,
        });
    }
}
