import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'error-cancel-continue-dialog',
    templateUrl: './error-cancel-continue-dialog.component.html',
    styleUrls: ['./error-cancel-continue-dialog.component.scss'],
})
export class ErrorCancelContinueDialogComponent {
    public continueText = $localize`:@@ERROR_CANCEL_CONTINUE_DIALOG.CONTINUE:Upload File`;
    public cancelText = $localize`:@@ERROR_CANCEL_CONTINUE_DIALOG.CANCEL:Cancel Upload`;
    public errorHeader = $localize`:@@ERROR_CANCEL_CONTINUE_DIALOG.HEADER_UNPARSED_COLUMNS:Some columns were ignored`;
    public errorText = $localize`:@@ERROR_CANCEL_CONTINUE_DIALOG.ERROR:An error occurred`;
    public questionText = $localize`:@@ERROR_CANCEL_CONTINUE_DIALOG.QUESTION:Do you want to continue?`;

    constructor(
        private readonly dialogRef: MatDialogRef<ErrorCancelContinueDialogComponent, boolean>,
        @Inject(MAT_DIALOG_DATA)
        public data?: {
            continueText?: string;
            cancelText?: string;
            errorText?: string;
        },
    ) {
        this.continueText = data?.continueText ?? this.continueText;
        this.cancelText = data?.cancelText ?? this.cancelText;
        this.errorText = data?.errorText ?? this.errorText;
    }

    public onCloseDialog(reason: boolean): void {
        this.dialogRef.close(reason);
    }
}
