import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Chart, ChartDataset } from 'chart.js';

import { NumberUnit, ThemeService } from '@shared/utils';
import { KpiCardInfoTranslations } from '@upscore-mobility-audit/core/translations/kpi-card-info.translations';
import { BaseKpiCardComponent } from '@upscore-mobility-audit/kpi/models/base-kpi-card.model';
import {
    MobilityScenario,
    MobilityScoreScenario,
} from '@upscore-mobility-audit/shared/interfaces/mobility-scenario.interface';

@Component({
    selector: 'mobility-score-card',
    templateUrl: './mobility-score-card.component.html',
    styleUrls: ['./mobility-score-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobilityScoreCardComponent extends BaseKpiCardComponent implements OnChanges {
    @Input() public mobilityScore: MobilityScenario;

    @Input() public improvedMobilityScore?: MobilityScenario;
    @Input() public hasMenu = true;

    @ViewChild('originalScoreChartCanvas', { static: true })
    public originalScoreChartCanvas: ElementRef<HTMLCanvasElement>;

    @ViewChild('optimumChartCanvas', { static: true })
    public optimumChartCanvas: ElementRef<HTMLCanvasElement>;

    @ViewChild('improvedScoreChartCanvas', { static: true })
    public improvedScoreChartCanvas: ElementRef<HTMLCanvasElement>;

    public unit: NumberUnit = NumberUnit.PERCENTAGE;

    // BUGNOTE: due to the fact that the UI Draft was not possible with one chart,
    // we use 3 charts above each other to illustrate the wanted UI
    public optimumChart: Chart | null;

    public improvedScoreChart: Chart | null;

    public originalScoreChart: Chart | null;

    public increase = 0;

    public currValue: number;

    public baselineValue: number;

    public showUnavailable: boolean;

    public translations: typeof KpiCardInfoTranslations = KpiCardInfoTranslations;

    constructor(
        private readonly themeService: ThemeService,
        private readonly zone: NgZone,
    ) {
        super();
    }

    /**
     * init chart js Douhgnut chart
     * @param canvas
     * @param data
     * @param colors
     */
    private static initChart(
        canvas: ElementRef<HTMLCanvasElement>,
        data: number[],
        colors: string[],
    ): Chart | null {
        const datasets: ChartDataset[] = [
            {
                data,
                backgroundColor: colors,
                borderWidth: 0,
            },
        ];
        const ctx = canvas.nativeElement.getContext('2d');

        if (ctx == null) {
            return null;
        }

        return new Chart(ctx, {
            // @ts-expect-error: RoundedDoughnut is our own defined chart
            type: 'RoundedDoughnut',
            data: {
                datasets,
            },
            options: {
                devicePixelRatio: 2,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                hover: { mode: undefined },
                aspectRatio: 1,
                // @ts-expect-error: options doesnt have cutout attribute but it still works...
                cutout: '80%',
            },
        });
    }

    /**
     * Angular Lifecycle
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        this.showUnavailable =
            (this.mobilityScore.scoreType !== 'MobilityScore' &&
                this.improvedMobilityScore == null) ||
            (this.improvedMobilityScore != null &&
                this.improvedMobilityScore.scoreType !== 'MobilityScore');

        if (changes['improvedMobilityScore'] || changes['mobilityScore']) {
            this.changes.next();
            if (!this.showUnavailable) {
                this.initData(
                    this.mobilityScore as MobilityScoreScenario,
                    this.improvedMobilityScore as MobilityScoreScenario,
                );
            }
        }
    }

    /**
     * init data method
     */
    private initData(
        mobilityScore: MobilityScoreScenario,
        improvedMobilityScore?: MobilityScoreScenario,
    ): void {
        if (!this.mobilityScore) {
            return;
        }

        this.currValue = Math.round(mobilityScore.mobilityScore);

        if (this.optimumChart) {
            this.optimumChart.destroy();
        }
        if (this.improvedScoreChart) {
            this.improvedScoreChart.destroy();
        }
        if (this.originalScoreChart) {
            this.originalScoreChart.destroy();
        }

        this.zone.runOutsideAngular(() => {
            this.optimumChart = MobilityScoreCardComponent.initChart(
                this.optimumChartCanvas,
                [100],
                [this.themeService.getHexFromCSSVariable('--ultralight-primary-color')],
            );

            if (mobilityScore.mobilityScore == null) {
                return;
            }

            this.originalScoreChart = MobilityScoreCardComponent.initChart(
                this.originalScoreChartCanvas,
                [mobilityScore.mobilityScore, mobilityScore.mobilityScore - 100],
                [this.themeService.getHexFromCSSVariable('--primary-color'), 'rgba(0, 0, 0, 0)'],
            );
        });

        if (improvedMobilityScore?.mobilityScore != null) {
            this.baselineValue = this.currValue;
            this.currValue = Math.round(improvedMobilityScore.mobilityScore);

            this.zone.runOutsideAngular(() => {
                if (improvedMobilityScore?.mobilityScore == null) {
                    return;
                }
                this.improvedScoreChart = MobilityScoreCardComponent.initChart(
                    this.improvedScoreChartCanvas,
                    [
                        improvedMobilityScore.mobilityScore,
                        improvedMobilityScore.mobilityScore - 100,
                    ],
                    [
                        this.themeService.getHexFromCSSVariable('--medium-primary-color'),
                        'rgba(0, 0, 0, 0)',
                    ],
                );
            });
        }
    }
}
