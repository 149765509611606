import { ModeString } from '@shared/map';
import { defaultCriteriaEstimates } from '@upscore-mobility-audit/data-collection/functions/default-criteria-estimates.function';
import { CriteriaOperatorsConfig } from '@upscore-mobility-audit/data-collection/interfaces/criteria-operators-config.interface';
import { CriteriaVisibleConfig } from '@upscore-mobility-audit/data-collection/interfaces/criteria-visible-config.interface';

import { CriteriaMetricTypeEnum } from '../enums/criteria-metric-type.enum';
import { CriteriaTypeEnum } from '../enums/criteria-type.enum';
import {
    AndCriteria,
    BasicCriteria,
    Criteria,
    DiffCriteria,
    LogicOperator,
    MetricCriteria,
    Modes,
    NotCriteria,
    NumberOfChangesCriteria,
} from '../interfaces/criteria-classes';
import { CriteriaConfig } from '../interfaces/criteria-config.interface';
import { PlainCriteria } from '../interfaces/plain-criteria.interface';
import { ModeCriteriaTranslations } from '../translations/mode-criteria-translations';

function toDiffCriteria(criteria: Criteria): DiffCriteria {
    return criteria as DiffCriteria;
}

function toMetricCriteria(criteria: Criteria): MetricCriteria {
    return criteria as MetricCriteria;
}

function isDiffCriteria(criteria: Criteria): boolean {
    return (
        criteria.type === CriteriaTypeEnum.RelativeDiffCutoffCriteria ||
        criteria.type === CriteriaTypeEnum.AbsoluteDiffCutoffCriteria
    );
}

function isSliderCriteria(criteria: Criteria): boolean {
    return criteria.type === CriteriaTypeEnum.NumberOfChangesCriteria || isMetricCriteria(criteria);
}

function isMetricCriteria(criteria: Criteria): boolean {
    return criteria.type === CriteriaTypeEnum.CutoffCriteria || isDiffCriteria(criteria);
}

function criteriaListToSimpleCriteriaList(criteriaList: Criteria[]): PlainCriteria[] {
    return criteriaList.map(criteria => ({
        type: criteria.type,
        metric: toMetricCriteria(criteria).metric,
        toCompare: toDiffCriteria(criteria).toCompare ?? null,
    }));
}

function criteriaToRequestCriteria(criteria: Criteria): unknown {
    const negate = criteria.negate ?? false;

    let newCriteria = null;

    switch (criteria.type) {
        case CriteriaTypeEnum.AndCriteria:
        case CriteriaTypeEnum.OrCriteria:
            throw new Error('AndCriteria not yet supported');
        case CriteriaTypeEnum.Always:
        case CriteriaTypeEnum.Never:
            newCriteria = {
                type: criteria.type,
            };
            break;
        case CriteriaTypeEnum.NumberOfChangesCriteria:
            newCriteria = {
                type: criteria.type,
                maxNumberOfChanges: (criteria as NumberOfChangesCriteria).value,
            };
            break;
        case CriteriaTypeEnum.CutoffCriteria: {
            const metricC: MetricCriteria = criteria as MetricCriteria;

            newCriteria = {
                type: metricC.type,
                max: metricC.value,
                metric: metricC.metric,
            };
            break;
        }
        case CriteriaTypeEnum.AbsoluteDiffCutoffCriteria:
        case CriteriaTypeEnum.RelativeDiffCutoffCriteria: {
            const diffC: DiffCriteria = criteria as DiffCriteria;

            newCriteria = {
                type: diffC.type,
                maxDiff: diffC.value,
                metric: diffC.metric,
                toCompare: diffC.toCompare,
            };
            break;
        }
    }

    if (negate) {
        return {
            type: CriteriaTypeEnum.NotCriteria,
            criteria: newCriteria,
        };
    } else {
        return newCriteria;
    }
}

function criteriaListToRecursiveCriteriaHelper(
    criterias: Criteria[],
    operator: LogicOperator,
    index = 0,
): unknown {
    if (criterias.length === 1) {
        return criteriaToRequestCriteria(criterias[index]);
    } else if (index + 1 === criterias.length - 1) {
        return {
            type: operator,
            first: criteriaToRequestCriteria(criterias[index]),
            second: criteriaToRequestCriteria(criterias[index + 1]),
        };
    } else {
        return {
            type: operator,
            first: criteriaToRequestCriteria(criterias[index]),
            second: criteriaListToRecursiveCriteriaHelper(criterias, operator, index + 1),
        };
    }
}

function criteriaListToRequestCriteria(
    criteriaConfig: CriteriaConfig,
    operators: CriteriaOperatorsConfig,
    enabled: Modes[],
): unknown {
    const o: { [mode: string]: unknown } = {};

    for (const mode of enabled) {
        o[mode] = criteriaListToRecursiveCriteriaHelper(criteriaConfig[mode], operators[mode]);
    }

    return o;
}

/**
 * This function combines server like objects with local needed data
 * Important to note, for type distance:
 * value is in server like type -> 1km is 1000
 * min, max, step are in client like format so 1km -> 1 (instead of 1000)
 * @param criteria
 * @param negate
 */
function plainCriteriaToCriteria(criteria: PlainCriteria, negate = false): Criteria {
    switch (criteria.type) {
        case CriteriaTypeEnum.Never:
        case CriteriaTypeEnum.Always:
            return { type: criteria.type, negate: negate } as BasicCriteria;
        case CriteriaTypeEnum.NumberOfChangesCriteria:
            return {
                type: criteria.type,
                value: 3,
                min: 0,
                max: 10,
                step: 1,
                negate: negate,
            } as NumberOfChangesCriteria;
        case CriteriaTypeEnum.CutoffCriteria:
            return {
                type: CriteriaTypeEnum.CutoffCriteria,
                metric: criteria.metric,
                value: 0,
                min: 0,
                max: 120,
                step: 1,
                negate: negate,
            } as MetricCriteria;
        case CriteriaTypeEnum.RelativeDiffCutoffCriteria:
            return {
                type: CriteriaTypeEnum.RelativeDiffCutoffCriteria,
                metric: criteria.metric,
                toCompare: criteria.toCompare,
                value: 1.0,
                max: 150,
                min: -50,
                step: 1,
                negate: negate,
            } as DiffCriteria;
        case CriteriaTypeEnum.AbsoluteDiffCutoffCriteria: {
            let value = 0;
            switch (criteria.metric?.type) {
                case CriteriaMetricTypeEnum.duration:
                    value = 50;
                    break;
                case CriteriaMetricTypeEnum.distance:
                    value = 50_000;
                    break;
            }

            return {
                type: CriteriaTypeEnum.AbsoluteDiffCutoffCriteria,
                metric: criteria.metric,
                toCompare: criteria.toCompare,
                value: value,
                max: 60,
                min: -60,
                step: 1,
                negate: negate,
            } as DiffCriteria;
        }
    }

    return {
        type: criteria.type,
        metric: criteria.metric,
        toCompare: criteria.toCompare,
        negate: negate,
    };
}

function requestCriteriaToLogicOperatorConfig(requestCriteria: {
    [key in Modes]?: BasicCriteria;
}): CriteriaOperatorsConfig {
    return {
        Car: requestCriteriaToLogicOperator(requestCriteria.Car),
        Bike: requestCriteriaToLogicOperator(requestCriteria.Bike),
        Walk: requestCriteriaToLogicOperator(requestCriteria.Walk),
        PublicTransport: requestCriteriaToLogicOperator(requestCriteria.PublicTransport),
    };
}

function requestCriteriaToVisibilityConfig(requestCriteria: {
    [key in Modes]?: BasicCriteria;
}): CriteriaVisibleConfig {
    return {
        Car: requestCriteria.Car ? true : false,
        Bike: requestCriteria.Bike ? true : false,
        Walk: requestCriteria.Walk ? true : false,
        PublicTransport: requestCriteria.PublicTransport ? true : false,
    };
}

/**
 * Chooses how to represent the given server-side criteria for mode-choice modeling in the frontend:
 * Per default, we expect an AND operator, except if it is explicitly defined to be an OR
 * @param requestCriteria
 */
function requestCriteriaToLogicOperator(requestCriteria?: BasicCriteria): LogicOperator {
    return requestCriteria?.type === CriteriaTypeEnum.OrCriteria
        ? CriteriaTypeEnum.OrCriteria
        : CriteriaTypeEnum.AndCriteria;
}

function requestCriteriaToCriteria(requestCriteria: unknown): Criteria {
    let criteria: Criteria;
    if ((requestCriteria as PlainCriteria).type === CriteriaTypeEnum.NotCriteria) {
        criteria = plainCriteriaToCriteria(
            (requestCriteria as NotCriteria).criteria as PlainCriteria,
            true,
        );
        requestCriteria = (requestCriteria as NotCriteria).criteria;
    } else {
        criteria = plainCriteriaToCriteria(requestCriteria as PlainCriteria);
    }

    switch (criteria.type) {
        case CriteriaTypeEnum.Always:
        case CriteriaTypeEnum.Never:
            return criteria;
        case CriteriaTypeEnum.NumberOfChangesCriteria:
            (criteria as NumberOfChangesCriteria).value = (
                requestCriteria as { maxNumberOfChanges: number }
            ).maxNumberOfChanges;
            break;
        case CriteriaTypeEnum.CutoffCriteria:
            (criteria as MetricCriteria).value = (requestCriteria as { max: number }).max;
            break;
        case CriteriaTypeEnum.AbsoluteDiffCutoffCriteria:
        case CriteriaTypeEnum.RelativeDiffCutoffCriteria:
            (criteria as DiffCriteria).value = (requestCriteria as { maxDiff: number }).maxDiff;
            break;
    }

    return criteria;
}

function requestCriteriaToCriteriaReverseParser(criteria: Criteria | AndCriteria): Criteria[] {
    switch (criteria.type) {
        case CriteriaTypeEnum.AndCriteria:
        case CriteriaTypeEnum.OrCriteria: {
            const c: AndCriteria = criteria as AndCriteria;
            const firstCriteria: Criteria[] = requestCriteriaToCriteriaReverseParser(c.first);
            const secondCriteria: Criteria[] = requestCriteriaToCriteriaReverseParser(c.second);

            return [...firstCriteria, ...secondCriteria];
        }
        default:
            return [requestCriteriaToCriteria(criteria)];
    }
}

// input here is something similar to Criteria
function requestCriteriaToCriteriaObject(data: {
    [type in Modes]: Criteria | AndCriteria;
}): CriteriaConfig {
    const defaultCriteria = defaultCriteriaEstimates();

    return {
        Car: data.Car ? requestCriteriaToCriteriaReverseParser(data.Car) : defaultCriteria.Car,
        Bike: data.Bike ? requestCriteriaToCriteriaReverseParser(data.Bike) : defaultCriteria.Bike,
        Walk: data.Walk ? requestCriteriaToCriteriaReverseParser(data.Walk) : defaultCriteria.Walk,
        PublicTransport: data.PublicTransport
            ? requestCriteriaToCriteriaReverseParser(data.PublicTransport)
            : defaultCriteria.PublicTransport,
    };
}

function getModeName(mode: Modes): string {
    switch (mode) {
        case Modes.Bike:
            return ModeCriteriaTranslations.bike;
        case Modes.Car:
            return ModeCriteriaTranslations.car;
        case Modes.Walk:
            return ModeCriteriaTranslations.walk;
        case Modes.PublicTransport:
            return ModeCriteriaTranslations.pt;
    }
}

function getModeNameFromModeString(modeString: ModeString): string {
    switch (modeString) {
        case 'BIKE':
            return ModeCriteriaTranslations.bike;
        case 'CAR_DRIVER':
        case 'CAR_DRIVER_TRAFFIC':
            return ModeCriteriaTranslations.car;
        case 'WALK':
            return ModeCriteriaTranslations.walk;
        case 'PUBLIC_TRANSPORT':
            return ModeCriteriaTranslations.pt;
        case 'CAR_PASSENGER':
        case undefined:
            return ModeCriteriaTranslations.carPassenger;
    }
}

export {
    toMetricCriteria,
    isDiffCriteria,
    isMetricCriteria,
    isSliderCriteria,
    toDiffCriteria,
    criteriaListToSimpleCriteriaList,
    plainCriteriaToCriteria,
    getModeName,
    getModeNameFromModeString,
    criteriaListToRequestCriteria,
    requestCriteriaToCriteria,
    criteriaToRequestCriteria,
    requestCriteriaToCriteriaObject,
    requestCriteriaToLogicOperator,
    requestCriteriaToLogicOperatorConfig,
    requestCriteriaToVisibilityConfig,
    requestCriteriaToCriteriaReverseParser,
};
