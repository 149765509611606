export class TripDetailsTranslations {
    public static readonly noModeSelected: string = $localize`:@@noModeSelected:No mode selected`;

    public static readonly noWalkWorkTrip: string = $localize`:@@noWalkWorkTrip:This employee can't get to work on foot`;
    public static readonly noWalkHomeTrip: string = $localize`:@@noWalkHomeTrip:This employee can't get home on foot`;

    public static readonly noBikeWorkTrip: string = $localize`:@@noBikeWorkTrip:This employee can't get to work on their bike`;
    public static readonly noBikeHomeTrip: string = $localize`:@@noBikeHomeTrip:This employee can't get home on their bike`;

    public static readonly noPtWorkTrip: string = $localize`:@@noPtWorkTrip:This employee can't get to work using public transport`;
    public static readonly noPtHomeTrip: string = $localize`:@@noPtHomeTrip:This employee can't get home using public transport`;

    public static readonly noCarWorkTrip: string = $localize`:@@noCarWorkTrip:This employee can't get to work using their car`;
    public static readonly noCarHomeTrip: string = $localize`:@@noCarHomeTrip:This employee can't get home using their car`;

    public static readonly noCarPassengerWorkTrip = $localize`:@@noCarPassengerWorkTrip:This employee can't get to work as a car passenger`;

    public static readonly noCarPassengerHomeTrip = $localize`:@@noCarPassengerHomeTrip:This employee can't get home as a car passenger`;

    public static readonly hours: string = $localize`:@@hours:hours`;

    public static readonly minutes: string = $localize`:@@minutes:minutes`;

    public static readonly kilometers: string = $localize`:@@kilometers:kilometers`;

    public static readonly meters: string = $localize`:@@meters:meters`;

    public static readonly walk: string = $localize`:Walk@@walk:Walk`;

    public static readonly bike: string = $localize`:@@bike:Bike`;

    public static readonly publicTransport: string = $localize`:Public Transport@@publicTransport:Public Transport`;

    public static readonly carDriver: string = $localize`:@@carDriver:Car Driver`;

    public static readonly carPassenger: string = $localize`:@@carPassenger:Car Passenger`;

    public static readonly electricVehicle: string = $localize`:@@electricVehicle:Electric Vehicle`;

    public static readonly largeVehicle: string = $localize`:@@largeVehicle:Large Vehicle`;

    public static readonly smallVehicle: string = $localize`:@@smallVehicle:Small Vehicle`;

    public static readonly regularVehicle: string = $localize`:@@regularVehicle:Regular Vehicle`;
}
