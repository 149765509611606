import {
    EmployeeScenarioResultToRead,
    KpiCalculationParameters,
} from '@upscore-mobility-audit/api';

export interface MobilityStats {
    walk: number;
    bike: number;
    transit: number;
    carDriver: number;
    carPassenger: number;
}

export interface MobilityScoreCalculated {
    yearlyEmissions: number[];
    yearlyEmployeeCosts: number[];
    yearlyAverageDuration: number[];
    yearlyTripDuration: number[];
    yearlyAverageDistance: number[];
    yearlyTripDistance: number[];
    dailyEmissions: number[];
    dailyEmployeeCosts: number[];
    dailyAverageDuration: number[];
    dailyTripDuration: number[];
    dailyAverageDistance: number[];
    dailyTripDistance: number[];
    mobilityStats: MobilityStats;

    maxPresenceDaysPerYear: number;
    maxPresenceDaysPerWeek: number;
    defaultPresenceDaysPerWeek: number;
}

type EmployeesInEachMode = { [mode: string]: number };

export const calculateMobilityScore = (
    employeeScenarioResults: EmployeeScenarioResultToRead[],
    attendanceParameters: Pick<
        KpiCalculationParameters,
        'yearlyPresenceDays' | 'daysInWeek' | 'defaultWeeklyPresenceDays'
    >,
): MobilityScoreCalculated => {
    const totalEmployeesInEachMode = employeeScenarioResults.reduce(
        (acc, employeeScenario) => {
            const mode = employeeScenario.combinedTripKpis.mode;
            acc[mode] = (acc[mode] || 0) + 1;

            return acc;
        },
        {} as { [mode: string]: number },
    );

    const mobilityStats = calculateMobilityStats(employeeScenarioResults, totalEmployeesInEachMode);
    const { yearlyData, dailyData } = calculateKPIs(
        employeeScenarioResults,
        totalEmployeesInEachMode,
        attendanceParameters.yearlyPresenceDays,
    );

    return {
        ...yearlyData,
        ...dailyData,
        mobilityStats,
        maxPresenceDaysPerWeek: attendanceParameters.daysInWeek,
        maxPresenceDaysPerYear: attendanceParameters.yearlyPresenceDays,
        defaultPresenceDaysPerWeek: attendanceParameters.defaultWeeklyPresenceDays,
    };
};

const getModeIndex = (mode: string): number => {
    switch (mode) {
        case 'WALK':
            return 0;
        case 'BIKE':
            return 1;
        case 'PUBLIC_TRANSPORT':
            return 2;
        case 'CAR_DRIVER':
            return 3;
        case 'CAR_PASSENGER':
            return 4;
        default:
            return -1;
    }
};

export const calculateKPIs = (
    employeeScenarioResults: EmployeeScenarioResultToRead[],
    totalEmployeesInEachMode: EmployeesInEachMode,
    workDaysPerYear: number,
) => {
    const yearlyData = {
        yearlyEmissions: [0, 0, 0, 0, 0],
        yearlyEmployeeCosts: [0, 0, 0, 0, 0],
        yearlyAverageDuration: [0, 0, 0, 0, 0],
        yearlyTripDuration: [0, 0, 0, 0, 0],
        yearlyAverageDistance: [0, 0, 0, 0, 0],
        yearlyTripDistance: [0, 0, 0, 0, 0],
    };

    const dailyData = {
        dailyEmissions: [0, 0, 0, 0, 0],
        dailyEmployeeCosts: [0, 0, 0, 0, 0],
        dailyAverageDuration: [0, 0, 0, 0, 0],
        dailyTripDuration: [0, 0, 0, 0, 0],
        dailyAverageDistance: [0, 0, 0, 0, 0],
        dailyTripDistance: [0, 0, 0, 0, 0],
    };

    employeeScenarioResults.forEach(employeeScenario => {
        const { mode, distance, duration, costs, emissions, yearlyPresenceDays } =
            employeeScenario.combinedTripKpis;
        const modeIndex = getModeIndex(mode);

        yearlyData.yearlyEmissions[modeIndex] += emissions * yearlyPresenceDays;
        yearlyData.yearlyEmployeeCosts[modeIndex] += costs * yearlyPresenceDays;
        yearlyData.yearlyAverageDuration[modeIndex] +=
            (duration * yearlyPresenceDays) / totalEmployeesInEachMode[mode] || 0;
        yearlyData.yearlyTripDuration[modeIndex] += duration * yearlyPresenceDays;
        yearlyData.yearlyAverageDistance[modeIndex] +=
            (distance * yearlyPresenceDays) / totalEmployeesInEachMode[mode] || 0;
        yearlyData.yearlyTripDistance[modeIndex] += distance * yearlyPresenceDays;

        dailyData.dailyEmissions[modeIndex] += (emissions * yearlyPresenceDays) / workDaysPerYear;
        dailyData.dailyEmployeeCosts[modeIndex] += (costs * yearlyPresenceDays) / workDaysPerYear;
        dailyData.dailyAverageDuration[modeIndex] +=
            (duration * yearlyPresenceDays) / workDaysPerYear / totalEmployeesInEachMode[mode] || 0;
        dailyData.dailyTripDuration[modeIndex] += (duration * yearlyPresenceDays) / workDaysPerYear;
        dailyData.dailyAverageDistance[modeIndex] +=
            (distance * yearlyPresenceDays) / workDaysPerYear / totalEmployeesInEachMode[mode] || 0;
        dailyData.dailyTripDistance[modeIndex] += (distance * yearlyPresenceDays) / workDaysPerYear;
    });

    return { yearlyData, dailyData };
};

export const calculateMobilityStats = (
    employeeScenarios: EmployeeScenarioResultToRead[],
    modeCounts: EmployeesInEachMode,
): MobilityStats => {
    const totalTrips = employeeScenarios.length;

    return {
        walk: modeCounts['WALK'] / totalTrips || 0,
        bike: modeCounts['BIKE'] / totalTrips || 0,
        transit: modeCounts['PUBLIC_TRANSPORT'] / totalTrips || 0,
        carDriver: modeCounts['CAR_DRIVER'] / totalTrips || 0,
        carPassenger: modeCounts['CAR_PASSENGER'] / totalTrips || 0,
    };
};
