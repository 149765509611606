import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ImprovementsService, MobilityIntervention } from '@upscore-mobility-audit/api';

@Injectable({
    providedIn: 'root',
})
export class ImprovementsWrapperService {
    constructor(private readonly improvementsService: ImprovementsService) {}

    public getMeasureImpact(
        companyLocationId: number,
        baseScenario: number,
        body: MobilityIntervention[],
    ) {
        return this.improvementsService.estimateImpact({
            companyLocationId,
            body,
            baseScenario,
        });
    }

    public getSuggestedMeasures(
        companyLocationId: number,
        baseScenario: number,
    ): Observable<MobilityIntervention[]> {
        return this.improvementsService.getSuggestedMeasures({
            companyLocationId: companyLocationId,
            baseScenario,
        });
    }
}
