import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Injector, Input } from '@angular/core';

import { HelpPopoverComponent } from '@upscore-mobility-audit/shared-ui/components/help-popover/help-popover.component';

@Directive({
    selector: '[appPopover]',
    standalone: true,
})
export class PopoverHoverDirective {
    @Input('appPopover') content = '';
    @Input('appPopoverLink') link? = '';
    @Input('appPopoverLinkText') linkText? = '';

    private overlayRef: OverlayRef | null = null;
    private shouldDismissPopover = true;

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef,
        private injector: Injector,
    ) {}

    @HostListener('mouseenter')
    public onMouseEnter(): void {
        this.showPopover();
    }

    @HostListener('mouseleave')
    public onMouseLeave(): void {
        setTimeout(() => {
            if (this.shouldDismissPopover) {
                this.dismissPopover();
            }
        }, 100);
    }

    private showPopover() {
        if (this.overlayRef) {
            this.dismissPopover();
        }

        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions([
                {
                    originX: 'center',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top',
                    offsetY: 8,
                },
            ]);

        this.overlayRef = this.overlay.create({ positionStrategy });

        const portal = new ComponentPortal(HelpPopoverComponent, null, this.injector);
        const componentRef = this.overlayRef.attach(portal);
        componentRef.instance.content = this.content;
        componentRef.instance.link = this.link;
        componentRef.instance.linkText = this.linkText;

        // Conditionally listen for mouseleave event on the popover content
        if (this.link) {
            componentRef.location.nativeElement.addEventListener('mouseenter', () => {
                this.shouldDismissPopover = false;
            });

            componentRef.location.nativeElement.addEventListener('mouseleave', () => {
                this.shouldDismissPopover = true;
                this.dismissPopover();
            });
        }
    }

    private dismissPopover(): void {
        if (this.overlayRef) {
            this.overlayRef.detach();
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }
}
