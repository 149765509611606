export const defaultHeatmapLayerOptions: google.maps.visualization.HeatmapLayerOptions = {
    radius: 25,
    opacity: 0.7,
    data: [],
    gradient: [
        'rgba(102, 255, 0, 0)',
        'rgba(102, 255, 0, 1)',
        'rgba(147, 255, 0, 1)',
        'rgba(193, 255, 0, 1)',
        'rgba(238, 255, 0, 1)',
        'rgba(244, 227, 0, 1)',
        'rgba(249, 198, 0, 1)',
        'rgba(255, 170, 0, 1)',
        'rgba(255, 113, 0, 1)',
        'rgba(255, 57, 0, 1)',
        'rgba(255, 0, 0, 1)',
    ],
};
