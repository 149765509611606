import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { MapService } from '@shared/map';
import { CountryData, PlacesInputPipe, countryData } from '@shared/utils';
import { GeoJsonExample, TimezoneApiResponse } from '@upscore-mobility-audit/api';
import { GooglePlacesInputComponent } from '@upscore-mobility-audit/data-collection/components/google-places-input/google-places-input.component';
import { EntranceFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/entrance-form-group.interface';
import { LocationFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/location-form-group.interface';
import { PlacesResultFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/places-result-form-group.interface';
import { TimezoneWrapperService } from '@upscore-mobility-audit/shared/api-services/timezone-wrapper.service';
import { UpscoreMobilityAuditUserDataService } from '@upscore-mobility-audit/shared/api-services/user-data.service';
import { timezoneData } from '@upscore-mobility-audit/shared/data/timezone.data';
import { SidebarSectionHeader } from '@upscore-mobility-audit/shared/interfaces/sidebar-section-header.interface';

import { defaultIndustries } from '../../constants/default-industries.constant';
import { InitialDataInputDialogTranslations } from '../../translations/initial-data-input-dialog-translations';

@Component({
    selector: 'company-location-step',
    templateUrl: './company-location-step.component.html',
    styleUrls: ['./company-location-step.component.scss'],
})
export class CompanyLocationStepComponent implements OnInit, AfterViewInit {
    @Input() public locationFormGroup!: FormGroup<LocationFormGroup>;

    @Input() public userCoverage?: GeoJsonExample;
    @ViewChild(GooglePlacesInputComponent) public placesInput: GooglePlacesInputComponent;

    // TODO fix TimeZone/Country Options and Data
    public filteredTimezoneOptions$!: Observable<string[]>;
    public filteredCountryOptions$!: Observable<CountryData[]>;
    public timezoneData: string[] = timezoneData;
    public countryData!: CountryData[];
    public readonly translations: typeof InitialDataInputDialogTranslations =
        InitialDataInputDialogTranslations;
    public searchText: string = this.translations.searchText;
    public industries: string[] = defaultIndustries;
    public placeResultFormGroup!: FormGroup<PlacesResultFormGroup>;
    public mapOptions?: google.maps.MapOptions;
    public mapMarker: google.maps.marker.AdvancedMarkerElementOptions[] = [];
    public selectedEntranceTab = 0;
    public sectionHeader: SidebarSectionHeader = {
        label: this.translations.advancedSectionHeader,
        isHidden: true,
        isExpandable: false,
        isExpanded: true,
        disabled: false,
    };

    public _entrancesFormGroup!: FormGroup<EntranceFormGroup>;

    constructor(
        public readonly userDataService: UpscoreMobilityAuditUserDataService,
        private readonly mapService: MapService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly timezoneService: TimezoneWrapperService,
        private readonly cd: ChangeDetectorRef,
    ) {}

    get entrances(): EntranceFormGroup['entrances'] {
        return this.entrancesFormGroup.controls.entrances;
    }

    public get entrancesFormGroup(): FormGroup<EntranceFormGroup> {
        return this._entrancesFormGroup;
    }

    @Input()
    public set entrancesFormGroup(entranceFormGroup: FormGroup<EntranceFormGroup>) {
        const validate: boolean = this._entrancesFormGroup != null;
        this._entrancesFormGroup = entranceFormGroup;

        // add main entrance if there is none
        if (validate && this.entrances.length === 0) {
            this.addEntranceTab();
            this.onEntrancesTabChanged(0);
        }
    }

    public ngOnInit(): void {
        this.countryData = countryData;
        this.placeResultFormGroup = this.formBuilder.group({});

        this.filteredTimezoneOptions$ = this.locationFormGroup.controls.timeZone.valueChanges.pipe(
            startWith(''),
            map(value =>
                this.timezoneData.filter(option =>
                    option.toLowerCase().includes(value?.toLowerCase() ?? ''),
                ),
            ),
        );

        if (this.entrances.length === 0) {
            this.addEntranceTab();
            this.onEntrancesTabChanged(0);
        }
    }

    public removeEntranceTab(index: number): void {
        this.mapMarker.splice(index, 1);

        if (this.selectedEntranceTab === index) {
            this.selectedEntranceTab = index - 1;
        }
        this.entrances.removeAt(index);
    }

    public addEntranceTab(): void {
        const entranceForm: UntypedFormGroup = this.formBuilder.group({
            street: ['', Validators.required],
            city: ['', Validators.required],
            country: [''],
            zipcode: ['', Validators.required],
            latitude: ['', Validators.required],
            longitude: ['', Validators.required],
        });

        this.entrances.push(entranceForm);

        this.selectedEntranceTab = this.entrances.length - 1;

        // To prevent the 'Expression has changed' error
        this.cd.detectChanges();
    }

    /**
     * Angular lifecycle hook
     */
    public ngAfterViewInit(): void {
        this.placeResultFormGroup.controls.placeResult?.valueChanges.subscribe(place => {
            if (place != null) {
                const placesPipe: PlacesInputPipe = new PlacesInputPipe();
                const streetNumber: string = placesPipe.transform(place, 'street_number');
                const street: string = placesPipe.transform(place, 'route');
                this.entrances
                    .at(this.selectedEntranceTab)
                    .get('street')
                    ?.setValue(streetNumber == null ? street : street + ' ' + streetNumber);
                this.entrances
                    .at(this.selectedEntranceTab)
                    .get('city')
                    ?.setValue(placesPipe.transform(place, 'locality'));
                this.entrances
                    .at(this.selectedEntranceTab)
                    .get('country')
                    ?.setValue(placesPipe.transform(place, 'country'));
                this.entrances
                    .at(this.selectedEntranceTab)
                    .get('zipcode')
                    ?.setValue(placesPipe.transform(place, 'postal_code'));

                this.placesInput.addressText?.nativeElement.focus();

                const lat = place.geometry?.location?.lat();
                const lng = place.geometry?.location?.lng();
                if (lat && lng) {
                    this.entrances.at(this.selectedEntranceTab).get('latitude')?.setValue(lat);
                    this.entrances.at(this.selectedEntranceTab).get('longitude')?.setValue(lng);

                    this.entranceCoordinateChange(lat, lng, this.selectedEntranceTab);

                    if (this.selectedEntranceTab === 0) {
                        this.timezoneService
                            .getLocationTimezone({
                                location: lat.toString() + ',' + lng.toString(),
                                timestamp: Math.floor(Date.now() / 1000),
                                language: 'en',
                            })
                            .subscribe((response: TimezoneApiResponse) => {
                                this.locationFormGroup.controls.timeZone.setValue(
                                    // @ts-expect-error: timezoneId is not typed correctly
                                    response.timeZoneId,
                                );
                            });
                    }
                }
            }
        });
    }

    public onHideSection($event: boolean): void {
        this.sectionHeader.isHidden = $event;
    }

    public onExpandSection($event: boolean): void {
        this.sectionHeader.isExpanded = $event;
    }

    public entranceCoordinateChange(
        latitude: number | string,
        longitude: number | string,
        index: number,
    ): void {
        const lat = +latitude;
        const lng = +longitude;

        if (lat == null || lng == null || isNaN(lat) || isNaN(lng)) {
            return;
        }
        this.createMarkerEntrance(lat, lng, index);
    }

    public createMarkerEntrance(lat: number, lng: number, index: number): void {
        if (lat == null || lng == null) {
            return;
        }
        this.mapOptions = this.mapService.buildMapOptions({ latitude: lat, longitude: lng });
        this.mapOptions.zoom = 12;

        this.mapMarker = [];
        for (const entrance of this.entrances.controls) {
            if (entrance.value.latitude && entrance.value.longitude) {
                this.mapMarker.push({
                    position: {
                        lat: entrance.value.latitude,
                        lng: entrance.value.longitude,
                    },
                    gmpDraggable: true,
                    gmpClickable: false,
                });
            }
        }
    }

    public onMarkerDrag(
        marker: google.maps.marker.AdvancedMarkerElement,
        arg: google.maps.MapMouseEvent,
    ) {
        const index = this.mapMarker.findIndex(
            markerOption =>
                markerOption.position?.lat == marker.position?.lat &&
                markerOption.position?.lng == marker.position?.lng,
        );
        if (arg.latLng != null && index !== -1) {
            this.entrances.at(index).get('latitude')?.setValue(arg.latLng.lat());
            this.entrances.at(index).get('longitude')?.setValue(arg.latLng.lng());
        }
    }

    /**
     * This is a bugfix for the tab-group not switching to the selected tab.
     */
    public onTabFocusChange() {
        this.placesInput.addressText?.nativeElement.focus();
    }

    public onEntrancesTabChanged(selectedEntrance: number): void {
        if (this.entrances.value.length > 0) {
            const selectedEntranceInfo = this.entrances.at(selectedEntrance).value;
            if (selectedEntranceInfo.latitude && selectedEntranceInfo.longitude) {
                this.mapOptions = this.mapService.buildMapOptions({
                    latitude: selectedEntranceInfo.latitude,
                    longitude: selectedEntranceInfo.longitude,
                });
                this.mapOptions.zoom = 13;
            }

            const country = this.entrances.at(this.selectedEntranceTab).get('country');
            this.filteredCountryOptions$ = country
                ? country.valueChanges.pipe(
                      startWith(''),
                      map(value => {
                          return this.countryData.filter(option =>
                              option.name.toLowerCase().includes(value.toLowerCase()),
                          );
                      }),
                  )
                : of(this.countryData);
        }
    }

    public mapInit() {
        // wait till custom-map is initialized
        this.initEntrances();
        this.cd.detectChanges();
    }

    private initEntrances(): void {
        this.mapMarker = [];
        if (!this.entrancesFormGroup?.value.entrances) {
            return;
        }

        this.entrancesFormGroup.value.entrances.forEach((entrance, index) => {
            if (index === 0) {
                this.mapOptions = this.mapService.buildMapOptions({
                    latitude: entrance.latitude || 0,
                    longitude: entrance.longitude || 0,
                });
                this.mapOptions.zoom = 12;
            }

            if (entrance.latitude && entrance.longitude) {
                this.mapMarker.push({
                    position: {
                        lat: entrance.latitude,
                        lng: entrance.longitude,
                    },
                    gmpDraggable: true,
                    gmpClickable: false,
                });
            }
        });
        this.onEntrancesTabChanged(0);
    }
}
