import { Pipe, PipeTransform } from '@angular/core';

import { UtilsService } from '@upscore-mobility-audit/shared/services/utils.service';

type ArraySum<T> = T extends null | undefined ? undefined : number;

@Pipe({
    name: 'arraySum',
    pure: true,
})
export class ArraySumPipe implements PipeTransform {
    constructor(private readonly utilsService: UtilsService) {}

    public transform<T extends number[] | null | undefined>(numbers: T): ArraySum<T> {
        if (numbers == null) {
            return undefined as ArraySum<T>;
        }

        return this.utilsService.getSumOfArray(numbers) as ArraySum<T>;
    }
}
