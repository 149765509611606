export class StatusQuoPageTranslations {
    public static readonly people: string = $localize`:People@@people:People`;
    public static readonly avgPerVehicleText: string = $localize`:Avg. Per Vehicle@@avgPerVehicle:Average Per Vehicle`;
    public static readonly modalSplitText: string = $localize`:Modal Split Distribution@@modalSplitDistribution:Modal Split Distribution`;
    public static readonly companyLocationText: string = $localize`:Company Location@@companyLocation:Company Location`;
    public static readonly bikeReachabilityText: string = $localize`:Bike Accessibility@@bikeAccessibility:Accessibility By Bike`;
    public static readonly walkReachabilityText: string = $localize`:Walk Accessibility@@walkAccessibility:Accessibility On Foot`;
    public static readonly carReachabilityText: string = $localize`:Car Accessibility@@carAccessibility:Accessibility By Car`;
    public static readonly ptReachability: string = $localize`:Public Transport Accessibility@@publicTransportAccessibility:Accessibility By Public Transport`;

    public static readonly motorizedIndividualTransportTitle: string = $localize`:@@carOccupancy:Car Occupancy`;
    public static readonly co2EmissionsTitle: string = $localize`:@@co2Emission:CO2 Emissions`;
    public static readonly employerCostsTitle: string = $localize`:@@businessCosts:Employer Costs`;
    public static readonly co2emissionsBusinessTravelTitle: string = $localize`:@@co2EmissionsBusinessTravel:CO2 Emissions - Business Travel`;
    public static readonly employeeCostsTitle: string = $localize`:@@employeeCosts:Employee Costs`;
    public static readonly tripDistanceTitle: string = $localize`:@@travelDistance:Travel Distance`;
    public static readonly tripDurationTitle: string = $localize`:@@travellingTime:Travel Time`;
    public static readonly avgEmployeeTitle: string = $localize`:@@employeeTotal:Ø per Employee (Total)`;
    public static readonly carpoolingTitle: string = $localize`:@@carpooling:Carpooling`;
    public static readonly modalSplitTitle: string = $localize`:@@modalSplitInCompany:Modal Split`;

    public static readonly legendEntryUnmarkedEmployees: string = $localize`:@@employeesUnmarked:Unmarked Employees`; // // MA ohne Kennzeichnung
    public static readonly legendEntryBiker: string = $localize`:@@bike:Bike`; // Radfahrer
    public static readonly legendEntryWalker: string = $localize`:@@walk:Walk`; // 'Fußgänger';
    public static readonly legendEntryDriver: string = $localize`:@@carDriver:Car Driver`; // 'Fahrer';
    public static readonly legendEntryPassenger: string = $localize`:@@carPassenger:Car Passenger`; // 'Mitfahrer';
    public static readonly legendEntryPTDriver: string = $localize`:@@ptUser:Public Transport Users`; // 'ÖV-Fahrer';
}
