import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import { InitialDataInputDialogTranslations } from '../../translations/initial-data-input-dialog-translations';

import { ruralPresets, urbanPresets } from './mobility-properties-presets.constants';

@Component({
    selector: 'mobility-properties-form',
    templateUrl: './mobility-properties-form.component.html',
    styleUrls: ['./mobility-properties-form.component.scss'],
})
export class MobilityPropertiesFormComponent implements OnChanges {
    @Input() public mobilityPropertiesForm: UntypedFormGroup;
    @Input() public userSettings: boolean;
    @Input() public enableForm = false;
    @Input() public enableModeEstimationConfig = false;

    @Output() public resetCalculationPropertiesForm: EventEmitter<void> = new EventEmitter<void>();
    @Output() public setDefaultUserProperties: EventEmitter<void> = new EventEmitter<void>();
    public readonly translations: typeof InitialDataInputDialogTranslations =
        InitialDataInputDialogTranslations;

    public ngOnChanges(): void {
        if (this.enableForm) {
            this.mobilityPropertiesForm.enable();
        } else {
            this.mobilityPropertiesForm.disable();
        }
    }

    public onPresetChange(val: MatSelectChange): void {
        switch (val.value) {
            case 'Urban':
                this.setPresetValues('urban');
                break;
            case 'Rural':
                this.setPresetValues('rural');
        }
    }

    public setPresetValues(val: string): void {
        if (val === 'urban') {
            // @ts-expect-error keyof typeof urbanPresets is not perfect, but works
            Object.keys(urbanPresets).forEach((value: keyof typeof urbanPresets) => {
                this.mobilityPropertiesForm.get(value)?.setValue(urbanPresets[value]);
            });
        } else {
            // @ts-expect-error keyof typeof urbanPresets is not perfect, but works
            Object.keys(ruralPresets).forEach((value: keyof typeof ruralPresets) => {
                this.mobilityPropertiesForm.get(value)?.setValue(ruralPresets[value]);
            });
        }
    }

    public resetToInitial(): void {
        this.resetCalculationPropertiesForm.emit();
        this.enableModeEstimationConfig = false;
    }

    public setUserDefaults(): void {
        this.setDefaultUserProperties.emit();
    }
}
