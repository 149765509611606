/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CatchmentFeatureCollection } from '../models/catchment-feature-collection';
import { OptimalCatchmentResponse } from '../models/optimal-catchment-response';
import { SimulatedCarpooling } from '../models/simulated-carpooling';

@Injectable({
  providedIn: 'root',
})
export class CatchmentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOptimalCatchmentAndCarpools
   */
  static readonly GetOptimalCatchmentAndCarpoolsPath = '/api/v1/locations/{companyLocationId}/optimalCatchment';

  /**
   * Calculate optimal catchment area.
   *
   * Deprecated! Use `/catchment-optimum` and `/carpools` endpoints instead.
   *
   * Optimal Catchment area for each individual transport mode and visualisation information for displaying carpooling information. <br /> The response consists for two parts: <br /> 1. a catchmentAreas field with a geojson FeatureCollection that consists of one Polygon or MultiPolygon per time step. The properties-object of each polygon contains a mode field with the travel mode, an employees field with the number of employees in the catchment area and a time field with the cutoff time of that isochrone in seconds. <br /> 2. a carpools field with the response object from the carpooling simulation (see the /simulation endpoint here)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOptimalCatchmentAndCarpools()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getOptimalCatchmentAndCarpools$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * the number of the entrance used as a center for the catchment calculation
     */
    entranceNumber?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OptimalCatchmentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CatchmentsService.GetOptimalCatchmentAndCarpoolsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('entranceNumber', params.entranceNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OptimalCatchmentResponse>;
      })
    );
  }

  /**
   * Calculate optimal catchment area.
   *
   * Deprecated! Use `/catchment-optimum` and `/carpools` endpoints instead.
   *
   * Optimal Catchment area for each individual transport mode and visualisation information for displaying carpooling information. <br /> The response consists for two parts: <br /> 1. a catchmentAreas field with a geojson FeatureCollection that consists of one Polygon or MultiPolygon per time step. The properties-object of each polygon contains a mode field with the travel mode, an employees field with the number of employees in the catchment area and a time field with the cutoff time of that isochrone in seconds. <br /> 2. a carpools field with the response object from the carpooling simulation (see the /simulation endpoint here)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOptimalCatchmentAndCarpools$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getOptimalCatchmentAndCarpools(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * the number of the entrance used as a center for the catchment calculation
     */
    entranceNumber?: number;
    context?: HttpContext
  }
): Observable<OptimalCatchmentResponse> {

    return this.getOptimalCatchmentAndCarpools$Response(params).pipe(
      map((r: StrictHttpResponse<OptimalCatchmentResponse>) => r.body as OptimalCatchmentResponse)
    );
  }

  /**
   * Path part for operation companyLocationCatchmentRequest
   */
  static readonly CompanyLocationCatchmentRequestPath = '/api/v1/locations/{companyLocationId}/catchment';

  /**
   * Calculate catchment area for mode.
   *
   * In V1 the catchment area for a specific mode is calculated whenever this REST service is called. One request can only calculate the catchment area for one transport mode and a given set of time steps. <br />The response is a geojson FeatureCollection that consists of one Polygon or MultiPolygon per time step. The properties-object of each <br />polygon should contain a time field with the travel time of that <br /> catchment area and the number of employees within that polygon. <br /> Also, we can add information about how many employees (or population of a certain age, education, etc) are within the given catchment area to the properties-object. <br /> An example response can be found here. <br /> In future versions, calculating catchment areas for multiple catchment areas could be supported by changing the mode parameter into an array.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationCatchmentRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationCatchmentRequest$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * mode of transport for calculating the catchment area
     */
    mode: 'walk' | 'bike' | 'transit' | 'car';

    /**
     * travel time steps for calculating catchment area in minutes (e.g. [&#x60;stepsize&#x60;, &#x60;max&#x60;])
     */
    times: Array<number>;

    /**
     * the number of the entrance used as a center for the catchment calculation
     */
    entranceNumber?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CatchmentFeatureCollection>> {

    const rb = new RequestBuilder(this.rootUrl, CatchmentsService.CompanyLocationCatchmentRequestPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('mode', params.mode, {});
      rb.query('times', params.times, {});
      rb.query('entranceNumber', params.entranceNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CatchmentFeatureCollection>;
      })
    );
  }

  /**
   * Calculate catchment area for mode.
   *
   * In V1 the catchment area for a specific mode is calculated whenever this REST service is called. One request can only calculate the catchment area for one transport mode and a given set of time steps. <br />The response is a geojson FeatureCollection that consists of one Polygon or MultiPolygon per time step. The properties-object of each <br />polygon should contain a time field with the travel time of that <br /> catchment area and the number of employees within that polygon. <br /> Also, we can add information about how many employees (or population of a certain age, education, etc) are within the given catchment area to the properties-object. <br /> An example response can be found here. <br /> In future versions, calculating catchment areas for multiple catchment areas could be supported by changing the mode parameter into an array.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationCatchmentRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationCatchmentRequest(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * mode of transport for calculating the catchment area
     */
    mode: 'walk' | 'bike' | 'transit' | 'car';

    /**
     * travel time steps for calculating catchment area in minutes (e.g. [&#x60;stepsize&#x60;, &#x60;max&#x60;])
     */
    times: Array<number>;

    /**
     * the number of the entrance used as a center for the catchment calculation
     */
    entranceNumber?: number;
    context?: HttpContext
  }
): Observable<CatchmentFeatureCollection> {

    return this.companyLocationCatchmentRequest$Response(params).pipe(
      map((r: StrictHttpResponse<CatchmentFeatureCollection>) => r.body as CatchmentFeatureCollection)
    );
  }

  /**
   * Path part for operation getOptimumCatchment
   */
  static readonly GetOptimumCatchmentPath = '/api/v1/locations/{companyLocationId}/catchment-optimum';

  /**
   * Get catchment areas for optimum parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOptimumCatchment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOptimumCatchment$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * the number of the entrance used as a center for the catchment calculation
     */
    entranceNumber?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CatchmentFeatureCollection>> {

    const rb = new RequestBuilder(this.rootUrl, CatchmentsService.GetOptimumCatchmentPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('entranceNumber', params.entranceNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CatchmentFeatureCollection>;
      })
    );
  }

  /**
   * Get catchment areas for optimum parameters.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOptimumCatchment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOptimumCatchment(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * the number of the entrance used as a center for the catchment calculation
     */
    entranceNumber?: number;
    context?: HttpContext
  }
): Observable<CatchmentFeatureCollection> {

    return this.getOptimumCatchment$Response(params).pipe(
      map((r: StrictHttpResponse<CatchmentFeatureCollection>) => r.body as CatchmentFeatureCollection)
    );
  }

  /**
   * Path part for operation getCarpools
   */
  static readonly GetCarpoolsPath = '/api/v1/locations/{companyLocationId}/carpools';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarpools()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarpools$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    calculationReference: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SimulatedCarpooling>> {

    const rb = new RequestBuilder(this.rootUrl, CatchmentsService.GetCarpoolsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('calculationReference', params.calculationReference, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimulatedCarpooling>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCarpools$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarpools(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    calculationReference: number;
    context?: HttpContext
  }
): Observable<SimulatedCarpooling> {

    return this.getCarpools$Response(params).pipe(
      map((r: StrictHttpResponse<SimulatedCarpooling>) => r.body as SimulatedCarpooling)
    );
  }

}
