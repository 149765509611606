<div class="header-wrapper">
    <div class="increase">
        <kpi-measure
            [measureCssClass]="'kpi-measure__measure--color-dark kpi-measure__measure--text-xs'"
            [unit]="numberUnit.PERCENTAGE"
            [showChangePrefix]="true"
            [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-sm'"
            [value]="currValue | percentageChange: baselineValue"></kpi-measure>
    </div>

    <div class="header-wrapper__comparison-values">
        @if (!isPercentageChange) {
            (
            @if (showTimePeriod) {
                <span i18n="Yearly@@annual">annual</span>
            }
            <kpi-measure
                [digitsInfo]="digitsInfo"
                [measureCssClass]="'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'"
                [showChangePrefix]="showPercentageChangePrefix"
                [unit]="unit"
                [valueCssClass]="
                    'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                "
                [value]="baselineValue"></kpi-measure>
            @if (showDailyComparisonValue) {
                ,
                @if (showTimePeriod) {
                    <span i18n="@@dailyKpi">per day</span>
                }
                <kpi-measure
                    [digitsInfo]="digitsInfo"
                    [measureCssClass]="
                        'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                    "
                    [showChangePrefix]="showPercentageChangePrefix"
                    [unit]="unit"
                    [valueCssClass]="
                        'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                    "
                    [value]="baselineDaily"></kpi-measure>
            }
            )
        } @else {
            <span class="header-wrapper__percentage-comparison-values"
                >(
                <kpi-measure
                    [digitsInfo]="digitsInfo"
                    [measureCssClass]="
                        'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                    "
                    [showChangePrefix]="false"
                    [unit]="numberUnit.PERCENTAGE"
                    [valueCssClass]="
                        'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                    "
                    [value]="baselineValue"></kpi-measure>
                <mat-icon>arrow_right_alt</mat-icon>
                <kpi-measure
                    [digitsInfo]="digitsInfo"
                    [measureCssClass]="
                        'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                    "
                    [showChangePrefix]="false"
                    [unit]="numberUnit.PERCENTAGE"
                    [valueCssClass]="
                        'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                    "
                    [value]="currValue"></kpi-measure
                >)</span
            >
        }
    </div>
</div>
