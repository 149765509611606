import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { AbstractUserDataService } from '@shared/utils';
import { UserInfo } from '@upscore-mobility-audit/api';

import { MeasureIconComponent } from '../measure-icon/measure-icon.component';

@Component({
    selector: 'measure-other-header',
    standalone: true,
    imports: [CommonModule, MeasureIconComponent],
    templateUrl: './measure-other-header.component.html',
    styleUrl: './measure-other-header.component.scss',
})
export class MeasureOtherHeaderComponent {
    public user$ = this.userDataService.user$;

    constructor(private readonly userDataService: AbstractUserDataService<UserInfo>) {}
}
