import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

import { AuthService } from '@auth/data-access-auth';
import { FeatureFlag } from '@shared/utils';
import { UserInfo } from '@upscore-mobility-audit/api';
import { SentryReportService } from '@upscore-mobility-audit/shared/services/sentry-report.service';
import { UtilsService } from '@upscore-mobility-audit/shared/services/utils.service';

@Component({
    selector: 'user-details',
    standalone: true,
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
    imports: [MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, MatIconButton, DatePipe, MatDivider],
})
export class UserDetailsComponent {
    @Input() public user: UserInfo;
    @Input() public canShowSettings: boolean;
    @Output() public showSettings = new EventEmitter<void>();

    public confirmLogout = false;
    protected readonly FeatureFlag = FeatureFlag;

    constructor(
        private readonly sentryReportService: SentryReportService,
        private readonly authService: AuthService,
        private readonly utilsService: UtilsService,
    ) {}

    public onReportError(): void {
        this.sentryReportService.reportUserError();
    }

    public onLogout(event: MouseEvent): void {
        if (this.confirmLogout) {
            this.authService.logout();

            return;
        }

        this.confirmLogout = true;
        this.utilsService.preventFurtherButtonClicks(event);
    }
}
