export class ModeCriteriaTranslations {
    public static car: string = $localize`:@@car:Car`;
    public static walk: string = $localize`:@@walk:Walk`;
    public static bike: string = $localize`:@@bike:Bike`;
    public static pt: string = $localize`:@@pt:Public Transport`;
    public static carPassenger: string = $localize`:@@carPassenger:Car Passenger`;

    public static always: string = $localize`:@@modeEstimation.always:Always`;
    public static never: string = $localize`:@@modeEstimation.never:Never`;
    public static duration: string = $localize`:@@modeEstimation.duration:Duration`;
    public static distance: string = $localize`:@@modeEstimation.distance:Distance`;
    public static relativeDuration: string = $localize`:@@modeEstimation.relativeDuration:Relative Duration`;
    public static relativeDistance: string = $localize`:@@modeEstimation.relativeDistance:Relative Distance`;
    public static distanceRelative: string = $localize`:@@modeEstimation.distanceRelative:Distance relative`;
    public static durationRelative: string = $localize`:@@modeEstimation.durationRelative:Duration relative`;

    public static lessThan: string = $localize`:@@modeEstimation.lessThan:less than`;
    public static moreThan: string = $localize`:@@modeEstimation.moreThan:more than`;
    public static equal: string = $localize`:@@modeEstimation.equal:equal`;
    public static notEqual: string = $localize`:@@modeEstimation.notEqual:not equal`;
    public static more: string = $localize`:@@modeEstimation.more:more`;
    public static less: string = $localize`:@@modeEstimation.less:less`;
    public static maxNumberOfChanges: string = $localize`:@@modeEstimation.maxNumberOfChanges:Max. Number of Changes`;
    public static minNumberOfChanges: string = $localize`:@@modeEstimation.minNumberOfChanges:Min. Number of Changes`;
}
