import { Observable } from 'rxjs';

import { TaskProgress } from '@upscore-mobility-audit/api';

import { TaskIdAndProgress } from './task-id-and-progress.interface';

export abstract class ProgressService {
    abstract subscribeToTasks(taskId: string): Observable<TaskProgress>;
    abstract subscribeToAllUserTasks(userId: number): Observable<TaskIdAndProgress>;
}
