<h2 class="error-page-headline" i18n="@@employeeUploadFailed">
    <mat-icon color="warn">warning</mat-icon>
    Employee Upload Failed
</h2>
<span i18n="@@updateOrReUploadEmployees"
    >The processing of your employees failed. Please fix the problems here or re-upload the fixed
    file. You can also skip this process if you are fine with only the uploaded employees.</span
>

<upscore-simple-table
    [data]="uploadFormGroup.value.error"
    [editable]="false"
    [headerData]="
        uploadFormGroup.value.uploadType === fileUploadTypes.AGGREGATED
            ? tableHeaderDataAggregated
            : tableHeaderDataDetailed
    "
    [pageSizes]="[5]">
</upscore-simple-table>
