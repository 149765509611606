import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InfoComponent } from '@ui-library/components';
import { CompanyLocationHeaderComponent } from '@ui-library/components/company-location-header/company-location-header.component';
import { MaterialModule } from '@ui-library/modules/material/material.module';
import { CompanyLocationHeaderDropdownsComponent } from '@upscore-mobility-audit/shared-ui/components/company-location-header-dropdowns/company-location-header-dropdowns.component';
import { DistributionBarComponent } from '@upscore-mobility-audit/shared-ui/components/distribution-bar/distribution-bar.component';
import { HeaderLessTabsDirective } from '@upscore-mobility-audit/shared-ui/directives/header-less-tabs.directive';
import { PopoverSelectDirective } from '@upscore-mobility-audit/shared-ui/directives/popover-select.directive';
import { EntranceAddressPipe } from '@upscore-mobility-audit/shared-ui/pipes/entrance-address.pipe';

import { BasicOverlayComponent } from './components/basic-overlay/basic-overlay.component';
import { CompanyLocationStepHeaderComponent } from './components/company-location-header/company-location-step-header.component';
import { EmployeeMarkerToggleComponent } from './components/employee-marker-toggle/employee-marker-toggle.component';
import { ExportMenuComponent } from './components/export-menu/export-menu.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { HelpPopoverComponent } from './components/help-popover/help-popover.component';
import { KpiMeasureComponent } from './components/kpi-measure/kpi-measure.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { PageTabsComponent } from './components/page-tabs/page-tabs.component';
import { UpscoreSimpleTableComponent } from './components/upscore-simple-table/upscore-simple-table.component';
import { UpscoreSliderInputComponent } from './components/upscore-slider-input/upscore-slider-input.component';
import { UpscoreTableComponent } from './components/upscore-table/upscore-table.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';

@NgModule({
    declarations: [
        BasicOverlayComponent,
        CompanyLocationStepHeaderComponent,
        CompanyLocationHeaderDropdownsComponent,
        EmployeeMarkerToggleComponent,
        FeedbackComponent,
        HelpPopoverComponent,
        PageTabsComponent,
        UpscoreSimpleTableComponent,
        UpscoreTableComponent,
        PopoverSelectDirective,
        HeaderLessTabsDirective,
    ],
    imports: [
        CommonModule,
        CompanyLocationHeaderComponent,
        UserDetailsComponent,
        ExportMenuComponent,
        KpiMeasureComponent,
        UpscoreSliderInputComponent,
        NavBarComponent,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        DistributionBarComponent,
        EntranceAddressPipe,
        InfoComponent,
    ],
    exports: [
        BasicOverlayComponent,
        CompanyLocationStepHeaderComponent,
        CompanyLocationHeaderDropdownsComponent,
        EmployeeMarkerToggleComponent,
        FeedbackComponent,
        HelpPopoverComponent,
        PageTabsComponent,
        UpscoreSimpleTableComponent,
        UpscoreTableComponent,
        UserDetailsComponent,
        KpiMeasureComponent,
        PopoverSelectDirective,
        DistributionBarComponent,
    ],
})
export class UpscoreSharedUiModule {}
