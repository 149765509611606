export class InitialDataInputDialogTranslations {
    public static readonly searchText: string = $localize`:@@search:Search`;
    public static readonly companyLocationText: string = $localize`:@@companyLocation:Company Location`;
    public static readonly mobilityParametersText: string = $localize`:@@mobilityParameters:Mobility Parameters`;
    public static readonly employeeUploadText: string = $localize`:@@employeeUpload:Employee Data`;
    public static readonly employeeUploadError: string = $localize`:@@employeeUploadError:Error Uploading Employees`;
    public static readonly employeeUploadErrorDescription: string = $localize`:@@employeeUploadErrorDescription:Employee upload contains problems. Please take a look at the dialog.`;

    public static readonly vehiclesUsedText: string = $localize`:@@vehiclesUsed:Vehicles Used`;
    public static readonly advancedSectionHeader: string = $localize`:@@advancedSectionHeader:Advanced Settings`;
    public static readonly calculationPropertiesText: string = $localize`:@@calculationPropertiesText:Calculation Properties`;

    public static readonly followingColumnsIgnoredText = (headers: string[]) =>
        $localize`:@@followingColumnsIgnored:The following columns were not parsed: ${headers.join(
            ', ',
        )}.`;
    public static readonly followingColumnsMissingText = (headers: string[]) =>
        $localize`:@@followingColumnsMissing:The following columns are missing:  ${headers.join(
            ', ',
        )}.`;
}
