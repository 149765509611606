import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EmployeesModes } from '@shared/map';
import { Employee } from '@upscore-mobility-audit/api';
import { AuditInputDataService } from '@upscore-mobility-audit/core/data-services/audit-input-data.service';
import { CacheResetBase } from '@upscore-mobility-audit/core/data-services/cache-reset-base.service';
import { ScenarioKpiService } from '@upscore-mobility-audit/core/data-services/scenario-kpi.service';
import { EmployeesWrapperService } from '@upscore-mobility-audit/shared/api-services/employees-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class EmployeesDataService extends CacheResetBase {
    public employeeModes$: Observable<EmployeesModes> =
        this.scenarioKpiService.selectedScenarioKpi$.pipe(
            map(scenarioResult => {
                if (scenarioResult == null) {
                    return {};
                }

                return scenarioResult.results.employeeScenarioResults.reduce(
                    (acc, employeeScenarioResult) => {
                        acc[employeeScenarioResult.employeeId] =
                            employeeScenarioResult.combinedTripKpis.mode;

                        return acc;
                    },
                    {} as EmployeesModes,
                );
            }),
        );

    public allEmployees$ = new BehaviorSubject<Employee[]>([]);
    public departments$ = new BehaviorSubject<string[]>([]);

    public employees$: Observable<Employee[]> = combineLatest([
        this.allEmployees$.notNull(),
        this.companyFilterInputDataService.selectedDepartment$,
        this.companyFilterInputDataService.selectedEntrances$.notNull(),
    ]).pipe(
        map(([employees, dept, entrance]) => {
            if (dept === null || dept === 'All') {
                return entrance.length > 1
                    ? employees
                    : employees.filter(emp => emp.entranceNumber === entrance[0].number);
            }

            // If Dept is not null or 'All'
            return entrance.length > 1
                ? employees.filter(emp => emp.department === dept)
                : employees.filter(
                      emp => emp.department === dept && emp.entranceNumber === entrance[0].number,
                  );
        }),
    );

    constructor(
        private employeeWrapperService: EmployeesWrapperService,
        private scenarioKpiService: ScenarioKpiService,
        private companyFilterInputDataService: AuditInputDataService,
    ) {
        super();
    }

    public loadEmployees(locationId: number) {
        // only called when opening audit so no cache reload needed
        return this.employeeWrapperService.getEmployeeLocations(locationId).pipe(
            tap(employees => {
                const departments = Array.from(
                    new Set(
                        employees
                            .filter(it => it.department && it.department.trim().length > 0)
                            .map(emp => emp.department as string),
                    ),
                );

                this.allEmployees$.next(employees);
                this.departments$.next(departments);
            }),
        );
    }

    protected override auditClosed(): void {
        this.allEmployees$.next([]);
        this.departments$.next([]);
    }
}
