import {
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Chart, ChartDataset } from 'chart.js';

import { NumberUnit, ThemeService } from '@shared/utils';
import { MobilityStats } from '@upscore-mobility-audit/api';
import { ModelSplitCircleTranslations } from '@upscore-mobility-audit/kpi/translations/model-split-circle-translations';

@Component({
    selector: 'modal-split-circle',
    templateUrl: './modal-split-circle.component.html',
    styleUrls: ['./modal-split-circle.component.scss'],
})
export class ModalSplitCircleComponent implements OnInit, OnChanges {
    @Input() public mobilityStats?: MobilityStats;
    @Input() public employeeCount?: number;
    @Input() public score?: number;
    @ViewChild('canvas', { static: true }) public canvas: ElementRef<HTMLCanvasElement>;
    public unitMeasureText: string = ModelSplitCircleTranslations.unitMeasureText;
    public ctx: CanvasRenderingContext2D | null;
    public chart: Chart;
    public numberUnit: typeof NumberUnit = NumberUnit;
    private chartData: number[];
    private chartColor: string[];

    constructor(
        private readonly themeService: ThemeService,
        private readonly zone: NgZone,
    ) {}

    /**
     * Angular Lifecycle
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['mobilityStats'] && !changes['mobilityStats'].isFirstChange()) {
            this.addChartData();
        }
    }

    /**
     * Angular Lifecycle
     */
    public ngOnInit(): void {
        this.zone.runOutsideAngular(() => {
            this.initChart();
        });
        this.addChartData();
    }

    /**
     * Method to init the chart
     */
    private initChart(): void {
        this.ctx = this.canvas.nativeElement.getContext('2d');
        if (this.ctx) {
            this.chart = new Chart(this.ctx, {
                // @ts-expect-error: RoundedDoughnut is our own Chart type
                type: 'RoundedDoughnut',
                options: {
                    devicePixelRatio: 2,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: { enabled: false },
                    },
                    // @ts-expect-error: weird typing bug
                    cutout: '85%',
                },
            });
        }
    }

    /**
     * prepare datasets
     */
    private prepareDatasets(): ChartDataset[] {
        return [
            {
                data: this.chartData,
                backgroundColor: this.chartColor,
                borderWidth: 0,
            },
        ];
    }

    /**
     * init chart data
     */
    private addChartData(): void {
        if (!this.mobilityStats) {
            return;
        }

        this.prepareData(this.mobilityStats);
        this.chart.data.datasets = this.prepareDatasets();
        this.chart.update();
    }

    /**
     * prepare data for chart
     */
    private prepareData(mobilityStats: MobilityStats): void {
        this.chartData = [];
        this.chartColor = [];
        this.addValue(mobilityStats.walk, '--walk-color');
        this.addValue(mobilityStats.bike, '--bike-color');
        this.addValue(mobilityStats.transit, '--public-transport-color');
        this.addValue(mobilityStats.carDriver, '--car-driver-color');
        this.addValue(mobilityStats.carPassenger, '--car-passenger-color');

        this.addSpacingToChart();
    }

    /**
     * add value to the chart
     * @param value
     * @param colorVar
     */
    private addValue(value: number, colorVar: string): void {
        if (value * 100 !== 0) {
            this.chartData.push(value * 100);
            this.chartColor.push(this.themeService.getHexFromCSSVariable(colorVar));
        }
    }

    /**
     * method to add spacing to each value
     * we need to add an invisible 'value' so that we have a white space between each real value
     */
    private addSpacingToChart(): void {
        const tempChartData: number[] = [];
        const tempChartColor: string[] = [];

        const marginSpace = 6;

        const newPercentage: number = 100 + this.chartData.length * marginSpace;

        this.chartData.forEach((value, index) => {
            tempChartData.push((value / 100) * newPercentage);
            tempChartData.push(marginSpace);

            tempChartColor.push(this.chartColor[index]);
            tempChartColor.push('rgba(0,0,0,0)');
        });

        this.chartData = tempChartData;
        this.chartColor = tempChartColor;
    }
}
