import { Injectable, computed, signal } from '@angular/core';
import {
    EMPTY,
    Subscription,
    catchError,
    combineLatest,
    firstValueFrom,
    mergeMap,
    scan,
    startWith,
    timestamp,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { LocalStorageKeys } from '@shared/utils';
import { TasksService } from '@upscore-mobility-audit/api';
import { ResetService } from '@upscore-mobility-audit/core/data-services/reset.service';
import { UpscoreMobilityAuditUserDataService } from '@upscore-mobility-audit/shared/api-services/user-data.service';

import { TaskCancelledError } from '../errors';
import { UpscoreTaskData } from '../interfaces/task-data.interface';

import { CompanyNameService } from './company-name.service';
import { HiddenAuditTasksService } from './hidden-audit-tasks.service';
import { TaskDefinitionService } from './task-definition.service';
import { TaskHandlerService } from './task-handler.service';
import { TaskUtilsService } from './task-utils.service';
import { TasksWrapperService } from './tasks-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class TaskNotificationService {
    public userTasks = signal<UpscoreTaskData[]>([]);

    // compute function to show all tasks from the current audit that are not hidden
    public auditTasks = computed(
        () =>
            this.auditTaskIds()
                .map(id => this.userTasks().find(task => task.id === id))
                .filter(
                    task =>
                        task != null &&
                        !this.hiddenAuditTaskService.hiddenAuditTaskIds().includes(task.id),
                ) as UpscoreTaskData[],
    );

    // all tasks from the audit that's getting viewed
    public auditTaskIds = signal<string[]>([]);

    private subscriptions = new Subscription();
    private connected = false;

    constructor(
        private taskHandlerService: TaskHandlerService,
        private tasksService: TasksService,
        private tasksWrapperService: TasksWrapperService,
        private userDataService: UpscoreMobilityAuditUserDataService,
        private taskDefinitionService: TaskDefinitionService,
        private companyNameService: CompanyNameService,
        private taskUtilsService: TaskUtilsService,
        public hiddenAuditTaskService: HiddenAuditTasksService,
        public resetService: ResetService,
    ) {
        this.userDataService._user$.subscribe(user => {
            if (user && !this.connected) {
                this.connected = true;
                this.connect();
            } else if (!user) {
                this.connected = false;
                this.disconnect();
            }
        });
    }

    /**
     * if undefined is returned the task was cancelled
     * @param condition
     * @param restCall
     */
    public async createTaskIfNecessary<T>(
        condition: (task: UpscoreTaskData) => boolean,
        restCall: () => Promise<T>,
    ) {
        const taskDefinitions = this.userTasks();
        const auditTaskData = taskDefinitions.find(condition);
        let runningTaskId = auditTaskData?.id;
        if (auditTaskData && !this.taskUtilsService.taskIsRunning(auditTaskData.progress)) {
            runningTaskId = undefined;
            this.tasksWrapperService.deleteTask(auditTaskData.id);
        }

        let response: T;

        try {
            if (runningTaskId) {
                response = await firstValueFrom(
                    this.taskHandlerService
                        .waitForTaskCompletion<T>(runningTaskId)
                        .pipe(takeUntil(this.resetService.auditClosed$)),
                );
            } else {
                response = await restCall();
            }
        } catch (error) {
            if (error instanceof TaskCancelledError) {
                return;
            }

            // dont forget to handle this error somewhere
            throw error;
        }

        return response;
    }

    public loadAndStoreCompanyLocationsTasks(locationId: number) {
        this.tasksService.getTasksForLocation({ locationId }).subscribe(tasks => {
            this.auditTaskIds.update(currentTasks =>
                Array.from(new Set(currentTasks.concat(tasks.map(task => task.id)))),
            );
        });
    }

    private connect() {
        // remove old task ids that a user has hidden
        this.subscriptions.add(
            this.tasksService.getMyTasks().subscribe(tasks => {
                this.hiddenAuditTaskService.hiddenAuditTaskIds.update(hiddenTasks => {
                    const filteredTasks = hiddenTasks.filter(taskId =>
                        tasks.some(task => task.id === taskId),
                    );
                    localStorage.setItem(
                        LocalStorageKeys.HIDDEN_TASK_NOTIFICATIONS,
                        JSON.stringify(filteredTasks),
                    );

                    return filteredTasks;
                });
            }),
        );

        this.subscriptions.add(
            this.taskHandlerService.waitingForTask.subscribe(taskId => {
                this.auditTaskIds.update(tasks => [...new Set(tasks.concat(taskId))]);
            }),
        );

        this.subscriptions.add(
            combineLatest([
                this.taskHandlerService.subscribeToUserTasks().pipe(
                    mergeMap(taskIdAndProgress =>
                        this.taskDefinitionService.getTaskDefinition(taskIdAndProgress.id).pipe(
                            catchError(() => EMPTY),
                            map(taskDefinition => ({
                                definition: taskDefinition,
                                progress: taskIdAndProgress.progress,
                                id: taskIdAndProgress.id,
                            })),
                        ),
                    ),
                    mergeMap(taskData =>
                        this.companyNameService.getCompanyName(taskData.definition.locationId).pipe(
                            map(
                                name =>
                                    ({
                                        ...taskData,
                                        locationName: name,
                                    }) as UpscoreTaskData,
                            ),
                        ),
                    ),
                    timestamp(),
                ),
                this.tasksWrapperService.taskDeleted.pipe(
                    timestamp(),
                    startWith({ timestamp: 0, value: -1 }),
                ),
            ])
                .pipe(
                    scan(
                        (acc, [taskDataTime, taskDeletedTime]) => {
                            if (taskDeletedTime.timestamp > taskDataTime.timestamp) {
                                const taskId = taskDeletedTime.value;
                                if (taskId in acc) {
                                    delete acc[taskId];
                                }
                            } else {
                                const taskData = taskDataTime.value;
                                if (taskData.progress?.status === 'DELETED' && taskData.id in acc) {
                                    delete acc[taskData.id];
                                } else if (taskData.id in acc) {
                                    // dont overwrite tasks if it already exists
                                    // else angular change detection will recreate the component that depends on this signal
                                    acc[taskData.id].progress = taskData.progress;
                                    acc[taskData.id].definition = taskData.definition;
                                    acc[taskData.id].locationName = taskData.locationName;
                                } else {
                                    acc[taskData.id] = taskData;
                                }
                            }

                            return acc;
                        },
                        {} as { [taskId: string]: UpscoreTaskData },
                    ),
                )
                .subscribe(tasks => {
                    this.userTasks.set(Object.values(tasks));
                }),
        );
    }

    private disconnect() {
        this.subscriptions.unsubscribe();
    }
}
