<google-map
    (mapDragstart)="mapDragStartCallback()"
    (mapDragend)="mapDragEndCallback()"
    [height]="height"
    [width]="width"
    (mapInitialized)="mapInitializedCallback($event)"
    class="gmap"
    id="googlemap">
    @for (markerOption of markerOptions; track markerOption) {
        <map-advanced-marker
            (mapDragend)="markerDragEnd.next([markerOption, $event])"
            [options]="markerOption"></map-advanced-marker>
    }
    <!-- polygons -->
    @for (polygonOption of polygonOptions; track polygonOption) {
        <map-polygon [options]="polygonOption"></map-polygon>
    }
    @for (polygonOption of carPoolingPolygonOptions; track polygonOption) {
        <map-polygon [options]="polygonOption"></map-polygon>
    }
    <!-- polylines -->
    @for (polylineOption of polylineOptions; track polylineOption) {
        <map-polyline [options]="polylineOption"></map-polyline>
    }
</google-map>
