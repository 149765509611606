import { Location } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isEmpty, isEqual, omitBy } from 'lodash-es';
import { Observable, Subject, firstValueFrom, from, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { AbstractUserDataService, FeatureFlag, LoadingService, ToastService } from '@shared/utils';
import {
    AggregatedEmployeeInput,
    AggregatedGeocodingResult,
    CalculationProperties,
    CompanyLocation,
    CompanyLocationToChange,
    DetailedEmployeeInput,
    DetailedGeocodingResult,
    Employee,
    FailedAggregatedEmployees,
    FailedDetailedEmployee,
    MobilityStats,
    PostedCompanyLocation,
    PostedLocation,
    UserInfo,
    ValidationResult,
    VehicleStats,
} from '@upscore-mobility-audit/api';
import { EntranceFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/entrance-form-group.interface';
import { LocationFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/location-form-group.interface';
import { MobilityParamsFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/mobility-params-form-group.interface';
import { UploadFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/upload-form-group.interface';
import { CompanyLocationsWrapperService } from '@upscore-mobility-audit/shared/api-services/company-locations-wrapper.service';
import { EmployeesWrapperService } from '@upscore-mobility-audit/shared/api-services/employees-wrapper.service';
import { MobilityPropertiesWrapperService } from '@upscore-mobility-audit/shared/api-services/mobility-properties-wrapper.service';
import { ModeEstimationWrapperService } from '@upscore-mobility-audit/shared/api-services/mode-estimation-wrapper.service';
import { ErrorCancelContinueDialogComponent } from '@upscore-mobility-audit/shared/dialogs/error-cancel-continue-dialog/error-cancel-continue-dialog.component';
import { DialogSteps } from '@upscore-mobility-audit/shared/enums/dialog-steps.enum';
import { UtilsService } from '@upscore-mobility-audit/shared/services/utils.service';

import { UpscoreStepComponent } from '../../components/upscore-step/upscore-step.component';
import { UpscoreStepperComponent } from '../../components/upscore-stepper/upscore-stepper.component';
import { CompanyLocationCreationState } from '../../enums/company-location-creation-state.enum';
import { FileUploadEnum } from '../../enums/file-upload.enum';
import { ModalSplitType } from '../../enums/modalsplit.enum';
import { InitialDataInputDialogCloseType } from '../../interfaces/initial-data-input-dialog-close-type.interface';
import { InitialDataInputDialogTranslations } from '../../translations/initial-data-input-dialog-translations';

@Component({
    selector: 'initial-data-input-dialog',
    templateUrl: './initial-data-input-dialog.component.html',
    styleUrls: ['./initial-data-input-dialog.component.scss'],
})
export class InitialDataInputDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(UpscoreStepperComponent) public upscoreStepper!: UpscoreStepperComponent;

    public readonly translations: typeof InitialDataInputDialogTranslations =
        InitialDataInputDialogTranslations;

    public featureFlag: typeof FeatureFlag = FeatureFlag;

    public unsubscribe$: Subject<void> = new Subject();

    public companyLocation?: CompanyLocation;

    public newCompanyLocation = true;

    // used to check which changes occurred in the form
    public originalUploadBody: PostedCompanyLocation;

    public locationFormGroup: FormGroup<LocationFormGroup>;

    public entrancesFormGroup: FormGroup<EntranceFormGroup>;

    public mobilityParamsFormGroup: FormGroup<MobilityParamsFormGroup>;

    public uploadFormGroup: FormGroup<UploadFormGroup>;

    public companyLocationCreationState = CompanyLocationCreationState.ESTIMATION_NOT_NEEDED;

    public locationPropertiesForm!: UntypedFormGroup;

    public originalMobilityPropertiesData?: CalculationProperties;
    public defaultNextButtonString = UpscoreStepComponent._defaultNextButtonString;
    public defaultSkipButtonString = UpscoreStepComponent._skipButtonString;
    public createNewLocationProperties = false;
    public enableModeEstimationConfig = false;
    public originalCompanyLocation?: CompanyLocation;

    public changedEmployees = false;

    constructor(
        private readonly untypedFormBuilder: UntypedFormBuilder,
        private readonly formBuilder: FormBuilder,
        private httpErrorToastService: ToastService,
        public readonly userDataService: AbstractUserDataService<UserInfo>,
        private readonly companyLocationsWrapperService: CompanyLocationsWrapperService,
        private readonly employeesWrapperService: EmployeesWrapperService,
        private readonly dialogRef: MatDialogRef<
            InitialDataInputDialogComponent,
            InitialDataInputDialogCloseType
        >,
        private readonly utilsService: UtilsService,
        private readonly dialog: MatDialog,
        private readonly toastService: ToastService,
        private readonly modeEstimationWrapperService: ModeEstimationWrapperService,
        private readonly loadingService: LoadingService,
        private readonly location: Location,
        private readonly mobilityPropertiesService: MobilityPropertiesWrapperService,
        @Inject(MAT_DIALOG_DATA)
        public data?: {
            companyLocation: CompanyLocation;
            index?: DialogSteps;
        },
    ) {
        if (data?.companyLocation) {
            this.newCompanyLocation = false;
            this.companyLocation = data.companyLocation;
            this.originalCompanyLocation = this.companyLocation;
        }
    }

    public ngAfterViewInit(): void {
        if (this.data?.index) {
            this.upscoreStepper.jumpToStep(this.data?.index);
        }
    }

    /**
     * Angular lifecycle hook
     */
    public ngOnInit(): void {
        this.initForms();
        this.originalUploadBody = this.createUploadBody();

        if (this.companyLocation == null) {
            this.dialogRef.backdropClick().subscribe(() => {
                if (this.dialogRef.disableClose) {
                    this.toastService.showInfoEvent(
                        $localize`:@@continueWithDialog:Continue with the dialog`,
                        false,
                        false,
                        $localize`:@@completeConfiguration:Complete the configuration to close the dialog.`,
                        null,
                        false,
                        null,
                        false,
                        null,
                        null,
                        true,
                        this.dialogRef.close.bind(this.dialogRef),
                    );
                }
            });
        } else {
            const forms = [
                this.locationPropertiesForm,
                this.locationFormGroup,
                this.entrancesFormGroup,
                this.uploadFormGroup,
                this.mobilityParamsFormGroup,
            ];

            this.dialogRef.disableClose = true;
            this.dialogRef
                .backdropClick()
                .pipe(withLatestFrom(forms))
                .subscribe(() => {
                    const closeObject = this.companyLocation
                        ? {
                              companyLocation: this.companyLocation,
                              employeesChanged: this.changedEmployees,
                          }
                        : undefined;

                    if (forms.some(f => f.dirty)) {
                        const confirmMessage: string = $localize`:@@confirmDiscardChanges:You have unsaved changes. Do you want to discard them?`;
                        const confirmed: boolean = confirm(confirmMessage);
                        if (confirmed) {
                            this.dialogRef.close(closeObject);
                        }
                    } else {
                        this.dialogRef.close(closeObject);
                    }
                });
        }
    }

    public estimateModalSplit() {
        if (this.companyLocation == null || this.companyLocation.id == null) {
            return of({} as MobilityStats);
        }

        this.companyLocationCreationState = CompanyLocationCreationState.ESTIMATING_MODAL_SPLIT;
        this.loadingService.loadingText.next(this.companyLocationCreationState.toString());

        return this.modeEstimationWrapperService
            .estimateModalSplitAsync(this.companyLocation.id)
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(stats => {
                    if (this.companyLocation == null) {
                        return;
                    }

                    this.companyLocation.mobilityStats = stats;
                    this.mobilityParamsFormGroup.controls.mobilityStats.setValue(stats);
                    this.mobilityParamsFormGroup.controls.mobilityStats.updateValueAndValidity();

                    this.companyLocationCreationState =
                        CompanyLocationCreationState.ESTIMATING_FINISHED;
                }),
            );
    }

    public resetUploadStepData(): Observable<void> {
        this.uploadFormGroup.controls.uploadType.setValue(null);
        this.uploadFormGroup.controls.uploadType.clearValidators();
        this.uploadFormGroup.controls.uploadType.updateValueAndValidity();

        return of(void 0);
    }

    public checkForCalculationPropertiesStep(): Observable<boolean> {
        return this.userDataService.showFeature$(FeatureFlag.CALCULATION_PROPERTIES).pipe(
            switchMap(val => {
                if (this.companyLocation == null || this.companyLocation.id == null) {
                    return of(null);
                }

                if (val) {
                    return this.mobilityPropertiesService
                        .getLocationMobilityProperties(this.companyLocation.id)
                        .pipe(
                            takeUntil(this.unsubscribe$),
                            catchError(err => {
                                if (err?.error?.code === 'NO_LOCATION_PROPERTIES') {
                                    this.createNewLocationProperties = true;
                                } else {
                                    this.createNewLocationProperties = false;
                                }

                                return of(null);
                            }),
                        );
                }

                return of(null);
            }),
            map(res => {
                if (res) {
                    const { optimalModeEstimationParams, ...value } = res;
                    this.enableModeEstimationConfig = optimalModeEstimationParams != null;
                    this.locationPropertiesForm.patchValue({
                        ...value,
                        modeEstimationConfig: { modeAssignment: optimalModeEstimationParams },
                    });

                    this.originalMobilityPropertiesData = res;
                }

                return true;
            }),
        );
    }

    public resetCalculationPropertiesForm(): void {
        if (this.originalMobilityPropertiesData) {
            const { optimalModeEstimationParams, ...value } = this.originalMobilityPropertiesData;

            this.locationPropertiesForm.patchValue({
                ...value,
                modeEstimationConfig: { modeAssignment: optimalModeEstimationParams },
            });
        } else {
            this.locationPropertiesForm.reset();
        }
    }

    /**
     * On steps reset
     */
    public onStepsReset(): void {
        if (this.newCompanyLocation) {
            if (this.companyLocation != null && this.companyLocation.id != null) {
                this.companyLocationsWrapperService
                    .deleteCompanyLocation(this.companyLocation.id)
                    .subscribe(() => {
                        this.location.replaceState('/audits/new');
                        this.companyLocation = undefined;
                        this.initForms();
                        this.originalUploadBody = this.createUploadBody();
                    });
            } else if (this.companyLocation != null) {
                this.companyLocation = undefined;
                this.initForms();
            } else {
                this.initForms();
            }

            /* TODO: Reset data
            void this.router.navigate(['/audits/new'], { state: { reset: true } }).then(() => {
                this.dataService
                    .resetData()
                    .pipe(take(1))
                    .subscribe(() => {
                        this.disableDialogCloseAndBackwardStep();
                    });
            });
            */
        } else {
            // Don't allow reset for already created company
        }
    }

    /**
     * Method which is called when the step changes
     */
    public onStepsCompleted(): void {
        this.upscoreStepper.disabled = true;
        if (this.mobilityParamsFormGroup.value.modalSplitType === ModalSplitType.MODE_SUGGESTION) {
            this.estimateModalSplit()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    if (this.companyLocation == null) {
                        return;
                    }
                    this.updateCompanyLocation(this.companyLocation).subscribe(() => {
                        this.handleFinishedLocationCreation();
                    });
                });
        } else if (this.companyLocation != null) {
            this.updateCompanyLocation(this.companyLocation).subscribe(() => {
                this.handleFinishedLocationCreation();
            });
        }
    }

    public createMobilityProperties(): Observable<boolean> {
        this.locationPropertiesForm.markAsPristine();

        if (this.createNewLocationProperties) {
            return this.setLocationMobilityProperties().pipe(
                map(() => {
                    return true;
                }),
            );
        } else {
            return this.updateLocationMobilityProperties();
        }
    }

    public setLocationMobilityProperties(): Observable<void> {
        if (this.companyLocation?.id == null) {
            return of(void 0);
        }

        return this.mobilityPropertiesService
            .setLocationMobilityProperties(
                this.companyLocation.id,
                this.locationPropertiesForm.value,
            )
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => {
                    this.createNewLocationProperties = false;
                }),
            );
    }

    public updateLocationMobilityProperties(): Observable<boolean> {
        if (this.companyLocation?.id == null) {
            return of(true);
        }

        return this.mobilityPropertiesService
            .updateLocationMobilityProperties(
                this.companyLocation.id,
                this.locationPropertiesForm.value,
            )
            .pipe(
                takeUntil(this.unsubscribe$),
                map(() => {
                    return true;
                }),
            );
    }

    public uploadCompanyLocation(): Observable<boolean> {
        this.entrancesFormGroup.markAsPristine();
        this.locationFormGroup.markAsPristine();
        const companyLocation = this.companyLocation;

        if (companyLocation == null) {
            return this.createLocations().pipe(
                map(() => {
                    return true;
                }),
            );
        } else {
            return this.updateCompanyLocation(companyLocation).pipe(
                map(() => {
                    return true;
                }),
                catchError(error => {
                    if (error?.error?.code === 'ENTRANCE_IN_USE') {
                        if (this.originalCompanyLocation) {
                            this.companyLocation = {
                                ...companyLocation,
                                ...this.originalCompanyLocation.entrances,
                            };
                            this.initForms();
                        }
                    }

                    return of(false);
                }),
            );
        }
    }

    public uploadAggregatedEmployees(
        companyLocation: CompanyLocation,
        file: File | Blob,
        geocoder: 'employeeGeocoder' | 'hereApi',
        overwriteExistingEmployees: boolean,
        limitDistanceFromCompanyLocation: number | undefined,
    ): Observable<AggregatedGeocodingResult> {
        return from(this.validateUploadedEmployeeData(companyLocation, file, 'AGGREGATED')).pipe(
            switchMap(validateResult => {
                return this.employeesWrapperService.uploadAggregatedEmployeeDataForLocationAsync({
                    companyLocationId: companyLocation.id,
                    body: { file },
                    geocoder: geocoder,
                    withDistanceCalculation: false,
                    overwriteExistingEmployees,
                    limitDistanceFromCompanyLocation,
                });
            }),
            takeUntil(this.unsubscribe$),
            tap({
                next: result => {
                    const newEmployeesCount: number = result.succeeded.reduce(
                        (prev, curr) => prev + (curr.count ?? 0),
                        0,
                    );

                    if (this.uploadFormGroup.value.overwrite) {
                        companyLocation.employeeCount = newEmployeesCount;
                    } else {
                        companyLocation.employeeCount += newEmployeesCount;
                    }
                    this.uploadEmployeeCallback(result);
                },
                error: error => {
                    this.uploadFormGroup.controls.file.setValue(null);
                },
            }),
        );
    }

    public uploadDetailedEmployees(
        companyLocation: CompanyLocation,
        file: File | Blob,
        geocoder: 'employeeGeocoder' | 'hereApi',
        overwriteExistingEmployees: boolean,
        limitDistanceFromCompanyLocation: number | undefined,
    ): Observable<DetailedGeocodingResult> {
        return from(this.validateUploadedEmployeeData(companyLocation, file, 'DETAILED')).pipe(
            switchMap(validateResult => {
                return this.employeesWrapperService.uploadDetailedEmployeeDataForLocationAsync({
                    companyLocationId: companyLocation.id,
                    body: { file },
                    geocoder,
                    withDistanceCalculation: false,
                    overwriteExistingEmployees,
                    limitDistanceFromCompanyLocation,
                });
            }),
            takeUntil(this.unsubscribe$),
            tap({
                next: result => {
                    if (this.uploadFormGroup.value.overwrite) {
                        companyLocation.employeeCount = result.succeeded.length;
                    } else {
                        companyLocation.employeeCount += result.succeeded.length;
                    }
                    this.uploadEmployeeCallback(result);
                },
                error: error => {
                    this.uploadFormGroup.controls.file.setValue(null);
                },
            }),
        );
    }

    public async validateUploadedEmployeeData(
        companyLocation: CompanyLocation,
        file: File | Blob,
        mode: 'DETAILED' | 'AGGREGATED',
    ): Promise<ValidationResult> {
        try {
            const validationResult = await firstValueFrom(
                this.employeesWrapperService.validateUploadedEmployeeData({
                    mode,
                    ignored: companyLocation.id.toString(),
                    body: { file },
                }),
            );

            if (validationResult.ignored && validationResult.ignored.length > 0) {
                const errorString = InitialDataInputDialogTranslations.followingColumnsIgnoredText(
                    validationResult.ignored,
                );
                const dialogRef: MatDialogRef<ErrorCancelContinueDialogComponent, boolean> =
                    this.dialog.open(ErrorCancelContinueDialogComponent, {
                        panelClass: 'error-cancel-continue-dialog-container',
                        data: { errorText: errorString },
                    });
                const cancel = await firstValueFrom(dialogRef.afterClosed());
                if (cancel) {
                    throw new Error('User cancelled upload');
                }
            }

            return validationResult;
        } catch (error: any) {
            if (error?.error?.missing && error?.error?.missing.length > 0) {
                const errorString: string =
                    InitialDataInputDialogTranslations.followingColumnsMissingText(
                        error?.error?.missing,
                    );
                void this.httpErrorToastService.showInfoEvent(errorString, true);
            }
            throw error;
        }
    }

    public skipStepperCheck(): Observable<boolean> {
        return of(true);
    }

    public createEmployees(): Observable<boolean> {
        this.uploadFormGroup.markAsPristine();

        if (this.uploadFormGroup.value.uploadType == null) {
            return this.skipStepperCheck();
        }

        if (this.companyLocation?.id == null) {
            return this.skipStepperCheck();
        }
        this.changedEmployees = true;
        switch (this.uploadFormGroup.value.uploadType) {
            case FileUploadEnum.DETAILED:
                if (this.uploadFormGroup.value.file == null) {
                    return this.skipStepperCheck();
                }

                this.loadingService.loadingText.next(
                    CompanyLocationCreationState.UPLOADING_EMPLOYEES,
                );

                return this.uploadDetailedEmployees(
                    this.companyLocation,
                    this.uploadFormGroup.value.file,
                    this.uploadFormGroup.value.geocoder ?? 'employeeGeocoder',
                    this.uploadFormGroup.value.overwrite ?? false,
                    this.uploadFormGroup.value.maxDistanceCheckbox
                        ? this.uploadFormGroup.value.distanceSlider
                        : undefined,
                ).pipe(
                    map(() => {
                        return true;
                    }),
                );
            case FileUploadEnum.AGGREGATED:
                if (this.uploadFormGroup.value.file == null) {
                    return this.skipStepperCheck();
                }

                this.loadingService.loadingText.next(
                    CompanyLocationCreationState.UPLOADING_EMPLOYEES,
                );

                return this.uploadAggregatedEmployees(
                    this.companyLocation,
                    this.uploadFormGroup.value.file,
                    this.uploadFormGroup.value.geocoder ?? 'employeeGeocoder',
                    this.uploadFormGroup.value.overwrite ?? false,
                    this.uploadFormGroup.value.maxDistanceCheckbox
                        ? this.uploadFormGroup.value.distanceSlider
                        : undefined,
                ).pipe(
                    map(() => {
                        return true;
                    }),
                );
            case FileUploadEnum.ESTIMATE:
                if (this.uploadFormGroup.value.employeeCount == null) {
                    return this.skipStepperCheck();
                }

                return this.estimateEmployees(
                    this.companyLocation,
                    this.uploadFormGroup.value.employeeCount,
                    this.uploadFormGroup.value.entranceNumber || 0,
                ).pipe(
                    map(() => {
                        return true;
                    }),
                );
        }
    }

    public reUploadFailedEmployees(
        options: {
            failedEmployees: FailedDetailedEmployee[] | FailedAggregatedEmployees[] | null;
            uploadType: FileUploadEnum;
        },
        companyLocation: CompanyLocation,
    ): void {
        if (this.companyLocation?.id == null) {
            return;
        }

        if (options.uploadType === FileUploadEnum.DETAILED) {
            // mapping from EmployeeLocation to DetailedEmployeeInput
            const body: DetailedEmployeeInput[] = (
                options.failedEmployees as FailedDetailedEmployee[]
            ).map((data: FailedDetailedEmployee) => {
                return {
                    zipcode: data.zipcode,
                    street: data.street,
                    city: data.city,
                    department: data.department,
                    hoursPerWeek: data.hoursPerWeek?.toString(),
                    shift: data.shift,
                    entranceNumber: data.entranceNumber?.toString(),
                    fixedMode: data.fixedMode,
                    secondaryFixedMode: data.secondaryFixedMode,
                    entryTime: data.entryTime,
                    exitTime: data.exitTime,
                    split: data.split,
                    daysInOffice: data.daysInOffice,
                    impossibleModes: data.impossibleModes,
                    id: data.realId,
                };
            });
            this.employeesWrapperService
                .uploadDetailedEmployeeDataForLocationAsJson({
                    companyLocationId: this.companyLocation.id,
                    geocoder: this.uploadFormGroup.value.geocoder ?? 'employeeGeocoder',
                    body,
                    withDistanceCalculation: false,
                    limitDistanceFromCompanyLocation: this.uploadFormGroup.value.maxDistanceCheckbox
                        ? this.uploadFormGroup.value.distanceSlider
                        : undefined,
                })
                .subscribe(result => {
                    companyLocation.employeeCount += result.succeeded.length;
                    if (result.failed != null && result.failed.length > 0) {
                        this.uploadFormGroup.controls.error.setValue(result.failed);
                    } else {
                        this.uploadFormGroup.controls.error.setValue(null);
                    }
                    if (result.failed == null || result.failed.length === 0) {
                        this.upscoreStepper.preCallCheck();
                    }
                });
        } else if (options.uploadType === FileUploadEnum.AGGREGATED) {
            const body = (options.failedEmployees as FailedAggregatedEmployees[]).map(data => {
                return {
                    zipcode: data.zipcode,
                    count: data.count.toString(),
                    entranceNumber: data.entranceNumber.toString(),
                } as AggregatedEmployeeInput;
            });
            this.employeesWrapperService
                .uploadAggregatedEmployeeDataForLocationAsJson({
                    companyLocationId: this.companyLocation.id,
                    body,
                    withDistanceCalculation: false,
                    limitDistanceFromCompanyLocation: this.uploadFormGroup.value.maxDistanceCheckbox
                        ? this.uploadFormGroup.value.distanceSlider
                        : undefined,
                })
                .subscribe(result => {
                    companyLocation.employeeCount += result.succeeded.reduce(
                        (prev, curr) => prev + (curr.count ?? 0),
                        0,
                    );
                    if (result.failed != null && result.failed.length > 0) {
                        this.uploadFormGroup.controls.error.setValue(result.failed ?? []);
                    } else {
                        this.uploadFormGroup.controls.error.setValue(null);
                    }
                    if (result.failed == null || result.failed.length === 0) {
                        this.upscoreStepper.preCallCheck();
                    }
                });
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }

    public resetOptimalModeEstimationParams(): void {
        this.locationPropertiesForm.controls['optimalModeEstimationParams'].setValue(null);
    }

    /**
     * Method to init the forms
     */
    private initForms(): void {
        this.locationFormGroup = this.formBuilder.nonNullable.group({
            name: this.companyLocation ? this.companyLocation.name : '',
            industry: [this.companyLocation ? this.companyLocation.industry : []],
            timeZone: [this.companyLocation ? this.companyLocation.timeZone : null],
        });

        this.entrancesFormGroup = this.formBuilder.nonNullable.group({
            entrances: this.untypedFormBuilder.array([]),
        });

        if (this.companyLocation) {
            this.companyLocation.entrances.forEach((entrance, index) => {
                const entranceForm = this.formBuilder.nonNullable.group({
                    street: [entrance.location.street, Validators.required],
                    city: [entrance.location.city, Validators.required],
                    country: [entrance.location.country, Validators.required],
                    zipcode: [entrance.location.zipcode, Validators.required],
                    latitude: [entrance.location.latitude, Validators.required],
                    longitude: [entrance.location.longitude, Validators.required],
                });

                this.entrancesFormGroup.controls['entrances'].push(entranceForm);
            });
        }

        this.locationFormGroup.addControl(
            'locations',
            this.entrancesFormGroup.controls['entrances'] as AbstractControl,
        );

        this.mobilityParamsFormGroup = this.formBuilder.nonNullable.group({
            // TODO when editing this also edit fillMobilityFormGroupWithCompanyLocation
            isImprovementOnly: this.formBuilder.nonNullable.control<boolean>(false),
            overrideFixedMode: this.formBuilder.nonNullable.control<boolean>(false),
            vehicleStats: this.formBuilder.nonNullable.control<VehicleStats | undefined>(
                this.companyLocation?.vehicleStats,
            ),
            mobilityStats: this.formBuilder.nonNullable.control<MobilityStats | undefined>(
                this.companyLocation?.mobilityStats,
            ),
            shiftWork: this.formBuilder.nonNullable.control<boolean>(
                this.companyLocation?.shiftWork ?? false,
            ),
            presenceDays: this.formBuilder.nonNullable.control<number | undefined>(
                this.companyLocation?.presenceDays,
                {
                    validators: Validators.compose([Validators.min(0.1), Validators.max(5)]),
                },
            ),
            parkingLotCosts: this.formBuilder.nonNullable.control<number>(
                this.companyLocation?.parkingLotCosts ?? 0,
                {
                    validators: Validators.min(0),
                },
            ),
            businessTravelTotalEmissions: this.formBuilder.nonNullable.control<number>(
                this.companyLocation?.businessTravelTotalEmissions ?? 0,
                {
                    validators: Validators.min(0),
                },
            ),
            otherCosts: this.formBuilder.nonNullable.control<number>(
                this.companyLocation?.otherCosts ?? 0,
                {
                    validators: Validators.min(0),
                },
            ),
            modalSplitType: this.formBuilder.nonNullable.control<ModalSplitType>(
                this.companyLocation?.modeEstimationConfig?.modeAssignment != null
                    ? ModalSplitType.MODE_CALCULATION
                    : this.newCompanyLocation
                      ? ModalSplitType.MODE_SUGGESTION
                      : ModalSplitType.MODAL_SPLIT,
            ),
            modeEstimationConfig: this.utilsService.modeEstimationConfigToFormGroup(
                this.companyLocation?.modeEstimationConfig,
            ),
        });

        this.uploadFormGroup = this.formBuilder.nonNullable.group({
            uploadType: this.formBuilder.control<FileUploadEnum | null>(null, {
                validators:
                    this.companyLocation?.employeeCount == null ? Validators.required : null,
            }),
            employeeCount: this.formBuilder.control<null | number>(null),
            file: this.formBuilder.control<null | File>(null),
            error: this.formBuilder.control<
                null | FailedDetailedEmployee[] | FailedAggregatedEmployees[]
            >(null),
            overwrite: this.formBuilder.nonNullable.control<boolean>(false),
            geocoder: this.formBuilder.nonNullable.control<'employeeGeocoder' | 'hereApi'>(
                'employeeGeocoder',
            ),
            maxDistanceCheckbox: this.formBuilder.nonNullable.control<boolean>(false),
            distanceSlider: this.formBuilder.nonNullable.control<number>(0),
            entranceNumber: this.formBuilder.nonNullable.control<number>(0),
        });

        this.locationPropertiesForm = this.untypedFormBuilder.group({
            maxBikingTime: [null],
            maxWalkingTime: [null],
            maxCarTime: [null],
            maxTransitTime: [null],
            smallVehicleEmissions: [null],
            regularVehicleEmissions: [null],
            largeVehicleEmissions: [null],
            electricCarEmissions: [null],
            generalCarEmissions: [null],
            transitEmissions: [null],
            bikeCostPerKm: [null],
            carCostPerKm: [null],
            walkingCostPerKm: [null],
            transitCostPerKm: [null],
            minimumCarpoolTime: [null],
            maxPeopleInCarpool: [null],
            presenceDaysPerYear: [null],
            enabledAgencies: [null],
            improvementOnlyOptimum: [null],
            optimalModeCarpoolingLikelihood: null,
            maxPresenceDaysPerWeek: [
                null,
                Validators.compose([Validators.min(0), Validators.max(6)]),
            ],
            entryTime: [null],
            exitTime: [null],
            modeEstimationConfig: this.formBuilder.nonNullable.group({
                modeAssignment: this.formBuilder.nonNullable.control<any>(null),
            }),
        });

        this.locationPropertiesForm.addControl(
            'optimalModeEstimationParams',
            (this.locationPropertiesForm.controls['modeEstimationConfig'] as FormGroup).controls[
                'modeAssignment'
            ],
        );
    }

    private createUploadBody(): PostedCompanyLocation {
        // @ts-expect-error: TODO This isnt a PostedCompanyLocation
        const uploadBody: PostedCompanyLocation = {
            ...this.locationFormGroup.value,
            industry:
                this.locationFormGroup.controls.industry.value === null
                    ? []
                    : this.locationFormGroup.controls.industry.value,
            ...this.mobilityParamsFormGroup.value,
        };
        // @ts-expect-error: Upload body has no Modal Split type
        delete uploadBody.modalSplitType;

        return uploadBody;
    }

    /**
     * Checks which properties got changed and adds only new ones to the change object
     */
    private updateCompanyLocation(companyLocation: CompanyLocation): Observable<CompanyLocation> {
        // Recreate an upload body with our new values
        const body: PostedCompanyLocation = this.createUploadBody();

        // As we need a CompanyLocationToChange and not a PostedCompanyLocation
        // we need to do some filtering and renaming

        // Remove all equal properties of PostedCompanyLocation
        const changedCompanyLocation: CompanyLocationToChange = omitBy<PostedCompanyLocation>(
            body,
            (v, k) => {
                return isEqual((this.originalUploadBody as { [index: string]: any })[k], v);
            },
        );

        // we need special handling for entrances, because we need to know which entrance to update
        if (!isEqual(body.locations, this.originalUploadBody.locations)) {
            changedCompanyLocation.entrances = body.locations.map((postedLocation, index) => {
                // Rest of the code remains unchanged
                if (this.originalUploadBody.locations.length > index) {
                    // find properties to update and omit others
                    postedLocation = omitBy<PostedLocation>(postedLocation, (v, k) => {
                        return (
                            this.originalUploadBody.locations[index][k as keyof PostedLocation] ===
                            v
                        );
                    }) as PostedLocation;
                }

                return {
                    location: postedLocation,
                    number: index,
                };
            });
        }

        if (isEmpty(changedCompanyLocation)) {
            return of(companyLocation);
        }

        this.dialogRef.disableClose = true;

        (body as CompanyLocationToChange).entrances = body.locations.map(
            (postedLocation, index) => {
                return {
                    location: postedLocation,
                    number: index,
                };
            },
        );

        return this.companyLocationsWrapperService
            .updateLocation({
                companyLocationId: companyLocation.id,
                body: body,
            })
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(response => {
                    this.companyLocation = response;
                }),
            );
    }

    /**
     * Method to create locations after a successful step completion
     */
    private createLocations(): Observable<CompanyLocation> {
        const body: PostedCompanyLocation = this.createUploadBody();

        return this.companyLocationsWrapperService
            .createLocation({
                body,
            })
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(response => {
                    this.companyLocation = response;
                    this.location.replaceState(`/audits/${this.companyLocation.id}`);
                    this.originalUploadBody = this.createUploadBody();
                    this.utilsService.fillMobilityFormGroupWithCompanyLocation(
                        this.mobilityParamsFormGroup,
                        response,
                    );
                }),
            );
    }

    private estimateEmployees(
        companyLocation: CompanyLocation,
        amount: number,
        entranceNumber: number,
    ): Observable<Employee[]> {
        this.companyLocationCreationState = CompanyLocationCreationState.ESTIMATING_EMPLOYEES;
        this.loadingService.loadingText.next(this.companyLocationCreationState.toString());

        return this.employeesWrapperService
            .estimateEmployees(companyLocation.id, amount, entranceNumber, false)
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(
                    emp => {
                        companyLocation.employeeCount += emp.length;
                    },
                    error => {
                        // Fixes a bug because company location will get created again.
                        this.companyLocationCreationState =
                            CompanyLocationCreationState.ESTIMATION_NEEDED;
                    },
                ),
            );
    }

    private uploadEmployeeCallback(
        result: AggregatedGeocodingResult | DetailedGeocodingResult,
    ): void {
        // always reset after an upload
        this.uploadFormGroup.controls.overwrite.setValue(false);
        this.uploadFormGroup.controls.uploadType.clearValidators();
        this.uploadFormGroup.controls.file.clearValidators();
        this.uploadFormGroup.controls.file.setValue(null);

        if (result.failed != null && result.failed.length > 0) {
            this.uploadFormGroup.controls.error.setValue(result.failed);
            this.toastService.showInfoEvent(
                this.translations.employeeUploadError,
                true,
                false,
                this.translations.employeeUploadErrorDescription,
            );
            // Fail this request
            throw new Error();
        } else {
            this.uploadFormGroup.controls.error.setValue(null);
            this.uploadFormGroup.controls.maxDistanceCheckbox.setValue(false);
        }
        this.uploadFormGroup.updateValueAndValidity();
    }

    private handleFinishedLocationCreation(): void {
        this.dialogRef.close(
            this.companyLocation
                ? {
                      companyLocation: this.companyLocation,
                      employeesChanged: this.changedEmployees,
                  }
                : undefined,
        );
    }
}
