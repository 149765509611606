import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { NumberUnit } from '@shared/utils';

import { BaseKpiCardComponent } from '../../models/base-kpi-card.model';

@Component({
    selector: 'single-value-card',
    templateUrl: './single-value-card.component.html',
    styleUrls: ['./single-value-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleValueCardComponent extends BaseKpiCardComponent implements OnChanges {
    @Input() public title: string;

    @Input() public unit: NumberUnit | string;
    @Input() public text: string;

    @Input() public baseline?: number;
    @Input() public current: number;

    @Input() public hasMenu = true;
    @Input() public showTimePeriod = false;

    @Input() public reversePercentageDisplay = false;

    public ngOnChanges(): void {
        this.changes.next();
    }
}
