export class OptimumPageTranslations {
    public static readonly people: string = $localize`:People@@people:People`;
    public static readonly avgPerVehicleText: string = $localize`:Avg. Per Vehicle@@avgPerVehicle:Average Per Vehicle`;
    public static readonly modalSplitText: string = $localize`:Modal Split Distribution@@modalSplitDistribution:Modal Split Distribution`;
    public static readonly carOccupancyTitle: string = $localize`:@@carOccupancy:Car Occupancy`;
    public static readonly co2EmissionText: string = $localize`:@@co2Emission:CO2 Emissions`;
    public static readonly businessCostsText: string = $localize`:@@businessCosts:Employer Costs`;
    public static readonly employeeCostsText: string = $localize`:@@employeeCosts:Employee Costs`;
    public static readonly travelDistanceText: string = $localize`:@@travelDistance:Travel Distance`;
    public static readonly travelTimeText: string = $localize`:@@travelTime:Travel Time`;
    public static readonly avgPerEmployeeText: string = $localize`:@@employeeTotal:Ø per Employee (Total)`;
    public static readonly legendEntryCompanyLocation: string = $localize`:@@companyLocation:Company Location`;
    public static readonly modalSplitTitle: string = $localize`:@@modalSplitInCompany:Modal Split`;

    public static readonly legendEntryUnmarkedEmployees: string = $localize`:@@employeesUnmarked:Unmarked Employees`; // // MA ohne Kennzeichnung
    public static readonly legendEntryBiker: string = $localize`:@@bike:Bike`; // Radfahrer
    public static readonly legendEntryWalker: string = $localize`:@@walk:Walk`; // 'Fußgänger';
    public static readonly legendEntryDriver: string = $localize`:@@carDriver:Car Driver`; // 'Fahrer';
    public static readonly legendEntryPassenger: string = $localize`:@@carPassenger:Car Passenger`; // 'Mitfahrer';
    public static readonly legendEntryPTDriver: string = $localize`:@@ptUser:Public Transport Users`; // 'ÖV-Fahrer';
    public static readonly legendEntryCarRoutes: string = $localize`:@@mivRoutes:Car Routes`; // 'MIV Routen';
    public static readonly legendEntryReachabilityBike: string = $localize`:@@bikeReachabilityOptimum:Optimal Accessibility By Bike`; // 'Opt. Erreichbarkeit per Rad';
    public static readonly legendEntryReachabilityWalk: string = $localize`:@@walkReachabilityOptimum:Optimal Accessibility On Foot`; // 'Opt. Erreichbarkeit per Fußweg';
    public static readonly legendEntryReachabilityCar: string = $localize`:@@carReachabilityOptimal:Optimal Accessibility By Car`; // 'Opt. Erreichbarkeit per MIV';
    public static readonly legendEntryReachabilityPT: string = $localize`:@@ptReachabilityOptimal:Optimal Accessibility By Public Transport`; // 'Opt. Erreichbarkeit per ÖV';

    public static readonly topicTableCardTitleCarpooling: string = $localize`:@@carpooling:Carpooling`;
    public static readonly carOccupancyAverageText: string = $localize`:@@avgPerVehicle:Average Per Vehicle`;
}
