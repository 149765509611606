<div class="w-[400px] rounded-[5px] max-h-[600px] overflow-y-auto overflow-x-clip">
    <!-- nested divs are necessary to prevent scroll bar from changing hr width. -->
    <div class="px-xl p-xl bg-white w-[352px] gap-xl flex flex-col">
        @if (tasks?.length === 0) {
            <div class="text-center text-grey-light">No notifications</div>
        }
        @for (task of tasks; track task; let last = $last) {
            <upscore-task-notification
                [task]="task.progress"
                [definition]="task.definition"
                [taskId]="task.id"
                [standalone]="false"
                [companyLocationName]="task.locationName"></upscore-task-notification>

            @if (!last) {
                <hr />
            }
        }
    </div>
</div>
