import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { AbstractUserDataService } from '@shared/utils';
import { UserInfo } from '@upscore-mobility-audit/api';
import { BetaFeatureType } from '@upscore-mobility-audit/shared/interfaces/beta-feature-type.interface';

import { PopoverHoverDirective } from '../../directives/popover-hover.directive';

@Component({
    selector: 'upscore-mobility-audit-beta-feature-info',
    standalone: true,
    imports: [CommonModule, PopoverHoverDirective],
    templateUrl: './beta-feature-info.component.html',
    styleUrl: './beta-feature-info.component.scss',
})
export class BetaFeatureInfoComponent {
    @Input() public source?: BetaFeatureType;

    public text = $localize`:@@betaText:This feature is currently in beta. Do you have any suggestions on how we can improve it, or would you like to leave other feedback?`;
    public user$ = this.userService.user$;

    constructor(private userService: AbstractUserDataService<UserInfo>) {}
}
