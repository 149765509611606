<div class="table-wrapper">
    <!-- column -->
    @for (entry of distribution; track entry; let last = $last) {
        <div class="entry-wrapper">
            <!-- first col -->
            <div class="first-row">
                <div class="first-column">
                    <span [style.background-color]="entry.color" class="dot"></span>
                    <span class="element-text">{{ entry.title }}</span>
                </div>
                <div class="second-column">
                    <div class="yearly-daily-content">
                        <span class="yearly-daily-text spacer">
                            <kpi-measure
                                [digitsInfo]="digitsInfo"
                                [measureCssClass]="
                                    'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                                "
                                [unit]="unit"
                                [valueCssClass]="
                                    'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                                "
                                [value]="entry.yearly"></kpi-measure>
                            <span class="digit-text-spacer" i18n="@@yearly">Annual</span>
                        </span>
                        <span class="yearly-daily-text">
                            <kpi-measure
                                [digitsInfo]="digitsInfo"
                                [measureCssClass]="
                                    'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                                "
                                [unit]="unit"
                                [valueCssClass]="
                                    'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                                "
                                [value]="entry.daily"></kpi-measure>
                            <span class="digit-text-spacer" i18n="@@daily">Per Day</span></span
                        >
                    </div>
                </div>
            </div>
            <!-- second col -->
            <div class="table-wrapper__second-col">
                <kpi-measure
                    [measureCssClass]="
                        'kpi-measure__measure--color-dark kpi-measure__measure--text-xs'
                    "
                    [showChangePrefix]="false"
                    [unit]="numberUnit.PERCENTAGE"
                    [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-sm'"
                    [value]="entry.distribution * 100"></kpi-measure>
            </div>
        </div>
        @if (!last) {
            <div class="divider"></div>
        }
    }
</div>
