// export enum SidebarSectionContentLabel {
//     'BIKE_REACHABILITY' = 'Opt. Erreichbarkeit per Rad',
//     'WALK_REACHABILITY' = 'Opt. Erreichbarkeit per Fuß',
//     'CAR_REACHABILITY' = 'Opt. Erreichbarkeit per MIV',
//     'PUBLIC_TRANSPORT_REACHABILITY' = 'Opt. Erreichbarkeit per ÖV',
//     'LOCATION_MARKER_BIKE' = 'Kennzeichnung Radfahrer',
//     'LOCATION_MARKER_WALK' = 'Kennzeichnung Fußgeher',
//     'LOCATION_MARKER_PT' = 'Kennzeichnung ÖV-Fahrer',
//     'LOCATION_MARKER_DRIVER' = 'Kennzeichnung Standort Fahrer',
//     'LOCATION_MARKER_PASSENGER' = 'Kennzeichnung Standort Mitfahrer',
//     'CARPOOL_ROUTES' = 'Carpooling Routen',

//     'BIKE_REACHABILITY_IN_MINUTES' = 'Erreichbarkeit per Rad in Minuten',
//     'WALK_REACHABILITY_IN_MINUTES' = 'Erreichbarkeit per Fuß in Minuten',
//     'CAR_REACHABILITY_IN_MINUTES' = 'Erreichbarkeit per MIV in Minuten',
//     'PUBLIC_TRANSPORT_REACHABILITY_IN_MINUTES' = 'Erreichbarkeit per in Minuten',

//     'MARKER_CLUSTERING' = 'Gruppierung von Standorten',
//     'MARKER_LOCATION' = 'Anzeige von Standorten',
// }

/* Converting enums into class as $localize is not yet supported in enums by Angular.
We can go back to using enums once the support for $ localize in string based enums is added. */

export class SidebarSectionContentLabel {
    public static readonly BIKE_REACHABILITY: string = $localize`:@@bikeReachabilityOptimum:Optimal Accessibility By Bike`;
    public static readonly WALK_REACHABILITY: string = $localize`:@@walkReachabilityOptimum:Optimal Accessibility On Foot`;
    public static readonly CAR_REACHABILITY: string = $localize`:@@carReachabilityOptimal:Optimal Accessibility By Car`;
    public static readonly PUBLIC_TRANSPORT_REACHABILITY: string = $localize`:@@ptReachabilityOptimal:Optimal Accessibility By Public Transport`;

    public static readonly CARPOOL_ROUTES: string = $localize`:@@carpoolRoutes:Carpooling Routes`;
    public static readonly BIKE_REACHABILITY_IN_MINUTES: string = $localize`:@@bikeReachabilityInMins:Reachability By Bike In Minutes`;
    public static readonly WALK_REACHABILITY_IN_MINUTES: string = $localize`:@@walkReachabilityInMins:Reachability On Foot In Minutes`;
    public static readonly CAR_REACHABILITY_IN_MINUTES: string = $localize`:@@carReachabilityInMins:Reachability By Car In Minutes`;
    public static readonly PUBLIC_TRANSPORT_REACHABILITY_IN_MINUTES: string = $localize`:@@ptReachabilityInMins:Reachability By Public Transport In Minutes`;
    public static readonly REACHABLE_IN_DISTANCE: string = $localize`:@@reachabilityInKm:Reachability In Kilometers`;
    public static readonly MARKER_CLUSTERING: string = $localize`:@@groupLocations:Group Locations`;
    public static readonly CLUSTERING_ALGORITHM: string = $localize`:@@groupLocationsByZipCode:Group By Zip Code`;
    public static readonly CLUSTERING_RENDERER: string = $localize`:@@modalSplitCluster:Show Modal Split In Group`;
    public static readonly MARKER_LOCATION: string = $localize`:@@showLocations:Show Locations`;
    public static readonly HEATMAP = $localize`:@@heatmap:Show Heatmap`;
}
