/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AggregatedEstimationQuality } from '../models/aggregated-estimation-quality';
import { Employee } from '../models/employee';
import { EmployeeIdAndLocationAccuracy } from '../models/employee-id-and-location-accuracy';
import { EmployeePatch } from '../models/employee-patch';
import { EmployeePut } from '../models/employee-put';
import { EmployeesInfo } from '../models/employees-info';
import { JsonNode } from '../models/json-node';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation putEmployee
   */
  static readonly PutEmployeePath = '/api/v1/locations/{companyLocationId}/employeeLocations/{id}';

  /**
   * Update a specific employee (PUT).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putEmployee$Response(params: {
    id: number;
    companyLocationId: number;
    context?: HttpContext
    body: EmployeePut
  }
): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.PutEmployeePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * Update a specific employee (PUT).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putEmployee(params: {
    id: number;
    companyLocationId: number;
    context?: HttpContext
    body: EmployeePut
  }
): Observable<Employee> {

    return this.putEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * Path part for operation deleteEmployee
   */
  static readonly DeleteEmployeePath = '/api/v1/locations/{companyLocationId}/employeeLocations/{id}';

  /**
   * Delete the employee with the given Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEmployee$Response(params: {
    id: number;
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.DeleteEmployeePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Delete the employee with the given Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEmployee(params: {
    id: number;
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.deleteEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateEmployee
   */
  static readonly UpdateEmployeePath = '/api/v1/locations/{companyLocationId}/employeeLocations/{id}';

  /**
   * Update a specific employee (PATCH).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmployee$Response(params: {
    id: number;
    companyLocationId: number;
    context?: HttpContext
    body: EmployeePatch
  }
): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.UpdateEmployeePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * Update a specific employee (PATCH).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmployee(params: {
    id: number;
    companyLocationId: number;
    context?: HttpContext
    body: EmployeePatch
  }
): Observable<Employee> {

    return this.updateEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * Path part for operation newAggregatedEmployeesFromGeoJsonRequest
   */
  static readonly NewAggregatedEmployeesFromGeoJsonRequestPath = '/api/v1/locations/{companyLocationId}/employees/geojson';

  /**
   * Upload employee address data.
   *
   * The aggregated address data that comes from the company-internal HR department.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newAggregatedEmployeesFromGeoJsonRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newAggregatedEmployeesFromGeoJsonRequest$Response(params: {
    companyLocationId: number;
    withDistanceCalculation?: boolean;

    /**
     * Allow deleting existing employees and replacing them with the new ones
     */
    overwriteExistingEmployees?: boolean;
    context?: HttpContext
    body: JsonNode
  }
): Observable<StrictHttpResponse<Employee>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.NewAggregatedEmployeesFromGeoJsonRequestPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('withDistanceCalculation', params.withDistanceCalculation, {});
      rb.query('overwriteExistingEmployees', params.overwriteExistingEmployees, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Employee>;
      })
    );
  }

  /**
   * Upload employee address data.
   *
   * The aggregated address data that comes from the company-internal HR department.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `newAggregatedEmployeesFromGeoJsonRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newAggregatedEmployeesFromGeoJsonRequest(params: {
    companyLocationId: number;
    withDistanceCalculation?: boolean;

    /**
     * Allow deleting existing employees and replacing them with the new ones
     */
    overwriteExistingEmployees?: boolean;
    context?: HttpContext
    body: JsonNode
  }
): Observable<Employee> {

    return this.newAggregatedEmployeesFromGeoJsonRequest$Response(params).pipe(
      map((r: StrictHttpResponse<Employee>) => r.body as Employee)
    );
  }

  /**
   * Path part for operation employeeLocations
   */
  static readonly EmployeeLocationsPath = '/api/v1/locations/{companyLocationId}/employeeLocations';

  /**
   * get estimated employee address data.
   *
   * List detailed information of all employees in the company. Response also includes the employee's mode (if mobility score calculation was already done beforehand)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeLocations$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Employee>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.EmployeeLocationsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Employee>>;
      })
    );
  }

  /**
   * get estimated employee address data.
   *
   * List detailed information of all employees in the company. Response also includes the employee's mode (if mobility score calculation was already done beforehand)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `employeeLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeLocations(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<Employee>> {

    return this.employeeLocations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Employee>>) => r.body as Array<Employee>)
    );
  }

  /**
   * Path part for operation importEmployeesFromCsv
   */
  static readonly ImportEmployeesFromCsvPath = '/api/v1/locations/{companyLocationId}/employeeLocations';

  /**
   * import employee data.
   *
   * import all detailed employee data for the company from a csv file that can be generated from export function
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importEmployeesFromCsv()` instead.
   *
   * This method sends `text/csv;charset=UTF-8` and handles request body of type `text/csv;charset=UTF-8`.
   */
  importEmployeesFromCsv$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
    body: string
  }
): Observable<StrictHttpResponse<Array<Employee>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.ImportEmployeesFromCsvPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'text/csv;charset=UTF-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Employee>>;
      })
    );
  }

  /**
   * import employee data.
   *
   * import all detailed employee data for the company from a csv file that can be generated from export function
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importEmployeesFromCsv$Response()` instead.
   *
   * This method sends `text/csv;charset=UTF-8` and handles request body of type `text/csv;charset=UTF-8`.
   */
  importEmployeesFromCsv(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
    body: string
  }
): Observable<Array<Employee>> {

    return this.importEmployeesFromCsv$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Employee>>) => r.body as Array<Employee>)
    );
  }

  /**
   * Path part for operation upload
   */
  static readonly UploadPath = '/api/v1/locations/{companyLocationId}/employeeLocations/json_upload';

  /**
   * Upload employees address data from json file.
   *
   * Each json object should include at least the coordinates of the employee (latitude and longitude), the other attributes are not required
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upload()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upload$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * Allow deleting existing employees and replacing them with the new ones
     */
    overwriteExistingEmployees?: boolean;
    context?: HttpContext
    body: Array<{
[key: string]: {
};
}>
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.UploadPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('withDistanceCalculation', params.withDistanceCalculation, {});
      rb.query('overwriteExistingEmployees', params.overwriteExistingEmployees, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload employees address data from json file.
   *
   * Each json object should include at least the coordinates of the employee (latitude and longitude), the other attributes are not required
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upload$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upload(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * Allow deleting existing employees and replacing them with the new ones
     */
    overwriteExistingEmployees?: boolean;
    context?: HttpContext
    body: Array<{
[key: string]: {
};
}>
  }
): Observable<void> {

    return this.upload$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation detailedEstimationQuality
   */
  static readonly DetailedEstimationQualityPath = '/api/v1/locations/{companyLocationId}/estimationQuality/detailed';

  /**
   * Get detailed estimation quality for all employee locations in a company.
   *
   * detailed quality estimation call provides for each employee : id, location accuracy, raw accuracy, and the accuracy source. The location accuracy is only meaningful when the source is either EMPLOYEE_GEOCODER or HEREAPI_GEOCODER. Otherwise the location accuracy should be disregarded.The rawAccuracy value is only relevant when the accuracy source is EMPLOYEE_GEOCODER
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detailedEstimationQuality()` instead.
   *
   * This method doesn't expect any request body.
   */
  detailedEstimationQuality$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<EmployeeIdAndLocationAccuracy>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.DetailedEstimationQualityPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeIdAndLocationAccuracy>>;
      })
    );
  }

  /**
   * Get detailed estimation quality for all employee locations in a company.
   *
   * detailed quality estimation call provides for each employee : id, location accuracy, raw accuracy, and the accuracy source. The location accuracy is only meaningful when the source is either EMPLOYEE_GEOCODER or HEREAPI_GEOCODER. Otherwise the location accuracy should be disregarded.The rawAccuracy value is only relevant when the accuracy source is EMPLOYEE_GEOCODER
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `detailedEstimationQuality$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  detailedEstimationQuality(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<EmployeeIdAndLocationAccuracy>> {

    return this.detailedEstimationQuality$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeIdAndLocationAccuracy>>) => r.body as Array<EmployeeIdAndLocationAccuracy>)
    );
  }

  /**
   * Path part for operation detailedEstimationQuality1
   */
  static readonly DetailedEstimationQuality1Path = '/api/v1/locations/{companyLocationId}/estimationQuality';

  /**
   * Get detailed estimation quality for all employee locations in a company.
   *
   * detailed quality estimation call provides for each employee : id, location accuracy, raw accuracy, and the accuracy source. The location accuracy is only meaningful when the source is either EMPLOYEE_GEOCODER or HEREAPI_GEOCODER. Otherwise the location accuracy should be disregarded.The rawAccuracy value is only relevant when the accuracy source is EMPLOYEE_GEOCODER
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detailedEstimationQuality1()` instead.
   *
   * This method doesn't expect any request body.
   */
  detailedEstimationQuality1$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<EmployeeIdAndLocationAccuracy>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.DetailedEstimationQuality1Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeIdAndLocationAccuracy>>;
      })
    );
  }

  /**
   * Get detailed estimation quality for all employee locations in a company.
   *
   * detailed quality estimation call provides for each employee : id, location accuracy, raw accuracy, and the accuracy source. The location accuracy is only meaningful when the source is either EMPLOYEE_GEOCODER or HEREAPI_GEOCODER. Otherwise the location accuracy should be disregarded.The rawAccuracy value is only relevant when the accuracy source is EMPLOYEE_GEOCODER
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `detailedEstimationQuality1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  detailedEstimationQuality1(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<EmployeeIdAndLocationAccuracy>> {

    return this.detailedEstimationQuality1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeIdAndLocationAccuracy>>) => r.body as Array<EmployeeIdAndLocationAccuracy>)
    );
  }

  /**
   * Path part for operation aggregatedEstimationQuality
   */
  static readonly AggregatedEstimationQualityPath = '/api/v1/locations/{companyLocationId}/estimationQuality/aggregated';

  /**
   * Get aggregated estimation quality for all employee locations in a company.
   *
   * Grouping the employees by accuracy source (employee geocoder, hereApi geocoder, ...), to provide their numbers per source. For each source, the employees are counted by location accuracy (e.g: street, zipcode,...).Aside from employee geocoder and here api geocoder sources, all the other accuracy sources do not have a location accuracy.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `aggregatedEstimationQuality()` instead.
   *
   * This method doesn't expect any request body.
   */
  aggregatedEstimationQuality$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AggregatedEstimationQuality>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.AggregatedEstimationQualityPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AggregatedEstimationQuality>>;
      })
    );
  }

  /**
   * Get aggregated estimation quality for all employee locations in a company.
   *
   * Grouping the employees by accuracy source (employee geocoder, hereApi geocoder, ...), to provide their numbers per source. For each source, the employees are counted by location accuracy (e.g: street, zipcode,...).Aside from employee geocoder and here api geocoder sources, all the other accuracy sources do not have a location accuracy.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `aggregatedEstimationQuality$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  aggregatedEstimationQuality(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<AggregatedEstimationQuality>> {

    return this.aggregatedEstimationQuality$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AggregatedEstimationQuality>>) => r.body as Array<AggregatedEstimationQuality>)
    );
  }

  /**
   * Path part for operation estimateEmployeesBasedOnCommuterData
   */
  static readonly EstimateEmployeesBasedOnCommuterDataPath = '/api/v1/locations/{companyLocationId}/estimateEmployees';

  /**
   * Estimate employees addresses based on commuters data (Austria only).
   *
   * First we check if the company location we are working on is located in Austria because this service is only available in that region then we send the location point and the number of employees desired to the geocoder (default number is 100) which then generate employees with locations based on commuters data  meaning the most likely places to commute to our company location Finally we save the employees in the database and calculate their distances and times (optional) and we return them to the user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimateEmployeesBasedOnCommuterData()` instead.
   *
   * This method doesn't expect any request body.
   */
  estimateEmployeesBasedOnCommuterData$Response(params: {
    companyLocationId: number;
    count?: number;
    withDistanceCalculation?: boolean;

    /**
     * The entrance number for which employees should be estimated
     */
    entranceNumber?: number;

    /**
     * Allow deleting existing employees and replacing them with the new ones
     */
    overwriteExistingEmployees?: boolean;

    /**
     * If work days of employees should be estimated based on distance to location.
     */
    estimateWorkDays?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Employee>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.EstimateEmployeesBasedOnCommuterDataPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('count', params.count, {});
      rb.query('withDistanceCalculation', params.withDistanceCalculation, {});
      rb.query('entranceNumber', params.entranceNumber, {});
      rb.query('overwriteExistingEmployees', params.overwriteExistingEmployees, {});
      rb.query('estimateWorkDays', params.estimateWorkDays, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Employee>>;
      })
    );
  }

  /**
   * Estimate employees addresses based on commuters data (Austria only).
   *
   * First we check if the company location we are working on is located in Austria because this service is only available in that region then we send the location point and the number of employees desired to the geocoder (default number is 100) which then generate employees with locations based on commuters data  meaning the most likely places to commute to our company location Finally we save the employees in the database and calculate their distances and times (optional) and we return them to the user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `estimateEmployeesBasedOnCommuterData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  estimateEmployeesBasedOnCommuterData(params: {
    companyLocationId: number;
    count?: number;
    withDistanceCalculation?: boolean;

    /**
     * The entrance number for which employees should be estimated
     */
    entranceNumber?: number;

    /**
     * Allow deleting existing employees and replacing them with the new ones
     */
    overwriteExistingEmployees?: boolean;

    /**
     * If work days of employees should be estimated based on distance to location.
     */
    estimateWorkDays?: boolean;
    context?: HttpContext
  }
): Observable<Array<Employee>> {

    return this.estimateEmployeesBasedOnCommuterData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Employee>>) => r.body as Array<Employee>)
    );
  }

  /**
   * Path part for operation getAggregatedEmployeesAsCsvFile
   */
  static readonly GetAggregatedEmployeesAsCsvFilePath = '/api/v1/locations/{companyLocationId}/employees';

  /**
   * generate aggregated employee input file.
   *
   * List all saved employee data for the company as a csv file that can be used to recreate employees
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAggregatedEmployeesAsCsvFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregatedEmployeesAsCsvFile$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.GetAggregatedEmployeesAsCsvFilePath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * generate aggregated employee input file.
   *
   * List all saved employee data for the company as a csv file that can be used to recreate employees
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAggregatedEmployeesAsCsvFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregatedEmployeesAsCsvFile(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.getAggregatedEmployeesAsCsvFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getEmployees
   */
  static readonly GetEmployeesPath = '/api/v1/locations/{companyLocationId}/employeesInfo';

  /**
   * get employee addresses.
   *
   * List all saved aggregated employee address data for the company. A successful response also contains the geocoded centroid of each district identified by the zipcode uploaded.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployees()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployees$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<EmployeesInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.GetEmployeesPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeesInfo>>;
      })
    );
  }

  /**
   * get employee addresses.
   *
   * List all saved aggregated employee address data for the company. A successful response also contains the geocoded centroid of each district identified by the zipcode uploaded.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployees(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<EmployeesInfo>> {

    return this.getEmployees$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeesInfo>>) => r.body as Array<EmployeesInfo>)
    );
  }

  /**
   * Path part for operation getDetailedEmployeesAsCsvFile
   */
  static readonly GetDetailedEmployeesAsCsvFilePath = '/api/v1/locations/{companyLocationId}/employees/detailed';

  /**
   * generate detailed employee input file.
   *
   * List all saved detailed employee data for the company as a csv file that can be used to recreate the same employees
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetailedEmployeesAsCsvFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedEmployeesAsCsvFile$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.GetDetailedEmployeesAsCsvFilePath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * generate detailed employee input file.
   *
   * List all saved detailed employee data for the company as a csv file that can be used to recreate the same employees
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDetailedEmployeesAsCsvFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedEmployeesAsCsvFile(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.getDetailedEmployeesAsCsvFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getDetailedEmployeesAsCsvFile1
   */
  static readonly GetDetailedEmployeesAsCsvFile1Path = '/api/v1/locations/{companyLocationId}/employees/single';

  /**
   * generate detailed employee input file.
   *
   * List all saved detailed employee data for the company as a csv file that can be used to recreate the same employees
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetailedEmployeesAsCsvFile1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedEmployeesAsCsvFile1$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.GetDetailedEmployeesAsCsvFile1Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * generate detailed employee input file.
   *
   * List all saved detailed employee data for the company as a csv file that can be used to recreate the same employees
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDetailedEmployeesAsCsvFile1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedEmployeesAsCsvFile1(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.getDetailedEmployeesAsCsvFile1$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation employeeLocations1
   */
  static readonly EmployeeLocations1Path = '/api/v1/locations/{companyLocationId}/employeeLocations.json';

  /**
   * get estimated employee address data.
   *
   * List detailed information of all employees in the company. Response also includes the employee's mode (if mobility score calculation was already done beforehand)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeLocations1()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeLocations1$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Employee>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.EmployeeLocations1Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Employee>>;
      })
    );
  }

  /**
   * get estimated employee address data.
   *
   * List detailed information of all employees in the company. Response also includes the employee's mode (if mobility score calculation was already done beforehand)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `employeeLocations1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeLocations1(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<Employee>> {

    return this.employeeLocations1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Employee>>) => r.body as Array<Employee>)
    );
  }

  /**
   * Path part for operation exportEmployeesAsCsv
   */
  static readonly ExportEmployeesAsCsvPath = '/api/v1/locations/{companyLocationId}/employeeLocations.csv';

  /**
   * export all database employee data.
   *
   * export all saved detailed employee data for the company as a csv file that can be used to import them again
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportEmployeesAsCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportEmployeesAsCsv$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.ExportEmployeesAsCsvPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * export all database employee data.
   *
   * export all saved detailed employee data for the company as a csv file that can be used to import them again
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportEmployeesAsCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportEmployeesAsCsv(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.exportEmployeesAsCsv$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation employeeLocationsPaging
   */
  static readonly EmployeeLocationsPagingPath = '/api/v1/locations/paging/{companyLocationId}/employeeLocations';

  /**
   * get estimated employee address data in pages.
   *
   * List detailed information of all employees in the company. Response also includes the employee's mode (if mobility score calculation was already done beforehand)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeLocationsPaging()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeLocationsPaging$Response(params: {

    /**
     * page number
     */
    page?: number;

    /**
     * page size
     */
    size?: number;

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Employee>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.EmployeeLocationsPagingPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Employee>>;
      })
    );
  }

  /**
   * get estimated employee address data in pages.
   *
   * List detailed information of all employees in the company. Response also includes the employee's mode (if mobility score calculation was already done beforehand)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `employeeLocationsPaging$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeLocationsPaging(params: {

    /**
     * page number
     */
    page?: number;

    /**
     * page size
     */
    size?: number;

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<Employee>> {

    return this.employeeLocationsPaging$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Employee>>) => r.body as Array<Employee>)
    );
  }

  /**
   * Path part for operation deleteEmployeesByEntranceNumber
   */
  static readonly DeleteEmployeesByEntranceNumberPath = '/api/v1/locations/{companyLocationId}/employeesByEntranceNumber/{entranceNumber}';

  /**
   * Delete all employees with a given entrance number.
   *
   * Delete all employees with a given entrance number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEmployeesByEntranceNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEmployeesByEntranceNumber$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * entrance number
     */
    entranceNumber: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeesService.DeleteEmployeesByEntranceNumberPath, 'delete');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('entranceNumber', params.entranceNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete all employees with a given entrance number.
   *
   * Delete all employees with a given entrance number
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteEmployeesByEntranceNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEmployeesByEntranceNumber(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * entrance number
     */
    entranceNumber: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteEmployeesByEntranceNumber$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
