import { Component, DestroyRef, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { FeatureFlag } from '@shared/utils';
import { CalculationProperties, MobilityPropertiesService } from '@upscore-mobility-audit/api';
import { UpscoreMobilityAuditUserDataService } from '@upscore-mobility-audit/shared/api-services/user-data.service';
import { handleChanges } from '@upscore-mobility-audit/shared/functions/has-changes';
import { UserPropertiesDataService } from '@upscore-mobility-audit/shared/services/user-properties.service';

@Component({
    selector: 'user-settings-dialog',
    templateUrl: './user-settings-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./user-settings-dialog.component.scss'],
})
export class UserSettingsDialogComponent implements OnInit {
    public createNewProperties = true;
    public userPropertiesForm: UntypedFormGroup;
    public originalFormData: CalculationProperties;
    public enableForm: boolean;
    public enableModeEstimationConfig = false;

    constructor(
        private readonly dialogRef: MatDialogRef<UserSettingsDialogComponent>,
        private readonly mobilityPropertiesService: MobilityPropertiesService,
        private readonly upscoreMobilityAuditUserDataService: UpscoreMobilityAuditUserDataService,
        private readonly userPropertiesDataService: UserPropertiesDataService,
        private readonly formBuilder: UntypedFormBuilder,
        public readonly destroyRef: DestroyRef,
    ) {}

    public ngOnInit(): void {
        this.userPropertiesForm = this.formBuilder.group({
            maxBikingTime: [null],
            maxWalkingTime: [null],
            maxCarTime: [null],
            maxTransitTime: [null],
            smallVehicleEmissions: [null],
            regularVehicleEmissions: [null],
            largeVehicleEmissions: [null],
            electricCarEmissions: [null],
            generalCarEmissions: [null],
            transitEmissions: [null],
            bikeCostPerKm: [null],
            carCostPerKm: [null],
            walkingCostPerKm: [null],
            transitCostPerKm: [null],
            minimumCarpoolTime: [null],
            maxPeopleInCarpool: [null],
            presenceDaysPerYear: [null],
            optimalModeCarpoolingLikelihood: null,
            improvementOnlyOptimum: [null],
            maxPresenceDaysPerWeek: [
                null,
                Validators.compose([Validators.min(0), Validators.max(5)]),
            ],
            entryTime: [null],
            exitTime: [null],
            enabledAgencies: [null],
            modeEstimationConfig: this.formBuilder.nonNullable.group({
                modeAssignment: this.formBuilder.nonNullable.control<any>(null),
            }),
        });

        this.userPropertiesForm.addControl(
            'optimalModeEstimationParams',
            (this.userPropertiesForm.controls['modeEstimationConfig'] as FormGroup).controls[
                'modeAssignment'
            ],
        );

        // userProperties are loaded in resolver and are always available in user space
        const userProperties = this.userPropertiesDataService.userProperties$.value;

        if (userProperties) {
            const optimalModeEstimationParams = userProperties.optimalModeEstimationParams;
            this.originalFormData = userProperties;
            this.createNewProperties = false;
            this.enableModeEstimationConfig = optimalModeEstimationParams != null;
            this.userPropertiesForm.patchValue({
                ...userProperties,
                modeEstimationConfig: { modeAssignment: optimalModeEstimationParams },
            });
        }

        this.enableForm = this.upscoreMobilityAuditUserDataService.showFeature(
            FeatureFlag.CALCULATION_PROPERTIES,
        );

        handleChanges(this.dialogRef, [this.userPropertiesForm]);
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onUpdateSettings(): void {
        let mobilityPropertiesRequest;
        if (this.createNewProperties) {
            mobilityPropertiesRequest = this.mobilityPropertiesService.setUserMobilityProperties(
                this.userPropertiesForm.value,
            );
        } else {
            mobilityPropertiesRequest = this.mobilityPropertiesService.updateUserMobilityProperties(
                this.userPropertiesForm.value,
            );
        }

        mobilityPropertiesRequest.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.userPropertiesDataService.userProperties$.next(this.userPropertiesForm.value);
            this.onCancel();
        });
    }

    public onResetForm(): void {
        if (this.originalFormData) {
            this.userPropertiesForm.setValue(this.originalFormData);
        } else {
            this.userPropertiesForm.reset();
        }
    }
}
