<div class="modal-split-circle">
    <div class="modal-split-circle__employee-count">
        @if (employeeCount) {
            {{ employeeCount }} {{ ' ' }}<span i18n="@@employeeCount">Employees</span>
        } @else {
            <span i18n="@@employeesMissing">Employees missing</span>
        }
    </div>

    @if (!employeeCount || !mobilityStats) {
        <div class="info-wrapper">
            <div class="overlay-text">
                <mat-icon class="material-icons-outlined info-icon">info</mat-icon>
                @if (!employeeCount && !mobilityStats) {
                    <p class="info-message" i18n="@@employeesAndMobilityPropertiesMissing">
                        Employees and mobility properties are missing
                    </p>
                }
                @if (employeeCount && !mobilityStats) {
                    <p class="info-message" i18n="@@mobilityPropertiesMissing">
                        Mobility properties are missing
                    </p>
                }
            </div>
        </div>
    }
    <!-- Chart -->
    <div class="modal-split-circle__canvas-wrapper export-canvas-processing">
        <canvas #canvas></canvas>
        @if (score) {
            <div class="modal-split-circle__score">
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-dark kpi-measure__measure--text-lg'
                    "
                    [showChangePrefix]="false"
                    [unit]="numberUnit.PERCENTAGE"
                    [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-lg'"
                    [value]="score"></kpi-measure>
                <p class="modal-split-circle__score-label">
                    {{ score === 100 ? 'Optimum' : 'Mobility Score' }}
                </p>
            </div>
        }
    </div>
    <!-- Content -->
    <div class="content-wrapper">
        <!-- Walk -->
        <div class="element-wrapper">
            <div class="text-wrapper">
                <span class="dot walk-color"></span>
                <span class="element-text" i18n="@@walk">Walk</span>
            </div>

            <div class="number-wrapper">
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-dark kpi-measure__measure--text-xs'
                    "
                    [showChangePrefix]="false"
                    [unit]="numberUnit.PERCENTAGE"
                    [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-sm'"
                    [value]="(mobilityStats?.walk ?? 0) * 100"></kpi-measure>
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                    "
                    [unit]="unitMeasureText"
                    [valueCssClass]="
                        'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                    "
                    [value]="(mobilityStats?.walk ?? 0) * (employeeCount ?? 0)"></kpi-measure>
            </div>
        </div>
        <!-- Bike -->
        <div class="element-wrapper">
            <div class="text-wrapper">
                <span class="dot bike-color"></span>
                <span class="element-text" i18n="@@biking">Biking</span>
            </div>
            <div class="number-wrapper">
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-dark kpi-measure__measure--text-xs'
                    "
                    [showChangePrefix]="false"
                    [unit]="numberUnit.PERCENTAGE"
                    [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-sm'"
                    [value]="(mobilityStats?.bike ?? 0) * 100"></kpi-measure>
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                    "
                    [unit]="unitMeasureText"
                    [valueCssClass]="
                        'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                    "
                    [value]="(mobilityStats?.bike ?? 0) * (employeeCount ?? 0)"></kpi-measure>
            </div>
        </div>
        <!-- Transit -->
        <div class="element-wrapper">
            <div class="text-wrapper">
                <span class="dot transit-color"></span>
                <span class="element-text" i18n="@@publicTransport">Public Transport</span>
            </div>

            <div class="number-wrapper">
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-dark kpi-measure__measure--text-xs'
                    "
                    [showChangePrefix]="false"
                    [unit]="numberUnit.PERCENTAGE"
                    [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-sm'"
                    [value]="(mobilityStats?.transit ?? 0) * 100"></kpi-measure>
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                    "
                    [unit]="unitMeasureText"
                    [valueCssClass]="
                        'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                    "
                    [value]="(mobilityStats?.transit ?? 0) * (employeeCount ?? 0)"></kpi-measure>
            </div>
        </div>
        <!-- Driver -->
        <div class="element-wrapper">
            <div class="text-wrapper">
                <span class="dot driver-color"></span>
                <span class="element-text" i18n="@@carDriver">Car Driver</span>
            </div>

            <div class="number-wrapper">
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-dark kpi-measure__measure--text-xs'
                    "
                    [showChangePrefix]="false"
                    [unit]="numberUnit.PERCENTAGE"
                    [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-sm'"
                    [value]="(mobilityStats?.carDriver ?? 0) * 100"></kpi-measure>
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                    "
                    [unit]="unitMeasureText"
                    [valueCssClass]="
                        'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                    "
                    [value]="(mobilityStats?.carDriver ?? 0) * (employeeCount ?? 0)"></kpi-measure>
            </div>
        </div>
        <!-- Passenger -->
        <div class="element-wrapper">
            <div class="text-wrapper">
                <span class="dot passenger-color"></span>
                <span class="element-text" i18n="@@carPassenger">Car Passenger</span>
            </div>

            <div class="number-wrapper">
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-dark kpi-measure__measure--text-xs'
                    "
                    [showChangePrefix]="false"
                    [unit]="numberUnit.PERCENTAGE"
                    [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-sm'"
                    [value]="(mobilityStats?.carPassenger ?? 0) * 100"></kpi-measure>
                <kpi-measure
                    [digitsInfo]="'1.0-0'"
                    [measureCssClass]="
                        'kpi-measure__measure--color-grey kpi-measure__measure--text-xs'
                    "
                    [unit]="unitMeasureText"
                    [valueCssClass]="
                        'kpi-measure__value--color-grey kpi-measure__value--text-xs kpi-measure__value--font-regular'
                    "
                    [value]="
                        (mobilityStats?.carPassenger ?? 0) * (employeeCount ?? 0)
                    "></kpi-measure>
            </div>
        </div>
    </div>
</div>
