<div class="relative">
    <button
        [matMenuTriggerFor]="menu"
        aria-label="Notifications"
        class="notification-button"
        mat-icon-button>
        <mat-icon>circle_notifications</mat-icon>
    </button>
    @if (tasks > 0) {
        <div
            class="absolute flex items-center justify-center text-white bg-callout-warning-icon w-l h-l rounded-[10px] top-[2px] right-[3px] tps-text-small"
            style="box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25)">
            {{ tasks }}
        </div>
    }
</div>

<mat-menu class="notification-list-menu" #menu="matMenu">
    <upscore-notification-list
        (click)="$event.stopPropagation()"
        [tasks]="taskNotificationService.userTasks()"></upscore-notification-list>
</mat-menu>
