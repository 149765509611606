import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MobilityIntervention } from '@upscore-mobility-audit/api';
import { KpiMeasureComponent } from '@upscore-mobility-audit/shared-ui/components/kpi-measure/kpi-measure.component';

import { MeasureColorPipe } from '../../pipes/measure-color.pipe';
import { MeasureNamePipe } from '../../pipes/measure-name.pipe';
import { MeasureTypePipe } from '../../pipes/measure-type.pipe';
import { MeasureIconComponent } from '../measure-icon/measure-icon.component';

@Component({
    selector: 'measure-impact-header',
    standalone: true,
    imports: [
        CommonModule,
        MeasureColorPipe,
        MeasureIconComponent,
        MeasureNamePipe,
        MeasureTypePipe,
        KpiMeasureComponent,
    ],
    templateUrl: './measure-impact-header.component.html',
    styleUrl: './measure-impact-header.component.scss',
})
export class MeasureImpactHeaderComponent {
    /**
     * If the `view affected employees` button should be shown
     */
    @Input() public includeCta = true;
    @Input({ required: true }) public measure: MobilityIntervention;
    @Input() public impactedEmployees?: number;
    @Input() public savedEmissions?: number;
    @Input() public savedMode: 'percentage' | 'absolute' = 'percentage';
    /**
     * Size of the card to display (regular for what's shown inside the Kpi view, compact for the measure edit screen)
     * -> Mainly changes required padding on the side
     */
    @Input() public size: 'regular' | 'compact' = 'regular';
    @Output() public ctaClick = new EventEmitter<void>();
}
