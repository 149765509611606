<kpi-card
    [showMenu]="hasMenu"
    [showUnavailable]="showUnavailable"
    [title]="'Mobility Score'"
    [moreInfoText]="translations.mobilityScoreDescription">
    <!-- header -->
    @if (improvedMobilityScore) {
        <div header>
            <increase-header
                [currValue]="currValue"
                [digitsInfo]="'1.0-0'"
                [isPercentageChange]="true"
                [baselineValue]="baselineValue"
                [showDailyComparisonValue]="false"
                [showPercentageChangePrefix]="false"
                [unit]="unit"></increase-header>
        </div>
    }
    <!-- content -->
    <div body class="body-wrapper">
        <div class="canvas-wrapper export-canvas-processing">
            <!-- BUGNOTE: due to the fact that the UI Draft was not possible with one chart, we use 3 charts above each other to illustrate the wanted UI -->
            <canvas
                #optimumChartCanvas
                [attr.data-html2canvas-ignore]="optimumChart == null ? '' : null"></canvas>
            <canvas
                #improvedScoreChartCanvas
                [attr.data-html2canvas-ignore]="improvedScoreChart == null ? '' : null"></canvas>
            <canvas
                #originalScoreChartCanvas
                [attr.data-html2canvas-ignore]="originalScoreChart == null ? '' : null"></canvas>
            <div class="score-text-wrapper">
                <span class="score-text">{{ currValue | number: '1.0-0' }}</span>
                <span class="percentage-text">%</span>
            </div>
        </div>
        <div class="legend-wrapper">
            <div class="text-wrapper">
                <span class="dot ultralight-primary-color"></span>
                <span class="element-text" i18n="@@optimalEstimate"
                    >Reachable optimum (<kpi-measure
                        [digitsInfo]="'1.0-0'"
                        [measureCssClass]="
                            'kpi-measure__measure--color-dark kpi-measure__measure--text-xs'
                        "
                        [showChangePrefix]="false"
                        [unit]="unit"
                        [valueCssClass]="
                            'kpi-measure__value--color-dark kpi-measure__value--text-sm kpi-measure__value--font-regular'
                        "
                        [value]="100"></kpi-measure>
                    )
                </span>
            </div>
            <div class="text-wrapper">
                <span class="primary-color dot"></span>
                <span class="element-text" i18n="@@statusQuoScoreText"
                    >Status Quo (<kpi-measure
                        [digitsInfo]="'1.0-0'"
                        [measureCssClass]="
                            'kpi-measure__measure--color-dark kpi-measure__measure--text-xs'
                        "
                        [showChangePrefix]="false"
                        [unit]="unit"
                        [valueCssClass]="
                            'kpi-measure__value--color-dark kpi-measure__value--text-sm kpi-measure__value--font-regular'
                        "
                        [value]="
                            improvedMobilityScore == null ? currValue : baselineValue
                        "></kpi-measure>
                    )
                </span>
            </div>
            @if (improvedMobilityScore; as iMS) {
                <div class="text-wrapper">
                    <span class="dot medium-primary-color"></span>
                    <span class="element-text" i18n="@@statusQuoScoreTextIncludingMeasures"
                        >Status Quo including measures (<kpi-measure
                            [digitsInfo]="'1.0-0'"
                            [measureCssClass]="
                                'kpi-measure__measure--color-dark kpi-measure__measure--text-xs'
                            "
                            [showChangePrefix]="false"
                            [unit]="unit"
                            [valueCssClass]="
                                'kpi-measure__value--color-dark kpi-measure__value--text-sm kpi-measure__value--font-regular'
                            "
                            [value]="currValue"></kpi-measure>
                        )</span
                    >
                </div>
            }
        </div>
    </div>
</kpi-card>
