import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { NumberUnit } from '@shared/utils';
import { KpiMeasureComponent } from '@upscore-mobility-audit/shared-ui/components/kpi-measure/kpi-measure.component';

@Component({
    selector: 'kpi-percentage-change',
    standalone: true,
    imports: [CommonModule, MatIcon, KpiMeasureComponent],
    templateUrl: './percentage-change.component.html',
    styleUrl: './percentage-change.component.scss',
})
export class PercentageChangeComponent implements OnChanges {
    @Input() current: number;
    @Input() baseline: number;
    @Input() unit: NumberUnit | string;
    @Input() yearly: boolean;

    @Input() invertColorScheme = false;

    public difference: number;
    public textColorClass: string;
    public iconColorClass: string;

    public ngOnChanges() {
        if (this.baseline && this.current) {
            this.difference = this.current - this.baseline;
            if (
                (!this.invertColorScheme && this.difference > 0) ||
                (this.invertColorScheme && this.difference < 0)
            ) {
                this.textColorClass = '!text-action-danger';
                this.iconColorClass = '!bg-action-danger';
            } else if (
                (!this.invertColorScheme && this.difference < 0) ||
                (this.invertColorScheme && this.difference > 0)
            ) {
                this.textColorClass = '!text-action-success';
                this.iconColorClass = '!bg-action-success';
            } else {
                this.iconColorClass = '!bg-black';
                this.textColorClass = '!text-black';
            }
        } else {
            this.difference = 0;
        }
    }
}
