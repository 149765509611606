export enum CriteriaTypeEnum {
    'Always' = 'Always',
    'Never' = 'Never',
    'CutoffCriteria' = 'CutoffCriteria',
    'AbsoluteDiffCutoffCriteria' = 'AbsoluteDiffCutoffCriteria',
    'RelativeDiffCutoffCriteria' = 'RelativeDiffCutoffCriteria',
    'AndCriteria' = 'AndCriteria',
    'OrCriteria' = 'OrCriteria',
    'NotCriteria' = 'NotCriteria',
    'NumberOfChangesCriteria' = 'NumberOfChangesCriteria',
}
