import { CdkStep } from '@angular/cdk/stepper';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'upscore-step',
    templateUrl: './upscore-step.component.html',
    styleUrls: ['./upscore-step.component.scss'],
    providers: [{ provide: CdkStep, useExisting: UpscoreStepComponent }],
})
export class UpscoreStepComponent extends CdkStep {
    public static _defaultNextButtonString: string = $localize`:@@proceed:Next`;
    public static _skipButtonString: string = $localize`:@@skip:Skip`;
    public static _defaultSaveAndNextButtonString: string = $localize`:@@saveAndProceed:Save And Next`;

    // runs after finishing a step checks return value -> true continue else stay
    @Input() public postCall: () => Observable<boolean>;

    // runs before displaying this step
    @Input() public preCall: () => Observable<unknown>;
    @Input() public nextButton: string = UpscoreStepComponent._defaultNextButtonString;
    @Input() public saveAndNextButton: string =
        UpscoreStepComponent._defaultSaveAndNextButtonString;
    @Input() public mandatory = true;
}
