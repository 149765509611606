<ng-content select="[body]"></ng-content>
<div class="controls">
    <div>
        <button
            (click)="moveCard(moveDirection.LEFT)"
            [disabled]="disabled(moveDirection.LEFT)"
            [matTooltip]="translations.moveToPrevPage"
            mat-icon-button>
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button
            (click)="moveCard(moveDirection.UP)"
            [disabled]="disabled(moveDirection.UP)"
            [matTooltip]="translations.moveUp"
            mat-icon-button>
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        <button
            (click)="moveCard(moveDirection.DOWN)"
            [disabled]="disabled(moveDirection.DOWN)"
            [matTooltip]="translations.moveDown"
            mat-icon-button>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <button
            (click)="moveCard(moveDirection.RIGHT)"
            [disabled]="disabled(moveDirection.RIGHT)"
            [matTooltip]="translations.moveToNextPage"
            mat-icon-button>
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
</div>
@if (deleteButton) {
    <button (click)="delete.emit()" class="delete-button" data-html2canvas-ignore mat-icon-button>
        <mat-icon>delete</mat-icon>
    </button>
}
