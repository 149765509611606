export class KpiGridServiceTranslations {
    public static readonly distributionTableWalkTitle: string = $localize`:Walk@@walk:Walk`;
    public static readonly distributionTableBikeTitle: string = $localize`:@@bike:Bike`;
    public static readonly distributionTablePTTitle: string = $localize`:Public Transport@@publicTransport:Public Transport`;
    public static readonly distributionTableCarDriverTitle: string = $localize`:@@carDriver:Car Driver`;
    public static readonly distributionTableCarPassengerTitle: string = $localize`:@@carPassenger:Car Passenger`;

    public static readonly averageEmployeeTopicEntryTitleCO2Emissions: string = $localize`:@@co2Emission:CO2 Emissions`;
    public static readonly averageEmployeeTopicEntryTitleEmployeeCosts: string = $localize`:@@employeeCosts:Employee Costs`;
    public static readonly averageEmployeeTopicEntryTitleBusinessCosts: string = $localize`:@@businessCosts:Employer Costs`;
    public static readonly averageEmployeeTopicEntryTitleTravelTime: string = $localize`:@@travelTime:Travel Time`;
    public static readonly averageEmployeeTopicEntryTitleTravelDistance: string = $localize`:@@travelDistance:Travel Distance`;

    public static readonly carpoolingTopicEntryTitleCarpoolCount: string = $localize`:@@noOfCarpools:No. of Carpools`;
    public static readonly carpoolingTopicEntryTitleEmissionsSaved: string = $localize`:@@emissionSavings:CO2 emissions saved`;
    public static readonly carpoolingTopicEntryTitleTimeSaved: string = $localize`:@@timeSaved:Time Saved`;
    public static readonly carpoolingTopicEntryTitleDistanceSaved: string = $localize`:@@distanceSaved:Distance Saved`;
    public static readonly carpoolingTopicEntryTitleCostsSaved: string = $localize`:@@costsSaved:Costs Saved`;

    public static readonly modalSplitTopicEntryTitleWalk: string = $localize`:Walk@@walk:Walk`;
    public static readonly modalSplitTopicEntryTitleBike: string = $localize`:@@bike:Bike`;
    public static readonly modalSplitTopicEntryTitlePT: string = $localize`:Public Transport@@publicTransport:Public Transport`;
    public static readonly modalSplitTopicEntryTitleCarDriver: string = $localize`:@@carDriver:Car Driver`;
    public static readonly modalSplitTopicEntryTitleCarPassenger: string = $localize`:@@carPassenger:Car Passenger`;
}
