<div class="flex flex-row gap-s">
    <div class="flex flex-col items-end">
        <kpi-measure
            [digitsInfo]="'1.0-1'"
            [measureCssClass]="'kpi-measure__value--text-lg ' + textColorClass"
            [unit]="'PERCENTAGE'"
            [valueCssClass]="'kpi-measure__value--text-lg ' + textColorClass"
            [showChangePrefix]="true"
            [value]="(difference / baseline) * 100"></kpi-measure>
        <div class="-mt-s">
            <kpi-measure
                [digitsInfo]="'1.0-1'"
                [measureCssClass]="'tps-body-3-bold ' + textColorClass"
                [unit]="unit"
                [showChangePrefix]="true"
                [value]="difference"
                [valueCssClass]="'tps-body-3-bold ' + textColorClass"></kpi-measure>
            <span class="tps-body-3" i18n="@@perYearDay">
                per
                @if (yearly) {
                    year
                } @else {
                    day
                }
            </span>
        </div>
    </div>
    @if (difference !== 0) {
        <div class="flex flex-col justify-center w-[24px]">
            <div
                class="flex justify-center items-center h-[24px] rounded-full mb-[8px] {{
                    iconColorClass
                }}">
                <mat-icon class="text-white" style="transform: scale(0.7)">
                    @if (difference < 0) {
                        arrow_downward
                    } @else if (difference > 0) {
                        arrow_upward
                    }
                </mat-icon>
            </div>
        </div>
    }
</div>
