import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ZipCodeUtilsService {
    /**
     * currently only converts a zip code from GB-XXXX XYZ to GB-XXXX
     * should be used pre clustering
     * @param zipCode
     */
    public static stripPreClustering(zipCode: string): string {
        if (zipCode.startsWith('GB-')) {
            return zipCode.split(' ')[0];
        }

        return zipCode;
    }

    public static stripCountryPrefix(zipCode: string): string {
        // check if third character is a hyphen
        if (zipCode.charAt(2) === '-') {
            return zipCode.slice(3);
        }

        return zipCode;
    }
}
