import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResetService {
    /**
     * Free of charge, public service announcement:
     * UnsafeCompanyLocationDataService currently resets the location and then triggers companyChanged$.
     * In MobilityScoreDataService the scores then gets resets and scoreDeleted$ is triggered.
     */

    /**
     * Is triggered when user leaves the audit.
     * All requests in services should be cancelled.
     * Caches should be cleared.
     */
    public auditClosed$ = new Subject<void>();

    /**
     * is triggered when a score gets deleted by a user action
     */
    public scoreDeleted$ = new Subject<number>();

    /**
     * is triggered when a score gets saved
     */
    public scoreSaved$ = new Subject<number>();

    /**
     * is triggered when a different company is selected
     */
    public companyChanged$ = new Subject<number | null>();
}
