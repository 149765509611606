import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TopicTableComponentEntry } from '../../interfaces/topic-table-component-entry.interface';
import { BaseKpiCardComponent } from '../../models/base-kpi-card.model';

@Component({
    selector: 'topic-table-card',
    templateUrl: './topic-table-card.component.html',
    styleUrls: ['./topic-table-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopicTableCardComponent extends BaseKpiCardComponent implements OnChanges {
    @Input() public title: string;

    @Input() public entries: TopicTableComponentEntry[];

    @Input() public showUnavailable = false;

    @Input() public useDailyPipe = false;

    @Input() public hasMenu = true;

    /**
     * Angular lifecycle hook
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['entries'] && !changes['entries'].isFirstChange()) {
            this.changes.next();
        }
    }
}
