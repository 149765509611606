export const defaultIndustries: string[] = [
    $localize`:@@industriesArtsEntertainment:Arts and Entertainment`, // Kunst und Unterhaltung
    $localize`:@@industriesAutomotive:Automotive`, // 'Automotive'
    $localize`:@@industriesBeautyFitness:Beauty and Fitness`, // 'Schönheit und Fitness'
    $localize`:@@industriesBooksLiterature:Books and Literature`, // 'Bücher und Literatur'
    $localize`:@@industriesBusinessIndustrialMarkets:Business and Industrial Markets`, // 'Geschäfts- und Industriemärkte'
    $localize`:@@industriesComputersElectronics:Computers and Electronics`, // 'Computer und Elektronik'
    $localize`:@@industriesFinance:Finance`, // 'Finanzen'
    $localize`:@@industriesFoodDrink:Food and Drink`, // 'Essen und Trinken'
    $localize`:@@industriesGames:Games`, // 'Spiele'
    $localize`:@@industriesHealthcare:Healthcare`, // 'Gesundheitsfürsorge'
    $localize`:@@industriesHobbyLeisure:Hobby and Leisure`, // 'Hobby und Freizeit'
    $localize`:@@industriesHomeGarden:Home and Garden`, // 'Haus und Garten'
    $localize`:@@industriesInternetTelecommunications:Internet and Telecommunications`, // 'Internet und Telekommunikation'
    $localize`:@@industriesJobsEducation:Jobs and Education`, // 'Jobs und Ausbildung'
    $localize`:@@industriesLawGovernment:Law and Government`, // 'Recht und Regierung'
    $localize`:@@industriesNews:News`, // 'Nachrichten'
    $localize`:@@industriesOnlineCommunities:Online Communities`, // 'Online-Gemeinschaften'
    $localize`:@@industriesPeopleSociety:People and Society`, // 'Mensch und Gesellschaft'
    $localize`:@@industriesPetsAnimals:Pets and Animals`, // 'Haustiere und Tiere'
    $localize`:@@industriesRealEstate:Real Estate`, // 'Immobilien'
    $localize`:@@industriesReference:Reference`, // 'Referenz'
    $localize`:@@industriesScience:Science`, // 'Wissenschaft'
    $localize`:@@industriesShopping:Shopping`, // 'Einkaufen'
    $localize`:@@industriesSports:Sports`, // 'Sportarten'
    $localize`:@@industriesTravel:Travel`, // 'Reisen'
    $localize`:@@industriesOther:Other`, // 'Andere'
];
