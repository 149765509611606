import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import {
    CalculationProperties,
    CompanyLocation,
    Employee,
    PackageDefinition,
} from '@upscore-mobility-audit/api';
import { ExportDialogComponent } from '@upscore-mobility-audit/core/dialogs/export-dialog/export-dialog.component';
import { Category } from '@upscore-mobility-audit/data-collection/components/parameters/measure-category-parameter/measure-category-parameter.component';
import { ViewEditEmployeesComponent } from '@upscore-mobility-audit/data-collection/components/view-edit-employees/view-edit-employees.component';
import { InitialDataInputDialogComponent } from '@upscore-mobility-audit/data-collection/dialogs/initial-data-input-dialog/initial-data-input-dialog.component';
import { MobilityScenarioBuilderComponent } from '@upscore-mobility-audit/data-collection/dialogs/mobility-scenario-builder/mobility-scenario-builder.component';
import { ShareMobilityAuditDialogComponent } from '@upscore-mobility-audit/data-collection/dialogs/share-mobility-audit-dialog/share-mobility-audit-dialog.component';
import { UserSettingsDialogComponent } from '@upscore-mobility-audit/data-collection/dialogs/user-settings-dialog/user-settings-dialog.component';
import { DialogId } from '@upscore-mobility-audit/data-collection/enums/dialog-id.enum';
import { InitialDataInputDialogCloseType } from '@upscore-mobility-audit/data-collection/interfaces/initial-data-input-dialog-close-type.interface';
import { ErrorCancelContinueDialogComponent } from '@upscore-mobility-audit/shared/dialogs/error-cancel-continue-dialog/error-cancel-continue-dialog.component';
import { DialogSteps } from '@upscore-mobility-audit/shared/enums/dialog-steps.enum';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private readonly dialog: MatDialog) {}

    /**
     * Method to open the initial data input dialog
     * @param companyLocation
     * @param disableClose
     * @param index
     */
    public openInitialDataInputDialog(
        companyLocation?: CompanyLocation,
        disableClose = false,
        index?: DialogSteps,
    ): Observable<InitialDataInputDialogCloseType | undefined> {
        const dialogRef: MatDialogRef<
            InitialDataInputDialogComponent,
            InitialDataInputDialogCloseType
        > = this.dialog.open(InitialDataInputDialogComponent, {
            id: DialogId.INITIAL_DATA_INPUT,
            panelClass: 'upscore-stepper-dialog-container',
            disableClose,
            data: { companyLocation, index },
        });

        return dialogRef.afterClosed();
    }

    /**
     * open view/edit employee dialog
     */
    public openViewEditGeoCodedEmployeeDialog(
        companyLocation: CompanyLocation,
        employees: Employee[],
        companyDepartments: string[],
        searchEmployeeInfo?: number,
        userProperties?: CalculationProperties,
        companyProperties?: CalculationProperties,
        displayLimitedEmployeesInfo = false,
    ): Observable<{ hasChanges: boolean }> {
        const dialogRef: MatDialogRef<ViewEditEmployeesComponent> = this.dialog.open(
            ViewEditEmployeesComponent,
            {
                panelClass: 'view-edit-employees-container',
                data: {
                    companyLocation,
                    employees,
                    companyDepartments,
                    searchEmployeeInfo,
                    userProperties,
                    companyProperties,
                    displayLimitedEmployeesInfo,
                },
                disableClose: true,
            },
        );

        return dialogRef.afterClosed();
    }

    public openUserSettingsDialog(): Observable<void> {
        const dialogRef: MatDialogRef<UserSettingsDialogComponent> = this.dialog.open(
            UserSettingsDialogComponent,
            {
                panelClass: 'user-settings-dialog-container',
            },
        );

        return dialogRef.afterClosed();
    }

    public openShareMobilityAudit(companyLocation?: CompanyLocation): Observable<void> {
        const dialogRef: MatDialogRef<ShareMobilityAuditDialogComponent> = this.dialog.open(
            ShareMobilityAuditDialogComponent,
            {
                panelClass: 'share-mobility-audit-dialog-container',
                data: { companyLocation },
            },
        );

        return dialogRef.afterClosed();
    }

    public openMobilityScenarioWizard(
        companyLocation: CompanyLocation,
        employeeCategories: Category[],
        packageDefinition?: PackageDefinition,
        duplicate = false,
        baseScenario?: number,
    ): Observable<void> {
        const dialogRef: MatDialogRef<MobilityScenarioBuilderComponent> = this.dialog.open(
            MobilityScenarioBuilderComponent,
            {
                panelClass: 'measure-creation-dialog-container',
                data: {
                    packageToEdit: packageDefinition,
                    baseScenario: baseScenario,
                    employeeCategories: employeeCategories,
                    duplicate,
                    companyLocation: companyLocation,
                },
            },
        );

        return dialogRef.afterClosed();
    }

    public openExportDialog(): Observable<void> {
        const dialogRef: MatDialogRef<ExportDialogComponent> = this.dialog.open(
            ExportDialogComponent,
            {
                panelClass: 'export-dialog-container',
            },
        );

        return dialogRef.afterClosed();
    }

    public openErrorCancelContinueDialog(errorText?: string): Observable<boolean | undefined> {
        const dialogRef: MatDialogRef<ErrorCancelContinueDialogComponent, boolean> =
            this.dialog.open(ErrorCancelContinueDialogComponent, {
                panelClass: 'error-cancel-continue-dialog-container',
                data: { errorText },
            });

        return dialogRef.afterClosed();
    }
}
