import { Injectable } from '@angular/core';
import equal from 'fast-deep-equal/es6';
import { Observable, interval, mergeMap, switchMap } from 'rxjs';
import { filter, map, share, takeWhile } from 'rxjs/operators';

import { TaskProgress, TasksService } from '@upscore-mobility-audit/api';
import { UpscoreMobilityAuditUserDataService } from '@upscore-mobility-audit/shared/api-services/user-data.service';

import { ProgressService, TaskIdAndProgress } from '../interfaces';

import { TaskUtilsService } from './task-utils.service';

@Injectable({
    providedIn: 'root',
})
export class PollingProgressService extends ProgressService {
    private oldTasks: { [taskId: string]: TaskIdAndProgress } = {};

    /**
     * Observable that polls the server for the user's tasks every 5 seconds
     * and emits the tasks that have been updated since the last poll
     * @private
     */
    private refreshUserTasks = interval(5000).pipe(
        switchMap(() => this.taskService.getMyTasks()),
        map(taskInfos => {
            const currentTasks = taskInfos.map(taskInfo => {
                return {
                    id: taskInfo.id,
                    progress: taskInfo.progress,
                } as TaskIdAndProgress;
            });

            const newTaskDict: any = {};
            const updates: TaskIdAndProgress[] = [];

            currentTasks.forEach(task => {
                // deep equal comparison to check if something got updated
                if (
                    !this.oldTasks[task.id] ||
                    !equal(this.oldTasks[task.id].progress, task.progress)
                ) {
                    updates.push(task);
                }

                newTaskDict[task.id] = task;
            });

            Object.values(this.oldTasks).forEach(oldTask => {
                if (!newTaskDict[oldTask.id]) {
                    updates.push({
                        id: oldTask.id,
                        progress: {
                            event: '',
                            status: 'DELETED',
                            type: oldTask.progress?.type ?? '',
                        },
                    } as TaskIdAndProgress);
                }
            });

            // overwrite dict with new tasks
            this.oldTasks = newTaskDict;

            return updates;
        }),
        // splits list into individual tasks
        mergeMap(tasks => tasks),
        share(),
    );

    constructor(
        private userDataService: UpscoreMobilityAuditUserDataService,
        private taskService: TasksService,
        private taskUtilsService: TaskUtilsService,
    ) {
        super();

        this.userDataService._user$.subscribe(user => {
            if (user == null) {
                this.oldTasks = {};
            }
        });
    }

    subscribeToTasks(taskId: string): Observable<TaskProgress> {
        // method doesn't use the userId parameter
        return this.subscribeToAllUserTasks(0).pipe(
            filter(task => task.id === taskId),
            map(task => task.progress as TaskProgress),
            takeWhile(this.taskUtilsService.taskIsRunning, true),
        );
    }

    subscribeToAllUserTasks(userId: number): Observable<TaskIdAndProgress> {
        // instead of creating the observable here, we return the observable that was created in the constructor
        // this way we can share the same observable with multiple subscribers
        return this.refreshUserTasks;
    }
}
