export const suggestedMeasures: {
    [name: string]: { header: string; description: string; icon: string };
} = {
    FreeBikeImprovementSuggestion: {
        header: $localize`:@@freeBikeHeader:Job Bikes`,
        description: $localize`:@@freeBikeImprovementText:With these parameters you can configure who gets a bike.`,
        icon: 'pedal_bike',
    },
    HomeOfficeImprovementSuggestion: {
        header: $localize`:@@homeOfficeHeader:Home Office`,
        description: $localize`:@@homeOfficeImprovementText:With these parameters you can configure who switches to home office and for how long.`,
        icon: 'home',
    },
    JobTicketImprovementSuggestion: {
        header: $localize`:@@jobTicketHeader:Job Tickets`,
        description: $localize`:@@jobTicketImprovementText:With these parameters you can configure who gets a public transportation ticket.`,
        icon: 'train',
    },
    CustomImprovement: {
        header: $localize`:@@customMeasureHeader:Custom Measure`,
        description: $localize`:@@customMeasureDescription:With these parameters you can configure a custom measure.`,
        icon: 'tune',
    },
};
