<div
    class="data-html2canvas-ignore border-solid border-purple bg-beta border-[1px] rounded-md px-m py-xs uppercase text-white text-xs hover:cursor-pointer"
    [appPopover]="text"
    appPopoverLink="https://survey.triply.at/beta-feedback?source={{ source }}&username={{
        (user$ | async)?.email
    }}"
    appPopoverLinkText="Open Survey"
    i18n-appPopoverLinkText="@@openSurvey">
    Beta
</div>
