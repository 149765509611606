import { Pipe, PipeTransform } from '@angular/core';

import { AuditProgress, EmployeeUploadProgress, TaskProgress } from '@upscore-mobility-audit/api';

import { TaskUtilsService } from '../services';

@Pipe({
    name: 'taskProgressFooter',
    standalone: true,
})
export class TaskProgressFooterPipe implements PipeTransform {
    constructor(private taskUtilsService: TaskUtilsService) {}

    transform(value: TaskProgress): string {
        const result = this.taskUtilsService.getTaskNameAndEvent(value);
        if (result == null) {
            return $localize`:@@taskIsRunning:A task is currently running.`;
        }

        const [name, event] = result;

        switch (value.status) {
            case 'QUEUED':
                return $localize`:@@taskWaitingForCalc:Task is waiting for calculation to start...`;
            case 'STARTING':
                return $localize`:@@taskStarting:Task is starting...`;
            case 'IN_PROGRESS':
                switch (name) {
                    case 'AuditProgress': {
                        const progress = value as AuditProgress;
                        const context = progress.context;
                        let scenarioProgress = 0;
                        if (context?.currentScenario && context?.allScenarios) {
                            scenarioProgress = context.allScenarios.indexOf(
                                context.currentScenario,
                            );
                        }

                        switch (event) {
                            case 'PlannedScenarios':
                                return $localize`:@@plannedScenarios:Starting calculation of ${(progress as never as { totalScenarios: object[] }).totalScenarios.length} scenarios...`;
                            case 'ScenarioStarted':
                                return $localize`:@@scenariosStarted:${scenarioProgress}/${context?.allScenarios?.length} scenarios calculated`;
                            case 'ScenarioFinished':
                                return $localize`:@@scenarioFinished:${scenarioProgress + 1}/${context?.allScenarios?.length} scenarios calculated`;
                            case 'TripsProgress':
                                return $localize`:@@tripsProgress:${(progress as never as { currentAmount: object[] }).currentAmount}/${context?.tripsForScenario} trips calculated`;
                            case 'TripsFinished':
                                return $localize`:@@tripsFinished:Finished calculating trips for scenario ${scenarioProgress + 1}/${context?.allScenarios?.length}`;
                            case 'InterventionStarted':
                            case 'InterventionFinished':
                                return $localize`:@@calcInterventions:Calculating mobility interventions...`;
                            case 'CalculatingKpis':
                                return $localize`:@@calcKpis:Calculating KPIs...`;
                            default:
                                return $localize`:@@taskInProgressFooter:Task is in progress...`;
                        }
                    }
                    case 'EmployeeUploadProgress':
                        switch (event) {
                            case 'ProcessedEmployees': {
                                const progress = value as EmployeeUploadProgress & {
                                    currentEmployees: number;
                                    totalEmployees: number;
                                };

                                return $localize`:@@processedEmployees:${progress.currentEmployees}/${progress.totalEmployees} employees processed`;
                            }
                            default:
                                return $localize`:@@taskInProgressFooter:Task is in progress...`;
                        }
                    default:
                        return $localize`:@@taskInProgressFooter:Task is in progress...`;
                }
            case 'FINISHED':
                switch (name) {
                    case 'AuditProgress':
                        return $localize`:@@auditProgressFinished:View Audit Results`;
                    case 'EmployeeUploadProgress':
                        return $localize`:@@employeeUploadProgressFinished:View Employees on the Map.`;
                    default:
                        return $localize`:@@viewTaskResult:View Task Results`;
                }
            case 'FAILED':
                return $localize`:@@taskFailedFooter:Find out what went wrong.`;
            case 'CANCELLED':
                return $localize`:@@taskCancelledFooter:Find out more`;
            default:
                return $localize`:@@taskWaitingForCalc:Task is waiting for calculation to start...`;
        }
    }
}
