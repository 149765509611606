<div
    export
    #toExport="toExport"
    [exportTitle]="title"
    class="kpi-card"
    [style.width]="'var(--kpi-card-width) * ' + widthScale">
    @if (showMenu) {
        <export-menu [exportTriggerFor]="toExport"></export-menu>
    }
    <!-- header -->
    <div class="kpi-card__header-wrapper">
        <span class="kpi-card__header-title icon-center">
            <div class="flex items-center">
                {{ title }}

                @if (moreInfoText != null) {
                    <mat-icon
                        class="info-icon"
                        [appPopover]="moreInfoText"
                        [appPopoverLink]="moreInfoLink"
                        >info_outline</mat-icon
                    >
                }
            </div>

            @if (betaFeature) {
                <upscore-mobility-audit-beta-feature-info
                    class="beta"
                    [source]="betaFeatureType"></upscore-mobility-audit-beta-feature-info>
            }
        </span>
        <div class="kpi-card__header-kpis">
            <ng-content select="[header]"></ng-content>
        </div>
    </div>
    <!-- body -->
    <div class="kpi-card__content-wrapper">
        <ng-content select="[body]"></ng-content>
    </div>
    @if (showUnavailable) {
        <div class="info-wrapper">
            <div class="overlay-text">
                <mat-icon class="material-icons-outlined info-icon">info</mat-icon>
                <p class="info-message">{{ infoMessage }}</p>
            </div>
        </div>
    }
</div>
