export const defaultMapOptions: google.maps.MapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    mapId: '1c004476411d2b72',
    zoom: 10,
    zoomControl: true,
    zoomControlOptions: {
        position: 9,
    },
    fullscreenControl: true,
    fullscreenControlOptions: {
        position: 9,
    },
    center: {
        lat: 48.208176,
        lng: 16.373819,
    },
    scaleControl: true,
    clickableIcons: false,
};
