<div class="table-container">
    @if (searchable) {
        <mat-form-field appearance="outline" class="search-bar">
            <input (keyup)="applyFilter($event)" matInput placeholder="Search" />
        </mat-form-field>
    }
    <table
        [dataSource]="dataSource"
        class="mat-elevation-z1 table row-border hover table-bordered table-hover table-striped"
        mat-table
        matSort>
        @for (columnData of headerData; track columnData; let i = $index) {
            <ng-container [matColumnDef]="columnData.display">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ columnData.display }}</th>
                <td *matCellDef="let element" mat-cell>
                    @if (!element.isEdit || !columnData.isEditable) {
                        {{ element[columnData.column] }}
                    } @else {
                        <mat-form-field>
                            <input
                                [(ngModel)]="element[columnData.column]"
                                [type]="columnData.type == null ? 'text' : columnData.type"
                                matInput />
                        </mat-form-field>
                    }
                </td>
            </ng-container>
        }
        @if (editable) {
            <ng-container [matColumnDef]="'Edit'">
                <th *matHeaderCellDef mat-header-cell>Edit</th>
                <td *matCellDef="let element" mat-cell>
                    <button (click)="element.isEdit = !element.isEdit" mat-icon-button>
                        <!--- its reversed because elements dont have an isEdit attribute --->
                        @if (element.isEdit) {
                            <mat-icon>done</mat-icon>
                        } @else {
                            <mat-icon>edit</mat-icon>
                        }
                    </button>
                </td>
            </ng-container>
        }

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

        <!-- Row shown when there is no matching data. -->
        <tr *matNoDataRow class="mat-row">
            <td class="mat-cell" colspan="4">
                No data matching the filter "{{ dataSource.filter }}"
            </td>
        </tr>
    </table>
    <mat-paginator
        [pageSizeOptions]="pageSizes"
        [pageSize]="pageSize"
        showFirstLastButtons></mat-paginator>
</div>
