// export enum CompanyLocationCreationState {
//     'ESTIMATION_NEEDED' = 'ESTIMATION_NEEDED',
//     'ESTIMATION_NOT_NEEDED' = 'ESTIMATION_NOT_NEEDED',
//     'ESTIMATING_EMPLOYEES' = 'Mitarbeiter werden generiert',
//     'UPLOADING_EMPLOYEES' = 'Mitarbeiter werden hochgeladen',
//     'ESTIMATING_MODAL_SPLIT' = 'Modalsplit wird generiert',
//     'ESTIMATING_FINISHED' = 'Generieren abgeschlossen',
// }

/* Converting enums into class as $localize is not yet supported in enums by Angular.
We can go back to using enums once the support for $ localize in string based enums is added. */

export class CompanyLocationCreationState {
    public static readonly ESTIMATION_NEEDED: string = 'ESTIMATION_NEEDED';
    public static readonly ESTIMATION_NOT_NEEDED: string = 'ESTIMATION_NOT_NEEDED';
    public static readonly ESTIMATING_EMPLOYEES: string = $localize`:@@employeesGenerating:Generating Employees`;
    public static readonly UPLOADING_EMPLOYEES: string = $localize`:@@employeesUploading:Uploading Employees`;
    public static readonly ESTIMATING_MODAL_SPLIT: string = $localize`:@@modalSplitCurrentlyGenerating:Generating Modal Split`;
    public static readonly ESTIMATING_FINISHED: string = 'ESTIMATING_FINISHED';
}
