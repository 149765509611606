<button [matMenuTriggerFor]="menu" aria-label="Account" class="account-button" mat-icon-button>
    <mat-icon>account_circle</mat-icon>
</button>
<mat-menu #menu="matMenu" (closed)="confirmLogout = false">
    <div class="account-details" mat-menu-item>
        <div class="header">
            <h4 class="mr-xl">Account</h4>
            @switch (user.accountType) {
                @case ('PREMIUM') {
                    <span class="premium" i18n="@@premiumUser">Premium</span>
                }
                @case ('LIGHT') {
                    <span class="not-premium" i18n="@@lightUser">Light</span>
                }
                @case ('VIEW_ONLY') {
                    <span class="not-premium" i18n="@@viewOnlyUser">View Only</span>
                }
            }
        </div>
        <div class="user-details">
            <span>
                {{ user.name }}
            </span>
            <span>
                {{ user.email }}
            </span>
            @if (user.activeUntil) {
                <span i18n="@@activeUntil"> active until {{ user.activeUntil | date }} </span>
            }
        </div>
    </div>
    <mat-divider></mat-divider>
    @if (canShowSettings) {
        <button (click)="showSettings.emit()" aria-label="Settings" mat-menu-item>
            <mat-icon>settings</mat-icon>
            <span>User Settings</span>
        </button>
    }
    <button (click)="onReportError()" mat-menu-item>
        <mat-icon color="warn">warning</mat-icon>
        <span i18n="@@reportAnError">Report an Error</span>
    </button>
    <button (click)="onLogout($event)" class="warn" mat-menu-item type="button">
        <mat-icon class="material-icons-outlined">exit_to_app</mat-icon>
        @if (!confirmLogout) {
            <ng-container i18n="@@logout">Logout</ng-container>
        } @else {
            <ng-container i18n="Confirm Logout@@confirmLogout">Confirm Logout? </ng-container>
        }
    </button>
</mat-menu>
