import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CompanyLocation, CompanyLocationOwner, UserInfo } from '@upscore-mobility-audit/api';
import { MobilitySharingWrapperService } from '@upscore-mobility-audit/shared/api-services/mobility-sharing-wrapper.service';
import { UpscoreTable } from '@upscore-mobility-audit/shared/interfaces/upscore-table.interface';
import { UpscoreTableModel } from '@upscore-mobility-audit/shared-ui/components/upscore-table/upscore-table.model';

@Component({
    selector: 'share-mobility-audit-dialog',
    templateUrl: './share-mobility-audit-dialog.component.html',
    styleUrls: ['./share-mobility-audit-dialog.component.scss'],
})
export class ShareMobilityAuditDialogComponent implements OnInit, OnDestroy {
    public newUserFormGroup;
    public resetPasswordFormGroup;
    public tableData: UserInfo[] = [];
    public shareMobilityAuditTableModel: UpscoreTableModel = new UpscoreTableModel();
    public shareMobilityTableColumns: UpscoreTable[] = [
        { sectionHeader: 'Email/UserName', field: 'email', isEditable: false },
        {
            sectionHeader: 'Name',
            field: 'name',
            isEditable: false,
        },
    ];
    public showAddForm: boolean;
    public showPasswordResetForm: boolean;
    public addedUserDetails: CompanyLocationOwner;
    public showAddedUserDetails: boolean;
    public selectedUserDetails: CompanyLocationOwner;
    public baseURL: string = window.location.origin;
    private unsubscribe$: Subject<void> = new Subject();

    constructor(
        private mobilitySharingService: MobilitySharingWrapperService,
        @Inject(MAT_DIALOG_DATA) public data: { companyLocation: CompanyLocation },
    ) {
        this.newUserFormGroup = new FormGroup({
            username: new FormControl('', { nonNullable: true, validators: Validators.required }),
            name: new FormControl('', { nonNullable: true, validators: Validators.required }),
            password: new FormControl('', { nonNullable: true, validators: Validators.required }),
        });
        this.resetPasswordFormGroup = new FormGroup({
            newpassword: new FormControl<string>('', {
                validators: Validators.required,
                nonNullable: true,
            }),
        });
    }

    public ngOnInit(): void {
        this.initTableProperties();
        this.listViewEmployees();
    }

    public listViewEmployees(): void {
        if (this.data.companyLocation.id == null) {
            return;
        }

        this.mobilitySharingService
            .listViewOnlyUsers(this.data.companyLocation.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                this.tableData = res;
            });
    }

    public initTableProperties(): void {
        const displayCols: string[] = this.shareMobilityTableColumns.map(
            (tableColumn: UpscoreTable) => tableColumn.field,
        );
        displayCols.push('action');

        this.shareMobilityAuditTableModel.displayedColumns = displayCols;
        this.shareMobilityAuditTableModel.showDeleteButton = true;
        this.shareMobilityAuditTableModel.showResetPasswordButton = true;
    }

    public onCreateNewUser(): void {
        if (this.data.companyLocation.id == null) {
            return;
        }

        this.mobilitySharingService
            .createNewUser(
                this.data.companyLocation.id,
                this.newUserFormGroup.controls.username.value,
                this.newUserFormGroup.controls.name.value,
                this.newUserFormGroup.controls.password.value,
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                this.addedUserDetails = res;
                this.showAddedUserDetails = true;
                this.showAddForm = false;
                this.listViewEmployees();
            });
    }

    public onAddNewUser(): void {
        this.showAddForm = true;
        this.showPasswordResetForm = false;
        this.showAddedUserDetails = false;
    }

    public onCancelAdd(): void {
        this.showAddForm = false;
        this.showPasswordResetForm = false;
        this.showAddedUserDetails = false;
    }

    public onRowDelete(val: CompanyLocationOwner): void {
        if (val.id == null || this.data.companyLocation.id == null) {
            return;
        }

        this.mobilitySharingService
            .deleteViewOnlyUser(val.id, this.data.companyLocation.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.onCancelAdd();
                this.listViewEmployees();
            });
    }

    public onPasswordReset(val: CompanyLocationOwner): void {
        this.showAddForm = false;
        this.showPasswordResetForm = true;
        this.showAddedUserDetails = false;
        this.selectedUserDetails = val;
    }

    public onResetPassword(): void {
        if (this.selectedUserDetails.id == null || this.data.companyLocation.id == null) {
            return;
        }

        this.mobilitySharingService
            .resetNewUserPassword(
                this.selectedUserDetails.id,
                this.data.companyLocation.id,
                this.resetPasswordFormGroup.controls.newpassword.value,
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.onCancelAdd();
                this.listViewEmployees();
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
