/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PackageDefinition } from '../models/package-definition';
import { PackageResponse } from '../models/package-response';
import { PackageTask } from '../models/package-task';
import { PostedPackage } from '../models/posted-package';
import { SmallScoreResponse } from '../models/small-score-response';

@Injectable({
  providedIn: 'root',
})
export class PackagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPackage
   */
  static readonly GetPackagePath = '/api/v1/packages/{packageId}';

  /**
   * Get a specific mobility package.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPackage()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getPackage$Response(params: {

    /**
     * package id
     */
    packageId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PackageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.GetPackagePath, 'get');
    if (params) {
      rb.path('packageId', params.packageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageResponse>;
      })
    );
  }

  /**
   * Get a specific mobility package.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getPackage(params: {

    /**
     * package id
     */
    packageId: number;
    context?: HttpContext
  }
): Observable<PackageResponse> {

    return this.getPackage$Response(params).pipe(
      map((r: StrictHttpResponse<PackageResponse>) => r.body as PackageResponse)
    );
  }

  /**
   * Path part for operation replacePackage
   */
  static readonly ReplacePackagePath = '/api/v1/packages/{packageId}';

  /**
   * Replace mobility package.
   *
   * Depreceted - use `updateSmallPackage` instead!
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replacePackage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  replacePackage$Response(params: {

    /**
     * package id
     */
    packageId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * calculate trip distance individually
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: PostedPackage
  }
): Observable<StrictHttpResponse<PackageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.ReplacePackagePath, 'put');
    if (params) {
      rb.path('packageId', params.packageId, {});
      rb.query('withDistanceCalculation', params.withDistanceCalculation, {});
      rb.query('detailedTrip', params.detailedTrip, {});
      rb.query('detailedTripType', params.detailedTripType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageResponse>;
      })
    );
  }

  /**
   * Replace mobility package.
   *
   * Depreceted - use `updateSmallPackage` instead!
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `replacePackage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  replacePackage(params: {

    /**
     * package id
     */
    packageId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * calculate trip distance individually
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: PostedPackage
  }
): Observable<PackageResponse> {

    return this.replacePackage$Response(params).pipe(
      map((r: StrictHttpResponse<PackageResponse>) => r.body as PackageResponse)
    );
  }

  /**
   * Path part for operation deletePackage
   */
  static readonly DeletePackagePath = '/api/v1/packages/{packageId}';

  /**
   * Delete a mobility package.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePackage$Response(params: {

    /**
     * package id
     */
    packageId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.DeletePackagePath, 'delete');
    if (params) {
      rb.path('packageId', params.packageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Delete a mobility package.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePackage(params: {

    /**
     * package id
     */
    packageId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.deletePackage$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateSmallPackage
   */
  static readonly UpdateSmallPackagePath = '/api/v1/locations/{companyLocationId}/updateSmallPackage/{packageId}';

  /**
   * Update a package.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSmallPackage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSmallPackage$Response(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
    body: PostedPackage
  }
): Observable<StrictHttpResponse<SmallScoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.UpdateSmallPackagePath, 'put');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('packageId', params.packageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmallScoreResponse>;
      })
    );
  }

  /**
   * Update a package.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSmallPackage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSmallPackage(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
    body: PostedPackage
  }
): Observable<SmallScoreResponse> {

    return this.updateSmallPackage$Response(params).pipe(
      map((r: StrictHttpResponse<SmallScoreResponse>) => r.body as SmallScoreResponse)
    );
  }

  /**
   * Path part for operation updateSmallPackageAsync
   */
  static readonly UpdateSmallPackageAsyncPath = '/api/v1/locations/{companyLocationId}/async/updateSmallPackage/{packageId}';

  /**
   * Update a new package.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSmallPackageAsync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSmallPackageAsync$Response(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
    body: PostedPackage
  }
): Observable<StrictHttpResponse<PackageTask>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.UpdateSmallPackageAsyncPath, 'put');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('packageId', params.packageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageTask>;
      })
    );
  }

  /**
   * Update a new package.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSmallPackageAsync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSmallPackageAsync(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
    body: PostedPackage
  }
): Observable<PackageTask> {

    return this.updateSmallPackageAsync$Response(params).pipe(
      map((r: StrictHttpResponse<PackageTask>) => r.body as PackageTask)
    );
  }

  /**
   * Path part for operation companyLocationPackages
   */
  static readonly CompanyLocationPackagesPath = '/api/v1/locations/{companyLocationId}/packages';

  /**
   * See all mobility packages for a company location.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationPackages()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  companyLocationPackages$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PackageResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.CompanyLocationPackagesPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PackageResponse>>;
      })
    );
  }

  /**
   * See all mobility packages for a company location.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationPackages$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  companyLocationPackages(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<PackageResponse>> {

    return this.companyLocationPackages$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PackageResponse>>) => r.body as Array<PackageResponse>)
    );
  }

  /**
   * Path part for operation newCompanyLocationPackageRequest
   */
  static readonly NewCompanyLocationPackageRequestPath = '/api/v1/locations/{companyLocationId}/packages';

  /**
   * Create new mobility packages.
   *
   * Deprecated - use `createSmallPackage` instead! 
   *
   * Use this API for creating a new modeled base scenario. If you want to create a new package based on an existing base scenario, use the functions in /improvements instead!
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newCompanyLocationPackageRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  newCompanyLocationPackageRequest$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * calculate trip distance individually
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: PostedPackage
  }
): Observable<StrictHttpResponse<PackageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.NewCompanyLocationPackageRequestPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('withDistanceCalculation', params.withDistanceCalculation, {});
      rb.query('detailedTrip', params.detailedTrip, {});
      rb.query('detailedTripType', params.detailedTripType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageResponse>;
      })
    );
  }

  /**
   * Create new mobility packages.
   *
   * Deprecated - use `createSmallPackage` instead! 
   *
   * Use this API for creating a new modeled base scenario. If you want to create a new package based on an existing base scenario, use the functions in /improvements instead!
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `newCompanyLocationPackageRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  newCompanyLocationPackageRequest(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * calculate trip distance individually
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: PostedPackage
  }
): Observable<PackageResponse> {

    return this.newCompanyLocationPackageRequest$Response(params).pipe(
      map((r: StrictHttpResponse<PackageResponse>) => r.body as PackageResponse)
    );
  }

  /**
   * Path part for operation recalculatePackage
   */
  static readonly RecalculatePackagePath = '/api/v1/locations/{companyLocationId}/packages/{packageId}/recalculate';

  /**
   * Recalculate a package - this only recalculates the mobility score and not the distances, except when deemed necessary. To force a recalculation of the distances use the REST api in ScoreController.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recalculatePackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  recalculatePackage$Response(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SmallScoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.RecalculatePackagePath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('packageId', params.packageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmallScoreResponse>;
      })
    );
  }

  /**
   * Recalculate a package - this only recalculates the mobility score and not the distances, except when deemed necessary. To force a recalculation of the distances use the REST api in ScoreController.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recalculatePackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recalculatePackage(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
  }
): Observable<SmallScoreResponse> {

    return this.recalculatePackage$Response(params).pipe(
      map((r: StrictHttpResponse<SmallScoreResponse>) => r.body as SmallScoreResponse)
    );
  }

  /**
   * Path part for operation createSmallPackage1
   */
  static readonly CreateSmallPackage1Path = '/api/v1/locations/{companyLocationId}/createSmallPackage';

  /**
   * Create a new package.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSmallPackage1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSmallPackage1$Response(params: {
    companyLocationId: number;
    context?: HttpContext
    body: PostedPackage
  }
): Observable<StrictHttpResponse<SmallScoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.CreateSmallPackage1Path, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmallScoreResponse>;
      })
    );
  }

  /**
   * Create a new package.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createSmallPackage1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSmallPackage1(params: {
    companyLocationId: number;
    context?: HttpContext
    body: PostedPackage
  }
): Observable<SmallScoreResponse> {

    return this.createSmallPackage1$Response(params).pipe(
      map((r: StrictHttpResponse<SmallScoreResponse>) => r.body as SmallScoreResponse)
    );
  }

  /**
   * Path part for operation recalculatePackageAsync
   */
  static readonly RecalculatePackageAsyncPath = '/api/v1/locations/{companyLocationId}/async/packages/{packageId}/recalculate';

  /**
   * Recalculate a package - this only recalculates the mobility score and not the distances, except when deemed necessary. To force a recalculation of the distances use the REST api in ScoreController.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recalculatePackageAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  recalculatePackageAsync$Response(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PackageTask>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.RecalculatePackageAsyncPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('packageId', params.packageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageTask>;
      })
    );
  }

  /**
   * Recalculate a package - this only recalculates the mobility score and not the distances, except when deemed necessary. To force a recalculation of the distances use the REST api in ScoreController.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recalculatePackageAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recalculatePackageAsync(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
  }
): Observable<PackageTask> {

    return this.recalculatePackageAsync$Response(params).pipe(
      map((r: StrictHttpResponse<PackageTask>) => r.body as PackageTask)
    );
  }

  /**
   * Path part for operation createSmallPackageAsync1
   */
  static readonly CreateSmallPackageAsync1Path = '/api/v1/locations/{companyLocationId}/async/createSmallPackage';

  /**
   * Create a new package.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSmallPackageAsync1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSmallPackageAsync1$Response(params: {
    companyLocationId: number;
    context?: HttpContext
    body: PostedPackage
  }
): Observable<StrictHttpResponse<PackageTask>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.CreateSmallPackageAsync1Path, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageTask>;
      })
    );
  }

  /**
   * Create a new package.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createSmallPackageAsync1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSmallPackageAsync1(params: {
    companyLocationId: number;
    context?: HttpContext
    body: PostedPackage
  }
): Observable<PackageTask> {

    return this.createSmallPackageAsync1$Response(params).pipe(
      map((r: StrictHttpResponse<PackageTask>) => r.body as PackageTask)
    );
  }

  /**
   * Path part for operation getPackageAsync
   */
  static readonly GetPackageAsyncPath = '/api/v1/locations/{companyLocationId}/packages/async/{packageId}';

  /**
   * Calculate the mobility score of a package.
   *
   * If results are already available this returns a score directly in a 200 response, otherwise an async calculation is started and the task definition is returned in a 202 response
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPackageAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPackageAsync$Response(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SmallScoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.GetPackageAsyncPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('packageId', params.packageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmallScoreResponse>;
      })
    );
  }

  /**
   * Calculate the mobility score of a package.
   *
   * If results are already available this returns a score directly in a 200 response, otherwise an async calculation is started and the task definition is returned in a 202 response
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPackageAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPackageAsync(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
  }
): Observable<SmallScoreResponse> {

    return this.getPackageAsync$Response(params).pipe(
      map((r: StrictHttpResponse<SmallScoreResponse>) => r.body as SmallScoreResponse)
    );
  }

  /**
   * Path part for operation getPackageDefinitions
   */
  static readonly GetPackageDefinitionsPath = '/api/v1/locations/{companyLocationId}/packageDefinitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPackageDefinitions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPackageDefinitions$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PackageDefinition>>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.GetPackageDefinitionsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PackageDefinition>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPackageDefinitions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPackageDefinitions(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<PackageDefinition>> {

    return this.getPackageDefinitions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PackageDefinition>>) => r.body as Array<PackageDefinition>)
    );
  }

  /**
   * Path part for operation getPackageDefinition
   */
  static readonly GetPackageDefinitionPath = '/api/v1/locations/{companyLocationId}/packageDefinitions/{packageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPackageDefinition()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPackageDefinition$Response(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PackageDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.GetPackageDefinitionPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('packageId', params.packageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageDefinition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPackageDefinition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPackageDefinition(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
  }
): Observable<PackageDefinition> {

    return this.getPackageDefinition$Response(params).pipe(
      map((r: StrictHttpResponse<PackageDefinition>) => r.body as PackageDefinition)
    );
  }

}
