<div class="p-xxxl text-dark flex flex-col gap-l" [formGroup]="measureFormGroup">
    @if (measureFormGroup.value.type != null && !disableBackButton) {
        <span
            (click)="selectMeasureType(null)"
            class="flex align-items-center justify-start cursor-pointer"
            style="color: #255487"
            ><mat-icon>arrow_back_ios</mat-icon>
            <ng-container i18n="@@back">Back</ng-container>
        </span>
    }

    <div class="flex flex-row justify-between tps-heading-2">
        @if (measureFormGroup.value.type !== measureType.SUGGESTION) {
            @if (packageToEdit == undefined) {
                <h2 i18n="@@scenario.newScenarioHeader">New Mobility Scenario</h2>
            } @else if (!data.duplicate) {
                <h2 i18n="@@scneario.editScenarioHeader">Edit Mobility Scenario</h2>
            } @else {
                <h2 i18n="@@scenario.duplicateScenario">Duplicate Mobility Scenario</h2>
            }
            <button mat-icon-button (click)="cancelDialog()">
                <mat-icon>close</mat-icon>
            </button>
        } @else {
            <h2 i18n="@@scenario.appliedMeasureHeader">Applied Measure</h2>
        }
    </div>

    <!-- switch shows which first / second page should be shown-->
    @switch (measureFormGroup.value.type) {
        @case (null) {
            <!-- first screen -->
            <div class="flex flex-col gap-m">
                <div class="text-b1 text-grey-medium" i18n="@@scenario.scenarioDescription">
                    A Mobility Scenario applies one or more measures on top of a base scenario.
                </div>
            </div>
            <mat-form-field class="w-1/2 pr-s">
                <mat-label i18n="@@scenario.scenarioNameLabel">Scenario Name</mat-label>
                <input matInput required [formControlName]="'title'" type="text" />
            </mat-form-field>

            @if (
                userDataService.showFeature(featureFlag.CREATE_BASE_SCENARIO) ||
                baseScenarios().length > 0
            ) {
                <div class="flex flex-col gap-m">
                    <h3 class="tps-heading-3" i18n="@@scenario.baseScenario">Base Scenario</h3>

                    <ng-template #baseScenarioTemplate let-id="id" let-name="name" let-icon="icon">
                        <div
                            (click)="
                                id
                                    ? selectBaseScenario(id)
                                    : (showAddBaseScenario = !showAddBaseScenario)
                            "
                            class="w-[144px] h-[144px] gap-m rounded-lg flex flex-row shadow-md items-center"
                            [ngClass]="{
                                'border-2 border-primary border-solid':
                                    measureFormGroup.value.baseScenario === id,
                                'cursor-pointer': measureFormGroup.value.baseScenario !== id,
                                'w-[calc(144px*3+16px*2)]': showAddBaseScenario && !id
                            }">
                            <div
                                class="w-[144px] h-[144px] flex flex-col justify-center items-center text-center">
                                @if (icon) {
                                    <mat-icon>{{ icon }}</mat-icon>
                                }
                                <h3 class="tps-heading-4">{{ name }}</h3>
                            </div>
                            @if (showAddBaseScenario && !id) {
                                <div
                                    class="m-m w-[120px] h-[120px] rounded border-2 border-solid border-grey-superlight flex items-center justify-center text-center"
                                    (click)="selectMeasureType(MeasureType.MODAL_SPLIT)">
                                    <h3 i18n="@@scenario.modalSplitModel">Modal Split Model</h3>
                                </div>
                                <div
                                    class="m-m w-[120px] h-[120px] rounded border-2 border-solid border-grey-superlight flex items-center justify-center text-center"
                                    (click)="selectMeasureType(MeasureType.CUSTOM_MEASURE)">
                                    <h3 i18n="@@scenario.modeChoiceModel">Mode Choice Model</h3>
                                </div>
                            }
                        </div>
                    </ng-template>

                    <div class="flex flex-row flex-wrap gap-m">
                        <ng-container
                            [ngTemplateOutlet]="baseScenarioTemplate"
                            [ngTemplateOutletContext]="{
                                id: -1,
                                name: MobilityScenarioBuilderTranslations.statusQuo
                            }">
                        </ng-container>

                        @for (package_ of baseScenarios(); track package_) {
                            <ng-container
                                *ngIf="package_.packageId !== packageToEdit?.packageId"
                                [ngTemplateOutlet]="baseScenarioTemplate"
                                [ngTemplateOutletContext]="{
                                    id: package_.packageId,
                                    name: package_.title
                                }">
                            </ng-container>
                        }

                        @if (
                            userDataService.showFeature(featureFlag.CREATE_BASE_SCENARIO) &&
                            packageToEdit == undefined
                        ) {
                            <ng-container
                                [ngTemplateOutlet]="baseScenarioTemplate"
                                [ngTemplateOutletContext]="{
                                    icon: 'add',
                                    name: MobilityScenarioBuilderTranslations.addBaseScenario
                                }">
                            </ng-container>
                        }
                    </div>
                </div>
            }

            <div class="flex flex-col gap-m">
                <h3 class="tps-heading-3" i18n="@@scenario.appliedMeasures">Applied Measures</h3>
                <div class="text-b1 text-grey-medium" i18n="@@scenario.appliedMeasuresDescription">
                    The following measures are applied in your scenario. Choose from the Suggested
                    Measures and Other Measures to add more.
                </div>
                <div
                    class="grid grid-cols-2 w-[calc(var(--measure-card-width)*2+16px)] xl:grid-cols-3 xl:w-[calc(var(--measure-card-width)*3+2*16px)] gap-m">
                    @for (measure of appliedMeasures(); track measure) {
                        <div class="relative">
                            <measure-impact-header
                                class="cursor-pointer"
                                [measure]="measure.intervention"
                                [size]="'compact'"
                                [savedMode]="'absolute'"
                                [includeCta]="false"
                                [impactedEmployees]="
                                    measure.impact()?.employeeInterventionResults?.length
                                "
                                [savedEmissions]="measure.impact()?.yearlySavedEmissions"
                                (click)="
                                    measure.source === 'baseScenario'
                                        ? dialogInfoBaseScenario()
                                        : ''
                                ">
                            </measure-impact-header>
                            @if (measure.source !== 'baseScenario') {
                                <button
                                    class="!absolute right-[3px] top-[3px]"
                                    data-html2canvas-ignore
                                    mat-icon-button
                                    [matMenuTriggerFor]="menu">
                                    <mat-icon class="mini-icon text-white">more_vert</mat-icon>
                                </button>
                                <mat-menu #menu>
                                    <button mat-menu-item (click)="selectMeasure(measure)">
                                        <mat-icon color="accent">edit</mat-icon>
                                        <span i18n="@@edit">Edit</span>
                                    </button>
                                    <button
                                        mat-menu-item
                                        class="warn"
                                        (click)="removeMeasure(measure.intervention)">
                                        <mat-icon>remove</mat-icon>
                                        <span i18n="@@remove">Remove</span>
                                    </button>
                                </mat-menu>
                            }
                        </div>
                    }
                </div>
            </div>
            <div class="flex flex-col gap-m">
                <h3 class="tps-heading-3" i18n="@@scenario.suggestedMeasures">
                    Suggested Measures
                </h3>
                <div
                    class="grid grid-cols-2 w-[calc(var(--measure-card-width)*2+16px)] xl:grid-cols-3 xl:w-[calc(var(--measure-card-width)*3+2*16px)] gap-m">
                    @for (measure of suggestedMeasures(); track measure) {
                        @if (measure.source === 'suggested') {
                            <measure-impact-header
                                class="cursor-pointer"
                                [measure]="measure.intervention"
                                [savedMode]="'absolute'"
                                [size]="'compact'"
                                [includeCta]="false"
                                [impactedEmployees]="
                                    measure.impact()?.employeeInterventionResults?.length
                                "
                                [savedEmissions]="measure.impact()?.yearlySavedEmissions"
                                (click)="selectMeasure(measure)">
                            </measure-impact-header>
                        }
                    }
                </div>
            </div>

            <div class="flex flex-col gap-m">
                <h3 class="tps-heading-3" i18n="@@scenario.otherMeasures">Other Measures</h3>
                <div
                    class="grid grid-cols-2 w-[calc(var(--measure-card-width)*2+16px)] xl:grid-cols-3 xl:w-[calc(var(--measure-card-width)*3+2*16px)] gap-m">
                    @for (measure of otherMeasures(); track measure) {
                        <measure-impact-header
                            class="cursor-pointer"
                            [measure]="measure.intervention"
                            [size]="'compact'"
                            [savedMode]="'absolute'"
                            [includeCta]="false"
                            [impactedEmployees]="
                                measure.impact()?.employeeInterventionResults?.length
                            "
                            [savedEmissions]="measure.impact()?.yearlySavedEmissions"
                            (click)="selectMeasure(measure)">
                        </measure-impact-header>
                    }
                    @for (measure of suggestedMeasures(); track measure) {
                        @if (measure.source === 'other') {
                            <measure-impact-header
                                class="cursor-pointer"
                                [measure]="measure.intervention"
                                [savedMode]="'absolute'"
                                [size]="'compact'"
                                [includeCta]="false"
                                [impactedEmployees]="
                                    measure.impact()?.employeeInterventionResults?.length
                                "
                                [savedEmissions]="measure.impact()?.yearlySavedEmissions"
                                (click)="selectMeasure(measure)">
                            </measure-impact-header>
                        }
                    }
                    <measure-other-header></measure-other-header>
                </div>
            </div>
        }
        @case (measureType.SUGGESTION) {
            <div class="mt-m">
                @if (selectedSuggestion()) {
                    <detailed-measure-suggestion
                        [originalSuggestion]="selectedSuggestion()"
                        [estimatedImpact]="selectedSuggestionImpact()"
                        [employeeCategories]="data.employeeCategories"
                        (addSuggestion)="onAddSuggestion($event[0], $event[1])"
                        (parametersChanged)="estimateImpactOfSuggestion($event)">
                    </detailed-measure-suggestion>
                }
            </div>
        }
        @case (measureType.MODAL_SPLIT) {
            <modal-split-measure
                [modalSplitType]="modalSplitType.MODAL_SPLIT"
                [companyLocation]="companyLocationDataService.companyLocation()"
                [parentFormGroup]="measureFormGroup"
                [parentFormControlName]="'measure'"
                [editedPackage]="data.packageToEdit"
                [duplicate]="data.duplicate">
            </modal-split-measure>
        }
        @case (measureType.CUSTOM_MEASURE) {
            <modal-split-measure
                [modalSplitType]="modalSplitType.MODE_CALCULATION"
                [companyLocation]="companyLocationDataService.companyLocation()"
                [parentFormGroup]="measureFormGroup"
                [parentFormControlName]="'measure'"
                [editedPackage]="data.packageToEdit"
                [duplicate]="data.duplicate"
                *ngIf="measureFormGroup.value.type === measureType.CUSTOM_MEASURE">
            </modal-split-measure>
        }
    }
    <div class="flex justify-end py-l">
        @if (measureFormGroup.value.type !== measureType.SUGGESTION) {
            <button
                mat-raised-button
                [disabled]="
                    measureFormGroup.value.type == null
                        ? !measureFormGroup.valid
                        : !measureFormGroup.controls.measure?.valid
                "
                color="primary"
                i18n="@@save"
                (click)="
                    (
                        measureFormGroup.value.type == null
                            ? measureFormGroup.valid
                            : measureFormGroup.controls.measure?.valid
                    )
                        ? onStepsCompleted()
                        : ''
                ">
                Save
            </button>
        }
    </div>
</div>
