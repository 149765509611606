import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'export-text-card',
    templateUrl: './export-text-card.component.html',
    styleUrls: ['./export-text-card.component.scss'],
})
export class ExportTextCardComponent {
    @Input() public heading: string;
    @Input() public text: string;

    @Output() public cardChanged: EventEmitter<[string, string]> = new EventEmitter<
        [string, string]
    >();
}
