<form [formGroup]="uploadFormGroup">
    <div class="employee-header">
        <h2
            *ngIf="uploadFormGroup.value.uploadType == null"
            i18n="@@pickUploadOption"
            style="display: inline-block">
            Pick an option:
        </h2>
        <span
            (click)="selectUploadType(null)"
            *ngIf="uploadFormGroup.value.uploadType != null"
            class="revert-selection"
            ><mat-icon>arrow_back_ios</mat-icon>
            <ng-container i18n="@@pickUploadType">Pick upload type</ng-container>
        </span>
        <div *ngIf="employeeCount !== 0" class="employee-info">
            <mat-icon>info</mat-icon>
            <span i18n="@@locationEmployees">Location has {{ employeeCount }} employees</span>
        </div>
    </div>
    <ng-container *ngIf="uploadFormGroup.value.uploadType == null">
        <div class="type-selector">
            <ng-container *ngFor="let type of uploadTypes" [ngSwitch]="type">
                <ng-container *ngSwitchCase="fileUploadTypes.DETAILED">
                    <div (click)="selectUploadType(type)" class="type-item">
                        <h3 class="type-name" i18n="@@uploadDetailedEmployees">
                            Upload Detailed Employees
                        </h3>
                        <span i18n="@@detailedEmployeeUploadInfo"
                            >Upload addresses and other information for individual employees. The
                            sample file contains all configurable fields. For more information about
                            the included data, see the documentation.</span
                        >
                    </div>
                    <div class="flex flex-wrap justify-center gap-m w-full">
                        <a
                            download
                            target="_blank"
                            href="https://docs.mobility-audit.com/files/v2.1/{{
                                fileLanguageKey
                            }}/employees_detailed_example.xlsx">
                            <div
                                class="flex gap-xs items-center bg-primary text-white rounded py-xs px-m">
                                <mat-icon class="!text-white">description</mat-icon>
                                <span i18n="@@downloadExcelExample">Example (Excel)</span>
                            </div>
                        </a>
                        <a
                            download
                            target="_blank"
                            href="https://docs.mobility-audit.com/files/v2.1/{{
                                fileLanguageKey
                            }}/employees_detailed_example.csv">
                            <div
                                class="flex gap-xs items-center bg-primary text-white rounded py-xs px-m">
                                <mat-icon class="!text-white">description</mat-icon>
                                <span i18n="@@downloadCsvExample">Example (CSV)</span>
                            </div>
                        </a>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="fileUploadTypes.AGGREGATED">
                    <div (click)="selectUploadType(type)" class="type-item">
                        <h3 class="type-name" i18n="@@uploadAggregatedEmployees">
                            Upload Aggregated Employees
                        </h3>
                        <span i18n="@@aggregatedEmployeeUploadInfo"
                            >Upload aggregated information about your employees. The sample file
                            contains all configurable fields. For more information about the
                            included data, see the documentation.</span
                        >
                    </div>
                    <div class="flex flex-wrap justify-center gap-m w-full">
                        <a
                            download
                            target="_blank"
                            href="https://docs.mobility-audit.com/files/v2.1/{{
                                fileLanguageKey
                            }}/employees_aggregated_example.xlsx">
                            <div
                                class="flex gap-xs items-center bg-primary text-white rounded py-xs px-m">
                                <mat-icon class="!text-white">description</mat-icon>
                                <span i18n="@@downloadExcelExample">Example (Excel)</span>
                            </div>
                        </a>
                        <a
                            download
                            target="_blank"
                            href="https://docs.mobility-audit.com/files/v2.1/{{
                                fileLanguageKey
                            }}/employees_aggregated_example.csv">
                            <div
                                class="flex gap-xs items-center bg-primary text-white rounded py-xs px-m">
                                <mat-icon class="!text-white">description</mat-icon>
                                <span i18n="@@downloadCsvExample">Example (CSV)</span>
                            </div>
                        </a>
                    </div>
                </ng-container>
                <div
                    (click)="selectUploadType(type)"
                    *ngSwitchCase="fileUploadTypes.ESTIMATE"
                    class="type-item">
                    <h3 class="type-name" i18n="@@estimateEmployees">Estimate Employees</h3>
                    <span i18n="@@estimateEmployeesInfo"
                        >No information on employees known yet? Enter the number of employees and we
                        will estimate the locations based on local commuting flows.</span
                    >
                </div>
            </ng-container>
        </div>
        <div class="triply-wiki-info">
            <mat-icon>info</mat-icon>
            <span i18n="@@employeeDocumentationText"
                >Check out more information about Employee Data in the
            </span>
            &nbsp;
            <a
                href="https://docs.mobility-audit.com/company-location/upload-employees"
                i18n-href="@@employeeDocumenation"
                rel="noopener noreferrer"
                target="_blank">
                Mobility Audit Docs</a
            >
        </div>
    </ng-container>
    <div
        *ngIf="
            uploadFormGroup.value.uploadType != null &&
            uploadFormGroup.value.uploadType !== fileUploadTypes.ESTIMATE
        "
        [ngSwitch]="uploadFormGroup.value.uploadType"
        class="upload-area">
        <ng-container *ngIf="uploadFormGroup.value.error == null">
            <h2
                *ngSwitchCase="fileUploadTypes.DETAILED"
                class="selected-page"
                i18n="@@uploadDetailedEmployees">
                Upload Detailed Employees
            </h2>
            <h2
                *ngSwitchCase="fileUploadTypes.AGGREGATED"
                class="selected-page"
                i18n="@@uploadAggregatedEmployees">
                Upload Aggregated Employees
            </h2>
            <div
                *ngIf="userDataService.showFeature$(featureFlag.DEBUG_VIEW) | async"
                class="max-distance-geocoder-container">
                <div class="max-distance-column">
                    <mat-checkbox
                        formControlName="maxDistanceCheckbox"
                        i18n="@@maxDistanceFromCompany"
                        >Only upload employees less than <b>{{ distanceSlider.value }} km</b> away
                        from the company.
                    </mat-checkbox>
                    <upscore-slider
                        #distanceSlider
                        (valueChanged)="onMaximumDistanceSliderChanged($event)"
                        [debounced]="true"
                        [disabled]="!uploadFormGroup.controls.maxDistanceCheckbox.value"
                        [max]="1000"
                        [min]="0"
                        [showValue]="false"
                        [value]="500"
                        [step]="5"
                        style="width: 100%">
                    </upscore-slider>
                </div>

                <mat-form-field appearance="outline" style="margin-left: 10rem">
                    <mat-label i18n="@@selectGeocoder">Select Geocoder</mat-label>
                    <mat-select formControlName="geocoder" value="employeeGeocoder">
                        <mat-option value="employeeGeocoder">Default Geocoder</mat-option>
                        <mat-option value="hereApi">Here API</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <upload-area [parentFormGroup]="uploadFormGroup"></upload-area>
            <div *ngIf="uploadFormGroup.value.file != null" class="selected-file">
                <selected-file [filename]="uploadFormGroup.value.file.name"></selected-file>
                <mat-slide-toggle
                    *ngIf="employeeCount !== 0"
                    formControlName="overwrite"
                    i18n="@@overwriteEmployees"
                    >Overwrite Employees
                </mat-slide-toggle>
            </div>
        </ng-container>
        <ng-container *ngIf="uploadFormGroup.value.error != null">
            <div
                *ngIf="userDataService.showFeature$(featureFlag.DEBUG_VIEW) | async"
                class="max-distance-column">
                <mat-checkbox formControlName="maxDistanceCheckbox" i18n="@@maxDistanceFromCompany"
                    >Only upload employees less than <b>{{ distanceSlider.value }} km</b> away from
                    the company.
                </mat-checkbox>
                <upscore-slider
                    #distanceSlider
                    (valueChanged)="onMaximumDistanceSliderChanged($event)"
                    [debounced]="true"
                    [disabled]="!uploadFormGroup.controls.maxDistanceCheckbox.value"
                    [max]="1000"
                    [min]="0"
                    [showValue]="false"
                    [value]="500"
                    [step]="5"
                    style="width: 100%">
                </upscore-slider>
            </div>
            <employee-upload-error [uploadFormGroup]="uploadFormGroup"></employee-upload-error>
            <div class="action-button-column">
                <button
                    (click)="
                        reUploadEmployees.emit({
                            failedEmployees: uploadFormGroup.value.error,
                            uploadType: uploadFormGroup.value.uploadType
                        })
                    "
                    color="primary"
                    i18n="@@retryEmployees"
                    mat-raised-button>
                    Retry Employees
                </button>
                <span>or</span>
                <button (click)="selectUploadType(null)" i18n="@@goBack" mat-stroked-button>
                    Go Back
                </button>
            </div>
        </ng-container>
    </div>
    <estimate-employee-selection
        *ngIf="
            uploadFormGroup.value.uploadType != null &&
            uploadFormGroup.value.uploadType === fileUploadTypes.ESTIMATE
        "
        [entrances]="entrances"
        [parentFormGroup]="uploadFormGroup"></estimate-employee-selection>
</form>
