<form [formGroup]="mobilityParamsFormGroup" class="locationForm">
    <div class="left-column">
        <mat-slide-toggle
            class="mat-form-field-wrapper"
            formControlName="shiftWork"
            i18n="@@shiftWork">
            Shift - Work
        </mat-slide-toggle>
        <mat-form-field appearance="outline">
            <mat-label i18n="@@attendancePerWeek">Average Days In Office Per Week</mat-label>
            <input formControlName="presenceDays" matInput placeholder="4.3" type="number" />
            @if (mobilityParamsFormGroup.controls['presenceDays'].value) {
                <button
                    (click)="mobilityParamsFormGroup.controls['presenceDays'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label i18n="@@annualParkingPrice">Annual Costs of Parking Lots</mat-label>
            <input formControlName="parkingLotCosts" matInput placeholder="300000" type="number" />
            @if (mobilityParamsFormGroup.controls['parkingLotCosts'].value) {
                <button
                    (click)="mobilityParamsFormGroup.controls['parkingLotCosts'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label i18n="@@furtherYearlyCosts">Additional Costs per Year</mat-label>
            <input formControlName="otherCosts" matInput placeholder="700000" type="number" />
            @if (mobilityParamsFormGroup.controls['otherCosts'].value) {
                <button
                    (click)="mobilityParamsFormGroup.controls['otherCosts'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label i18n="@@co2EmissionsInTrips"
                >Co2 emissions in business trips (in t)
            </mat-label>
            <input
                formControlName="businessTravelTotalEmissions"
                matInput
                placeholder="300.000"
                type="number" />
            @if (mobilityParamsFormGroup.controls['businessTravelTotalEmissions'].value) {
                <button
                    (click)="
                        mobilityParamsFormGroup.controls['businessTravelTotalEmissions'].setValue(
                            ''
                        )
                    "
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
    </div>

    <div class="modal-split-change">
        <mobility-stats-input
            [allowDifferentType]="
                userDataService.showFeature$(featureFlag.MODE_ESTIMATION_V2) | async
            "
            [companyLocationId]="companyLocationId"
            [parentFormGroup]="mobilityParamsFormGroup"
            [statsPercentageChange]="mobilityStatsSliderOptions"
            parentFormControlName="mobilityStats">
        </mobility-stats-input>
    </div>

    <div class="vehicle-change">
        <basic-percentage-change-wrapper
            [formControlValidatorFn]="percentageChangeValidatorFn"
            [parentFormGroup]="mobilityParamsFormGroup"
            [statsPercentageChange]="vehicleStatsSliderOptions"
            [title]="translations.vehiclesUsedText"
            parentFormControlName="vehicleStats">
        </basic-percentage-change-wrapper>
    </div>
</form>
