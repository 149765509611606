import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import {
    Cluster,
    ClusterStats,
    MarkerClusterer,
    SuperClusterAlgorithm,
    SuperClusterViewportAlgorithm,
} from '@googlemaps/markerclusterer';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import {
    ClusterChartCreatorService,
    EmployeeMarkerCluster,
    defaultHeatmapLayerOptions,
    defaultMapOptions,
    markerClusterIconStyleOptions,
} from '@shared/map';
import { ThemeService } from '@shared/utils';
import { GeoJsonExample, TransportLeg, Trip } from '@upscore-mobility-audit/api';
import { ZipCodeClusterAlgorithm } from '@upscore-mobility-audit/map/classes/zip-code-cluster-algorithm';

interface MapObject {
    googleMap: google.maps.Map;
    data: google.maps.Data;
}

@Component({
    selector: 'custom-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnChanges, OnDestroy {
    @ViewChild(GoogleMap, { static: true }) public googleMap!: GoogleMap;

    @Input() public mapOptions?: google.maps.MapOptions;

    @Input() public heatmapData?: google.maps.visualization.WeightedLocation[];

    @Input() public markerOptions?: google.maps.marker.AdvancedMarkerElementOptions[];

    /**
     * clusteredMarkerOptions
     * Only employee marker options are allowed
     */
    @Input() public markerToCluster: google.maps.marker.AdvancedMarkerElement[] = [];

    @Input() public height = '100%';

    @Input() public width = '100%';

    @Input() public polygonOptions?: google.maps.PolygonOptions[];

    @Input() public polylineOptions?: google.maps.PolylineOptions[];

    @Input() public carPoolingPolygonOptions?: google.maps.PolygonOptions[];

    @Input() public boundingBox?: GeoJsonExample;

    @Input() public useModalSplitRenderer = false;

    @Input() public useZipCodeClusterAlgorithm = false;

    @Output() public readonly markerSelect = new EventEmitter<{
        shiftKey?: boolean;
        marker: google.maps.marker.AdvancedMarkerElement;
    }>();

    @Output() public readonly markerDragEnd = new EventEmitter<
        [google.maps.marker.AdvancedMarkerElementOptions, google.maps.MapMouseEvent]
    >();

    @Output() public mapInitialized = new EventEmitter<google.maps.Map>();

    @Output() public markerClustererUpdate = new EventEmitter<void>();

    public markerClusterer!: MarkerClusterer;

    private readonly updateMapOptions$: ReplaySubject<google.maps.MapOptions> = new ReplaySubject(
        1,
    );

    private readonly updateHeatmapData$: ReplaySubject<
        google.maps.visualization.WeightedLocation[]
    > = new ReplaySubject(1);

    private readonly updateClusteredMarkers$: ReplaySubject<
        google.maps.marker.AdvancedMarkerElement[]
    > = new ReplaySubject(1);

    private readonly unsubscribe$: Subject<void> = new Subject();

    private heatmapLayer: google.maps.visualization.HeatmapLayer;

    constructor(
        public readonly clusterChartCreatorService: ClusterChartCreatorService,
        private readonly themeService: ThemeService,
    ) {
        // insert your code here
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['mapOptions']) {
            this.updateMapOptions$.next(changes['mapOptions'].currentValue);
        }

        if (changes['heatmapData']) {
            this.updateHeatmapData$.next(changes['heatmapData'].currentValue);
        }

        if (changes['markerToCluster']) {
            this.updateClusteredMarkers$.next([...changes['markerToCluster'].currentValue]);
        }

        if (
            (changes['useModalSplitRenderer'] || changes['useZipCodeClusterAlgorithm']) &&
            this.markerClusterer != null
        ) {
            if (this.useModalSplitRenderer || this.useZipCodeClusterAlgorithm) {
                this.markerClusterer['renderer'] = { render: this.modalSplitRenderer };
            } else {
                this.markerClusterer['renderer'] = { render: this.defaultRenderer };
            }

            // @ts-expect-error: SuperClusterViewportAlgorithm typing does not accept null as a value
            (this.markerClusterer['algorithm'] as SuperClusterViewportAlgorithm)['markers'] = null;

            this.markerClusterer.render();
        }

        if (changes['useZipCodeClusterAlgorithm'] && this.markerClusterer != null) {
            if (this.useZipCodeClusterAlgorithm) {
                // @ts-expect-error ZipCodeClusterAlgorithm typing does not match
                // (because markercluster doesn't have a generic type for makers)
                this.markerClusterer['algorithm'] = new ZipCodeClusterAlgorithm({});
            } else {
                this.markerClusterer['algorithm'] = new SuperClusterAlgorithm({});
            }
            this.markerClusterer.render();
        }
    }

    /**
     * Angular lifecycle hook
     * We need to call initial map operations (e.g. add markers or layers)
     * on ngAfterViewInit and not before as we cant be sure the map is ready
     */
    public ngAfterViewInit(): void {
        this.initMap();
        this.initMapLayers();
        this.initPropertyUpdateSubscriptions();
    }

    /**
     * Angular Lifecycle
     */
    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public showTrip(tripDetails: Trip[]): void {
        const mapObject: MapObject = this.googleMap as unknown as MapObject;

        // clear old trip
        mapObject.data.forEach((ele: google.maps.Data.Feature) => {
            if (ele.getProperty('name') === 'employee-trip') {
                mapObject.data.remove(ele);
            }
        });

        if (tripDetails == null) {
            return;
        }

        tripDetails.forEach((trip: Trip) => {
            // add new trip
            trip.transportLegs.forEach((transportLeg: TransportLeg) => {
                if (transportLeg.detailedMode !== 'WAIT') {
                    mapObject.data.addGeoJson({
                        type: 'Feature',
                        geometry: transportLeg.transportLegDetails?.geometry as GeoJsonExample,
                        properties: {
                            name: 'employee-trip',
                            color: this.themeService.getModeColor(transportLeg.detailedMode),
                        },
                    });
                }
            });
        });

        // set style after adding all features
        mapObject.data.setStyle((feature: google.maps.Data.Feature) => {
            const color: string = feature.getProperty('color') as string;

            if (!color) {
                return {};
            }

            return {
                strokeColor: color,
                strokeWeight: 4,
            };
        });
    }

    public defaultRenderer = (cluster: Cluster, _: ClusterStats) => {
        let sum = 0;
        this.markerClusterer['clusters'].forEach(c => {
            // @ts-expect-error: accessing protected property
            const len = c['markers'].length;
            if (len > 1) {
                sum += len;
            }
        });

        const count: number = cluster.count;
        let index: number = Math.floor(
            cluster.count / (sum / markerClusterIconStyleOptions.length),
        );

        // make sure that we're not loading the default icon if we have all employees in th cluster
        if (index > markerClusterIconStyleOptions.length - 1) {
            index = markerClusterIconStyleOptions.length - 1;
        }

        const container = document.createElement('div');
        container.style.position = 'relative';
        container.style.alignItems = 'center';
        container.style.justifyContent = 'center';
        container.style.transform = 'translateY(25%)';
        container.style.width = `${markerClusterIconStyleOptions[index].width}px`;
        container.style.height = `${markerClusterIconStyleOptions[index].height}px`;

        const iconImg = document.createElement('img');
        iconImg.style.width = '100%';
        iconImg.style.height = '100%';

        iconImg.src = markerClusterIconStyleOptions[index].url;

        // Create a paragraph or any other tag to hold text
        const text = document.createElement('p');
        text.style.position = 'absolute';
        text.style.top = '50%';
        text.style.left = '50%';
        text.style.transform = 'translate(-50%, -50%)';
        text.style.color = 'white';
        text.textContent = `${count}`;

        container.appendChild(iconImg);
        container.appendChild(text);

        // add text as content children to iconImg

        return new google.maps.marker.AdvancedMarkerElement({
            map: count > 0 ? this.googleMap.googleMap : undefined,
            content: container,
            gmpClickable: true,
            zIndex: -99,
            position: cluster.position,
        });
    };

    public mapInitializedCallback($event: google.maps.Map): void {
        this.mapInitialized.emit($event);
    }

    public mapDragStartCallback = () => {
        for (const cluster of this.markerClusterer['clusters']) {
            (cluster.marker as google.maps.marker.AdvancedMarkerElement).zIndex = 9999999999;
        }
    };

    public mapDragEndCallback = () => {
        for (const cluster of this.markerClusterer['clusters']) {
            (cluster.marker as google.maps.marker.AdvancedMarkerElement).zIndex = -99;
        }
    };

    /**
     * Method to init property update subscriptions
     * This is necessary for data for which we don't know then it is ready but the map should be ready to show beforehand
     */
    private initPropertyUpdateSubscriptions(): void {
        this.updateMapOptions$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((options: google.maps.MapOptions) => {
                this.updateMapOptions(options);
            });

        this.updateHeatmapData$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(data => data !== null),
            )
            .subscribe((heatmapData: google.maps.visualization.WeightedLocation[]) => {
                this.updateHeatmapData(heatmapData);
            });

        this.updateClusteredMarkers$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((clusteredMarkerOptions: google.maps.marker.AdvancedMarkerElement[]) => {
                if (this.googleMap.googleMap && this.markerClusterer) {
                    this.updateClusteredMarkers(clusteredMarkerOptions);
                }
            });
    }

    /**
     * Method to init the map
     */
    private initMap() {
        const mapObject: {
            googleMap: google.maps.Map;
            data: google.maps.Data;
        } = this.googleMap as unknown as {
            googleMap: google.maps.Map;
            data: google.maps.Data;
        };
        const googleMapsTimeout = 1500;
        google.maps.event.addListenerOnce(mapObject.googleMap, 'idle', () => {
            setTimeout(() => {
                Array.from(document.getElementsByClassName('gm-style-cc'))
                    .concat(Array.from(document.getElementsByClassName('gm-bundled-control')))
                    .concat(Array.from(document.getElementsByClassName('gm-control-active')))
                    .forEach((c: Element) => {
                        c.setAttribute('data-html2canvas-ignore', '');
                    });
            }, googleMapsTimeout);
        });

        mapObject.googleMap.setOptions(defaultMapOptions as google.maps.MapOptions);

        if (this.boundingBox != null) {
            mapObject.data.addGeoJson({
                type: 'Feature',
                geometry: this.boundingBox,
                properties: {
                    name: 'Bounding Box',
                },
            });
            mapObject.data.setStyle({
                fillOpacity: 0.0,
                strokeColor: '#11548B',
            });
        }
    }

    /**
     * Method to init several map layers
     */
    private initMapLayers(): void {
        this.buildHeatmapLayer();
        this.buildMarkerClusterLayer();
    }

    /**
     * Method to build the initial heatmap layer
     */
    private buildHeatmapLayer(): void {
        this.heatmapLayer = new google.maps.visualization.HeatmapLayer(defaultHeatmapLayerOptions);
        this.heatmapLayer.setMap(
            (this.googleMap as unknown as { googleMap: google.maps.Map }).googleMap,
        );
    }

    private modalSplitRenderer = (cluster: Cluster, _: ClusterStats) => {
        const sizes = this.clusterChartCreatorService.sizes;

        const count: number = cluster.count;

        let index: number = Math.floor(
            cluster.count / (this.markerToCluster.length / sizes.length),
        );

        // make sure that we're not loading the default icon if we have all employees in th cluster
        if (index > sizes.length - 1) {
            index = sizes.length - 1;
        }

        const svg: string = this.clusterChartCreatorService.createModalSplitChart(
            cluster as unknown as EmployeeMarkerCluster,
            this.useModalSplitRenderer,
            this.useZipCodeClusterAlgorithm,
            index,
        );

        const parser = new DOMParser();

        const pinSvgElement = parser.parseFromString(svg, 'image/svg+xml').documentElement;

        const size = sizes[index] * (this.useZipCodeClusterAlgorithm ? 1.5 : 1);

        const container = document.createElement('div');
        container.style.position = 'relative';
        container.style.alignItems = 'center';
        container.style.justifyContent = 'center';
        container.style.transform = 'translateY(25%)';
        container.style.width = `${size}px`;
        container.style.height = `${size}px`;

        pinSvgElement.style.width = '100%';
        pinSvgElement.style.height = '100%';
        container.appendChild(pinSvgElement);

        return new google.maps.marker.AdvancedMarkerElement({
            content: container,
            map: count > 0 ? this.googleMap.googleMap : undefined,
            gmpClickable: !this.useZipCodeClusterAlgorithm,
            position: cluster.position,
            zIndex: -99,
        });
    };

    /**
     * Method to build the initial marker cluster layer
     */
    private buildMarkerClusterLayer(): void {
        this.markerClusterer = new MarkerClusterer({
            map: (this.googleMap as unknown as { googleMap: google.maps.Map }).googleMap,
            // @ts-expect-error ZipCodeClusterAlgorithm typing does not match (because markercluster doesn't have a generic type for makers)
            algorithm: this.useZipCodeClusterAlgorithm
                ? new ZipCodeClusterAlgorithm({})
                : new SuperClusterViewportAlgorithm({}),
            renderer: {
                render:
                    this.useModalSplitRenderer || this.useZipCodeClusterAlgorithm
                        ? this.modalSplitRenderer
                        : this.defaultRenderer,
            },
        });
    }

    /**
     * Method to update map options
     * @param mapOptions
     */
    private updateMapOptions(mapOptions: google.maps.MapOptions): void {
        (this.googleMap as unknown as { googleMap: google.maps.Map }).googleMap.setOptions(
            mapOptions,
        );
    }

    /**
     * Method to update the heatmap data
     * @param heatmapData
     */
    private updateHeatmapData(heatmapData: google.maps.visualization.WeightedLocation[]): void {
        this.heatmapLayer.setData(heatmapData);
    }

    /**
     * Method to update clustered markers
     * This method looks at the employee id stored in the new clustered markers and compares it to the old ones
     * @param newClusteredMarkers
     */
    private updateClusteredMarkers(
        newClusteredMarkers: google.maps.marker.AdvancedMarkerElement[],
    ): void {
        let changes = false;
        // @ts-expect-error markers is protected
        if (this.markerClusterer.markers.length > 0) {
            this.markerClusterer.clearMarkers();
            // @ts-expect-error: SuperClusterViewportAlgorithm typing does not accept null as a value
            (this.markerClusterer['algorithm'] as SuperClusterViewportAlgorithm)['markers'] = null;
            changes = true;
        }

        if (newClusteredMarkers.length > 0) {
            this.markerClusterer.addMarkers(newClusteredMarkers);
            changes = true;
        }
        if (this.googleMap.googleMap && changes) {
            this.markerClusterer.render();
        }
        this.markerClustererUpdate.emit();
    }
}
