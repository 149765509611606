import { DecimalPipe, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    LOCALE_ID,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

import { NumberUnit, getMeasure, getNumberAbbreviation, getSwitchedValue } from '@shared/utils';
import { UtilsService } from '@upscore-mobility-audit/shared/services/utils.service';

@Component({
    selector: 'kpi-measure',
    standalone: true,
    templateUrl: './kpi-measure.component.html',
    styleUrls: ['./kpi-measure.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass],
})
export class KpiMeasureComponent implements OnChanges {
    @Input() public value!: number;

    @Input() public unit!: NumberUnit | string;

    @Input() public valueCssClass = '';

    @Input() public measureCssClass = '';

    @Input() public digitsInfo?: string;

    @Input() public showChangePrefix?: boolean = false;

    public kpiMeasures: string[] = [];

    constructor(
        private readonly decimalPipe: DecimalPipe,
        private readonly utilsService: UtilsService,
        @Inject(LOCALE_ID) public locale: string,
    ) {}

    /**
     * Angular lifecycle hook
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['value']) {
            this.calculateValues();
        }
    }

    /**
     * Method to calculate the values
     * Values are provided in the following way
     * Weight --> tons
     * Money --> €
     * Time --> min
     * Distance --> km
     */
    private calculateValues(): void {
        const unit = this.unit as NumberUnit;

        if (this.value === null) {
            return;
        }

        // previous pipes might transform value to string --> make sure its a number
        this.value = +this.value;

        this.digitsInfo = this.digitsInfo ? this.digitsInfo : '1.0-2';

        if (
            unit === NumberUnit.DISTANCE ||
            unit === NumberUnit.WEIGHT ||
            unit === NumberUnit.MONEY
        ) {
            const updatedValue: number = getSwitchedValue(this.value, unit);
            const measure: string = getMeasure(this.value, unit);
            const numberAbbr: string = getNumberAbbreviation(this.value);

            const numberPrefix: string = this.value >= 0 && this.showChangePrefix ? '+' : '';

            this.kpiMeasures = [
                `${numberPrefix}${this.decimalPipe.transform(updatedValue, this.digitsInfo)}${numberAbbr}`,
                measure,
            ];
        } else if (unit === NumberUnit.TIME) {
            this.value = Math.round(+this.value);

            const { days, hours, minutes }: { days: number; hours: number; minutes: number } =
                this.utilsService.convertTime(this.value);
            this.kpiMeasures = [];

            let numberPrefix = '';

            if (this.showChangePrefix && (days > 0 || hours > 0 || minutes > 0)) {
                numberPrefix = '+';
            }

            if (days > 0) {
                this.kpiMeasures.push(`${this.decimalPipe.transform(days, '1.0-0')}`, 'd');
            }
            if (hours > 0) {
                this.kpiMeasures.push(`${this.decimalPipe.transform(hours, '1.0-0')}`, 'h');
            }
            if (minutes > 0 || (days === 0 && hours === 0 && minutes === 0)) {
                this.kpiMeasures.push(`${this.decimalPipe.transform(minutes, '1.0-0')}`, 'min');
            }

            if (this.kpiMeasures.length > 0) {
                this.kpiMeasures[0] = `${numberPrefix}${this.kpiMeasures[0]}`;
            }
        } else if (unit === NumberUnit.PERCENTAGE) {
            const updatedValue: number = getSwitchedValue(this.value, unit);
            const measure: string = getMeasure(this.value, unit);
            const numberPrefix: string = this.value >= 0 && this.showChangePrefix ? '+' : '';
            const numberAbbr: string = getNumberAbbreviation(this.value);
            this.kpiMeasures = [
                `${numberPrefix}${this.decimalPipe.transform(
                    updatedValue,
                    this.digitsInfo,
                )}${numberAbbr}`,
                measure,
            ];
        } else {
            const updatedValue: number = getSwitchedValue(this.value, unit);
            const numberAbbr: string = getNumberAbbreviation(this.value);
            const numberPrefix: string = this.value >= 0 && this.showChangePrefix ? '+' : '';

            this.kpiMeasures = [
                `${numberPrefix}${this.decimalPipe.transform(updatedValue, this.digitsInfo)}${numberAbbr}`,
                unit,
            ];
        }
    }
}
