import { Injector, WritableSignal, computed, runInInjectionContext, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { differenceWith, isEqual } from 'lodash-es';
import { Observable, debounceTime } from 'rxjs';

import { ModeString } from '@shared/map';
import { ThemeService } from '@shared/utils';
import { defaultCriteriaEstimates } from '@upscore-mobility-audit/data-collection/functions/default-criteria-estimates.function';
import { CriteriaConfig } from '@upscore-mobility-audit/data-collection/interfaces/criteria-config.interface';
import { CriteriaOperatorsConfig } from '@upscore-mobility-audit/data-collection/interfaces/criteria-operators-config.interface';
import { CriteriaOperatorTranslations } from '@upscore-mobility-audit/data-collection/translations/criteria-operator.translations';
import { ModeCriteriaTranslations } from '@upscore-mobility-audit/data-collection/translations/mode-criteria-translations';
import { modeToSliderColorClass } from '@upscore-mobility-audit/shared-ui/functions/mode-to-slider-color-class';

import { CriteriaMetricTypeEnum } from '../enums/criteria-metric-type.enum';
import { CriteriaTypeEnum } from '../enums/criteria-type.enum';
import {
    criteriaListToRequestCriteria,
    criteriaListToSimpleCriteriaList,
    plainCriteriaToCriteria,
} from '../functions/criteria-utils';

import { Criteria, Modes } from './criteria-classes';
import { PlainCriteria } from './plain-criteria.interface';

type ModeConfig = {
    mode: Exclude<ModeString, undefined>;
    criteriaMode?: Modes;
    enabled: boolean;
};

export type SignalBasedCriteriaConfig = {
    [type in Modes]: WritableSignal<Criteria>[];
};

export class CriteriaManager {
    public criteriaOperatorConfig = signal({
        [Modes.Walk]: CriteriaTypeEnum.AndCriteria,
        [Modes.Bike]: CriteriaTypeEnum.AndCriteria,
        [Modes.PublicTransport]: CriteriaTypeEnum.AndCriteria,
        [Modes.Car]: CriteriaTypeEnum.AndCriteria,
    } as CriteriaOperatorsConfig);

    public selectedModeId = signal(0);

    public modes = signal<ModeConfig[]>(
        [
            {
                mode: 'WALK',
                criteriaMode: Modes.Walk,
                enabled: false,
            },
            {
                mode: 'BIKE',
                criteriaMode: Modes.Bike,
                enabled: false,
            },
            {
                mode: 'PUBLIC_TRANSPORT',
                criteriaMode: Modes.PublicTransport,
                enabled: false,
            },
            {
                mode: 'CAR_DRIVER',
                criteriaMode: Modes.Car,
                enabled: false,
            },
            {
                mode: 'CAR_PASSENGER',
                criteriaMode: undefined,
                enabled: false,
            },
        ]
            .filter(m => !this.excludedModes.includes(m.mode as ModeString))
            .map(c => ({ ...c }) as ModeConfig),
    );

    public operators: string[] = [
        CriteriaOperatorTranslations.and,
        CriteriaOperatorTranslations.or,
    ];

    public selectedMode = computed(() => {
        return this.modes()[this.selectedModeId()];
    });
    public selectedModeName = computed(() => {
        switch (this.selectedMode().mode) {
            case 'WALK':
                return ModeCriteriaTranslations.walk;
            case 'BIKE':
                return ModeCriteriaTranslations.bike;
            case 'PUBLIC_TRANSPORT':
                return ModeCriteriaTranslations.pt;
            case 'CAR_DRIVER':
                return ModeCriteriaTranslations.car;
            case 'CAR_PASSENGER':
                return ModeCriteriaTranslations.carPassenger;
            default:
                throw new Error('Mode not found');
        }
    });

    public selectedColorClass = computed(() => modeToSliderColorClass(this.selectedMode().mode));
    public selectedColor = computed(() =>
        this.themeService.getModeColor(this.selectedMode().mode as string),
    );
    public enabledModes = computed(() => this.modes().filter(m => m.enabled));
    public disabledModes = computed(() => this.modes().filter(m => !m.enabled));

    public selectedCriterias = computed(() => {
        const selectedCriteriaMode = this.selectedMode().criteriaMode;
        if (selectedCriteriaMode === undefined) {
            return [];
        }

        return this.criteriaConfig()[selectedCriteriaMode];
    });
    public selectedOperator = computed(() => {
        const selectedCriteriaMode = this.selectedMode().criteriaMode;
        if (selectedCriteriaMode === undefined) {
            return CriteriaTypeEnum.AndCriteria;
        }

        return this.criteriaOperatorConfig()[selectedCriteriaMode];
    });
    public possibleCriteriasToSelect = computed(() => {
        const modeKey = this.selectedMode().criteriaMode;
        const selectedCriteria = this.selectedCriterias().map(it => it());

        return differenceWith(
            this.allSelectableCriteria,
            criteriaListToSimpleCriteriaList(selectedCriteria),
            isEqual,
        ).filter(criteria => {
            if (!modeKey) {
                return false;
            }
            // if there is already a NumberOfChangesCriteria, don't allow another one
            if (
                criteria.type == CriteriaTypeEnum.NumberOfChangesCriteria &&
                selectedCriteria.some(
                    otherCriteria => otherCriteria.type == CriteriaTypeEnum.NumberOfChangesCriteria,
                )
            ) {
                return false;
            }
            // don't show criterias that compare to the mode itself
            if (criteria.toCompare === modeKey) {
                return false;
            }

            // check for criteria restricted to some modes (i.e. number of changes for PT)
            return criteria.restrictedTo == undefined || criteria.restrictedTo.includes(modeKey);
        });
    });

    /**
     * A (computed) signal server-style request criteria that get updated whenever a single criteria or the operators change.
     */
    public requestCriteria = computed(() => {
        return criteriaListToRequestCriteria(
            criteriaConfigFromSignals(this.criteriaConfig()),
            this.criteriaOperatorConfig(),
            this.enabledModes()
                .map(it => it.criteriaMode)
                .filter(it => it !== undefined) as Modes[],
        );
    });

    /**
     * A (computed) signal of the whole criteria configuration (without operators) that gets updated whenever a single criteria changes.
     */
    public currentCriteriaConfig = computed(() => {
        return criteriaConfigFromSignals(this.criteriaConfig());
    });

    public currentCriteriaConfigDebounced$: Observable<CriteriaConfig>;
    public requestCriteriaDebounced$: Observable<unknown>;

    /**
     * List of all criteria (including variations for different modes to compare)
     * that could be selected, excluding `Always` and `Never` Criteria
     */
    private allSelectableCriteria: PlainCriteria[] = []; // without always or never

    constructor(
        private readonly themeService: ThemeService,
        injector: Injector,
        private readonly excludedModes: ModeString[] = [],
        private readonly criteriaConfig: WritableSignal<SignalBasedCriteriaConfig> = signal<SignalBasedCriteriaConfig>(
            criteriaConfigToSignals(defaultCriteriaEstimates()),
        ),
        debounceTimeMs = 300,
    ) {
        this.setupAllPossibleCriteria();
        runInInjectionContext(injector, () => {
            this.currentCriteriaConfigDebounced$ = toObservable(this.currentCriteriaConfig).pipe(
                debounceTime(debounceTimeMs),
            );
            this.requestCriteriaDebounced$ = toObservable(this.requestCriteria).pipe(
                debounceTime(debounceTimeMs),
            );
        });
    }

    public disableMode(mode: Modes | undefined): void {
        const toExclude = this.modes().find(v => v.criteriaMode === mode);
        if (toExclude) {
            toExclude.enabled = false;
            this.modes.set([...this.modes()]);

            if (toExclude === this.selectedMode()) {
                this.selectedModeId.set(this.modes().findIndex(v => v.enabled));
            }
        }
    }

    public enableMode(mode: Modes | undefined) {
        const toInclude = this.modes().find(v => v.criteriaMode === mode);
        if (toInclude) {
            toInclude.enabled = true;
            this.modes.set([...this.modes()]);
            this.selectedModeId.set(this.modes().findIndex(v => v == toInclude));
        }
    }

    public changeOperator(operator: string) {
        let newOperator: CriteriaTypeEnum | undefined = undefined;
        switch (operator) {
            case CriteriaOperatorTranslations.and:
                newOperator = CriteriaTypeEnum.AndCriteria;
                break;
            case CriteriaOperatorTranslations.or:
                newOperator = CriteriaTypeEnum.OrCriteria;
                break;
        }
        this.criteriaOperatorConfig.set({
            ...this.criteriaOperatorConfig(),
            [this.selectedMode().criteriaMode as Modes]: newOperator,
        });
    }

    public selectMode(mode: Modes | undefined): void {
        const modeIdx = this.modes().findIndex(v => v.criteriaMode === mode);
        if (modeIdx > -1) {
            this.selectedModeId.set(modeIdx);
        }
    }

    public addCriteria(newPlainCriteria: PlainCriteria): void {
        const mode = this.selectedMode().criteriaMode;
        if (mode != null) {
            const newCriteria = plainCriteriaToCriteria(newPlainCriteria);
            const removeOtherCriteria =
                newCriteria.type === CriteriaTypeEnum.Always ||
                newCriteria.type === CriteriaTypeEnum.Never;
            this.criteriaConfig.set({
                ...this.criteriaConfig(),
                [mode]: removeOtherCriteria
                    ? [signal(newCriteria)]
                    : [...this.selectedCriterias(), signal(newCriteria)],
            });
        }
    }

    public deleteCriteria(index: number): void {
        const mode = this.selectedMode().criteriaMode;

        if (mode != undefined) {
            this.selectedCriterias().splice(index, 1);
            this.criteriaConfig.update(criteriaConfig => ({
                ...criteriaConfig,
                [mode]: [...this.selectedCriterias()],
            }));
        }
    }

    public setCriteriaConfig(config: CriteriaConfig) {
        this.criteriaConfig.set(criteriaConfigToSignals(config));
    }

    private setupAllPossibleCriteria() {
        const _possibleCriteria: PlainCriteria[] = [];
        for (const item of Object.values(CriteriaMetricTypeEnum)) {
            let criteria: PlainCriteria = {
                type: CriteriaTypeEnum.CutoffCriteria,
                metric: { type: item },
                // @ts-expect-error: null is needed here in place of undefined!
                toCompare: null,
            };
            _possibleCriteria.push(criteria);
            for (const mode of Object.values(Modes)) {
                criteria = {
                    type: CriteriaTypeEnum.AbsoluteDiffCutoffCriteria,
                    metric: { type: item },
                    toCompare: mode,
                };
                _possibleCriteria.push(criteria);
                criteria = {
                    type: CriteriaTypeEnum.RelativeDiffCutoffCriteria,
                    metric: { type: item },
                    toCompare: mode,
                };
                _possibleCriteria.push(criteria);
            }
        }

        _possibleCriteria.push({
            type: CriteriaTypeEnum.NumberOfChangesCriteria,
            restrictedTo: [Modes.PublicTransport],
        });

        this.allSelectableCriteria = _possibleCriteria;
    }
}

export function criteriaConfigToSignals(config: CriteriaConfig) {
    const signals: SignalBasedCriteriaConfig = { ...config } as any as SignalBasedCriteriaConfig;
    for (const mode in config) {
        signals[mode as Modes] = config[mode as Modes].map(c => signal(c));
    }

    return signals;
}

export function criteriaConfigFromSignals(config: SignalBasedCriteriaConfig) {
    const criteriaConfig = { ...config } as any as CriteriaConfig;
    for (const mode in config) {
        criteriaConfig[mode as Modes] = config[mode as Modes].map(c => c());
    }

    return criteriaConfig;
}
