import { Injectable, computed, signal } from '@angular/core';

import { EmployeeIconOptions } from '@shared/map';
import { FeatureFlag } from '@shared/utils';
import { CompanyLocation } from '@upscore-mobility-audit/api';
import { SliderValues } from '@upscore-mobility-audit/core/components/step/sidebar-step.component';
import { getSidebarMapOptions } from '@upscore-mobility-audit/core/constants/sidebar-map-options.constant';
import { CacheResetBase } from '@upscore-mobility-audit/core/data-services/cache-reset-base.service';
import { UnsafeCompanyLocationDataService } from '@upscore-mobility-audit/core/data-services/unsafe-company-location-data.service';
import { MapType } from '@upscore-mobility-audit/core/enums/map-type.enum';
import { ReachabilityOptions } from '@upscore-mobility-audit/core/interfaces/reachability-options.interface';
import { SidebarMapOptions } from '@upscore-mobility-audit/core/interfaces/sidebar-map-options.interface';
import { ClusterOptions } from '@upscore-mobility-audit/map/interfaces/cluster-options.interface';
import { UpscoreMobilityAuditUserDataService } from '@upscore-mobility-audit/shared/api-services/user-data.service';
import {
    bikeReachabilityMin,
    carReachabilityMin,
    ptReachabilityMin,
    walkReachabilityMin,
} from '@upscore-mobility-audit/shared/constants/reachability-defaults';
import { SidebarSectionContentLabel } from '@upscore-mobility-audit/shared/enums/sidebar-section-content-label';

@Injectable({
    providedIn: 'root',
})
export class SidebarDataService extends CacheResetBase {
    // should we extend with reset Base and reset data if company changes?
    // the reset is currently done in main-page.component.ts

    public sidebarOptions: { [id in MapType]: SidebarMapOptions } = getSidebarMapOptions();

    public get currentStepIndex() {
        return this._currentStepIndex.asReadonly();
    }

    public get iconOptions() {
        return this._iconOptions.asReadonly();
    }

    public get reachabilityOptions() {
        return this._reachabilityOptions.asReadonly();
    }

    public get showCarpooling() {
        return this._showCarpooling.asReadonly();
    }

    public get showHeatMap() {
        return this._showHeatMap.asReadonly();
    }

    public get showEmployees() {
        return this._showEmployees.asReadonly();
    }

    public get clusterEmployees() {
        return this._clusterEmployees.asReadonly();
    }

    public get clusterOptions() {
        return this._clusterOptions.asReadonly();
    }

    public get selectedTabIndex() {
        return this._selectedTabIndex.asReadonly();
    }

    public get sections() {
        return this._sections.asReadonly();
    }

    public type = computed(() => this.sidebarOptions[this._currentStepIndex()].type);

    public get currentSidebarOptions() {
        return this.sidebarOptions[this._currentStepIndex()];
    }

    private _currentStepIndex = signal(MapType.CURRENT);
    private _iconOptions = signal(this.currentSidebarOptions.iconOptions);
    private _reachabilityOptions = signal(this.currentSidebarOptions.reachabilityOptions);
    private _showCarpooling = signal(this.currentSidebarOptions.showCarpooling);
    private _showHeatMap = signal(this.currentSidebarOptions.showHeatMap);
    private _showEmployees = signal(this.currentSidebarOptions.showEmployees);
    private _clusterEmployees = signal(this.currentSidebarOptions.clusterEmployees);
    private _clusterOptions = signal(this.currentSidebarOptions.clusterOptions);
    private _selectedTabIndex = signal(this.currentSidebarOptions.selectedTabIndex);
    private _sections = signal(this.currentSidebarOptions.sections);

    constructor(
        private userDataService: UpscoreMobilityAuditUserDataService,
        private unsafeCompanyLocationDataService: UnsafeCompanyLocationDataService,
    ) {
        super();
        this.companyLocationChanged();
    }

    public setCurrentStepIndex(index: MapType) {
        this._currentStepIndex.set(index);
        this._iconOptions.set(this.sidebarOptions[index].iconOptions);
        this._reachabilityOptions.set(this.sidebarOptions[index].reachabilityOptions);
        this._showCarpooling.set(this.sidebarOptions[index].showCarpooling);
        this._showHeatMap.set(this.sidebarOptions[index].showHeatMap);
        this._showEmployees.set(this.sidebarOptions[index].showEmployees);
        this._clusterEmployees.set(this.sidebarOptions[index].clusterEmployees);
        this._clusterOptions.set(this.sidebarOptions[index].clusterOptions);
        this._selectedTabIndex.set(this.sidebarOptions[index].selectedTabIndex);
        this._sections.set(this.sidebarOptions[index].sections);
    }

    public setIconOptions(iconOptions: EmployeeIconOptions) {
        this.currentSidebarOptions.iconOptions = iconOptions;
        this._iconOptions.set(iconOptions);
    }

    public setReachabilityOptions(reachabilityOptions: ReachabilityOptions) {
        this.currentSidebarOptions.reachabilityOptions = reachabilityOptions;
        this._reachabilityOptions.set(reachabilityOptions);
    }

    public setShowCarpooling(showCarpooling: boolean) {
        this.currentSidebarOptions.showCarpooling = showCarpooling;
        this._showCarpooling.set(showCarpooling);
    }

    public setShowHeatMap(showHeatMap: boolean) {
        this.currentSidebarOptions.showHeatMap = showHeatMap;
        this._showHeatMap.set(showHeatMap);
    }

    public setShowEmployees(showEmployees: boolean) {
        this.currentSidebarOptions.showEmployees = showEmployees;
        this._showEmployees.set(showEmployees);
    }

    public setClusterEmployees(clusterEmployees: boolean) {
        this.currentSidebarOptions.clusterEmployees = clusterEmployees;
        this._clusterEmployees.set(clusterEmployees);
    }

    public setClusterOptions(clusterOptions: ClusterOptions) {
        this.currentSidebarOptions.clusterOptions = clusterOptions;
        this._clusterOptions.set(clusterOptions);
    }

    public setSelectedTabIndex(index: number) {
        this.currentSidebarOptions.selectedTabIndex = index;
        this._selectedTabIndex.set(index);
    }

    public getMaxSliderValues(companyLocation: CompanyLocation) {
        // @ts-expect-error TODO remove this line after backend changes
        let [walkMax, bikeMax, ptMax, carMax]: number[] = companyLocation.config.sliderMaxValues;

        const uiConfig: { [id: string]: any } =
            this.userDataService.user?.features['uiConfig'] ?? {};

        let maxSliderValuesOverwrite: { [type: string]: number } | boolean =
            uiConfig[FeatureFlag.MAX_SLIDER_VALUES];

        if (maxSliderValuesOverwrite != null && maxSliderValuesOverwrite !== false) {
            maxSliderValuesOverwrite = maxSliderValuesOverwrite as { [type: string]: number };
            for (const key of Object.keys(maxSliderValuesOverwrite)) {
                if (
                    maxSliderValuesOverwrite[key] == null ||
                    typeof maxSliderValuesOverwrite[key] !== 'number'
                ) {
                    continue;
                }
                switch (key) {
                    case 'walk':
                        walkMax = maxSliderValuesOverwrite[key];
                        break;
                    case 'bike':
                        bikeMax = maxSliderValuesOverwrite[key];
                        break;
                    case 'publicTransport':
                        ptMax = maxSliderValuesOverwrite[key];
                        break;
                    case 'car':
                        carMax = maxSliderValuesOverwrite[key];
                        break;
                }
            }
        }

        return {
            walk: walkMax,
            bike: bikeMax,
            transit: ptMax,
            car: carMax,
        };
    }

    protected override companyLocationChanged() {
        this.sidebarOptions = getSidebarMapOptions();
        this.setCurrentStepIndex(0);

        const companyLocation = this.unsafeCompanyLocationDataService.companyLocation();
        if (companyLocation != null) {
            this.fixSliderValues(companyLocation, MapType.CURRENT);
            this.fixSliderValues(companyLocation, MapType.PACKAGE);
            // trigger a change in the reachability options
            this.setReachabilityOptions(this.currentSidebarOptions.reachabilityOptions);
        }
    }

    protected override auditClosed() {
        this.sidebarOptions = getSidebarMapOptions();
    }

    /**
     * Method to calculate the initial slider value based on max slider value (40% rounded up to nearest 10 value)
     * @param maxSliderValue
     */
    private calcInitialCatchmentValue(maxSliderValue: number): number {
        const percentage = 0.4;
        const newValue: number = maxSliderValue * percentage;
        const targetRound = 5;

        return Math.ceil(newValue / targetRound) * targetRound;
    }

    /**
     * Sets default values for reachabilityOptions and the slider configuration
     * @param companyLocation
     * @param mapType
     * @private
     */
    private fixSliderValues(companyLocation: CompanyLocation | null, mapType: MapType) {
        if (companyLocation == null) {
            return;
        }

        const { walk, bike, transit, car } = this.getMaxSliderValues(companyLocation);
        const [walkOptimal, bikeOptimal, ptOptimal, carOptimal]: number[] =
            // @ts-expect-error TODO remove this line after backend changes
            companyLocation.config.optimalTravelTimes;

        const calcDefaultValue = (maxValue: number, optimalValue: number) =>
            isPremiumUser || isViewOnlyUser
                ? this.calcInitialCatchmentValue(maxValue)
                : optimalValue;

        const isPremiumUser = this.userDataService.isUserType('PREMIUM');
        const isViewOnlyUser = this.userDataService.isUserType('VIEW_ONLY');

        const carCurrent = calcDefaultValue(car, carOptimal);
        const bikeCurrent = calcDefaultValue(bike, bikeOptimal);
        const walkCurrent = calcDefaultValue(walk, walkOptimal);
        const ptCurrent = calcDefaultValue(transit, ptOptimal);

        const reachabilityOptions = this.sidebarOptions[mapType].reachabilityOptions;
        reachabilityOptions.walk.value = walkCurrent;
        reachabilityOptions.bike.value = bikeCurrent;
        reachabilityOptions.car.value = carCurrent;
        reachabilityOptions.transit.value = ptCurrent;

        this.sidebarOptions[mapType].reachabilityOptions = reachabilityOptions;

        const carSliderValues: SliderValues = {
            min: isPremiumUser || isViewOnlyUser ? carReachabilityMin : carOptimal,
            max: isPremiumUser || isViewOnlyUser ? car : carOptimal,
            current: reachabilityOptions.car.value ?? 0,
        };
        const bikeSliderValues: SliderValues = {
            min: isPremiumUser || isViewOnlyUser ? bikeReachabilityMin : bikeOptimal,
            max: isPremiumUser || isViewOnlyUser ? bike : bikeOptimal,
            current: reachabilityOptions.bike.value ?? 0,
        };
        const walkSliderValues: SliderValues = {
            min: isPremiumUser || isViewOnlyUser ? walkReachabilityMin : walkOptimal,
            max: isPremiumUser || isViewOnlyUser ? walk : walkOptimal,
            current: reachabilityOptions.walk.value ?? 0,
        };

        const ptSliderValues: SliderValues = {
            min: isPremiumUser || isViewOnlyUser ? ptReachabilityMin : ptOptimal,
            max: isPremiumUser || isViewOnlyUser ? transit : ptOptimal,
            current: reachabilityOptions.transit.value ?? 0,
        };

        this.sidebarOptions[mapType].sections.forEach(section => {
            section.contentElements?.forEach(contentElement => {
                let sliderValues: SliderValues | null = null;
                switch (contentElement.label) {
                    case SidebarSectionContentLabel.BIKE_REACHABILITY_IN_MINUTES:
                        sliderValues = bikeSliderValues;
                        break;
                    case SidebarSectionContentLabel.WALK_REACHABILITY_IN_MINUTES:
                        sliderValues = walkSliderValues;
                        break;
                    case SidebarSectionContentLabel.CAR_REACHABILITY_IN_MINUTES:
                        sliderValues = carSliderValues;
                        break;
                    case SidebarSectionContentLabel.PUBLIC_TRANSPORT_REACHABILITY_IN_MINUTES:
                        sliderValues = ptSliderValues;
                        break;
                    default:
                        return;
                }
                if (
                    'min' in contentElement &&
                    'max' in contentElement &&
                    'currentValue' in contentElement
                ) {
                    contentElement.min = sliderValues.min;
                    contentElement.max = sliderValues.max;
                    contentElement.currentValue = sliderValues.current;
                }
            });
        });
    }
}
