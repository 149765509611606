export class EmployeeDetailsTableTranslations {
    public static readonly realIdText: string = $localize`:@@realId:Personnel number`;
    public static readonly addressText: string = $localize`:@@address:Address`;
    public static readonly currentModeText: string = $localize`:@@currentMode:Current Mode`;
    public static readonly optimalModeText: string = $localize`:@@optimalMode:Optimal Mode`;
    public static readonly entranceNoText: string = $localize`:@@locationNo:Location No.`;
    public static readonly daysInOfficeText: string = $localize`:@@daysInOffice:Days In Office`;
    public static readonly hoursPerWeekText: string = $localize`:@@hoursPerWeek:Hours Per Week`;
    public static readonly entryTimeText: string = $localize`:@@entryTime:Entry Time`;
    public static readonly exitTimeText: string = $localize`:@@exitTime:Exit Time`;
    public static readonly fixedModeText: string = $localize`:@@fixedMode:Fixed Mode`;
    public static readonly departmentText: string = $localize`:@@department:Department`;
    public static readonly employeeCountText: string = $localize`:@@employeeCount:Employees`;
    public static readonly locationAccuracyText: string = $localize`:@@locationAccuracy:Location Accuracy`;
    public static readonly rawAccuracyText: string = $localize`:@@rawAccuracy:Raw Accuracy`;
    public static readonly accuracySourceText: string = $localize`:@@accuracySource:Accuracy Source`;
    public static readonly co2EmissionsText = $localize`:@@co2Emissions:CO2 Emissions (g/km)`;
    public static readonly vehicleTypeText = $localize`:@@vehicleType:Vehicle Type`;
    public static readonly vehicleIdText = $localize`:@@vehicleId:Vehicle ID`;
    public static readonly impossibleModesText = $localize`:@@impossibleModes:Impossible Modes`;
    public static readonly secondaryFixedModeText = $localize`:@@secondaryFixedMode:Secondary Fixed Mode`;
}
