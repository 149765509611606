import { Injectable } from '@angular/core';

import { TaskProgress } from '@upscore-mobility-audit/api';

@Injectable({
    providedIn: 'root',
})
export class TaskUtilsService {
    /**
     * @param task
     * @returns array with [name, event]
     * .AuditProgress$PlannedScenarios -> ['AuditProgress', 'PlannedScenarios']
     */
    public getTaskNameAndEvent(task: TaskProgress) {
        const regexp = /^\.(\w+)\$(\w+)$/g;
        const matches = Array.from(task.type.matchAll(regexp));

        if (matches.length === 0) {
            return null;
        }

        // matches[0][0] is the full match, 1, 2 are the sub groups
        return [matches[0][1], matches[0][2]];
    }

    public taskIsRunning(progress: TaskProgress): boolean {
        return (
            progress.status !== 'FINISHED' &&
            progress.status !== 'FAILED' &&
            progress.status !== 'CANCELLED' &&
            progress.status !== 'DELETED'
        );
    }
}
