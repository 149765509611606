import { SliderColorClass } from '@ui-library/enums/slider-color-class.enum';
import { SidebarSectionContentLabel } from '@upscore-mobility-audit/shared/enums/sidebar-section-content-label';
import { SidebarSectionContentType } from '@upscore-mobility-audit/shared/enums/sidebar-section-content-type.enum';
import { SidebarSectionHeaderLabel } from '@upscore-mobility-audit/shared/enums/sidebar-section-header-label.enum';
import { SidebarSectionType } from '@upscore-mobility-audit/shared/enums/sidebar-section-type.enum';
import { SidebarSection } from '@upscore-mobility-audit/shared/interfaces/sidebar-section.interface';

import { sidebarDistanceRadius } from './sidebar-distance-radius.constant';

export function getCatchmentSlidersSection(sidebarType: SidebarSectionType): SidebarSection[] {
    return [
        {
            type: sidebarType,
            header: {
                label: SidebarSectionHeaderLabel.WALK_REACHABILITY,
                isHidden: true,
                isExpandable: true,
                isExpanded: false,
                disabled: false,
            },
            contentElements: [
                {
                    type: SidebarSectionContentType.SLIDER,
                    label: SidebarSectionContentLabel.WALK_REACHABILITY_IN_MINUTES,
                    min: 0,
                    max: 0,
                    currentValue: 0,
                    colorClass: SliderColorClass.WALKING,
                    disabledByDependency: false,
                    info: '',
                },
            ],
        },
        {
            type: sidebarType,
            header: {
                label: SidebarSectionHeaderLabel.BIKE_REACHABILITY,
                isHidden: true,
                isExpandable: true,
                isExpanded: false,
                disabled: false,
            },
            contentElements: [
                {
                    type: SidebarSectionContentType.SLIDER,
                    label: SidebarSectionContentLabel.BIKE_REACHABILITY_IN_MINUTES,
                    min: 0,
                    max: 0,
                    currentValue: 0,
                    colorClass: SliderColorClass.BIKING,
                    disabledByDependency: false,
                    info: '',
                },
            ],
        },
        {
            type: sidebarType,
            header: {
                label: SidebarSectionHeaderLabel.PUBLIC_TRANSPORT_REACHABILITY,
                isHidden: true,
                isExpandable: true,
                isExpanded: false,
                disabled: false,
            },
            contentElements: [
                {
                    type: SidebarSectionContentType.SLIDER,
                    label: SidebarSectionContentLabel.PUBLIC_TRANSPORT_REACHABILITY_IN_MINUTES,
                    min: 0,
                    max: 0,
                    currentValue: 0,
                    colorClass: SliderColorClass.PUBLIC_TRANSPORT,
                    disabledByDependency: false,
                    info: '',
                },
            ],
        },
        {
            type: sidebarType,
            header: {
                label: SidebarSectionHeaderLabel.CAR_REACHABILITY,
                isHidden: true,
                isExpandable: true,
                isExpanded: false,
                disabled: false,
            },
            contentElements: [
                {
                    type: SidebarSectionContentType.SLIDER,
                    label: SidebarSectionContentLabel.CAR_REACHABILITY_IN_MINUTES,
                    min: 0,
                    max: 0,
                    currentValue: 0,
                    colorClass: SliderColorClass.CAR_PASSENGER,
                    disabledByDependency: false,
                    info: '',
                },
                {
                    label: SidebarSectionContentLabel.CARPOOL_ROUTES,
                    isHidden: true,
                    type: SidebarSectionContentType.TOGGLE,
                    disabledByDependency: false,
                },
            ],
        },
        {
            type: sidebarType,
            header: {
                label: SidebarSectionHeaderLabel.RADIUS_REACHABILITY,
                isHidden: true,
                isExpandable: true,
                isExpanded: false,
                disabled: false,
            },
            contentElements: [
                {
                    type: SidebarSectionContentType.SLIDER,
                    label: SidebarSectionContentLabel.REACHABLE_IN_DISTANCE,
                    step: 1,
                    min: 1,
                    max: 100,
                    currentValue: sidebarDistanceRadius,
                    disabledByDependency: false,
                    info: '',
                },
            ],
        },
    ];
}
