export const urbanPresets = {
    maxBikingTime: 30,
    maxWalkingTime: 15,
    maxTransitTime: 60,
    maxCarTime: 45,
};

export const ruralPresets = {
    maxBikingTime: 60,
    maxWalkingTime: 35,
    maxTransitTime: 120,
    maxCarTime: 100,
};
