import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    FormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';

import { AbstractUserDataService, FeatureFlag } from '@shared/utils';
import { CompanyLocation, PackageDefinition, UserInfo } from '@upscore-mobility-audit/api';
import { MeasureFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/measure-form-group.interface';
import { StatsPercentageChange } from '@upscore-mobility-audit/shared/interfaces/stats-percentage-change.interface';
import { DataCollectionService } from '@upscore-mobility-audit/shared/services/data-collection.service';
import { UtilsService } from '@upscore-mobility-audit/shared/services/utils.service';

import { defaultMobilityStatsSliderOptions } from '../../constants/default-mobility-stats-slider-options.constant';
import { defaultVehicleStatsSliderOptions } from '../../constants/default-vehicle-stats-slider-options.constant';
import { ModalSplitType } from '../../enums/modalsplit.enum';
import { AddMeasureDialogTranslations } from '../../translations/add-measure-dialog-translations';
import { percentageChangeValidator } from '../../validators/percentage-change.validator';

@Component({
    selector: 'modal-split-measure',
    templateUrl: './modal-split-measure.component.html',
    styleUrls: ['./modal-split-measure.component.scss'],
})
export class ModalSplitMeasureComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() public parentFormGroup: UntypedFormGroup;
    @Input() public allowSwitchingMeasureType = false;
    @Input() public companyLocation: CompanyLocation;
    @Input() public parentFormControlName: string;

    @Input() public editedPackage?: PackageDefinition;
    @Input() public duplicate = false;
    public readonly translations: typeof AddMeasureDialogTranslations =
        AddMeasureDialogTranslations;
    public measureFormGroup: FormGroup<MeasureFormGroup>;
    public vehicleStatsSliderOptions: StatsPercentageChange[] = this.utilsService.deepClone(
        defaultVehicleStatsSliderOptions,
    );
    public mobilityStatsSliderOptions: StatsPercentageChange[] = this.utilsService.deepClone(
        defaultMobilityStatsSliderOptions,
    );
    public percentageChangeValidatorFn = Validators.compose([
        Validators.required,
        percentageChangeValidator,
    ]) as ValidatorFn;

    public featureFlag: typeof FeatureFlag = FeatureFlag;
    private defaultMeasureType: ModalSplitType = ModalSplitType.MODAL_SPLIT;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly dataCollectionService: DataCollectionService,
        private readonly utilsService: UtilsService,
        public readonly userDataService: AbstractUserDataService<UserInfo>,
    ) {}

    @Input()
    public set modalSplitType(type: ModalSplitType) {
        if (this.measureFormGroup) {
            this.measureFormGroup.controls.modalSplitType.setValue(type);
            this.measureFormGroup.updateValueAndValidity();
        } else {
            this.defaultMeasureType = type;
        }
    }

    /**
     * method to check if the parts are valid
     * @param parts
     */
    public static validateParts(parts: UntypedFormControl): boolean {
        return !(parts.value && parts.value.length === 0);
    }

    public ngOnInit(): void {
        this.initForm();
        this.prefillValues();
    }

    public ngOnDestroy(): void {
        this.parentFormGroup.removeControl(this.parentFormControlName);
    }

    /**
     * Angular Lifecycle
     */
    public ngAfterViewInit(): void {
        this.parentFormGroup.addControl(this.parentFormControlName, this.measureFormGroup);
        this.parentFormGroup.updateValueAndValidity();
    }

    /**
     * prefill values
     */
    public prefillValues(): void {
        this.dataCollectionService.updateStatsSlider(
            this.mobilityStatsSliderOptions,
            this.companyLocation.mobilityStats,
        );
        this.dataCollectionService.updateStatsSlider(
            this.vehicleStatsSliderOptions,
            this.companyLocation.vehicleStats,
        );

        if (
            this.companyLocation.modeEstimationConfig?.modeAssignment != null &&
            this.allowSwitchingMeasureType
        ) {
            this.measureFormGroup.controls.modalSplitType.setValue(ModalSplitType.MODE_CALCULATION);
        }

        if (!this.editedPackage) {
            this.measureFormGroup.addControl(
                'modeEstimationConfig',
                this.utilsService.modeEstimationConfigToFormGroup(
                    this.companyLocation.modeEstimationConfig,
                ),
            );

            return;
        }

        this.measureFormGroup.controls.title.setValue(this.editedPackage.title);
        this.measureFormGroup.controls.overrideFixedMode.setValue(
            this.editedPackage.overrideFixedModes ?? false,
        );
        this.measureFormGroup.controls.isImprovementOnly.setValue(
            this.editedPackage.isImprovementOnly ?? false,
        );
        this.measureFormGroup.controls.presenceDays.setValue(
            this.editedPackage.presenceDays ?? undefined,
        );
        this.measureFormGroup.addControl(
            'modeEstimationConfig',
            this.utilsService.modeEstimationConfigToFormGroup(
                this.editedPackage.modeEstimationConfig,
            ),
        );
        this.measureFormGroup.controls.modalSplitType.setValue(
            this.editedPackage.modeEstimationConfig?.modeAssignment != null
                ? ModalSplitType.MODE_CALCULATION
                : ModalSplitType.MODAL_SPLIT,
        );

        this.dataCollectionService.updateStatsSlider(
            this.mobilityStatsSliderOptions,
            // TODO shouldnt be optional
            this.editedPackage.modalSplit!,
        );
        this.dataCollectionService.updateStatsSlider(
            this.vehicleStatsSliderOptions,
            // TODO shouldnt be optional
            this.editedPackage.vehicleStats!,
        );
    }

    /**
     * init form
     */
    public initForm(): void {
        this.measureFormGroup = this.formBuilder.group({
            title: ['', Validators.required],
            parts: [[], ModalSplitMeasureComponent.validateParts],
            presenceDays: [null, Validators.compose([Validators.min(1), Validators.max(5)])],
            vehicleStats: [],
            mobilityStats: [{}],
            modalSplitType: [this.defaultMeasureType],
            overrideFixedMode: [false],
            isImprovementOnly: [false],
            detailedTripType: 'ENTRY_EXIT_WITH_UNDEFINED',
        });
    }
}
