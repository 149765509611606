<kpi-card title="" [showMenu]="false">
    <div body>
        <mat-form-field>
            <input
                (change)="cardChanged.emit([heading, text])"
                [(ngModel)]="heading"
                class="heading"
                matInput
                type="text" />
            <mat-label i18n="@@heading">Heading</mat-label>
        </mat-form-field>
        <mat-form-field>
            <input
                (change)="cardChanged.emit([heading, text])"
                [(ngModel)]="text"
                class="text"
                matInput
                type="text" />
            <mat-label i18n="@@text">Text</mat-label>
        </mat-form-field>
    </div>
</kpi-card>
