import { Injectable, signal } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';

import { EmployeesDataService } from '@upscore-mobility-audit/core/data-services/employees-data.service';
import { ResetService } from '@upscore-mobility-audit/core/data-services/reset.service';
import { EmployeesWrapperService } from '@upscore-mobility-audit/shared/api-services/employees-wrapper.service';
import { GeocodingData } from '@upscore-mobility-audit/shared/interfaces/geocoding-data.interface';

@Injectable({
    providedIn: 'root',
})
export class GeocodingEmployeeDataService {
    private geocodingDataSubject = signal<GeocodingData>({
        geocodedEmployeeData: [],
        aggregatedGeocodingData: [],
    });

    private dataExpired = true;
    private employeeChangeSubscription?: Subscription;

    constructor(
        private resetService: ResetService,
        private employeesDataService: EmployeesDataService,
        private employeesWrapperService: EmployeesWrapperService,
    ) {
        this.resetService.auditClosed$.subscribe(() => {
            this.employeeChangeSubscription?.unsubscribe();
            this.employeeChangeSubscription = undefined;
        });
    }

    public loadGeocodingData(locationId: number) {
        this.dataExpired = false;
        combineLatest([
            this.employeesWrapperService.getDetailedEstimationQuality(locationId),
            this.employeesWrapperService.getAggregatedEstimationQuality(locationId),
        ]).subscribe({
            next: ([geocodedEmployeeData, aggregatedGeocodingData]) => {
                this.geocodingDataSubject.set({
                    geocodedEmployeeData,
                    aggregatedGeocodingData,
                });
            },
            error: () => {
                this.dataExpired = true;
            },
        });
    }

    public getGeocodingData(locationId: number) {
        if (this.dataExpired) {
            // either here or in the constructor subscription
            this.geocodingDataSubject.set({
                geocodedEmployeeData: [],
                aggregatedGeocodingData: [],
            });

            // reset data expired flag when employees data changes
            // these also changes if we change company location
            if (this.employeeChangeSubscription === undefined) {
                this.employeeChangeSubscription = this.employeesDataService.allEmployees$.subscribe(
                    () => {
                        this.dataExpired = true;
                        this.loadGeocodingData(locationId);
                    },
                );
            } else {
                this.loadGeocodingData(locationId);
            }
        }

        return this.geocodingDataSubject.asReadonly();
    }
}
