import { Component, Input } from '@angular/core';

@Component({
    selector: 'selected-file',
    templateUrl: './selected-file.component.html',
    styleUrls: ['./selected-file.component.scss'],
})
export class SelectedFileComponent {
    @Input() public filename: string;
}
