import { AbstractControl } from '@angular/forms';

// google places change validator
export const googlePlacesChangeValidator = (control: AbstractControl) => {
    if (
        control &&
        ((control.value && typeof control.value === 'object' && 'place_id' in control.value) ||
            control.value === '')
    ) {
        return null;
    }

    return { placeChange: true };
};
