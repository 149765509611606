<kpi-card [showMenu]="hasMenu" [title]="title">
    <!-- body -->
    <div body class="wrapper">
        <kpi-change-header
            [invertColorScheme]="reversePercentageDisplay"
            [baselineYearly]="baseline"
            [currentYearly]="current"
            [unit]="unit"></kpi-change-header>

        <span class="optimum-text">{{ text }}</span>
    </div>
</kpi-card>
