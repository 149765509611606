import { Component, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { LoadingService } from '@shared/utils';
import { ChartJsService } from '@upscore-mobility-audit/shared/services/chart-js.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public loading = toSignal(this.loadingService.loading(), {
        requireSync: true,
    });
    public loadingText = toSignal(this.loadingService.loadingText, {
        requireSync: true,
    });
    public mapsSDKLoaded = signal(false);

    // LanguageSwitcherService needs to be loaded here
    constructor(
        public loadingService: LoadingService,
        private readonly chartJsService: ChartJsService,
    ) {
        this.chartJsService.init();

        // This fixes a map export issue where the canvas is empty
        const originalGetContext = HTMLCanvasElement.prototype.getContext;
        // @ts-expect-error - monkey patching type mismatch
        HTMLCanvasElement.prototype.getContext = function (type, attributes) {
            if (type === 'webgl' || type === 'webgl2') {
                attributes = attributes || {};
                attributes.preserveDrawingBuffer = true;
            }

            // Call the original getContext method with the modified attributes
            return originalGetContext.call(this, type, attributes);
        };
    }

    /**
     * Angular lifecycle
     * @method ngOnInit
     */
    public ngOnInit(): void {
        // for now we load the maps SDK here
        // thats currently a lot easier than loading it in the map component
        // as the code breaks quite easily if not handled correctly
        // if the map-component is more refined we can move it there
        Promise.allSettled([
            google.maps.importLibrary('core'),
            google.maps.importLibrary('maps'),
            google.maps.importLibrary('visualization'),
            google.maps.importLibrary('places'),
            google.maps.importLibrary('marker'),
        ])
            .then(result => {
                this.mapsSDKLoaded.set(true);
            })
            .catch(error => {
                console.error('Error loading maps SDK', error);
            });
    }
}
