import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { NotifyUserComponent } from '../dialogs/notify-user/notify-user.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private readonly dialog: MatDialog) {}

    public openNotifyUserDialog(taskId: string): Observable<void> {
        const dialogRef: MatDialogRef<NotifyUserComponent> = this.dialog.open(NotifyUserComponent, {
            data: taskId,
        });

        return dialogRef.afterClosed();
    }
}
