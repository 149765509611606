import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { captureException, captureMessage, showReportDialog } from '@sentry/angular-ivy';

import { AbstractUserDataService } from '@shared/utils';
import { UserInfo } from '@upscore-mobility-audit/api';
import { FeedbackDialogTranslations } from '@upscore-mobility-audit/shared/translations/feedback-dialog-translations';

@Injectable({
    providedIn: 'root',
})
export class SentryReportService {
    public readonly translations: typeof FeedbackDialogTranslations = FeedbackDialogTranslations;

    constructor(private readonly userDataService: AbstractUserDataService<UserInfo>) {}

    /**
     * This function reports normal Errors or objects to Sentry
     * @param error
     * @param extra
     */
    public reportError(
        error: HttpErrorResponse | string | { [data: string]: unknown } | Error,
        extra: { [data: string]: unknown } = {},
    ): string {
        let exception: unknown = error;

        if (exception instanceof HttpErrorResponse) {
            return captureException(exception);
        } else if (typeof error === 'string' || error instanceof String) {
            return captureException(new Error(error as string));
        } else if ('code' in error) {
            exception = new Error(error['code'] as string);
            extra = error;
        } else if (!(error instanceof Error)) {
            exception = new Error(JSON.stringify(error));
            extra = error as { [data: string]: unknown };
        }

        return captureException(exception, {
            extra: extra,
        });
    }

    public reportUserError(eventId?: string): void {
        if (eventId == null) {
            const userId = this.userDataService.user?.id?.toString();
            eventId = captureMessage(`User Feedback Submitted - ${userId ? userId : ''}`);
        }
        showReportDialog({
            eventId: eventId,
            title: this.translations.feedbackDialogTitle,
            subtitle: '',
            subtitle2: this.translations.feedbackDialogSubtitle2,
            labelComments: this.translations.feedbackCommentLabel,
            labelSubmit: this.translations.feedbackSubmitBtnLabel,
        });
    }
}
