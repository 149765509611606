import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import buffer from '@turf/buffer';
import convex from '@turf/convex';
import {
    Feature,
    MultiPolygon,
    Polygon,
    Position,
    Properties,
    featureCollection,
    multiPolygon,
    point,
    polygon,
} from '@turf/helpers';
import rfdc from 'rfdc';
import { from } from 'rxjs';
import { delay, first } from 'rxjs/operators';

import { ThemeService } from '@shared/utils';
import {
    CompanyLocation,
    Employee,
    ModeEstimationConfigWithCarpoolInfo,
} from '@upscore-mobility-audit/api';
import { ReachabilityOptions } from '@upscore-mobility-audit/core/interfaces/reachability-options.interface';
import { MobilityParamsFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/mobility-params-form-group.interface';
import { TripDetailsTranslations } from '@upscore-mobility-audit/map/translations/trip-details-translations';
import { MapFeature } from '@upscore-mobility-audit/shared/interfaces/map-feature.interface';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor(
        private readonly themeService: ThemeService,
        private readonly decimalPipe: DecimalPipe,
        private readonly formBuilder: FormBuilder,
    ) {}

    /**
     * get the sum of an array
     * @param numbers
     */
    public getSumOfArray(numbers: number[] | undefined): number {
        if (!numbers) {
            return 0;
        }

        return numbers.reduce((a, b) => a + b, 0);
    }

    /**
     * Method to deepClone
     * @origin
     */
    public deepClone<T>(origin: T): T {
        return rfdc()(origin) as unknown as T;
    }

    /**
     * create custom wait method without triggering the change detection
     * @param time in ms
     * @param callback
     */
    public customWait(time: number, callback: (...payload: unknown[]) => void): void {
        // we create an observable from the array [1] and then we pipe and delay it, after its finished we call the callback function
        from([1])
            .pipe(first(), delay(time))
            .subscribe(() => {
                callback();
            });
    }

    /**
     * Calculates in percent, the change between 2 numbers.
     * e.g from 1000 to 500 = -50%
     * make sure to provide full percent not (0.02, 0.42)
     * @param initialValue (e.g. 10)
     * @param compareValue (e.g. 200)
     */
    public calculatePercentageChange(initialValue: number, compareValue: number): number {
        const updatedInitialValue: number = initialValue === 0 ? 1 : initialValue;
        const decreaseValue: number = updatedInitialValue === 1 ? 0 : 100;

        return (100 / updatedInitialValue) * compareValue - decreaseValue;
    }

    /**
     * Returns a save filename not usable with extension!
     * @param filename
     */
    public getSafeFilename(filename: string): string {
        return filename
            .toLowerCase()
            .replace(/\s+/g, '_')
            .replace(/[^a-z0-9_-]/gi, '');
    }

    /**
     * prevent further button clicks on mat menu
     * @param event
     */
    public preventFurtherButtonClicks(event: MouseEvent): void {
        // because the whole card has a click listener to select the page,
        // we need to prevent that it is fired when an inner button is being pressed
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Method to convert provided minutes to days, hours and minutes
     * @param value
     */
    public convertTime(value: number): {
        days: number;
        hours: number;
        minutes: number;
    } {
        const rawDays: number = value / 60 / 24;
        const days: number = Math.floor(rawDays);
        const rawHours: number = (rawDays - days) * 24;
        const hours: number = Math.floor(rawHours);
        const rawMinutes: number = (rawHours - hours) * 60;
        const minutes: number = Math.round(rawMinutes);

        return {
            days,
            hours,
            minutes,
        };
    }

    /**
     * Toggles the polygon visibilities
     * @param polygons
     * @param showBike
     * @param showWalk
     * @param showPt
     * @param showCar
     */
    public togglePolygonVisibilities(
        polygonOptions: google.maps.PolygonOptions[],
        options: ReachabilityOptions,
    ): google.maps.PolygonOptions[] {
        polygonOptions.forEach((polygonOption, index) => {
            switch (polygonOption.fillColor) {
                case this.themeService.getHexFromCSSVariable('--bike-color'):
                    polygonOption.visible = options.bike.show;
                    break;
                case this.themeService.getHexFromCSSVariable('--walk-color'):
                    polygonOption.visible = options.walk.show;
                    break;
                case this.themeService.getHexFromCSSVariable('--public-transport-color'):
                    polygonOption.visible = options.transit.show;
                    break;
                case this.themeService.getHexFromCSSVariable('--car-driver-color'):
                    polygonOption.visible = options.car.show;
                    break;
            }

            polygonOptions[index] = {
                ...polygonOption,
            };
        });

        return polygonOptions;
    }

    /* Get count of employees within a catchment */
    public countEmployeesInCatchment(feature: MapFeature | Feature, employees: Employee[]): number {
        // @ts-expect-error: As I understand MapFeature is defined wrong in the backend
        const polygonGeometry: {
            type: string;
            coordinates: number[][][] | number[][][][];
        } = feature?.geometry;

        let searchWithin: Feature<MultiPolygon | Polygon>;
        if (polygonGeometry.type === 'Polygon') {
            searchWithin = polygon(polygonGeometry?.coordinates as Position[][]);
        } else {
            searchWithin = multiPolygon(polygonGeometry?.coordinates as Position[][][]);
        }

        return employees
            .map((employee: Employee) => [employee.longitude, employee.latitude])
            .filter(ele => booleanPointInPolygon(ele, searchWithin)).length;
    }

    public formatDuration(duration: number): string {
        const { days, hours, minutes }: { days: number; hours: number; minutes: number } =
            this.convertTime(duration);

        const realHours: number = hours + days * 24;

        const durationArr: string[] = [];
        if (realHours > 0) {
            durationArr.push(
                `${this.decimalPipe.transform(realHours, '1.0-0')} ` +
                    TripDetailsTranslations.hours,
            );
        }
        if (minutes > 0 || (hours === 0 && minutes === 0)) {
            durationArr.push(
                `${this.decimalPipe.transform(minutes, '1.0-0')} ` +
                    TripDetailsTranslations.minutes,
            );
        }

        return durationArr.join(' and ');
    }

    /**
     * formats distance into meters or kilometers as needed
     * 1343 -> 1.34 kilometers
     * 987 -> 987 meters
     * @param distance
     */
    public formatDistance(distance: number): string {
        const km: number = distance / 1000;
        if (km < 1) {
            return (
                this.decimalPipe.transform(distance, '1.0-0') + ' ' + TripDetailsTranslations.meters
            );
        }

        return this.decimalPipe.transform(km, '1.0-2') + ' ' + TripDetailsTranslations.kilometers;
    }

    public fillMobilityFormGroupWithCompanyLocation(
        formGroup: FormGroup<MobilityParamsFormGroup>,
        companyLocation: CompanyLocation,
    ) {
        if (formGroup.value.modalSplitType) {
            formGroup.setValue({
                // TODO defaulting currently to false
                isImprovementOnly: false,
                overrideFixedMode: false,
                vehicleStats: companyLocation.vehicleStats,
                mobilityStats: companyLocation.mobilityStats,
                shiftWork: companyLocation.shiftWork,
                presenceDays: companyLocation.presenceDays,
                parkingLotCosts: companyLocation.parkingLotCosts,
                businessTravelTotalEmissions: companyLocation.businessTravelTotalEmissions,
                otherCosts: companyLocation.otherCosts,
                modeEstimationConfig: {
                    modeAssignment: companyLocation.modeEstimationConfig?.modeAssignment,
                    carpooling: {
                        pickupPercentage:
                            companyLocation.modeEstimationConfig?.carpooling?.pickupPercentage ??
                            0.25,
                        maxPeopleInCarpool:
                            companyLocation.modeEstimationConfig?.carpooling?.maxPeopleInCarpool ??
                            3,
                    },
                },
                modalSplitType: formGroup.value.modalSplitType,
            });
        }
    }

    public modeEstimationConfigToFormGroup(
        modeEstimationConfig?: ModeEstimationConfigWithCarpoolInfo,
    ) {
        return this.formBuilder.nonNullable.group({
            modeAssignment: this.formBuilder.nonNullable.control<any>(
                modeEstimationConfig?.modeAssignment ?? null,
            ),
            carpooling: this.formBuilder.nonNullable.group({
                pickupPercentage: this.formBuilder.nonNullable.control(
                    modeEstimationConfig?.carpooling?.pickupPercentage ?? 0.25,
                    {
                        validators: [Validators.required, Validators.min(0)],
                    },
                ),
                maxPeopleInCarpool: this.formBuilder.nonNullable.control(
                    modeEstimationConfig?.carpooling?.maxPeopleInCarpool ?? 3,
                    {
                        validators: [Validators.required, Validators.min(1)],
                    },
                ),
            }),
        });
    }

    public createConcaveHullPolygon(latlong: number[][]): Feature<Polygon, Properties> | null {
        const points = featureCollection(latlong.map(p => buffer(point(p), 0.05)));

        return convex(points);
    }
}
