import { Employee, EmployeeIdAndLocationAccuracy, VehicleInfo } from '@upscore-mobility-audit/api';

enum CarVehicleType {
    'SMALL_VEHICLE' = 'SMALL_VEHICLE',
    'REGULAR_VEHICLE' = 'REGULAR_VEHICLE',
    'LARGE_VEHICLE' = 'LARGE_VEHICLE',
    'ELECTRIC_VEHICLE' = 'ELECTRIC_VEHICLE',
}

interface EmployeeTableData extends Employee {
    address?: string;
    accuracySource: EmployeeIdAndLocationAccuracy['accuracySource'];
    employeeLocationAccuracy?: EmployeeIdAndLocationAccuracy['locationAccuracy'];
    rawAccuracy?: number;
    zipcode?: string;
    co2Emissions?: number;
    vehicleId?: string;
    vehicleType?: VehicleInfo['vehicleType'];
}

interface AggregatedGeocodingTableData {
    sourceType: string;
    count: number;
    displayDetails?: string;
}

export { AggregatedGeocodingTableData, EmployeeTableData, CarVehicleType };
