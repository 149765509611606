<kpi-card
    [showMenu]="hasMenu"
    [showUnavailable]="showUnavailable"
    [title]="title"
    [moreInfoText]="kpiCardTranslations.modalSplitDescription">
    <div body class="body-content">
        <!-- circle -->
        <modal-split-circle
            [employeeCount]="employeeCount"
            [mobilityStats]="mobilityStats"></modal-split-circle>

        <!-- modalsplit changes -->
        @if (baselineMobilityStats != null) {
            <modal-split-change-chart
                [mobilityStats]="mobilityStats"
                [baselineMobilityStats]="baselineMobilityStats"></modal-split-change-chart>
        }
    </div>
</kpi-card>
