<form [formGroup]="mobilityPropertiesForm">
    <div class="section-margin">
        <mat-form-field appearance="outline" class="category-input preset-dropdowns">
            <mat-label i18n="@@selectPreset">Select Preset (Optional)</mat-label>
            <mat-select (selectionChange)="onPresetChange($event)">
                <mat-option i18n="@@rural" value="Rural">Rural</mat-option>
                <mat-option i18n="@@urban" value="Urban">Urban</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="icon-btns">
            @if (!userSettings) {
                <button
                    (click)="setUserDefaults()"
                    class="reset-button"
                    i18n-matTooltip="@@setUserDefaults"
                    mat-button
                    matTooltip="Set User Defaults">
                    <mat-icon>person</mat-icon>
                </button>
            }
            <button
                (click)="resetToInitial()"
                class="reset-button"
                i18n-matTooltip="@@reset"
                mat-button
                matTooltip="Reset">
                <mat-icon>replay</mat-icon>
            </button>
        </div>
    </div>
    <hr class="sidebar__separator section-separator" />
    <div class="section-margin">
        <span class="section-title" i18n="@@timePreference">Travel times for Optimum [min]</span>
    </div>
    <hr class="sidebar__separator section-separator" />
    <div class="section-row">
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@maxBikingTime">Max. Biking Time</mat-label>
            <input formControlName="maxBikingTime" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['maxBikingTime'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['maxBikingTime'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@maxWalkingTime">Max. Walking Time</mat-label>
            <input formControlName="maxWalkingTime" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['maxWalkingTime'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['maxWalkingTime'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@maxCarTime">Max. Car Time</mat-label>
            <input formControlName="maxCarTime" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['maxCarTime'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['maxCarTime'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@maxPtTime">Max. Public Transport Time</mat-label>
            <input formControlName="maxTransitTime" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['maxTransitTime'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['maxTransitTime'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
    </div>
    <div class="section-margin">
        <span class="section-title" i18n="@@co2EmissionsWithUnit">CO₂ Emissions [g/km]</span>
    </div>
    <hr class="sidebar__separator section-separator" />

    <div class="section-row">
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@dieselCarEmissions">Diesel Car Emissions</mat-label>
            <input formControlName="smallVehicleEmissions" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['smallVehicleEmissions'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['smallVehicleEmissions'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@petrolCarEmissions">Petrol Car Emissions</mat-label>
            <input formControlName="regularVehicleEmissions" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['regularVehicleEmissions'].value) {
                <button
                    (click)="
                        mobilityPropertiesForm.controls['regularVehicleEmissions'].setValue('')
                    "
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@pluginCarEmissions">Plug-in Hybrid Vehicle Emissions</mat-label>
            <input formControlName="largeVehicleEmissions" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['largeVehicleEmissions'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['largeVehicleEmissions'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@bevEmission">Battery-Electric Vehicle Emissions</mat-label>
            <input formControlName="electricCarEmissions" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['electricCarEmissions'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['electricCarEmissions'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
    </div>

    <div class="section-row">
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@generalCarEmission">General Car Emissions</mat-label>
            <input formControlName="generalCarEmissions" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['generalCarEmissions'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['generalCarEmissions'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@ptEmission">Public Transport Emissions</mat-label>
            <input formControlName="transitEmissions" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['transitEmissions'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['transitEmissions'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
    </div>
    <div class="section-margin">
        <span class="section-title" id="@@mobilityCosts">Costs [EUR/km]</span>
    </div>
    <hr class="sidebar__separator section-separator" />

    <div class="section-row">
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@bikingCost">Biking Costs</mat-label>
            <input formControlName="bikeCostPerKm" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['bikeCostPerKm'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['bikeCostPerKm'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@walkingCost">Walking Costs</mat-label>
            <input formControlName="walkingCostPerKm" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['walkingCostPerKm'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['walkingCostPerKm'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@carCost">Car Costs</mat-label>
            <input formControlName="carCostPerKm" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['carCostPerKm'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['carCostPerKm'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@ptCost">Public Transport Costs</mat-label>
            <input formControlName="transitCostPerKm" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['transitCostPerKm'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['transitCostPerKm'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
    </div>
    <div class="section-margin">
        <span class="section-title">Carpooling Preferences</span>
    </div>
    <hr class="sidebar__separator section-separator" />

    <div class="section-row">
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@minCarpoolTime">Minimum Carpool Time</mat-label>
            <input formControlName="minimumCarpoolTime" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['minimumCarpoolTime'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['minimumCarpoolTime'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@maxPeopleInCarpool">Max People In A Car</mat-label>
            <input formControlName="maxPeopleInCarpool" matInput min="1" type="number" />
            @if (mobilityPropertiesForm.controls['maxPeopleInCarpool'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['maxPeopleInCarpool'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
    </div>
    <div class="top-margin-2">
        <span class="section-title">General Preferences</span>
    </div>
    <hr class="sidebar__separator section-separator" />

    <div class="section-row">
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@presenceDaysPerYear">Presence Days Per Year</mat-label>
            <input formControlName="presenceDaysPerYear" matInput min="0" type="number" />
            @if (mobilityPropertiesForm.controls['presenceDaysPerYear'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['presenceDaysPerYear'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@presenceDaysPerWeek">Max Presence Days Per Week</mat-label>
            <input
                formControlName="maxPresenceDaysPerWeek"
                matInput
                max="5"
                min="0"
                placeholder="0-5"
                type="number" />
            @if (mobilityPropertiesForm.controls['maxPresenceDaysPerWeek'].value) {
                <button
                    (click)="mobilityPropertiesForm.controls['maxPresenceDaysPerWeek'].setValue('')"
                    mat-icon-button
                    matSuffix
                    type="button">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@entryTime">Entry Time</mat-label>
            <input formControlName="entryTime" matInput type="time" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
            <mat-label i18n="@@exitTime">Exit Time</mat-label>
            <input formControlName="exitTime" matInput type="time" />
        </mat-form-field>
    </div>

    <div class="top-margin-2">
        <span class="section-title" i18n="@@modeEstimationHeader"> Mode Estimation </span>
    </div>
    <hr class="sidebar__separator section-separator" />
    <div class="section-row">
        <mat-slide-toggle
            appearance="outline"
            class="input-field large-toggle"
            formControlName="improvementOnlyOptimum">
            <mat-label i18n="@@isImprovementOnlyOptimum">Improvement-only Optimum</mat-label>
        </mat-slide-toggle>
    </div>

    <div class="configure">
        <div class="configure-text">
            @if (enableModeEstimationConfig) {
                <span i18n="@@configureModeEstimationTextCustom"> Using Custom Configuration </span>
            } @else {
                <span i18n="@@configureModeEstimationTextDefault"> Using Default </span>
            }
        </div>
        <div class="configure-button">
            @if (enableModeEstimationConfig) {
                <button
                    (click)="
                        enableModeEstimationConfig = false;
                        mobilityPropertiesForm.controls['optimalModeEstimationParams'].setValue(
                            null
                        )
                    "
                    class="primary-button-filled"
                    mat-button
                    type="button">
                    <mat-icon>autorenew</mat-icon>
                    <span i18n="@@configureModeEstimationButtonCustom">Reset to Default</span>
                </button>
            } @else {
                <button
                    (click)="enableModeEstimationConfig = true"
                    class="primary-button-filled"
                    mat-button
                    type="button">
                    <mat-icon>settings</mat-icon>
                    <span i18n="@@configureModeEstimationButtonDefault">Configure</span>
                </button>
            }
        </div>
    </div>
    @if (enableModeEstimationConfig) {
        <mode-estimate-input-wrapper
            [excludeModes]="['CAR_PASSENGER']"
            [parentFormGroup]="mobilityPropertiesForm">
        </mode-estimate-input-wrapper>
    }
</form>
