import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { FailedAggregatedEmployees, FailedDetailedEmployee } from '@upscore-mobility-audit/api';
import { UploadFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/upload-form-group.interface';
import { UpscoreTableInput } from '@upscore-mobility-audit/shared/interfaces/upscore-table-input.interface';

import { FileUploadEnum } from '../../enums/file-upload.enum';

@Component({
    selector: 'employee-upload-error',
    templateUrl: './employee-upload-error.component.html',
    styleUrls: ['./employee-upload-error.component.scss'],
})
export class EmployeeUploadErrorComponent implements OnDestroy, OnInit {
    @Input() public uploadFormGroup!: FormGroup<UploadFormGroup>;
    public unsubscribe$ = new Subject<void>();

    public tableHeaderDataAggregated: UpscoreTableInput[] = [
        {
            column: 'count',
            display: 'Count',
            isEditable: true,
            type: 'number',
        },
        {
            column: 'entranceNumber',
            display: 'Entrance Number',
            isEditable: true,
            type: 'number',
        },
        {
            column: 'zipcode',
            display: 'Zipcode',
            isEditable: true,
        },
    ];

    public tableHeaderDataDetailed: UpscoreTableInput[] = [
        {
            column: 'realId',
            display: 'Line/RealId',
            isEditable: false,
        },
        {
            column: 'street',
            display: 'Street',
            isEditable: true,
        },
        {
            column: 'city',
            display: 'City',
            isEditable: true,
        },
        {
            column: 'zipcode',
            display: 'Zipcode',
            isEditable: true,
        },
    ];

    public fileUploadTypes: typeof FileUploadEnum = FileUploadEnum;

    public ngOnInit(): void {
        this.addIsEdit(
            this.uploadFormGroup.value.error as
                | FailedAggregatedEmployees[]
                | FailedDetailedEmployee[],
        );
        this.uploadFormGroup.controls.error.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                map(data => data as FailedAggregatedEmployees[] | FailedDetailedEmployee[]),
            )
            .subscribe((data: FailedAggregatedEmployees[] | FailedDetailedEmployee[]) => {
                this.addIsEdit(data);
            });
    }

    public ngOnDestroy(): void {
        this.uploadFormGroup.controls.error.setValue(null);
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private addIsEdit(data: FailedAggregatedEmployees[] | FailedDetailedEmployee[]): void {
        if (data == null) {
            return;
        }
        data.forEach(row => {
            // @ts-expect-error here we are adding a property to the data row
            row.isEdit = true;
        });
    }
}
