import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ResetService } from '@upscore-mobility-audit/core/data-services/reset.service';

/**
 * Base class for services that need to reset their cache when the audit is closed
 * or when the company location is changed
 * or when a score is deleted
 */
@Injectable({
    providedIn: 'root',
})
export abstract class CacheResetBase {
    protected resetService = inject(ResetService);

    private lastCompanyLocationId: number | null = null;

    constructor() {
        this.resetService.auditClosed$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.auditClosed();
        });

        this.resetService.scoreSaved$.pipe(takeUntilDestroyed()).subscribe(packageId => {
            this.scoreSaved(packageId);
        });

        this.resetService.scoreDeleted$.pipe(takeUntilDestroyed()).subscribe(packageId => {
            this.scoreDeleted(packageId);
        });

        this.resetService.companyChanged$.pipe(takeUntilDestroyed()).subscribe(locationId => {
            if (locationId != this.lastCompanyLocationId) {
                this.lastCompanyLocationId = locationId;
                this.companyLocationChanged();
            }
        });
    }

    // check reset.service.ts for how these methods interact with each other

    /**
     * Called when the company location is changed
     * @protected
     */
    protected companyLocationChanged() {
        // do something
    }

    /**
     * Called when a score is deleted (for sure) locally
     * @param packageId could be -1 (status-quo) or any other scenarioId
     */
    protected scoreDeleted(packageId: number) {
        // do something
    }

    /**
     * Called when a score is saved in local cache.
     * @param packageId could be -1 (status-quo) or any other scenarioId
     */
    protected scoreSaved(packageId: number) {
        // do something
    }

    /**
     * Called when the audit is closed, and we move away from the core application
     */
    protected auditClosed() {
        // do something
    }
}
