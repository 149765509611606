import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    CatchmentFeatureCollection,
    CompanyLocation,
    CompanyLocationToChange,
    CompanyLocationsService,
    DashboardLocationDto,
    PostedCompanyLocation,
} from '@upscore-mobility-audit/api';

import { OptimalCatchment } from '../interfaces/optimal-catchment-response.interface';

@Injectable({
    providedIn: 'root',
})
export class CompanyLocationsWrapperService {
    constructor(private companyLocationsService: CompanyLocationsService) {}

    /**
     * Method to post new locations
     * @params
     */
    public createLocation(params: { body: PostedCompanyLocation }): Observable<CompanyLocation> {
        return this.companyLocationsService.newCompanyLocation(params);
    }

    /**
     * Method to patch location
     * @params
     */
    public updateLocation(params: {
        companyLocationId: number;
        body: CompanyLocationToChange;
    }): Observable<CompanyLocation> {
        return this.companyLocationsService.updateCompanyLocation(params);
    }

    /**
     * Method to get all company locations
     */
    public getAllCompanyLocations(): Observable<CompanyLocation[]> {
        return this.companyLocationsService.allCompanyLocations();
    }

    /**
     * Method to get company location with a provided id
     * @param locationId
     */
    public getCompanyLocation(locationId: number): Observable<CompanyLocation> {
        const params: { companyLocationId: number } = {
            companyLocationId: locationId,
        };

        return this.companyLocationsService.companyLocation(params);
    }

    /**
     * Method to get minimal location with a provided id
     * @param locationId
     */
    public getMinimalCompanyLocation(locationId: number): Observable<DashboardLocationDto> {
        const params: { id: number } = {
            id: locationId,
        };

        return this.companyLocationsService.getCompanyLocationMinimal(params);
    }

    /**
     * Method to delete company location with a provided id
     * @param locationId
     */
    public deleteCompanyLocation(locationId: number): Observable<string> {
        const params: { companyLocationId: number } = {
            companyLocationId: locationId,
        };

        return this.companyLocationsService.deleteCompanyLocation(params);
    }

    /**
     * Method to get the catchment area for transport modes
     * @param params
     */
    public getCatchmentArea(params: {
        companyLocationId: number;
        // mode: 'bike' | 'walk' | 'car' | 'transit' | 'carpooling' | 'transit_and_bike';
        mode: 'bike' | 'walk' | 'car' | 'transit';
        times: number[];
        entranceNumber?: number;
    }): Observable<CatchmentFeatureCollection> {
        return this.companyLocationsService.companyLocationCatchmentRequest(params);
    }

    /**
     * Method to get the optimal catchment area
     * @param locationId
     * @param entranceNumber
     */
    public getOptimalCatchment(
        locationId: number,
        entranceNumber: number,
    ): Observable<OptimalCatchment> {
        const params: { companyLocationId: number; entranceNumber: number } = {
            companyLocationId: locationId,
            entranceNumber,
        };

        return this.companyLocationsService
            .getOptimalCatchmentAndCarpools(params)
            .pipe(map(res => res as unknown as OptimalCatchment));
    }

    public getMinimalCompanyLocations(params: {
        offset: number;
        limit: number;
        searchQuery: string;
        industry: string;
    }) {
        return this.companyLocationsService.allCompanyLocationsMinimal(params);
    }

    public getAllIndustries() {
        return this.companyLocationsService.getAllIndustries();
    }
}
