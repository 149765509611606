import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AbstractUserDataService, FeatureFlag } from '@shared/utils';
import {
    Entrance,
    FailedAggregatedEmployees,
    FailedDetailedEmployee,
    UserInfo,
} from '@upscore-mobility-audit/api';
import { UploadFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/upload-form-group.interface';

import { FileUploadEnum } from '../../enums/file-upload.enum';

@Component({
    selector: 'employee-data-step',
    templateUrl: './employee-data-step.component.html',
    styleUrls: ['./employee-data-step.component.scss'],
})
export class EmployeeDataStepComponent {
    @Input() public uploadFormGroup!: FormGroup<UploadFormGroup>;
    @Input() public employeeCount!: number;
    @Input() public entrances!: Entrance[];

    @Output() public reUploadEmployees: EventEmitter<{
        failedEmployees: FailedDetailedEmployee[] | FailedAggregatedEmployees[] | null;
        uploadType: FileUploadEnum;
    }> = new EventEmitter();

    public uploadTypes: FileUploadEnum[] = Object.values(FileUploadEnum);
    public fileUploadTypes: typeof FileUploadEnum = FileUploadEnum;
    public featureFlag: typeof FeatureFlag = FeatureFlag;

    public fileLanguageKey: string;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        public userDataService: AbstractUserDataService<UserInfo>,
        @Inject(LOCALE_ID) locale: string,
    ) {
        // currently we only support German and English template files, and everything that's not german falls back to english
        if (locale == 'de') {
            this.fileLanguageKey = 'de';
        } else {
            this.fileLanguageKey = 'en';
        }
    }

    public selectUploadType(value: FileUploadEnum | null): void {
        if (value == null) {
            if (this.uploadFormGroup.value.error != null) {
                this.uploadFormGroup.controls.overwrite.setValue(true);
            }
            this.uploadFormGroup.controls.error.setValue(null);
        }
        this.uploadFormGroup.controls.uploadType.setValue(value);
        this.changeDetectorRef.detectChanges();
    }

    public onMaximumDistanceSliderChanged(distanceSliderValue: number): void {
        this.uploadFormGroup.controls.distanceSlider.setValue(distanceSliderValue * 1000);
    }
}
