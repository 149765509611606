import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, share } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { SmallScoreResponse } from '@upscore-mobility-audit/api';
import { AuditInputDataService } from '@upscore-mobility-audit/core/data-services/audit-input-data.service';
import { ResetService } from '@upscore-mobility-audit/core/data-services/reset.service';

@Injectable({
    providedIn: 'root',
})
export class ScoreDataService {
    public mobilityScoreError$ = new BehaviorSubject<HttpErrorResponse | null>(null);

    public scoreDeleted$ = this.resetService.scoreDeleted$;
    public scoreSaved$ = this.resetService.scoreSaved$;

    public selectedScenarioAndScore$ = combineLatest([
        this.coreInputDataService.selectedScenarioId$.pipe(distinctUntilChanged()),
        this.scoreDeleted$.pipe(
            filter(
                scoreDeleted => scoreDeleted == this.coreInputDataService.selectedScenarioId$.value,
            ),
            startWith(null),
        ),
        this.scoreSaved$.pipe(
            filter(scoreSaved => scoreSaved == this.coreInputDataService.selectedScenarioId$.value),
            startWith(null),
        ), // startwith null to trigger combineLatest
    ]).pipe(
        map(([id]) => {
            return { scenarioRef: id, score: id ? this.scores.get(id) ?? null : null };
        }),
        share(),
    );

    public baselineScore$ = combineLatest([
        this.coreInputDataService.selectedScenarioId$,
        this.scoreDeleted$.pipe(startWith(null)),
        this.scoreSaved$.pipe(startWith(null)),
    ]).pipe(
        map(([id]) => {
            if (id != -1) {
                return this.scores.get(-1) ?? null;
            }

            return null;
        }),
        distinctUntilChanged(),
        share(),
    );

    private scores = new Map<number, SmallScoreResponse>();

    constructor(
        private coreInputDataService: AuditInputDataService,
        private resetService: ResetService,
    ) {}

    public hasScore(key: number): boolean {
        return this.scores.has(key);
    }

    public saveScore(id: number, score: SmallScoreResponse) {
        this.scores.set(id, score);
        this.scoreSaved$.next(id);
    }

    public deleteScore(id: number) {
        if (this.scores.has(id)) {
            this.scores.delete(id);
            this.scoreDeleted$.next(id);
        }
        if (id == -1) {
            Array.from(this.scores.keys()).forEach(v => this.deleteScore(v));
        }
    }

    public saveOptimumScore() {
        this.scores.set(-2, { mobilityScore: 100 });
        this.scoreSaved$.next(-2);
    }
}
