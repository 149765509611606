import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { FeatureFlag } from '@shared/utils';
import { NavBarLayoutComponent } from '@ui-library/components/nav-bar-layout/nav-bar-layout.component';
import { UpscoreMobilityAuditUserDataService } from '@upscore-mobility-audit/shared/api-services/user-data.service';
import { UpscoreSharedModule } from '@upscore-mobility-audit/shared/shared.module';
import { UserDetailsComponent } from '@upscore-mobility-audit/shared-ui/components/user-details/user-details.component';
import { DialogService } from '@upscore-mobility-audit/shared-ui/services/dialog.service';
import { TaskNotificationService, UserNotificationsComponent } from '@upscore-mobility-audit/tasks';

@Component({
    selector: 'upscore-nav-bar',
    standalone: true,
    imports: [
        CommonModule,
        NavBarLayoutComponent,
        UpscoreSharedModule,
        UserNotificationsComponent,
        UserDetailsComponent,
    ],
    templateUrl: './nav-bar.component.html',
    styleUrl: './nav-bar.component.scss',
})
export class NavBarComponent {
    public featureFlag = FeatureFlag;

    constructor(
        public readonly userDataService: UpscoreMobilityAuditUserDataService,
        public readonly taskNotificationService: TaskNotificationService,
        private readonly dialogService: DialogService,
        private readonly router: Router,
    ) {}

    public onClickUserSettings(): void {
        this.dialogService.openUserSettingsDialog();
    }

    public onLogoClick() {
        void this.router.navigate(['/']);
    }
}
