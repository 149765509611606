import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TimezoneApiResponse, TimezoneService } from '@upscore-mobility-audit/api';

@Injectable({
    providedIn: 'root',
})
export class TimezoneWrapperService {
    constructor(private timezoneService: TimezoneService) {}

    /**
     * Method to get timezone of a location
     * @params
     */
    public getLocationTimezone(params: {
        location: string;
        timestamp: number;
        language: string;
    }): Observable<TimezoneApiResponse> {
        return this.timezoneService.getTimezone(params);
    }
}
