import { Inject, Injectable } from '@angular/core';
import { IFrame, RxStomp, RxStompConfig, RxStompState } from '@stomp/rx-stomp';
import { replace } from 'lodash-es';
import { Observable, Subject, concat, mergeMap } from 'rxjs';
import { filter, map, share, takeWhile } from 'rxjs/operators';

import { AuthService } from '@auth/data-access-auth';
import { BeforeWindowUnloadService, ENVIRONMENT, Environment } from '@shared/utils';
import { TaskProgress, TasksService } from '@upscore-mobility-audit/api';
import { UpscoreMobilityAuditUserDataService } from '@upscore-mobility-audit/shared/api-services/user-data.service';

import { ProgressService, TaskIdAndProgress } from '../interfaces';

import { TaskUtilsService } from './task-utils.service';

@Injectable({
    providedIn: 'root',
})
export class StompProgressService extends ProgressService {
    public connected = false;

    private active = false;
    private rxStomp: RxStomp;

    private userTasksObservable: Observable<TaskIdAndProgress> | null = null;

    constructor(
        private readonly authService: AuthService,
        private readonly taskUtilsService: TaskUtilsService,
        private tasksService: TasksService,
        private userService: UpscoreMobilityAuditUserDataService,
        private beforeWindowUnloadService: BeforeWindowUnloadService,
        @Inject(ENVIRONMENT) readonly environment: Environment,
    ) {
        super();
        this.rxStomp = new RxStomp();
        this.rxStomp.configure(stompConfig);

        // replaces http with ws in the apiUrl
        // also works with (http)s -> (ws)s (removes http, the additional s stays)
        this.rxStomp.configure({
            brokerURL: `${replace(environment.apiUrl, RegExp('^http'), 'ws')}/ws`,

            beforeConnect: (client: RxStomp): void => {
                client.configure({
                    connectHeaders: {
                        login: this.authService.getJwtToken(),
                    },
                });
            },
        });

        this.beforeWindowUnloadService.addBeforeUnloadListener(this.forceCloseStomp.bind(this));
        this.userService._user$.subscribe(user => {
            if (user) {
                this.rxStomp.activate();
                this.active = true;
            } else {
                void this.rxStomp.deactivate();
                this.active = false;
                this.userTasksObservable = null;
            }
        });

        this.connected$.subscribe(state => {
            this.connected = state === RxStompState.OPEN;
        });
    }

    get connected$(): Observable<RxStompState> {
        return this.rxStomp.connected$;
    }

    get connectionState$(): Observable<RxStompState> {
        return this.rxStomp.connectionState$.pipe(
            filter(() => {
                // here we only want to emit the state if the user is logged in or recently logged out
                return this.active;
            }),
        );
    }

    get error$(): Subject<IFrame> {
        return this.rxStomp.stompErrors$;
    }

    /**
     * Watch the progress of a task.
     * The observable will complete when the task is finished, failed or cancelled.
     * @param taskId
     */
    public subscribeToTasks(taskId: string): Observable<TaskProgress> {
        const userId = this.userService.user?.id;
        if (userId == null) {
            throw new Error('User not logged in');
        }

        return this.subscribeToAllUserTasks(userId).pipe(
            filter(task => task.id === taskId),
            map(task => task.progress as TaskProgress),
            takeWhile(this.taskUtilsService.taskIsRunning, true),
        );
    }

    /**
     * Watch all tasks of the given user.
     * Note that as opposed to `watchTask`, this observable does not complete.
     * @param userId
     */
    public subscribeToAllUserTasks(userId: number): Observable<TaskIdAndProgress> {
        if (this.userTasksObservable == null) {
            this.userTasksObservable = concat(
                this.tasksService
                    .getMyTasks()
                    .pipe(
                        mergeMap(tasks =>
                            tasks.map(
                                task =>
                                    ({ id: task.id, progress: task.progress }) as TaskIdAndProgress,
                            ),
                        ),
                    ),
                this.rxStomp
                    .watch(`/topic/tasksByUser/${userId}`)
                    .pipe(map(message => JSON.parse(message.body) as TaskIdAndProgress)),
            ).pipe(share());
        }

        return this.userTasksObservable;
    }

    private forceCloseStomp() {
        void this.rxStomp.deactivate({ force: true });
    }
}

export const stompConfig: RxStompConfig = {
    heartbeatIncoming: 10_000,
    heartbeatOutgoing: 10_000,
    // debug: (msg: string): void => {
    //     console.log(new Date(), msg);
    // },
};
