import { Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import html2canvas from 'html2canvas';
import { HTMLOptions, jsPDF } from 'jspdf';
import { BehaviorSubject, combineLatest, map, shareReplay, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';

import { LoadingService, LocalStorageKeys, ToastService } from '@shared/utils';
import { CompanyLocationDataService } from '@upscore-mobility-audit/core/data-services/company-location-data.service';
import { SidebarDataService } from '@upscore-mobility-audit/core/data-services/sidebar-data.service';
import { MapType } from '@upscore-mobility-audit/core/enums/map-type.enum';
import { UtilsService } from '@upscore-mobility-audit/shared/services/utils.service';
import { ExportTranslations } from '@upscore-mobility-audit/shared/translations/export-translations';

@Injectable({
    providedIn: 'root',
})
export class ExportService {
    public pages: BehaviorSubject<SelectedCard[][]> = new BehaviorSubject<SelectedCard[][]>([[]]);

    private companyLocation$ = this.companyLocationDataService.companyLocation$;
    private currentStepLabel$ = toObservable(this.sidebarDataService.currentStepIndex).pipe(
        map(
            index =>
                ({
                    [MapType.CURRENT]: $localize`:@@statusQuoUpperCase:STATUS QUO`,
                    [MapType.OPTIMUM]: $localize`:@@optimumUpperCase:OPTIMUM`,
                    [MapType.PACKAGE]: $localize`:@@measuresUpperCase:MEASURES`,
                })[index],
        ),
    );

    private exportBase = combineLatest([this.companyLocation$, this.currentStepLabel$]).pipe(
        map(([location, label]) => [location.name, label]),
        shareReplay(1),
    );

    constructor(
        private readonly toasterService: ToastService,
        private readonly loading: LoadingService,
        private readonly sidebarDataService: SidebarDataService,
        private readonly companyLocationDataService: CompanyLocationDataService,
        private readonly utilsService: UtilsService,
    ) {}

    /**
     * creates a html-canvas-element from $element
     * @param element
     */
    private static getCanvas(element: HTMLElement): Promise<HTMLCanvasElement> {
        for (const elem of Array.from(
            document.getElementsByClassName('data-html2canvas-ignore'),
        ) as HTMLElement[]) {
            elem.style.position = 'absolute';
            elem.style.top = '0';
        }

        return html2canvas(element, {
            scale: 4,
            useCORS: true,
            allowTaint: true,
            onclone: (doc: Document) => {
                for (const elem of Array.from(
                    doc.getElementsByClassName('data-html2canvas-ignore'),
                )) {
                    elem.remove();
                }
            },
        }).finally(() => {
            for (const elem of Array.from(
                document.getElementsByClassName('data-html2canvas-ignore'),
            ) as HTMLElement[]) {
                elem.style.position = 'relative';
                elem.style.top = 'unset';
            }
        });
    }

    public export({
        element,
        format,
        title,
        contentType,
    }: {
        element: HTMLElement;
        format: ExportFormat;
        title: string;
        contentType: ContentType;
    }) {
        const failedText = ExportMessages[contentType]?.failed[format];
        const info = ExportMessages[contentType]?.info;

        if (info && localStorage.getItem(info.localStorageKey) === null) {
            this.toasterService.showInfoEvent(info.header, false, false, info.content);
            localStorage.setItem(info.localStorageKey, 'true');
        }

        this.loading.startLoading();

        this.exportBase
            .pipe(
                take(1),
                map(baseParts => {
                    return this.utilsService.getSafeFilename(baseParts.concat(title).join('-'));
                }),
                switchMap(exportTitle => {
                    switch (format) {
                        case 'png':
                            return this.exportPng(element, `${exportTitle}.png`);
                        case 'pdf':
                            return this.exportPdf(element, `${exportTitle}.pdf`);
                    }
                }),
            )
            .subscribe({
                error: () => {
                    this.toasterService.showInfoEvent(failedText ?? 'Export failed.', true, false);
                },
            })
            .add(() => {
                this.loading.endLoading();
            });
    }

    /**
     * exports given element as png
     * @param element
     * @param filename
     */
    private exportPng(element: HTMLElement, filename: string): Promise<void> {
        return ExportService.getCanvas(element).then(canvas => {
            const a: HTMLAnchorElement = document.createElement('a');
            a.href = canvas.toDataURL('image/png', 1);
            a.download = filename;
            a.click();
            a.remove();
        });
    }

    /**
     * exports given element as pdf
     * @param element
     * @param filename
     */
    private exportPdf(element: HTMLElement, filename: string): Promise<void> {
        const pdf: jsPDF = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });
        const options: HTMLOptions = {
            width: pdf.internal.pageSize.getWidth(), // target width in document. A4 is 210mm
            windowWidth: element.clientWidth, // The window width in CSS pixels
            html2canvas: {
                onclone: doc => {
                    Array.from(
                        doc.getElementsByClassName('kpi-card') as HTMLCollectionOf<HTMLElement>,
                    ).forEach(e => {
                        e.style.border = '1px solid var(--light-grey-color)';
                    });

                    Array.from(
                        doc.getElementsByClassName(
                            'mat-form-field-underline',
                        ) as HTMLCollectionOf<HTMLElement>,
                    ).forEach(e => {
                        e.remove();
                    });

                    // for every canvas element with transparent background, the parent needs to have this class export-canvas-processing;
                    const canvasWrapper: Element[] = Array.from(
                        doc.getElementsByClassName('export-canvas-processing'),
                    );
                    canvasWrapper.forEach(wrapper => {
                        const rectElements: HTMLCanvasElement[] = Array.from(
                            wrapper.getElementsByTagName('canvas'),
                        );

                        if (rectElements.length >= 1) {
                            // This is a fix for our custom canvas
                            // We have multiple canvas overlying with transparent background
                            // Transparent background will be black in a pdf, so the top most canvas will hide all other canvas

                            // first canvas store rect
                            // draw whole background white
                            // draw first canvas rect
                            // draw second canvas rect onto first one
                            // remove second canvas rect

                            const backgroundCanvas: HTMLCanvasElement =
                                rectElements.shift() as HTMLCanvasElement;

                            const cacheCanvas: HTMLCanvasElement = doc.createElement('canvas');
                            cacheCanvas.width = backgroundCanvas.width;
                            cacheCanvas.height = backgroundCanvas.height;

                            const cacheRect: CanvasRenderingContext2D = cacheCanvas.getContext(
                                '2d',
                            ) as CanvasRenderingContext2D;

                            // cacheRect.globalCompositeOperation = 'destination-over';
                            cacheRect.save();
                            cacheRect.drawImage(backgroundCanvas, 0, 0);
                            cacheRect.restore();

                            const backgroundRect: CanvasRenderingContext2D =
                                backgroundCanvas.getContext('2d') as CanvasRenderingContext2D;
                            backgroundRect.save();
                            backgroundRect.fillStyle = '#FFF';
                            backgroundRect.fillRect(
                                0,
                                0,
                                backgroundCanvas.width,
                                backgroundCanvas.height,
                            );
                            backgroundRect.drawImage(cacheCanvas, 0, 0);
                            backgroundRect.restore();

                            rectElements.forEach(canvas => {
                                backgroundRect.save();
                                backgroundRect.drawImage(canvas, 0, 0);
                                backgroundRect.restore();
                                canvas.remove();
                            });
                        }
                    });
                },
            },
            image: {
                type: 'png',
                quality: 1,
            },
            x: 0,
            y: 0,
            fontFaces: [
                {
                    family: 'source-sans-pro-semi-bold',
                    src: [
                        {
                            url: 'assets/fonts/SourceSansPro-SemiBold.ttf',
                            format: 'truetype',
                        },
                    ],
                },
                {
                    family: 'source-sans-pro-regular',
                    src: [
                        {
                            url: 'assets/fonts/SourceSansPro-Regular.ttf',
                            format: 'truetype',
                        },
                    ],
                },
                {
                    family: 'source-sans-pro-bold',
                    src: [
                        {
                            url: 'assets/fonts/SourceSansPro-Bold.ttf',
                            format: 'truetype',
                        },
                    ],
                },
                {
                    family: 'Material Icons',
                    src: [
                        {
                            url: 'assets/fonts/MaterialIcons-Regular.ttf',
                            format: 'truetype',
                        },
                    ],
                },
            ],
        };

        return pdf.html(element, options).save(filename);
    }
}

export interface SelectedCard {
    id: string;
    type: string;
    size: number;
    panelOpen?: boolean;
    heading?: string;
    text?: string;
}

export type ExportFormat = 'png' | 'pdf';
export type ContentType = 'kpi' | 'map';

export const ExportMessages: {
    [key in ContentType]?: {
        info?: { localStorageKey: LocalStorageKeys; header: string; content: string };
        failed: { [key in ExportFormat]?: string };
    };
} = {
    kpi: {
        info: undefined,
        failed: {
            png: ExportTranslations.kpiPngExportFailed,
            pdf: ExportTranslations.kpiPdfExportFailed,
        },
    },
    map: {
        info: {
            localStorageKey: LocalStorageKeys.MAP_EXPORT_INFO,
            header: ExportTranslations.triplyInformation,
            content: ExportTranslations.mapExportInfo,
        },
        failed: {
            png: ExportTranslations.mapExportFailed,
        },
    },
};
