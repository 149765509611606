<span class="kpi-measure">
    @for (
        kpiMeasure of kpiMeasures;
        track kpiMeasure;
        let i = $index;
        let even = $even;
        let odd = $odd
    ) {
        <span
            [class.kpi-measure__measure]="odd"
            [class.kpi-measure__value]="even"
            [ngClass]="even ? valueCssClass : measureCssClass">
            {{ kpiMeasure }}</span
        >
    }
</span>
