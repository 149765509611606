<section class="upscore-stepper">
    <header>
        <div class="upscore-header-wrap">
            @for (
                step of upscoreSteps;
                track step;
                let i = $index;
                let first = $first;
                let last = $last
            ) {
                @if (step.mandatory) {
                    <div
                        (click)="jumpToStep(i)"
                        [class.first-step]="first"
                        [class.highlighted-step]="i <= selectedIndex"
                        [class.last-step]="last"
                        [class.middle-steps]="!last && !first"
                        [class.step-disabled]="backwardStepDisabled && i !== selectedIndex"
                        class="upscore-header">
                        <div>{{ step.label }}</div>
                    </div>
                }
            }
        </div>
    </header>

    <div class="upscore-stepper-content">
        <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
    </div>

    <footer class="upscore-stepper-footer">
        <div class="navigation-buttons">
            @if (allowReset) {
                <button (click)="resetSteps()" class="reset-button button-with-icon" mat-button>
                    <mat-icon>replay</mat-icon>
                    <span i18n="@@reset">Reset</span>
                </button>
            }
            @if (selectedIndex !== 0) {
                <button
                    (click)="previousStep()"
                    [disabled]="backwardStepDisabled"
                    class="cancel-button-filled back-button"
                    mat-button>
                    <span i18n="@@back">Back</span>
                </button>
            }
            @if (selectedIndex !== this.steps.length - 1) {
                <div>
                    @if (
                        upscoreSelected.nextButton != null &&
                        upscoreSteps.get(selectedIndex + 1)?.mandatory
                    ) {
                        <button
                            (click)="nextStepWithCheck()"
                            [disabled]="
                                (selected?.stepControl?.valid ?? false) === false &&
                                !selected?.optional
                            "
                            class="primary-button-filled forward-button"
                            mat-button
                            type="submit">
                            <span data-cy="button-next">{{
                                upscoreSelected.stepControl.dirty
                                    ? upscoreSelected.saveAndNextButton
                                    : upscoreSelected.nextButton
                            }}</span>
                        </button>
                    }
                    @if (!upscoreSteps.get(selectedIndex + 1)?.mandatory) {
                        <div>
                            <button
                                (click)="nextStepWithCheck()"
                                [disabled]="
                                    (selected?.stepControl?.valid ?? false) === false &&
                                    !selected?.optional
                                "
                                class="primary-button-filled forward-button"
                                mat-button>
                                <span>{{ steps.get(selectedIndex + 1)?.label }}</span>
                            </button>
                            @if (upscoreSelected.nextButton != null) {
                                <button
                                    (click)="jumpToStep(selectedIndex + 2)"
                                    [disabled]="
                                        (selected?.stepControl?.valid ?? false) === false &&
                                        !selected?.optional
                                    "
                                    class="primary-button-filled forward-button"
                                    mat-button
                                    type="submit">
                                    <span data-cy="button-next">{{
                                        upscoreSelected.stepControl.dirty
                                            ? upscoreSelected.saveAndNextButton
                                            : upscoreSelected.nextButton
                                    }}</span>
                                </button>
                            }
                        </div>
                    }
                </div>
            }
            @if (selectedIndex === this.steps.length - 1) {
                <button
                    [disabled]="
                        (selected?.stepControl?.valid ?? false) === false || finalStepButtonDisabled
                    "
                    class="primary-button-filled forward-button"
                    (click)="
                        this.steps.last.stepControl == null || this.steps.last.stepControl.valid
                            ? this.stepsCompleted.emit()
                            : ''
                    "
                    mat-button
                    data-cy="button-analysis"
                    i18n="@@goToAnalysis">
                    Save & View Analysis
                </button>
            }
        </div>
    </footer>
</section>
