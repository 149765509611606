import { Component, Input } from '@angular/core';
import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { AbstractUserDataService, FeatureFlag } from '@shared/utils';
import { MobilityStats, UserInfo, VehicleStats } from '@upscore-mobility-audit/api';
import { StatsPercentageChange } from '@upscore-mobility-audit/shared/interfaces/stats-percentage-change.interface';
import { DataCollectionService } from '@upscore-mobility-audit/shared/services/data-collection.service';
import { UtilsService } from '@upscore-mobility-audit/shared/services/utils.service';

import { defaultMobilityStatsSliderOptions } from '../../constants/default-mobility-stats-slider-options.constant';
import { defaultVehicleStatsSliderOptions } from '../../constants/default-vehicle-stats-slider-options.constant';
import { InitialDataInputDialogTranslations } from '../../translations/initial-data-input-dialog-translations';
import { percentageChangeValidator } from '../../validators/percentage-change.validator';

@Component({
    selector: 'mobility-parameters-step',
    templateUrl: './mobility-parameters-step.component.html',
    styleUrls: ['./mobility-parameters-step.component.scss'],
})
export class MobilityParametersStepComponent {
    @Input() public mobilityParamsFormGroup: UntypedFormGroup;
    @Input() public companyLocationId: number;
    public readonly translations: typeof InitialDataInputDialogTranslations =
        InitialDataInputDialogTranslations;

    public featureFlag: typeof FeatureFlag = FeatureFlag;

    public percentageChangeValidatorFn = Validators.compose([
        Validators.required,
        percentageChangeValidator,
    ]) as ValidatorFn;
    public mobilityStatsSliderOptions: StatsPercentageChange[] = this.utilsService.deepClone(
        defaultMobilityStatsSliderOptions,
    );
    public vehicleStatsSliderOptions: StatsPercentageChange[] = this.utilsService.deepClone(
        defaultVehicleStatsSliderOptions,
    );

    constructor(
        private readonly dataCollectionService: DataCollectionService,
        private readonly utilsService: UtilsService,
        public readonly userDataService: AbstractUserDataService<UserInfo>,
    ) {}

    @Input()
    public set vehicleStats(value: VehicleStats) {
        if (value != null) {
            this.dataCollectionService.updateStatsSlider(this.vehicleStatsSliderOptions, value);
            this.vehicleStatsSliderOptions = [...this.vehicleStatsSliderOptions];
        }
    }

    @Input()
    public set mobilityStats(value: MobilityStats) {
        if (value != null) {
            this.dataCollectionService.updateStatsSlider(this.mobilityStatsSliderOptions, value);
            this.mobilityStatsSliderOptions = [...this.mobilityStatsSliderOptions];
        }
    }
}
