import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
    FormControl,
    FormsModule,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

import { LanguageSwitcherService, languages } from '@shared/utils';
import { TasksService } from '@upscore-mobility-audit/api';
import { UpscoreMobilityAuditUserDataService } from '@upscore-mobility-audit/shared/api-services/user-data.service';

@Component({
    selector: 'notify-user',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogActions,
        MatDialogContent,
        MatFormField,
        MatDialogTitle,
        MatInput,
        MatButton,
        MatDialogClose,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatOption,
        MatSelect,
    ],
    templateUrl: './notify-user.component.html',
    styleUrl: './notify-user.component.scss',
})
export class NotifyUserComponent {
    public form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        languageCode: ['', Validators.required],
    });

    protected readonly languages = languages;

    constructor(
        private tasksService: TasksService,
        private formBuilder: NonNullableFormBuilder,
        private languageSwitcherService: LanguageSwitcherService,
        private userDataService: UpscoreMobilityAuditUserDataService,
        @Inject(MAT_DIALOG_DATA) public taskId: string,
    ) {
        const user = this.userDataService.user;
        if (user) {
            const emailResults = Validators.email(new FormControl(user.email));
            // if there is a result its invalid
            const email = emailResults ? '' : user.email;

            const languageKey = this.languageSwitcherService.getCurrentLanguage();

            this.form.patchValue({
                email: email,
                languageCode: languages[languageKey].path,
            });
        }
    }

    public notifyUser(email: string, languageCode: string) {
        this.tasksService
            .notifyOnFinish({
                taskId: this.taskId,
                body: {
                    email: email,
                    languageCode: languageCode.toLowerCase(),
                },
            })
            .subscribe();

        return true;
    }
}
