import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { ExportDirective } from '@upscore-mobility-audit/shared-ui/directives/export.directive';
import { ExportFormat } from '@upscore-mobility-audit/shared-ui/services/export.service';
/**
 * Directive that marks a button that triggers an export action.
 *
 * The thing to be export has to have an `export` directive. If the `exportButton` is a descendent
 * of the `export` directive, it will trigger the export action automatically. If not, you need to link
 * the `exportButton` to the `export` directive by setting the `exportTriggerFor` input like this:
 *
 * ```html
 * <div export #export="toExport">
 *     text
 * </div>
 * <button exportButton [exportTriggerFor]="export" format="pdf">
 * ```
 */
@Directive({
    selector: '[exportButton]',
    standalone: true,
})
export class ExportButtonDirective {
    @Input({ required: true }) format: ExportFormat;
    @Input() exportTriggerFor: ExportDirective | undefined;
    @Output() export = new EventEmitter<ExportFormat>();

    @HostListener('click') onClick() {
        if (this.exportTriggerFor) {
            this.exportTriggerFor.export(this.format);
        } else {
            this.export.emit(this.format);
        }
    }
}
