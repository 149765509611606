import { AbstractControl } from '@angular/forms';

// percentage change validator for percentage distribution
export const percentageChangeValidator = (control: AbstractControl) => {
    if (control && control.value) {
        if (Object.values(control.value).length === 0) {
            return null;
        }
        const sum: number = (Object.values(control.value) as number[]).reduce(
            (a: number, b: number) => a + b,
            0,
        );
        // As we are checking for percentage
        // If the sum is bigger or smaller than 0.01
        // there is a problem with user input
        // else wise its just floating point tolerance
        if (Math.abs(sum - 1) >= 0.01) {
            return { percentageChange: true };
        }
    }

    return null;
};
