import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Entrance } from '@upscore-mobility-audit/api';
import { CacheResetBase } from '@upscore-mobility-audit/core/data-services/cache-reset-base.service';

@Injectable({
    providedIn: 'root',
})
export class AuditInputDataService extends CacheResetBase {
    public selectedDepartment$ = new BehaviorSubject<string>('All');

    // this should only be null when exiting an audit, possibly set it to some pre defined location instead of null?
    public selectedEntrances$ = new BehaviorSubject<Entrance[] | null>(null);
    public selectedScenarioId$ = new BehaviorSubject<number | null>(null);

    protected override auditClosed() {
        this.selectedScenarioId$.next(null);
    }
}
