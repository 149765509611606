import { Injectable } from '@angular/core';

import { MobilityStats, VehicleStats } from '@upscore-mobility-audit/api';

import { StatsPercentageChange } from '../interfaces/stats-percentage-change.interface';

@Injectable({
    providedIn: 'root',
})
export class DataCollectionService {
    /**
     * update stats slider
     * @param stats
     * @param values
     */
    public updateStatsSlider(
        stats: StatsPercentageChange[],
        values: VehicleStats | MobilityStats,
    ): void {
        stats.forEach((sliderOption: StatsPercentageChange) => {
            // @ts-expect-error: TS7053
            sliderOption.sliderOptions.value = Math.round(values[sliderOption.identifier] * 100);
        });
    }
}
