import { v4 as uuidv4 } from 'uuid';

import { CustomImprovement, MobilityIntervention } from '@upscore-mobility-audit/api';

export function defaultCustomImprovement(): CustomImprovement {
    return {
        order: 10,
        type: 'CustomImprovement',
        disablesMobilityScore: false,
        id: uuidv4(),
        parameters: [
            { type: 'SimpleImprovementParameter', name: 'customName', value: 'Custom Name' },
            {
                type: 'ModeMapParameter',
                name: 'modeMap',
                value: { Walk: { type: 'CutoffCriteria', metric: { type: 'duration' }, max: 15 } },
            },
        ],
    };
}

export function computeOtherMeasures(suggestedMeasures: MobilityIntervention[]) {
    const measures: MobilityIntervention[] = [];
    if (!suggestedMeasures.find(it => it.type == 'CustomImprovement')) {
        measures.push(defaultCustomImprovement());
    }

    return measures;
}
