// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isEmpty, pick } from 'lodash-es';
import { Subject } from 'rxjs';

import { AbstractUserDataService, FeatureFlag } from '@shared/utils';
import {
    AggregatedEstimationQuality,
    CalculationProperties,
    CompanyLocation,
    Employee,
    EmployeeIdAndLocationAccuracy,
    EmployeePut,
    UserInfo,
} from '@upscore-mobility-audit/api';
import { DisplayModeOptions } from '@upscore-mobility-audit/kpi/interfaces/transport-modes.interface';
import { TripDetailsTranslations } from '@upscore-mobility-audit/map/translations/trip-details-translations';
import { EmployeesWrapperService } from '@upscore-mobility-audit/shared/api-services/employees-wrapper.service';
import { GeocodingData } from '@upscore-mobility-audit/shared/interfaces/geocoding-data.interface';
import { UpscoreTable } from '@upscore-mobility-audit/shared/interfaces/upscore-table.interface';
import { UpscoreTableModel } from '@upscore-mobility-audit/shared-ui/components/upscore-table/upscore-table.model';

import {
    AggregatedGeocodingTableData,
    CarVehicleType,
    EmployeeTableData,
} from '../../interfaces/employee-table-classes';
import { EmployeeDetailsTableTranslations } from '../../translations/employee-details-table-translations';

import { EditLocationDialogComponent } from './edit-location-dialog/edit-location-dialog.component';

@Component({
    selector: 'employee-details-table',
    templateUrl: './employee-details-table.component.html',
    styleUrls: ['./employee-details-table.component.scss'],
})
export class EmployeeDetailsTableComponent implements OnInit, OnDestroy {
    @Input() public companyLocation: CompanyLocation;
    @Input() public employees: EmployeeTableData[];
    @Input() public geocodedEmployeeData: EmployeeIdAndLocationAccuracy[];
    @Input() public companyDepartments: string[];
    @Input() public searchEmployeeInfo: number;
    @Input() public userProperties: CalculationProperties;
    @Input() public companyProperties: CalculationProperties;
    @Input() public set geocodingData(geocodingData?: GeocodingData) {
        if (geocodingData.aggregatedGeocodingData?.length > 0) {
            this.initAggregatedGeocodingInfoTable(geocodingData.aggregatedGeocodingData);
        } else {
            this.aggregatedGeocodingInfoTableData = [];
        }
        this.employeeGeocodingData = geocodingData?.geocodedEmployeeData;
        if (this.employeeGeocodingCheckbox) {
            this.showHideEmployeeGeocodingData(true);
        }
    }

    @Output() public changes = new EventEmitter<void>();

    public employeeGeocodingData: EmployeeIdAndLocationAccuracy[];
    public employeeData: EmployeeTableData[];
    public readonly translations: typeof EmployeeDetailsTableTranslations =
        EmployeeDetailsTableTranslations;
    public employeeTableModel: UpscoreTableModel = new UpscoreTableModel();
    public aggregatedGeocodingInfoTableModel: UpscoreTableModel = new UpscoreTableModel();
    public showEmployeeGeocodingCheckbox = true;
    public transportModes: DisplayModeOptions[] = [
        {
            displayName: TripDetailsTranslations.walk,
            value: 'WALK',
        },
        {
            displayName: TripDetailsTranslations.bike,
            value: 'BIKE',
        },
        {
            displayName: TripDetailsTranslations.publicTransport,
            value: 'PUBLIC_TRANSPORT',
        },
        {
            displayName: TripDetailsTranslations.carDriver,
            value: 'CAR_DRIVER',
        },
    ];

    public vehicleTypes: DisplayModeOptions[] = [
        {
            displayName: TripDetailsTranslations.electricVehicle,
            value: CarVehicleType.ELECTRIC_VEHICLE,
        },
        {
            displayName: TripDetailsTranslations.largeVehicle,
            value: CarVehicleType.LARGE_VEHICLE,
        },
        {
            displayName: TripDetailsTranslations.smallVehicle,
            value: CarVehicleType.SMALL_VEHICLE,
        },
        {
            displayName: TripDetailsTranslations.regularVehicle,
            value: CarVehicleType.REGULAR_VEHICLE,
        },
    ];
    public employeeTableColumns: UpscoreTable[] = [
        {
            sectionHeader: this.translations.realIdText,
            field: 'realId',
            isEditable: true,
            confirmDelete: false,
            searchable: true,
        },
        {
            sectionHeader: this.translations.addressText,
            field: 'address',
            isEditable: false,
            showButton: true,
            buttonIcon: 'search',
            inputType: 'textArea',
            searchable: true,
            buttonPurpose: 'address',
            openDialog: true,
        },
        {
            sectionHeader: this.translations.entranceNoText,
            field: 'entranceNumber',
            isEditable: true,
            inputType: 'dropdown',
        },
        {
            sectionHeader: this.translations.daysInOfficeText,
            field: 'daysInOffice',
            isEditable: true,
            inputType: 'number',
            minNumber: 0,
            placeHolder: '(0-5)',
        },
        {
            sectionHeader: this.translations.hoursPerWeekText,
            field: 'hoursPerWeek',
            isEditable: true,
            inputType: 'number',
            minNumber: 0,
            placeHolder: '(0-60)',
        },
        {
            sectionHeader: this.translations.entryTimeText,
            field: 'entryTime',
            isEditable: true,
            inputType: 'time',
        },
        {
            sectionHeader: this.translations.exitTimeText,
            field: 'exitTime',
            isEditable: true,
            inputType: 'time',
        },
        {
            sectionHeader: this.translations.fixedModeText,
            field: 'fixedMode',
            isEditable: true,
            inputType: 'dropdown',
            unset: true,
            dropDownValues: this.transportModes,
            dropDownValueKey: 'value',
            dropDownValueDisplayKey: 'displayName',
        },
        {
            sectionHeader: this.translations.impossibleModesText,
            field: 'impossibleModes',
            isEditable: true,
            inputType: 'dropdown',
            unset: true,
            dropDownValues: this.transportModes,
            dropDownValueKey: 'value',
            dropDownValuesMultiple: true,
            dropDownValueDisplayKey: 'displayName',
        },
        {
            sectionHeader: this.translations.secondaryFixedModeText,
            field: 'secondaryFixedMode',
            isEditable: true,
            inputType: 'dropdown',
            unset: true,
            dropDownValues: this.transportModes,
            dropDownValueKey: 'value',
            dropDownValueDisplayKey: 'displayName',
        },
        {
            sectionHeader: this.translations.departmentText,
            field: 'department',
            isEditable: true,
            inputType: 'autocomplete',
        },
        {
            sectionHeader: this.translations.co2EmissionsText,
            field: 'co2Emissions',
            isEditable: true,
            inputType: 'number',
        },
        {
            sectionHeader: this.translations.vehicleTypeText,
            field: 'vehicleType',
            isEditable: true,
            inputType: 'dropdown',
            unset: true,
            dropDownValues: this.vehicleTypes,
            dropDownValueKey: 'value',
            dropDownValueDisplayKey: 'displayName',
        },
        {
            sectionHeader: this.translations.vehicleIdText,
            field: 'vehicleId',
            isEditable: true,
        },
    ];
    public aggregatedGeocodingInfoDataTableColumns: UpscoreTable[] = [
        {
            sectionHeader: this.translations.accuracySourceText,
            field: 'sourceType',
            isEditable: false,
        },
        {
            sectionHeader: this.translations.employeeCountText,
            field: 'count',
            isEditable: false,
            showButton: true,
            buttonIcon: 'info_outline',
            buttonPurpose: 'details',
        },
    ];
    public companyId: number;
    public locationKeys: string[] = [
        'city',
        'country',
        'street',
        'zipcode',
        'latitude',
        'longitude',
    ];
    public originalColumns: UpscoreTable[];
    public originalEmployees: Employee[];
    public aggregatedGeocodingInfoTableData: AggregatedGeocodingTableData[];
    public locationEditRowIndex: number;
    public employeeGeocodingCheckbox = false;
    public defaultStartTime;
    public defaultEndTime;

    protected readonly FeatureFlag = FeatureFlag;

    private readonly unsubscribe$: Subject<void> = new Subject();

    constructor(
        public readonly userDataService: AbstractUserDataService<UserInfo>,
        private readonly employeeService: EmployeesWrapperService,
        private readonly dialog: MatDialog,
        private dialogRef: MatDialogRef<EditLocationDialogComponent>,
    ) {}

    public ngOnInit(): void {
        this.companyId = this.companyLocation?.id;
        const index: number = this.employeeTableColumns.findIndex(
            ele => ele.field === 'entranceNumber',
        );

        this.employees = this.setDefaultTableValues(this.employees);
        this.employeeTableColumns[index].dropDownValues = this.companyLocation.entrances;
        this.employeeTableColumns[index].dropDownValueDisplayKey = 'number';
        this.employeeTableColumns[index].dropDownValueKey = 'number';

        this.originalColumns = this.employeeTableColumns;

        this.initTableProperties();
        this.flatNestedEmployeeListData(this.employees);
    }

    public setDefaultTableValues(empArr): Employee[] {
        this.defaultStartTime = this.companyProperties?.entryTime
            ? this.companyProperties?.entryTime
            : this.userProperties?.entryTime
              ? this.userProperties?.entryTime
              : '09:00:00';

        this.defaultEndTime = this.companyProperties?.exitTime
            ? this.companyProperties?.exitTime
            : this.userProperties?.exitTime
              ? this.userProperties?.exitTime
              : '17:00:00';

        const defaultDaysInOffice = this.companyLocation.presenceDays
            ? this.companyLocation.presenceDays
            : 4.3;

        return empArr.map(obj => {
            const daysInOffice = obj?.daysInOffice
                ? obj.daysInOffice
                : obj.hoursPerWeek
                  ? obj.hoursPerWeek / 5
                  : defaultDaysInOffice;
            const hoursPerWeek = obj?.hoursPerWeek ? obj.hoursPerWeek : 5 * daysInOffice;

            return {
                ...obj,
                entryTime: obj.entryTime ? obj.entryTime : this.defaultStartTime,
                exitTime: obj.exitTime ? obj.exitTime : this.defaultEndTime,
                daysInOffice,
                hoursPerWeek,
            };
        });
    }

    public initAggregatedGeocodingInfoTable(
        aggregatedGeocodingData: AggregatedEstimationQuality[],
    ): void {
        const displayCols: string[] = this.aggregatedGeocodingInfoDataTableColumns.map(
            (tableColumn: UpscoreTable) => tableColumn.field,
        );

        this.aggregatedGeocodingInfoTableModel.displayedColumns = displayCols;
        this.aggregatedGeocodingInfoTableData = [];
        const accuracySourceList: string[] = Object.keys(aggregatedGeocodingData);
        accuracySourceList.forEach(source => {
            const aggregatedGeoCodingInfoObj: AggregatedGeocodingTableData = {
                sourceType: source,
                count: aggregatedGeocodingData[source].count,
            };

            if (!isEmpty(aggregatedGeocodingData[source].locationAccuracy)) {
                aggregatedGeoCodingInfoObj.displayDetails = JSON.stringify(
                    aggregatedGeocodingData[source].locationAccuracy,
                );
            }

            this.aggregatedGeocodingInfoTableData.push(aggregatedGeoCodingInfoObj);
        });
    }

    public showHideEmployeeGeocodingData(show: boolean) {
        let emps: Employee[];
        if (show) {
            if (this.employeeGeocodingData?.length > 0) {
                this.addGeoCodingInfo(this.employeeData, this.employeeGeocodingData);
                this.initTableProperties();
            }
        } else {
            this.employeeTableColumns = this.originalColumns;
            emps = this.originalEmployees;
            this.initTableProperties();
            this.flatNestedEmployeeListData(emps);
        }
    }

    public onCheckboxChange(evnt: Event): void {
        const checked = (evnt.currentTarget as HTMLInputElement).checked;

        this.showHideEmployeeGeocodingData(checked);
    }

    public addGeoCodingInfo(
        emparr: EmployeeTableData[],
        employeeGeocodingData: EmployeeIdAndLocationAccuracy[],
    ): void {
        this.employeeTableColumns = [
            ...this.originalColumns,
            {
                sectionHeader: this.translations.locationAccuracyText,
                field: 'employeeLocationAccuracy',
                isEditable: false,
            },
            {
                sectionHeader: this.translations.rawAccuracyText,
                field: 'rawAccuracy',
                isEditable: false,
            },
            {
                sectionHeader: this.translations.accuracySourceText,
                field: 'accuracySource',
                isEditable: false,
            },
        ];

        const employeeInfoArr: EmployeeTableData[] = emparr;
        employeeInfoArr.forEach((ele: EmployeeTableData) => {
            const geocodingInfo: EmployeeIdAndLocationAccuracy = employeeGeocodingData.find(
                emp => emp.id === ele.id,
            );
            if (geocodingInfo) {
                ele.employeeLocationAccuracy = geocodingInfo.locationAccuracy;
                ele.rawAccuracy = geocodingInfo.rawAccuracy;
                ele.accuracySource = geocodingInfo.accuracySource;
            }
        });

        this.employeeData = [...employeeInfoArr];
    }

    public flatNestedEmployee(employee: EmployeeTableData): void {
        employee.address =
            employee.street +
            ',' +
            employee.city +
            ',' +
            employee.country +
            ',' +
            employee.postalCode;
        employee.co2Emissions = employee.vehicleInfo?.co2Emissions;
        employee.vehicleType = employee.vehicleInfo?.vehicleType;
        employee.vehicleId = employee.vehicleInfo?.vehicleId;
        employee.secondaryFixedMode = employee.secondaryFixedMode?.secondaryMode;
    }

    public flatNestedEmployeeListData(employees: EmployeeTableData[]): void {
        employees.forEach((employee: EmployeeTableData) => {
            this.flatNestedEmployee(employee);
        });
        this.employeeData = [...employees];
        this.originalEmployees = this.employeeData;
    }

    public initTableProperties(): void {
        const displayCols: string[] = this.employeeTableColumns.map(
            (tableColumn: UpscoreTable) => tableColumn.field,
        );
        displayCols.unshift('action');

        this.employeeTableModel.displayedColumns = displayCols;
        this.employeeTableModel.showEditButton = true;
        this.employeeTableModel.autocompleteoptions.push({
            id: 'department',
            options: this.companyDepartments,
        });
        this.employeeTableModel.enableTableSearches = true;
    }

    /* Edit employee info without opening location edit modal */
    public onEditRow(evnt: { id: number; editedData: EmployeeTableData }): void {
        this.onEditEmployee(evnt.id, this.companyId, evnt.editedData);
    }

    public onDeleteRow(evnt: { id: number; editedData: EmployeeTableData }) {
        this.onDeleteEmployee(evnt.id, this.companyId);
    }

    public onDeleteEmployee(id: number, companyLocationId: number): void {
        this.employeeService.deleteEmployee({ id, companyLocationId }).subscribe(() => {
            this.employeeData = this.employeeData.filter(emp => emp.id !== id);

            this.changes.next();
        });
    }

    /* Edit employee info after opening location edit modal */
    public onEditLocation(val: { rowData: EmployeeTableData; index: number }): void {
        this.dialogRef = this.dialog.open(EditLocationDialogComponent, {
            data: val.rowData,
        });
        this.dialogRef.afterClosed().subscribe(res => {
            if (res) {
                const rowToEditIndex: number = this.employeeData.findIndex(
                    ele => ele.id === val.rowData.id,
                );

                this.employeeData[rowToEditIndex].address =
                    (res.data.street ? res.data.street : val.rowData.street) +
                    ',' +
                    (res.data.city ? res.data.city : val.rowData.city) +
                    ',' +
                    (res.data.country ? res.data.country : val.rowData.country) +
                    ',' +
                    (res.data.zipcode ? res.data.zipcode : val.rowData.postalCode);

                this.employeeData = [...this.employeeData];
                this.locationEditRowIndex = rowToEditIndex;
            }
        });
    }

    public onEditEmployee(
        id: number,
        companyLocationId: number,
        editedData: EmployeeTableData,
    ): void {
        const locationData: unknown = {};
        const empUpdateObj: EmployeePut = {};

        if (editedData.postalCode) {
            editedData.zipcode = editedData.postalCode;
            delete editedData.postalCode;
        }
        if (editedData.address) {
            const addressInfo = editedData.address.split(',');
            editedData.street = addressInfo[0];
            editedData.city = addressInfo[1];
            editedData.country = addressInfo[2];
            editedData.zipcode = addressInfo[3];
            delete editedData.address;
        }

        if (!editedData.vehicleInfo) {
            editedData.vehicleInfo = {};
        }
        editedData.vehicleInfo.vehicleId = editedData.vehicleId;
        editedData.vehicleInfo.co2Emissions = editedData.co2Emissions;
        editedData.vehicleInfo.vehicleType = editedData.vehicleType;

        for (const key in editedData) {
            if (this.locationKeys.includes(key)) {
                if (editedData[key]) {
                    locationData[key] = editedData[key];
                }
            } else {
                empUpdateObj[key] = editedData[key];
            }
        }
        if (!isEmpty(locationData)) {
            empUpdateObj.location = locationData;
        }

        empUpdateObj.impossibleModes = (editedData.impossibleModes ?? []).join(',');

        const body = this.filterObjectByInterface(empUpdateObj);
        this.employeeService
            .updateEmployee({ id, companyLocationId, body })
            .subscribe(updatedEmployeeInfo => {
                const employeeInfo = updatedEmployeeInfo as unknown as EmployeeTableData;
                this.flatNestedEmployee(employeeInfo);

                this.employeeData = this.employeeData.map(item =>
                    item.id === employeeInfo.id ? employeeInfo : item,
                );

                this.locationEditRowIndex = null;

                this.changes.next();
            });
    }

    public filterObjectByInterface(obj: EmployeePut): EmployeePut {
        const interfaceKeys: Array<keyof EmployeePut> = [
            'daysInOffice',
            'department',
            'entranceNumber',
            'entryTime',
            'exitTime',
            'fixedMode',
            'impossibleModes',
            'hoursPerWeek',
            'location',
            'secondaryFixedMode',
            'shift',
            'realId',
            'vehicleInfo',
        ];

        return pick(obj, interfaceKeys) as EmployeePut;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
