import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isEqual, pickBy } from 'lodash-es';

import { AbstractUserDataService, PlacesInputPipe } from '@shared/utils';
import { Employee, UserInfo } from '@upscore-mobility-audit/api';
import { PlacesResultFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/places-result-form-group.interface';

@Component({
    selector: 'edit-location-dialog',
    templateUrl: './edit-location-dialog.component.html',
    styleUrls: ['./edit-location-dialog.component.scss'],
})
export class EditLocationDialogComponent implements AfterViewInit {
    public placeResultFormGroup: FormGroup<PlacesResultFormGroup>;
    public locationFormGroup;

    constructor(
        private formBuilder: FormBuilder,
        public userDataService: AbstractUserDataService<UserInfo>,
        private dialogRef: MatDialogRef<EditLocationDialogComponent, { data?: Employee }>,
        @Inject(MAT_DIALOG_DATA) public data?: Employee,
    ) {
        this.placeResultFormGroup = this.formBuilder.group({});
        this.locationFormGroup = this.formBuilder.group({
            latitude: [this.data?.latitude, Validators.required],
            longitude: [this.data?.longitude, Validators.required],
            street: [this.data?.street, Validators.required],
            city: [this.data?.city, Validators.required],
            country: [this.data?.country],
            zipcode: [this.data?.postalCode, Validators.required],
        });
    }

    public ngAfterViewInit(): void {
        this.placeResultFormGroup.controls.placeResult?.valueChanges.subscribe(place => {
            if (place) {
                const placesPipe: PlacesInputPipe = new PlacesInputPipe();
                const streetNumber: string = placesPipe.transform(place, 'street_number');
                const street: string = placesPipe.transform(place, 'route');
                this.locationFormGroup
                    .get('street')
                    ?.setValue(streetNumber == null ? street : street + ' ' + streetNumber);
                this.locationFormGroup
                    .get('city')
                    ?.setValue(placesPipe.transform(place, 'locality'));
                this.locationFormGroup
                    .get('country')
                    ?.setValue(placesPipe.transform(place, 'country'));
                this.locationFormGroup
                    .get('zipcode')
                    ?.setValue(placesPipe.transform(place, 'postal_code'));
                const lat = place.geometry?.location?.lat();
                const lng = place.geometry?.location?.lng();
                if (lat && lng) {
                    this.locationFormGroup.controls.latitude.setValue(lat);
                    this.locationFormGroup.controls.longitude.setValue(lng);
                }
            }
        });
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onUpdateLocation(): void {
        if (this.data) {
            // @ts-expect-error: Typing issues
            const result: Employee & { isEditable?: boolean } = pickBy(
                this.locationFormGroup.value,
                // @ts-expect-error: Typing issues
                (v, k) => !isEqual(this.data[k], v),
            );
            delete result.isEditable;

            this.dialogRef.close({ data: result });
        } else {
            this.dialogRef.close({ data: undefined });
        }
    }
}
