import { getCatchmentSlidersSection } from '@upscore-mobility-audit/core/constants/catchment-sliders.constants';
import { SidebarSectionContentLabel } from '@upscore-mobility-audit/shared/enums/sidebar-section-content-label';
import { SidebarSectionContentType } from '@upscore-mobility-audit/shared/enums/sidebar-section-content-type.enum';
import { SidebarSectionHeaderLabel } from '@upscore-mobility-audit/shared/enums/sidebar-section-header-label.enum';
import { SidebarSectionType } from '@upscore-mobility-audit/shared/enums/sidebar-section-type.enum';
import { SidebarSection } from '@upscore-mobility-audit/shared/interfaces/sidebar-section.interface';

export function getStatusQuoSections(): SidebarSection[] {
    return [
        {
            type: SidebarSectionType.STATUS_QUO,
            header: {
                label: SidebarSectionHeaderLabel.EMPLOYEE_LOCATION,
                isHidden: true,
                isExpandable: true,
                isExpanded: false,
                disabled: false,
            },
            contentElements: [
                {
                    label: SidebarSectionContentLabel.MARKER_LOCATION,
                    type: SidebarSectionContentType.EMPLOYEE_MARKER,
                    disabledByDependency: false,
                    isHidden: false,
                    dependencies: [SidebarSectionContentLabel.MARKER_CLUSTERING],
                },
                {
                    type: SidebarSectionContentType.TOGGLE,
                    label: SidebarSectionContentLabel.MARKER_CLUSTERING,
                    isHidden: true,
                    dependencies: [
                        SidebarSectionContentLabel.CLUSTERING_RENDERER,
                        SidebarSectionContentLabel.CLUSTERING_ALGORITHM,
                    ],
                    disabledByDependency: false,
                },
                {
                    type: SidebarSectionContentType.TOGGLE,
                    label: SidebarSectionContentLabel.CLUSTERING_RENDERER,
                    isHidden: true,
                    disabledByDependency: false,
                },
                {
                    type: SidebarSectionContentType.TOGGLE,
                    label: SidebarSectionContentLabel.CLUSTERING_ALGORITHM,
                    isHidden: true,
                    disabledByDependency: false,
                },
                {
                    type: SidebarSectionContentType.TOGGLE,
                    label: SidebarSectionContentLabel.HEATMAP,
                    isHidden: true,
                    disabledByDependency: false,
                },
            ],
        },
        ...getCatchmentSlidersSection(SidebarSectionType.STATUS_QUO),
    ];
}
