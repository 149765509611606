import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { InterventionType } from '@upscore-mobility-audit/core/interfaces/intervention-type.interface';

@Component({
    selector: 'measure-icon',
    standalone: true,
    imports: [CommonModule, MatIcon],
    templateUrl: './measure-icon.component.html',
    styleUrl: './measure-icon.component.scss',
})
export class MeasureIconComponent {
    @Input() public measureType: InterventionType;
    @Input() public size: 'small' | 'medium' | 'large' = 'medium';
}
