import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AbstractUserDataService, FeatureFlag } from '@shared/utils';
import {
    CalculationProperties,
    MobilityPropertiesService,
    UserInfo,
} from '@upscore-mobility-audit/api';

@Injectable({
    providedIn: 'root',
})
export class UserPropertiesDataService {
    public userProperties$ = new BehaviorSubject<CalculationProperties | undefined>(undefined);

    constructor(
        private readonly mobilityPropertiesService: MobilityPropertiesService,
        private readonly userDataService: AbstractUserDataService<UserInfo>,
    ) {}

    /**
     * Get the user and location calculation properties and publish them to the respective subjects.
     *
     * Note that this method returns without calling the API if the `CALCULATION_PROPERTIES` feature flag is not enabled.
     */
    public getUserProperties(): Observable<CalculationProperties | undefined> {
        const canConfigureCalculationProperties = this.userDataService.showFeature(
            FeatureFlag.CALCULATION_PROPERTIES,
        );

        if (!canConfigureCalculationProperties) {
            this.userProperties$.next(undefined);

            return of(undefined);
        }

        return this.mobilityPropertiesService.getUserMobilityProperties().pipe(
            catchError(() => of(undefined)),
            tap(userProperties => {
                this.userProperties$.next(userProperties);
            }),
        );
    }
}
