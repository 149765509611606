import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-upload-button',
    templateUrl: './upload-button.component.html',
    styleUrls: ['./upload-button.component.scss'],
})
export class UploadButtonComponent {
    @Output() public uploadFile: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Method to open the file upload dialog
     */
    public openFileDialog(): void {
        this.uploadFile.emit();
    }
}
