import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';

import { TaskDefinition, TasksService } from '@upscore-mobility-audit/api';

import { TasksWrapperService } from './tasks-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class TaskDefinitionService {
    private taskDefinitions: { [taskId: string]: Observable<TaskDefinition> } = {};

    constructor(
        private tasksService: TasksService,
        tasksWrapperService: TasksWrapperService,
    ) {
        tasksWrapperService.taskDeleted.subscribe(taskId => {
            delete this.taskDefinitions[taskId];
        });
    }

    public getTaskDefinition(taskId: string) {
        if (this.taskDefinitions[taskId]) {
            return this.taskDefinitions[taskId];
        }

        this.taskDefinitions[taskId] = this.tasksService
            .getTaskDefinition({ taskId })
            .pipe(shareReplay(1));

        return this.taskDefinitions[taskId];
    }
}
