import { Injectable } from '@angular/core';

import { ThemeService } from '@shared/utils';

import { ZipCodeUtilsService } from './zip-code-utils.service';

export interface EmployeeMarkerCluster {
    markers: [
        {
            employee: {
                postalCode: string;
            };
            mode: string;
            position: google.maps.LatLngLiteral | undefined;
        },
    ];
}

@Injectable({
    providedIn: 'root',
})
export class ClusterChartCreatorService {
    public readonly allModes = ['WALK', 'BIKE', 'PUBLIC_TRANSPORT', 'CAR_DRIVER', 'CAR_PASSENGER'];
    public sizes = [40, 45, 50, 60, 70]; // 600, 675, 750, 900, 1050 would be the respective sizes if we do the scaling in the svg

    private readonly modeColors: string[];

    constructor(themeService: ThemeService) {
        this.modeColors = this.allModes.map(mode => themeService.getModeColor(mode));
    }

    /**
     * Create a modal split chart based on the given employee cluster
     * @param cluster
     * @param drawModalSplitDonut
     * @param zipCodeCluster
     * @param scaleIndex
     * @param zipCodeCluster
     * @param scaleIndex
     */
    public createModalSplitChart(
        cluster: EmployeeMarkerCluster,
        drawModalSplitDonut = false,
        zipCodeCluster = false,
        scaleIndex = 0,
    ): string {
        const employeesPerMode: number[] = this.allModes.map(
            mode => cluster.markers.filter(c => c.mode === mode).length,
        );
        const allVisibleEmployees = employeesPerMode.reduce((a, b) => a + b, 0);

        const center = 300;
        const radius = 230;

        let donutSvg = `<circle r="300" cx="${center}" cy="${center}" fill-opacity="0.4" fill="#1b4669"></circle>`;

        if (drawModalSplitDonut) {
            const arr = this.Donut(center, center, radius, employeesPerMode);
            donutSvg = arr
                .map((item, idx) =>
                    item
                        ? `<g><path d="${item.d}" stroke="${this.modeColors[idx]}" fill="none" stroke-width="120" /></g>`
                        : null,
                )
                .filter(item => item !== null)
                .join('\n');
        }

        let infoTextSvg = '';
        let styling = '';

        if (!zipCodeCluster) {
            const textScaleFactor = 1 / (this.sizes[scaleIndex] / 40);

            infoTextSvg = `
                <text x="50%" y="50%" class="employee-count">${allVisibleEmployees}</text>
            `;
            styling = `
                <style>
                    text {
                        fill: white;
                        font-family: 'source-sans-pro-semi-bold';
                        text-anchor: middle;
                        dominant-baseline: middle;
                    }
                    .employee-count {
                        font-size: ${160 * textScaleFactor}px;
                    }
                </style>
            `;
        }

        if (zipCodeCluster) {
            // this is needed to scale the text down, so that if the other parts are scaled up
            // by scaledSize: new Size()
            // the text is always the same size
            const textScaleFactor = 1 / (this.sizes[scaleIndex] / 40);

            const zipCode = ZipCodeUtilsService.stripCountryPrefix(
                ZipCodeUtilsService.stripPreClustering(cluster.markers[0].employee.postalCode),
            );

            infoTextSvg = `
                <text x="50%" y="42%" class="employee-count">${allVisibleEmployees}</text>
                <text x="50%" y="60%" class="postal-code" >${zipCode}</text>
            `;
            styling = `
                <style>
                    text {
                        fill: white;
                        font-family: 'source-sans-pro-semi-bold';
                        text-anchor: middle;
                        dominant-baseline: middle;
                    }
                    .employee-count {
                        font-size: ${160 * textScaleFactor}px;
                    }
                    .postal-code {
                        font-size: ${110 * textScaleFactor}px;
                    }
                </style>
            `;
        }

        return `<svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
            ${styling}
            ${donutSvg}
            <circle r="${
                zipCodeCluster ? 201 : 172
            }" cx="${center}" cy="${center}" fill="#004b7d"></circle>
            ${infoTextSvg}
        </svg>`;
    }

    private Donut(
        cx: number,
        cy: number,
        radius: number,
        data: number[],
    ): { d: number | string }[] {
        function arcradius(cx: number, cy: number, radius: number, degrees: number) {
            const radians = ((degrees - 90) * Math.PI) / 180.0;

            return { x: cx + radius * Math.cos(radians), y: cy + radius * Math.sin(radians) };
        }

        const decimals = 4;
        const total = data.reduce((a, b) => a + b, 0);
        const arr = [];
        let beg = 0;
        let end = 0;
        let count = 0;

        for (let i = 0; i < data.length; i++) {
            const item: any = data[i];

            if (item === 0) {
                arr.push(null);
                continue;
            }

            const tmp: any = {};

            let p = Math.round((item / total) * 100);

            count += p;

            if (i === length - 1 && count < 100) {
                p = p + (100 - count);
            }

            end = beg + (360 / 100) * p;

            tmp.index = i;
            tmp.value = item;
            tmp.data = item;

            const b = arcradius(cx, cy, radius, end);
            const e = arcradius(cx, cy, radius, beg);
            const la = end - beg <= 180 ? 0 : 1;

            tmp.d = [
                'M',
                Math.floor(b.x * Math.pow(10, decimals)) / Math.pow(10, decimals),
                Math.floor(b.y * Math.pow(10, decimals)) / Math.pow(10, decimals),
                'A',
                radius,
                radius,
                0,
                la,
                0,
                Math.floor(e.x * Math.pow(10, decimals)) / Math.pow(10, decimals),
                Math.floor(e.y * Math.pow(10, decimals)) / Math.pow(10, decimals),
            ].join(' ');
            arr.push(tmp);
            beg = end;
        }

        return arr;
    }
}
