/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MobilityStats } from '../models/mobility-stats';
import { ModeEstimationConfigWithCarpoolInfo } from '../models/mode-estimation-config-with-carpool-info';
import { ModeEstimationTask } from '../models/mode-estimation-task';

@Injectable({
  providedIn: 'root',
})
export class ModeEstimationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation estimateWithBaseParams
   */
  static readonly EstimateWithBaseParamsPath = '/api/v1/mode-estimation/estimate/{companyLocationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimateWithBaseParams()` instead.
   *
   * This method doesn't expect any request body.
   */
  estimateWithBaseParams$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MobilityStats>> {

    const rb = new RequestBuilder(this.rootUrl, ModeEstimationService.EstimateWithBaseParamsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MobilityStats>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `estimateWithBaseParams$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  estimateWithBaseParams(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<MobilityStats> {

    return this.estimateWithBaseParams$Response(params).pipe(
      map((r: StrictHttpResponse<MobilityStats>) => r.body as MobilityStats)
    );
  }

  /**
   * Path part for operation estimateWithCustomParams
   */
  static readonly EstimateWithCustomParamsPath = '/api/v1/mode-estimation/estimate/{companyLocationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimateWithCustomParams()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  estimateWithCustomParams$Response(params: {
    companyLocationId: number;
    improvementOnly?: boolean;
    overrideFixedModes?: boolean;
    context?: HttpContext
    body: ModeEstimationConfigWithCarpoolInfo
  }
): Observable<StrictHttpResponse<MobilityStats>> {

    const rb = new RequestBuilder(this.rootUrl, ModeEstimationService.EstimateWithCustomParamsPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('improvementOnly', params.improvementOnly, {});
      rb.query('overrideFixedModes', params.overrideFixedModes, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MobilityStats>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `estimateWithCustomParams$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  estimateWithCustomParams(params: {
    companyLocationId: number;
    improvementOnly?: boolean;
    overrideFixedModes?: boolean;
    context?: HttpContext
    body: ModeEstimationConfigWithCarpoolInfo
  }
): Observable<MobilityStats> {

    return this.estimateWithCustomParams$Response(params).pipe(
      map((r: StrictHttpResponse<MobilityStats>) => r.body as MobilityStats)
    );
  }

  /**
   * Path part for operation estimateWithBaseParamsAsync
   */
  static readonly EstimateWithBaseParamsAsyncPath = '/api/v1/mode-estimation/async/estimate/{companyLocationId}';

  /**
   * Start a task for mode estimation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimateWithBaseParamsAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  estimateWithBaseParamsAsync$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ModeEstimationTask>> {

    const rb = new RequestBuilder(this.rootUrl, ModeEstimationService.EstimateWithBaseParamsAsyncPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModeEstimationTask>;
      })
    );
  }

  /**
   * Start a task for mode estimation.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `estimateWithBaseParamsAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  estimateWithBaseParamsAsync(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<ModeEstimationTask> {

    return this.estimateWithBaseParamsAsync$Response(params).pipe(
      map((r: StrictHttpResponse<ModeEstimationTask>) => r.body as ModeEstimationTask)
    );
  }

  /**
   * Path part for operation estimateWithCustomParamsAsync
   */
  static readonly EstimateWithCustomParamsAsyncPath = '/api/v1/mode-estimation/async/estimate/{companyLocationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimateWithCustomParamsAsync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  estimateWithCustomParamsAsync$Response(params: {
    companyLocationId: number;
    improvementOnly?: boolean;
    overrideFixedModes?: boolean;
    context?: HttpContext
    body: ModeEstimationConfigWithCarpoolInfo
  }
): Observable<StrictHttpResponse<ModeEstimationTask>> {

    const rb = new RequestBuilder(this.rootUrl, ModeEstimationService.EstimateWithCustomParamsAsyncPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('improvementOnly', params.improvementOnly, {});
      rb.query('overrideFixedModes', params.overrideFixedModes, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModeEstimationTask>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `estimateWithCustomParamsAsync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  estimateWithCustomParamsAsync(params: {
    companyLocationId: number;
    improvementOnly?: boolean;
    overrideFixedModes?: boolean;
    context?: HttpContext
    body: ModeEstimationConfigWithCarpoolInfo
  }
): Observable<ModeEstimationTask> {

    return this.estimateWithCustomParamsAsync$Response(params).pipe(
      map((r: StrictHttpResponse<ModeEstimationTask>) => r.body as ModeEstimationTask)
    );
  }

  /**
   * Path part for operation getDefaultParameters
   */
  static readonly GetDefaultParametersPath = '/api/v1/mode-estimation/defaults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultParameters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultParameters$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ModeEstimationService.GetDefaultParametersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDefaultParameters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultParameters(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.getDefaultParameters$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
