import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { UpscoreTaskData } from '../../interfaces/task-data.interface';
import { TaskNotificationComponent } from '../task-notification/task-notification.component';

@Component({
    selector: 'upscore-audit-task-notifications',
    standalone: true,
    imports: [CommonModule, TaskNotificationComponent],
    templateUrl: './audit-task-notifications.component.html',
    styleUrl: './audit-task-notifications.component.scss',
})
export class AuditTaskNotificationsComponent {
    @Input() public tasks: UpscoreTaskData[] = [];
}
