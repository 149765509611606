import { Pipe, PipeTransform } from '@angular/core';

import { TaskProgress } from '@upscore-mobility-audit/api';

import { TaskUtilsService } from '../services';

@Pipe({
    name: 'taskProgressPercentage',
    standalone: true,
})
export class TaskProgressPercentagePipe implements PipeTransform {
    constructor(private taskUtilsService: TaskUtilsService) {}

    public getProgressPercentage(task: TaskProgress, name: string, status: string): number {
        // for AuditProgress:
        // progress per scenario possible? = 100/scenarios
        // in each scenario its possible to calculate trips, interventions and kpis

        // example 4 scenarios, 100% / 4 = 25% per scenario
        // scenario 1: 0% - 25% (0-25)
        // scenario 1 trips finished: 8%
        // scenario 1 interventions finished: 16%
        // scenario 1 kpis finished: 25%
        // scenario 2: 25% - 50%
        // scenario 2 trips finished: 33%
        // scenario 2 interventions finished: 41%
        // scenario 2 kpis finished: 50%

        const progress = task as any;

        switch (name) {
            case 'AuditProgress': {
                const context = progress['context'] as {
                    allScenarios: number[];
                    currentScenario?: number;
                    tripsForScenario: number;
                    interventions: string[];
                };

                let scenarioProgress = 0;
                const perScenarioProgress = 1 / (context.allScenarios?.length ?? 1);

                // possible percentage progress by trips, interventions and kpis
                const perSubScenarioProgress = perScenarioProgress / 3;
                if (context.currentScenario) {
                    scenarioProgress = context.allScenarios.indexOf(context.currentScenario);
                }

                switch (status) {
                    case 'PlannedScenarios':
                        return 0;
                    case 'ScenarioStarted':
                        return Math.round(scenarioProgress * perScenarioProgress);
                    case 'TripsProgress':
                        return Math.round(
                            scenarioProgress * perScenarioProgress +
                                ((progress['currentAmount'] +
                                    (progress['direction'] == true
                                        ? 0
                                        : context.tripsForScenario)) /
                                    (context.tripsForScenario * 2)) *
                                    perSubScenarioProgress,
                        );
                    case 'ScenarioFinished':
                        return Math.round((scenarioProgress + 1) * perScenarioProgress);
                    case 'TripsFinished':
                    case 'InterventionStarted':
                        return Math.round(
                            scenarioProgress * perScenarioProgress + perSubScenarioProgress,
                        );
                    case 'InterventionFinished':
                    case 'CalculatingKpis':
                        return Math.round(
                            scenarioProgress * perScenarioProgress + 2 * perSubScenarioProgress,
                        );
                    default:
                        return 0;
                }
            }
            case 'EmployeeUploadProgress':
                return progress['currentEmployees'] / progress['totalEmployees'];
            default:
                return 0;
        }
    }

    transform(value: TaskProgress): number {
        const result = this.taskUtilsService.getTaskNameAndEvent(value);
        if (result == null) {
            return 0;
        }

        const [name, event] = result;

        switch (value.status) {
            case 'QUEUED':
            case 'STARTING':
            case 'FAILED':
            case 'CANCELLED':
            default:
                return 0;
            case 'IN_PROGRESS':
                return this.getProgressPercentage(value, name, event);
            case 'FINISHED':
                return 100;
        }
    }
}
