<div>
    @if (title) {
        <span class="title">
            {{ title }}
        </span>
    }
    @if (loading) {
        <div
            class="bar-wrapper bg-grey-superlight animate-pulse"
            [ngClass]="{
                '!rounded-[0.5rem]': displayPercentages,
                'h-[2rem]': displayPercentages,
                'h-[0.625rem]': !displayPercentages
            }"></div>
    } @else {
        <div
            [class.small-border]="displayPercentages"
            [class.with-title]="title != null"
            class="bar-wrapper">
            @if (!loading) {
                @for (bar of distribution; track bar; let first = $first; let last = $last) {
                    @if (bar.width > 0) {
                        <div
                            [class.bar-div]="!displayPercentages"
                            [class.display-percentages]="displayPercentages"
                            [class.first]="first"
                            [class.last]="last"
                            [style.background-color]="bar.color"
                            [style.width.%]="bar.width * 100">
                            @if (displayPercentages) {
                                <div class="h-full w-1 inline-block"></div>
                                <span class="percentage-text">{{ bar.width | percent }}</span>
                            }
                        </div>
                    }
                }
            }
        </div>
    }
</div>
