import { Pipe, PipeTransform } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ScenarioKpiService } from '@upscore-mobility-audit/core/data-services/scenario-kpi.service';

@Pipe({
    name: 'dailyKpi',
})
export class DailyKpiPipe implements PipeTransform {
    constructor(private readonly scenarioKpiService: ScenarioKpiService) {}

    /**
     * Important: if you ever use this pipe you need to chain it with the | async pipe because this pipe returns an observable
     * Transform yearly kpi value to daily kpi value
     * @param yearlyValue
     */
    public transform(yearlyValue: number): Observable<number> {
        return combineLatest([this.scenarioKpiService.selectedMobilityScenario$.notNull()]).pipe(
            take(1),
            map(([score]) => {
                return (
                    (yearlyValue /
                        score.maxPresenceDaysPerYear /
                        score.defaultPresenceDaysPerWeek) *
                    score.maxPresenceDaysPerWeek
                );
            }),
        );
    }
}
