<div class="dialog-wrapper">
    <span class="title mb-l inline-block" i18n="@@userSettings"> USER SETTINGS </span>
    <callout
        header="Affected Companies"
        i18n-header="@@settingsAffectedCompaniesHeader"
        i18n="@@settingsAffectedCompaniesContent"
        >These settings are taken into account for all <b> newly created companies</b>. To change
        assumptions for existing companies, edit their calculation properties in the
        <i>Edit Company</i> dialogue.</callout
    >
    <mobility-properties-form
        (resetCalculationPropertiesForm)="onResetForm()"
        [enableForm]="enableForm"
        [mobilityPropertiesForm]="userPropertiesForm"
        [enableModeEstimationConfig]="enableModeEstimationConfig"
        [userSettings]="true"></mobility-properties-form>
    <div class="footer-wrapper" mat-dialog-actions>
        <button
            (click)="onCancel()"
            class="cancel-button-filled cancel-button"
            mat-button
            type="button">
            <span i18n="@@cancel">Cancel</span>
        </button>
        <button
            (click)="onUpdateSettings()"
            [disabled]="!userPropertiesForm.valid"
            class="primary-button-filled"
            mat-button
            type="button">
            <span i18n="@@update">Update</span>
        </button>
    </div>
</div>
