// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { AbstractUserDataService, LoadingService } from '@shared/utils';
import { UserInfo } from '@upscore-mobility-audit/api';
import { KpiCard, KpiCardInfo } from '@upscore-mobility-audit/shared/enums/kpi-card.enum';
import {
    ExportService,
    SelectedCard,
} from '@upscore-mobility-audit/shared-ui/services/export.service';

import { ExportDialogTranslations } from '../../translations/export-dialog-translations';

@Component({
    selector: 'export-dialog',
    templateUrl: './export-dialog.component.html',
    styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent implements OnInit {
    @ViewChild('search') public search: ElementRef;
    @ViewChild('exportElement') public exportElement: ElementRef;
    public searchString = '';
    public kpiCards: Array<{ id: string; selected: boolean; size: number }> = [];
    public pages: SelectedCard[][];
    public kpiSelectionOpened = false;
    public currentPage = 0;
    public kpiCardValues: KpiCardInfo[] = [];
    public loading = false;
    public translations: typeof ExportDialogTranslations = ExportDialogTranslations;

    constructor(
        public readonly exportService: ExportService,
        public readonly userDataService: AbstractUserDataService<UserInfo>,
        private readonly loadingService: LoadingService,
    ) {}

    @HostListener('click', ['$event.target'])
    public onClick(target: HTMLElement): void {
        if (!target.classList.contains('empty-page')) {
            let element: HTMLElement | null = target;
            let targetIsChildOfInput = false;

            while (element !== null && !targetIsChildOfInput) {
                targetIsChildOfInput = element === this.search.nativeElement;
                element = element.parentElement;
            }

            this.kpiSelectionOpened = targetIsChildOfInput;
        }
    }

    public ngOnInit(): void {
        if (this.userDataService.user.accountType === 'PREMIUM') {
            this.kpiCardValues = (Object.values(KpiCard) as KpiCardInfo[]).filter(c =>
                c.userTypes.includes('PREMIUM'),
            );
        } else if (this.userDataService.user.accountType === 'LIGHT') {
            this.kpiCardValues = (Object.values(KpiCard) as KpiCardInfo[]).filter(c =>
                c.userTypes.includes('LIGHT'),
            );
        }

        this.exportService.pages.subscribe(p => {
            this.pages = p;
            this.filterKpiCards();
        });
    }

    public filterKpiCards(): void {
        const cards: KpiCardInfo[] = this.kpiCardValues
            .filter(c => c.title.toLowerCase().includes(this.searchString.toLowerCase()))
            .sort((c1, c2) => c1.title.localeCompare(c2.title));
        const allCards: SelectedCard[] = [].concat(...this.pages);

        this.kpiCards = [];

        for (const card of cards) {
            this.kpiCards.push({
                id: card.title,
                selected: allCards.find(c => c.id === card.title) !== undefined,
                size: card.size,
            });
        }
    }

    public selectionChanged(
        $event: MatCheckboxChange,
        card: { id: string; selected: boolean; size: number },
    ): void {
        card.selected = $event.checked;

        if ($event.checked) {
            const page: number = this.findUnoccupiedPage(this.currentPage, card.size, this.pages);

            if (!this.pages[page]) {
                this.pages.push([]);
            }

            this.pages[this.currentPage].push({
                id: card.id,
                type: card.id,
                size: card.size,
            });
        } else {
            for (const [i, page] of this.pages.entries()) {
                this.pages[i] = page.filter(c => c.id !== card.id);
            }

            if (this.currentPage >= this.pages.length) {
                this.changePage(this.currentPage - 1);
            }
        }

        this.exportService.pages.next(this.pages);

        this.reloadLayout();
    }

    public changePage(page: number): void {
        if (page >= 0 && page < this.pages.length) {
            this.currentPage = page;
        }
    }

    public switchCards($event: [number, number] | null): void {
        if ($event) {
            [this.pages[this.currentPage][$event[0]], this.pages[this.currentPage][$event[1]]] = [
                this.pages[this.currentPage][$event[1]],
                this.pages[this.currentPage][$event[0]],
            ];
        }

        this.exportService.pages.next(this.pages);

        this.reloadLayout();
    }

    public reloadLayout(): void {
        const pages: SelectedCard[][] = [];

        for (const [i, page] of this.pages.entries()) {
            let pageNo: number = i;

            if (!pages[i]) {
                pages[i] = [];
            }

            for (const card of page) {
                pageNo = this.findUnoccupiedPage(pageNo, card.size, pages);

                if (!pages[pageNo]) {
                    pages[pageNo] = [];
                }

                pages[pageNo].push(card);
            }
        }

        while (pages.length > 1 && pages[pages.length - 1].length === 0) {
            pages.pop();
        }

        if (this.currentPage >= pages.length - 1) {
            this.changePage(pages.length - 1);
        }

        this.exportService.pages.next(pages);
    }

    public cardPageChanged($event: [string, number, number]): void {
        const card: SelectedCard = this.pages[$event[1]].find(c => c.id === $event[0]);

        if (!this.pages[$event[2]]) {
            this.pages[$event[2]] = [];
        }

        this.pages[$event[2]].splice(0, 0, card);
        this.pages[$event[1]] = this.pages[$event[1]].filter(c => c !== card);

        this.exportService.pages.next(this.pages);

        this.reloadLayout();
    }

    public async export(): void {
        this.loadingService.startLoading();
        this.loading = true;

        await this.exportService
            .exportPDFOld(this.exportElement.nativeElement, 'KPIs.pdf')
            .then(() => {
                this.loadingService.endLoading();
                this.loading = false;
            });
    }

    public addText(): void {
        const card: SelectedCard = {
            id: String(Date.now()),
            type: this.translations.text,
            size: 2,
        };

        if (this.findUnoccupiedPage(this.currentPage, 2, this.pages) !== this.currentPage) {
            this.pages[this.currentPage].splice(0, 0, card);
        } else {
            this.pages[this.currentPage].push(card);
        }

        this.exportService.pages.next(this.pages);

        this.reloadLayout();
    }

    private findUnoccupiedPage(page: number, cardSize: number, pages: SelectedCard[][]): number {
        let sizeCounter = 0;

        if (page > pages.length - 1) {
            return page;
        }

        for (const selectedCard of pages[page]) {
            if (sizeCounter < 5 && sizeCounter + selectedCard.size > 5) {
                sizeCounter = 5;
            }

            sizeCounter += selectedCard.size;
        }

        if (sizeCounter + cardSize <= 10) {
            return page;
        }

        return this.findUnoccupiedPage(page + 1, cardSize, pages);
    }
}
