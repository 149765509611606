<ng-template (fileDropped)="onFileUpload($event)" appUploadAreaWrapper>
    <div [formGroup]="parentFormGroup" style="height: 100%">
        <div class="inner-container">
            <mat-icon class="upload-icon">create_new_folder</mat-icon>
            <h3 i18n="@@dragCSVHere">Drag CSV file into this area</h3>
            <p i18n="@@or">or</p>
            <app-upload-button (uploadFile)="onFileUploadButton()">
                <!--(fileUpload)="onFileUpload($event)">-->
            </app-upload-button>
        </div>
        <input
            #fileInput
            (change)="buttonOnFileUpload($event.target)"
            accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            hidden
            type="file" />
    </div>
</ng-template>
