<div class="flex flex-col gap-xxl">
    @if (nameParam()) {
        <mat-form-field class="w-1/2">
            <mat-label i18n="@@scenario.measureName">Measure Name</mat-label>
            <input
                matInput
                type="text"
                [ngModel]="nameParam()!.value"
                (ngModelChange)="nameParam()!.value = $event; paramChanged()" />
        </mat-form-field>
    }
    <div class="flex flex-col gap-xl">
        <div class="flex flex-col gap-m">
            <h3 class="tps-heading-3" i18n="@@scenario.targeted">Targeted employees</h3>
            <ng-container i18n="@@scenario.targetedDetails"
                >This measure applies to employees that fit the following criteria</ng-container
            >
        </div>
        <div
            class="grid grid-cols-2 auto-rows-[var(--measure-card-height)] w-[calc(var(--measure-card-width)*2+16px)] xl:grid-cols-3 xl:w-[calc(var(--measure-card-width)*3+2*16px)] gap-m">
            @for (param of targetParameters(); track param) {
                @switch (param.name) {
                    <!--                    todo min work days-->
                    @case ('maxDistanceToCompany') {
                        <measure-distance-parameter
                            [type]="'max'"
                            mode="BIKE"
                            [param]="$any(param)"
                            (paramChanged)="
                                param.value = $event; paramChanged()
                            "></measure-distance-parameter>
                    }
                    @case ('minDistanceToCompany') {
                        <measure-distance-parameter
                            [type]="'min'"
                            [mode]="
                                suggestion().type === 'JobTicketImprovementSuggestion'
                                    ? 'PUBLIC_TRANSPORT'
                                    : undefined
                            "
                            [param]="$any(param)"
                            (paramChanged)="
                                param.value = $event; paramChanged()
                            "></measure-distance-parameter>
                    }
                    @case ('filterEmployees') {
                        <measure-category-parameter
                            [categories]="employeeCategories()"
                            [param]="$any(param)"
                            [enabled]="$any(param).enabled ?? true"
                            (paramChanged)="param.value = $event; paramChanged()">
                        </measure-category-parameter>
                    }
                    @case ('cutOffTransitDurationComparedToCar') {
                        <measure-criteria-parameter
                            class="row-span-2 order-first"
                            [mode]="'PUBLIC_TRANSPORT'"
                            [param]="$any(param)"
                            (paramChanged)="
                                $any(param).value = $event; paramChanged()
                            "></measure-criteria-parameter>
                    }
                }
            }
        </div>
    </div>
    <div class="flex flex-col gap-xl">
        <h3 class="tps-heading-3" i18n="@@scenario.causedChange">Caused Change</h3>
        <div
            class="grid grid-cols-2 auto-rows-[var(--measure-card-height)] w-[calc(var(--measure-card-width)*2+16px)] xl:grid-cols-3 xl:w-[calc(var(--measure-card-width)*3+2*16px)] gap-m">
            @for (param of causedChangeParameters(); track param) {
                @switch (param.name) {
                    @case ('additionalHomeOfficeDays') {
                        <measure-home-office-days-parameter
                            [param]="$any(param)"
                            (paramChanged)="
                                param.value = $event; paramChanged()
                            "></measure-home-office-days-parameter>
                    }
                    @case ('modeMap') {
                        <measure-custom-parameter
                            class="col-span-2 row-span-3"
                            [param]="$any(param)"
                            (paramChanged)="
                                $any(param).value = $event; paramChanged()
                            "></measure-custom-parameter>
                    }
                }
            }
            @if (
                suggestion().type === 'FreeBikeImprovementSuggestion' ||
                suggestion().type === 'JobTicketImprovementSuggestion'
            ) {
                <measure-impact-parameter
                    [impactType]="$any(suggestion().type)"></measure-impact-parameter>
            }
        </div>
    </div>
    <div class="flex flex-col gap-xl">
        <h3 class="tps-heading-3" i18n="@@scenario.result">Result</h3>
        <measure-impact-header
            [measure]="suggestion()"
            [size]="'compact'"
            [includeCta]="false"
            [savedMode]="'absolute'"
            [impactedEmployees]="estimatedImpact()?.employeeInterventionResults?.length"
            [savedEmissions]="estimatedImpact()?.yearlySavedEmissions"></measure-impact-header>
    </div>
    <div class="flex justify-end py-l">
        <button mat-raised-button color="primary" i18n="@@add" (click)="onAddSuggestion()">
            Add
        </button>
    </div>
</div>
