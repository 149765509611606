<div class="sidebar">
    <div #search class="search">
        <mat-form-field>
            <input
                #searchInput
                (focus)="kpiSelectionOpened = true"
                (input)="filterKpiCards()"
                [(ngModel)]="searchString"
                matInput
                type="text" />
            <mat-label i18n="@@addKpis">Add KPIs</mat-label>
        </mat-form-field>
        @if (kpiSelectionOpened) {
            <mat-card appearance="outlined" class="kpi-selection">
                @for (card of kpiCards; track card) {
                    <mat-checkbox
                        (change)="selectionChanged($event, card)"
                        [checked]="card.selected"
                        >{{ card.id }}</mat-checkbox
                    >
                }
            </mat-card>
        }
    </div>
    <div class="page-list">
        @for (cards of pages; track cards; let i = $index) {
            <div>
                <h3 (click)="changePage(i)">{{ translations.page }} {{ i + 1 }}</h3>
                <div>
                    @for (card of cards; track card) {
                        <span>{{ card.type }}</span>
                    }
                </div>
            </div>
        }
    </div>
</div>
<div class="pages">
    <export-page
        (cardPageChanged)="cardPageChanged($event)"
        (cardPositionChanged)="switchCards($event)"
        (emptyPageClicked)="searchInput.focus()"
        (layoutChanged)="reloadLayout()"
        [currentPage]="currentPage"
        [kpiCardInfo]="kpiCardValues"></export-page>
    <div class="page-navigation">
        <button
            (click)="changePage(currentPage - 1)"
            [class.invisible]="currentPage === 0"
            color="primary"
            mat-icon-button>
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button
            (click)="changePage(currentPage + 1)"
            [class.invisible]="currentPage === pages.length - 1"
            color="primary"
            mat-icon-button>
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>
    <div class="page-number">
        <h3>{{ currentPage + 1 }}/{{ pages.length }}</h3>
    </div>
    <button
        (click)="export()"
        [disabled]="loading"
        class="export-button"
        color="primary"
        i18n="@@export"
        mat-raised-button>
        Export
    </button>
    <button (click)="addText()" class="add-text-button" color="primary" mat-raised-button>
        <mat-icon>add</mat-icon>
        <span i18n="@@addText">Add Text</span>
    </button>
</div>
<div class="export-container">
    <div #exportElement>
        @for (_ of pages; track _; let i = $index) {
            <export-page [currentPage]="i" class="export"></export-page>
        }
    </div>
</div>
