<div
    class="rounded flex flex-col justify-between items-end gap-l text-light box-border"
    [ngClass]="
        size === 'compact'
            ? ['p-m', 'w-[var(--measure-card-width)]', 'h-[var(--measure-card-height)]']
            : ['px-xxl', 'py-xl', 'kpi-card-width']
    "
    [style.background-color]="'var(' + (measure | measureType | measureColor) + ')'">
    <div class="flex gap-l w-full items-center">
        <measure-icon
            class="flex-none"
            [measureType]="measure | measureType"
            size="large"></measure-icon>
        <div class="shrink min-w-0 flex flex-col items-start">
            <div class="text-h4 min-w-0 max-w-full font-semibold truncate">
                {{ measure | measureName: false }}
            </div>
            <div class="text-b2">
                @switch (measure | measureType) {
                    @case ('JobTicketImprovementSuggestion') {
                        <ng-container i18n="@@measure.jobTicketDescription"
                            >Introduce Job-Tickets to subsidise commuting with public
                            transport.</ng-container
                        >
                    }
                    @case ('FreeBikeImprovementSuggestion') {
                        <ng-container i18n="@@measure.jobBikeDescription"
                            >Provide bicycles and e-bikes for your employees to incentivise active
                            mobility.</ng-container
                        >
                    }
                    @case ('HomeOfficeImprovementSuggestion') {
                        <ng-container i18n="@@measure.homeOfficeDescription"
                            >Enable certain employees to work from home and eliminate commuting
                            trips.</ng-container
                        >
                    }
                    @case ('CarpoolingIntervention') {
                        <ng-container i18n="@@measure.carpoolingDescription"
                            >Help employees plan shared trips and motivate them to join a carpool on
                            their way to work.</ng-container
                        >
                    }
                    @case ('CustomImprovement') {
                        <ng-container i18n="@@measure.customDescription"
                            >This is a custom measure defined by modelled behaviour
                            changes.</ng-container
                        >
                    }
                }
            </div>
        </div>
    </div>
    <div class="flex justify-between w-full items-center">
        <div class="flex-1 flex flex-col items-start">
            @if (impactedEmployees != undefined) {
                <span class="text-h5/tight font-semibold">{{ impactedEmployees }}</span>
            } @else {
                <div class="w-8/12 bg-grey-superlight rounded h-l animate-pulse"></div>
            }
            <span class="text-b2/tight">
                @switch (measure | measureType) {
                    @case ('JobTicketImprovementSuggestion') {
                        <ng-container i18n="@@measure.affectedEmployees-jobTickets"
                            >Job-Tickets</ng-container
                        >
                    }
                    @case ('FreeBikeImprovementSuggestion') {
                        <ng-container i18n="@@measure.affectedEmployees-jobBikes"
                            >Job Bikes</ng-container
                        >
                    }
                    @case ('CarpoolingIntervention') {
                        <ng-container i18n="@@measure.affectedEmployees-carpooling"
                            >Car Passengers</ng-container
                        >
                    }
                    @default {
                        <ng-container i18n="@@measure.affectedEmployees-general"
                            >Affected Employees</ng-container
                        >
                    }
                }
            </span>
        </div>
        <div class="h-[30px] w-1 flex-initial bg-light"></div>
        <div class="flex-1 flex flex-col items-end">
            @if (savedEmissions != undefined) {
                @if (savedMode === 'percentage') {
                    <span class="text-h5/tight font-semibold">{{ savedEmissions | percent }} </span>
                } @else {
                    <span class="text-h5/tight font-semibold !text-white"
                        ><kpi-measure
                            [value]="savedEmissions"
                            [unit]="'WEIGHT'"
                            [valueCssClass]="'!text-white'"></kpi-measure>
                    </span>
                }
            } @else {
                <div class="w-8/12 bg-grey-superlight rounded h-l animate-pulse"></div>
            }
            <span class="text-b2/tight" i18n="@@measure.lessEmissionsText">less CO₂-emissions</span>
        </div>
    </div>
    @if (includeCta) {
        <a (click)="ctaClick.emit()" class="after:content-['_→'] text-xs cursor-pointer"
            ><span class="underline uppercase font-semibold" i18n="@@measure.viewAffectedEmployees"
                >View affected employees on map</span
            ></a
        >
    }
</div>
