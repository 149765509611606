import { Pipe, PipeTransform } from '@angular/core';

import { MobilityIntervention } from '@upscore-mobility-audit/api';
import { InterventionType } from '@upscore-mobility-audit/core/interfaces/intervention-type.interface';
import { suggestedMeasures } from '@upscore-mobility-audit/data-collection/constants/defined-suggested-measures.constant';

import { MeasureTranslations } from './measure.translations';

@Pipe({
    name: 'measureName',
    standalone: true,
})
export class MeasureNamePipe implements PipeTransform {
    transform(value: MobilityIntervention, includeCustomPrefix = true): string {
        switch (value.type as InterventionType) {
            case 'CarpoolingIntervention':
                return MeasureTranslations.carpoolingInterventionHeader;
            case 'JobTicketImprovementSuggestion':
            case 'HomeOfficeImprovementSuggestion':
            case 'FreeBikeImprovementSuggestion':
                return suggestedMeasures[value.type].header;
            case 'CustomImprovement':
                return ((includeCustomPrefix ? MeasureTranslations.customPrefix : '') +
                    value.parameters.find(param => param?.name === 'customName')?.value) as string;
            case '__OTHER__':
                return 'Other';
        }
    }
}
