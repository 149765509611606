import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { ProgressBarComponent } from '@ui-library/components/progress-bar/progress-bar.component';

@Component({
    selector: 'upscore-task-progress-bar',
    standalone: true,
    imports: [CommonModule, ProgressBarComponent],
    templateUrl: './task-progress-bar.component.html',
    styleUrl: './task-progress-bar.component.scss',
})
export class TaskProgressBarComponent {
    @Input({ required: true }) public progress: number;
    @Input() public progressText?: string;
}
