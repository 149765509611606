import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { GeoJsonExample } from '@upscore-mobility-audit/api';
import { PlacesResultFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/places-result-form-group.interface';

import { googlePlacesChangeValidator } from '../../validators/google-places.validator';

@Component({
    selector: 'google-places-input',
    templateUrl: './google-places-input.component.html',
    styleUrls: ['./google-places-input.component.scss'],
})
export class GooglePlacesInputComponent implements OnInit, AfterViewInit {
    @ViewChild('addressSearch', { static: false, read: ElementRef })
    public addressText: ElementRef;

    @Input() public parentFormGroup!: FormGroup<PlacesResultFormGroup>;
    @Input() public locationRestriction!: GeoJsonExample | null | GeoJsonExample[];
    @Input() public placeholder!: string;
    @Input() public label!: string;
    @Input() public defaultValue: google.maps.places.PlaceResult | string = '';
    @Input() public required = false;
    @Input() public entranceIndex = 0;

    private autocomplete!: google.maps.places.Autocomplete;

    constructor(private zone: NgZone) {}

    /**
     * Angular lifecycle hook
     */
    public ngOnInit(): void {
        this.initFormControl();
    }

    /**
     * Angular lifecycle hook
     */
    public ngAfterViewInit(): void {
        this.initPlaceAutocomplete();
    }

    /**
     * Method to init the form control
     */
    private initFormControl(): void {
        this.parentFormGroup.addControl(
            'placeResult',
            new FormControl<google.maps.places.PlaceResult | null>(
                null,
                Validators.compose([googlePlacesChangeValidator]),
            ),
        );
    }

    /**
     * Init Google Places autocomplete results when the user starts typing
     */
    private initPlaceAutocomplete(): void {
        let biasBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(48.2082, 16.3738), // Vienna
            new google.maps.LatLng(52.52, 13.405), // Berlin
        );
        let userBounds = false;

        if (this.locationRestriction != null && Array.isArray(this.locationRestriction)) {
            userBounds = this.locationRestriction != null && this.locationRestriction.length === 4;
            if (userBounds) {
                biasBounds = new google.maps.LatLngBounds(
                    new google.maps.LatLng(
                        this.locationRestriction[1],
                        this.locationRestriction[0],
                    ),
                    new google.maps.LatLng(
                        this.locationRestriction[3],
                        this.locationRestriction[2],
                    ),
                );
            }
        }

        if (this.addressText == null) {
            return;
        }

        this.autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
            strictBounds: userBounds,
            bounds: biasBounds,
        });

        google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
            const place: google.maps.places.PlaceResult | undefined = this.autocomplete.getPlace();
            this.zone.run(() => {
                if (!place || !('place_id' in place)) {
                    return;
                }
                if (this.parentFormGroup && this.parentFormGroup.controls.placeResult) {
                    this.parentFormGroup.controls.placeResult.setValue(place);
                }
                if (this.addressText != null) {
                    this.addressText.nativeElement.value = null;
                }
            });
        });
    }
}
