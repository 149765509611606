import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { AbstractUserDataService, FeatureFlag } from '@shared/utils';
import { CalculationProperties, UserInfo } from '@upscore-mobility-audit/api';
import { MobilityPropertiesWrapperService } from '@upscore-mobility-audit/shared/api-services/mobility-properties-wrapper.service';

import { Modes } from '../../interfaces/criteria-classes';

@Component({
    selector: 'mobility-properties-step',
    templateUrl: './mobility-properties-step.component.html',
    styleUrls: ['./mobility-properties-step.component.scss'],
})
export class MobilityPropertiesStepComponent implements OnInit, OnDestroy {
    @Input() public mobilityPropertiesForm: UntypedFormGroup;
    @Input() public enableModeEstimationConfig = false;
    @Output() public resetCalculationPropertiesForm: EventEmitter<void> = new EventEmitter<void>();
    public Modes = Modes;
    public defaultUserProperties: CalculationProperties;
    private unsubscribe$: Subject<void> = new Subject();

    constructor(
        private userDataService: AbstractUserDataService<UserInfo>,
        private mobilityPropertiesService: MobilityPropertiesWrapperService,
    ) {}

    public ngOnInit(): void {
        if (this.userDataService.showFeature(FeatureFlag.CALCULATION_PROPERTIES)) {
            this.mobilityPropertiesService.getUserMobilityProperties().subscribe(response => {
                if (response) {
                    this.defaultUserProperties = response;
                }
            });
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public onResetForm(): void {
        this.resetCalculationPropertiesForm.emit();
    }

    public setDefaultUserProperties(): void {
        if (this.defaultUserProperties) {
            this.mobilityPropertiesForm.setValue(this.defaultUserProperties);
        }
    }
}
