<div class="flex flex-row justify-between gap-[1.5rem]">
    <div>
        <kpi-measure
            [unit]="unit"
            [value]="showYearly ? currentYearly : currentDaily"
            [digitsInfo]="digitsInfo"
            [showChangePrefix]="false"
            [measureCssClass]="'kpi-measure__measure--color-primary'"
            [valueCssClass]="'kpi-measure__value--color-dark kpi-measure__value--text-lg'">
        </kpi-measure>
        @if (currentDaily) {
            <div class="tps-body-3-bold">
                per
                <span
                    [ngClass]="{ 'disabled tps-body-3': showYearly }"
                    (click)="showYearly = false"
                    i18n="@@day"
                    >day</span
                >
                <span class="tps-body-3 disabled"> | </span>
                <span
                    [ngClass]="{ 'disabled tps-body-3': !showYearly }"
                    (click)="showYearly = true"
                    i18n="@@year"
                    >year</span
                >
            </div>
        }
    </div>

    @if (baselineYearly) {
        <kpi-percentage-change
            [invertColorScheme]="invertColorScheme"
            [current]="showYearly ? currentYearly : currentDaily"
            [baseline]="showYearly ? baselineYearly : baselineDaily"
            [yearly]="showYearly"
            [unit]="unit"></kpi-percentage-change>
    }
</div>
