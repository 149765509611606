import { NgClass, PercentPipe } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'distribution-bar',
    standalone: true,
    templateUrl: './distribution-bar.component.html',
    styleUrls: ['./distribution-bar.component.scss'],
    imports: [NgClass, PercentPipe],
})
export class DistributionBarComponent {
    @Input() public title?: string;
    @Input() public displayPercentages = false;
    @Input() public loading = false;

    // make sure that the sum of the numbers equals 1
    @Input() public distribution?: Array<{ width: number; color: string }>;
}
