import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BetaFeatureType } from '@upscore-mobility-audit/shared/interfaces/beta-feature-type.interface';

@Component({
    selector: 'kpi-card',
    templateUrl: './kpi-card.component.html',
    styleUrls: ['./kpi-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiCardComponent implements OnInit {
    @Input({ required: true }) public title: string;

    @Input() public showUnavailable = false;
    @Input() public betaFeature = false;
    @Input() public betaFeatureType: BetaFeatureType;

    @Input() public showMenu = true;
    @Input() public moreInfoText?: string;
    @Input() public moreInfoLink?: string;
    @Input() public widthScale = 1;
    public infoMessage = '';

    /**
     * Angular Lifecycle
     */
    public ngOnInit(): void {
        const unavailableText: string = $localize`:@@unavailableText:unavailble at the department level.`;
        this.infoMessage = this.title + ' ' + unavailableText;
    }
}
