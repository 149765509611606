import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TaskDefinition, TaskProgress } from '@upscore-mobility-audit/api';

import { TaskUtilsService } from '../services';

@Pipe({
    name: 'taskProgressBody',
    standalone: true,
})
export class TaskProgressBodyPipe implements PipeTransform {
    constructor(
        private taskUtilsService: TaskUtilsService,
        private sanitizer: DomSanitizer,
    ) {}

    transform(
        value: TaskProgress,
        definition: TaskDefinition,
        companyLocationOrPackageName: string,
    ): SafeHtml {
        const result = this.taskUtilsService.getTaskNameAndEvent(value);
        if (result == null) {
            return $localize`:@@taskIsCalculating:A task is currently running.`;
        }

        const [name] = result;

        let resultText = '';

        switch (definition.type) {
            case 'MobilityAuditTask':
            case 'PackageTask':
                resultText = $localize`:@@mobilityScoreCalcBase:The mobility score for <strong>${companyLocationOrPackageName}</strong> `;
                switch (value.status) {
                    case 'FAILED':
                        resultText += $localize`:@@mobilityScoreCalcFailed:could not be calculated. Please try again later.`;
                        break;
                    case 'FINISHED':
                        resultText += $localize`:@@mobilityScoreCalcFinished:has been calculated. You can now view the calculated KPIs.`;
                        break;
                    case 'QUEUED':
                        resultText += $localize`:@@mobilityScoreCalcQueued:will be calculated shortly. While the calculation is ongoing, you can already view the distribution of employees on the map.`;
                        break;
                    case 'STARTING':
                    case 'IN_PROGRESS':
                        resultText += $localize`:@@mobilityScoreCalcProgress:is currently being calculated. While the calculation is ongoing, you can already view the distribution of employees on the map.`;
                        break;
                    case 'CANCELLED':
                        resultText = $localize`:@@mobilityScoreCalcCancelled:The mobility score calculation for <strong>${companyLocationOrPackageName}</strong> has been cancelled.`;
                        break;
                }
                break;
            case 'EmployeeUploadTask':
                switch (value.status) {
                    case 'FAILED':
                        resultText = $localize`:@@taskEmployeeUploadFailed:There was an error while uploading employees for <strong>${companyLocationOrPackageName}</strong>.`;
                        break;
                    case 'FINISHED':
                        resultText = $localize`:@@taskEmployeeUploadFinished:The Employees for <strong>${companyLocationOrPackageName}</strong> were successfully uploaded.`;
                        break;
                    case 'QUEUED':
                        resultText = $localize`:@@taskEmployeeUploadQueued:Employees for <strong>${companyLocationOrPackageName}</strong> will be uploaded shortly.`;
                        break;
                    case 'STARTING':
                    case 'IN_PROGRESS':
                        resultText = $localize`:@@taskEmployeeUploadProgress:Employees for <strong>${companyLocationOrPackageName}</strong> are currently being uploaded.`;
                        break;
                    case 'CANCELLED':
                        resultText = $localize`:@@taskEmployeeUploadCancelled:The employee upload for <strong>${companyLocationOrPackageName}</strong> has been cancelled.`;
                        break;
                }
                break;
            case 'ModeEstimationTask': {
                resultText = $localize`:@@taskModeEstimationBase:Mode estimation for <strong>${companyLocationOrPackageName}</strong> `;
                switch (value.status) {
                    case 'FAILED':
                        resultText += $localize`:@@taskModeEstimationFailed:failed.`;
                        break;
                    case 'FINISHED':
                        resultText += $localize`:@@taskModeEstimationFinished:has finished.`;
                        break;
                    case 'QUEUED':
                        resultText += $localize`:@@taskModeEstimationQueued:is queued.`;
                        break;
                    case 'STARTING':
                    case 'IN_PROGRESS':
                        resultText += $localize`:@@taskModeEstimationProgress:is in progress.`;
                        break;
                    case 'CANCELLED':
                        resultText += $localize`:@@taskModeEstimationCancelled:has been cancelled.`;
                        break;
                }
                break;
            }
            default:
                resultText = $localize`:@@taskProgressBase:The task <strong>${name}</strong> `;
                switch (value.status) {
                    case 'FAILED':
                        resultText += $localize`:@@taskFailedBody:failed.`;
                        break;
                    case 'FINISHED':
                        resultText += $localize`:@@taskFinishedBody:has finished.`;
                        break;
                    case 'QUEUED':
                        resultText += $localize`:@@taskQueuedBody:is queued.`;
                        break;
                    case 'STARTING':
                    case 'IN_PROGRESS':
                        resultText += $localize`:@@taskProgressBody:is in progress.`;
                        break;
                    case 'CANCELLED':
                        resultText += $localize`:@@taskCancelledBody:has been cancelled.`;
                        break;
                }
                break;
        }

        return this.sanitizer.bypassSecurityTrustHtml(resultText);
    }
}
