import { Component, DestroyRef, Inject, OnInit, computed, effect, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, differenceWith, isEqual } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbstractUserDataService, FeatureFlag, ToastService } from '@shared/utils';
import {
    CompanyLocation,
    EstimatedImpact,
    MobilityIntervention,
    MobilityStats,
    PackageDefinition,
    PostedPackage,
    UserInfo,
} from '@upscore-mobility-audit/api';
import { CompanyLocationDataService } from '@upscore-mobility-audit/core/data-services/company-location-data.service';
import { PackageDataService } from '@upscore-mobility-audit/core/data-services/package-data.service';
import { Category } from '@upscore-mobility-audit/data-collection/components/parameters/measure-category-parameter/measure-category-parameter.component';
import { computeOtherMeasures } from '@upscore-mobility-audit/data-collection/functions/default-custom-improvement.function';
import { MeasureFormGroup } from '@upscore-mobility-audit/data-collection/interfaces/measure-form-group.interface';
import { MobilityScenarioBuilderTranslations } from '@upscore-mobility-audit/data-collection/translations/mobility-scenario-builder-translations';
import { ImprovementsWrapperService } from '@upscore-mobility-audit/shared/api-services/improvements-wrapper.service';
import { MeasureType } from '@upscore-mobility-audit/shared/enums/measure-type.enum';
import { handleChanges } from '@upscore-mobility-audit/shared/functions/has-changes';

import { ModalSplitType } from '../../enums/modalsplit.enum';

class InterventionData {
    constructor(
        public intervention: MobilityIntervention,
        public source: 'baseScenario' | 'currentlyAdded' | 'suggested' | 'other',
        public impact = signal<EstimatedImpact | undefined>(undefined),
        public isDuplicate = false,
    ) {}
}

@Component({
    selector: 'upscore-mobility-audit-mobility-scenario-builder',
    templateUrl: './mobility-scenario-builder.component.html',
    styleUrls: ['./mobility-scenario-builder.component.scss'],
})
export class MobilityScenarioBuilderComponent implements OnInit {
    public allPackages$;

    public baseScenarios = toSignal(this.packagesDataService.definitions$, {
        initialValue: [] as PackageDefinition[],
    });

    public appliedMeasures = signal<InterventionData[]>([]);
    public suggestedMeasures = signal<InterventionData[]>([]);
    public otherMeasures = computed(() => {
        const suggestedMeasures = this.suggestedMeasures().map(i => i.intervention);

        return computeOtherMeasures(suggestedMeasures).map(
            i => new InterventionData(i, 'suggested'),
        );
    });

    public packageToEdit: PackageDefinition | undefined;

    public measureFormGroup;

    public measureType: typeof MeasureType = MeasureType;

    public modalSplitType: typeof ModalSplitType = ModalSplitType;

    public cancelImprovementImpactRequest = new Subject<void>();
    public cancelEstimateDetailedSuggestionRequest = new Subject<void>();

    public showAddBaseScenario = false;
    public showAddMeasure = false;
    public disableBackButton = false;

    public featureFlag: typeof FeatureFlag = FeatureFlag;
    protected readonly MeasureType = MeasureType;

    protected selectedSuggestion = signal<MobilityIntervention | undefined>(undefined);
    protected selectedSuggestionImpact = signal<EstimatedImpact | undefined>(undefined);

    protected readonly MobilityScenarioBuilderTranslations = MobilityScenarioBuilderTranslations;

    constructor(
        private readonly formBuilder: FormBuilder,
        public readonly improvementsWrapperService: ImprovementsWrapperService,
        public readonly packagesDataService: PackageDataService,
        public readonly companyLocationDataService: CompanyLocationDataService,
        public readonly toastService: ToastService,
        public readonly userDataService: AbstractUserDataService<UserInfo>,
        public readonly destroyRef: DestroyRef,
        private readonly dialogRef: MatDialogRef<MobilityScenarioBuilderComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            companyLocation: CompanyLocation;
            employeeCategories: Category[];
            packageToEdit?: PackageDefinition;
            baseScenario?: number;
            duplicate: boolean;
        },
    ) {
        this.measureFormGroup = this.formBuilder.group<{
            type: FormControl<MeasureType | null>;
            suggestion: FormControl<MobilityIntervention | null>;
            mobilityStats?: FormControl<MobilityStats>;
            measure?: FormGroup<MeasureFormGroup>;
            baseScenario: FormControl<number>;
            title: FormControl<string>;
        }>({
            type: this.formBuilder.control<MeasureType | null>(null),
            suggestion: this.formBuilder.control<MobilityIntervention | null>(null),
            baseScenario: this.formBuilder.nonNullable.control<number>(-1),
            title: this.formBuilder.nonNullable.control<string>(''),
        });

        this.allPackages$ = this.packagesDataService.definitions$;

        effect(
            () => {
                const measures = this.appliedMeasures();
                this.cancelImprovementImpactRequest.next();
                this.loadImpact(measures);

                this.getMeasureSuggestions();
            },
            { allowSignalWrites: true },
        );

        effect(() => {
            const appliedMeasures = this.appliedMeasures();
            const suggestions = this.suggestedMeasures();
            const otherMeasures = this.otherMeasures();

            this.loadImpact(appliedMeasures, suggestions.concat(otherMeasures));
        });

        const editedPackage = this.data.packageToEdit;
        if (editedPackage) {
            this.packageToEdit = editedPackage;
            this.fillFormWithPackageData(editedPackage);
        }

        if (data.baseScenario) {
            this.selectBaseScenario(data.baseScenario);
        }
    }

    public estimateImpactOfSuggestion(parameters: MobilityIntervention['parameters']) {
        const suggestion = this.selectedSuggestion();
        if (suggestion == null) {
            return;
        }
        this.cancelEstimateDetailedSuggestionRequest.next();
        this.selectedSuggestionImpact.set(undefined);

        const appliedMeasures = this.appliedMeasures()
            .filter(i => i.source !== 'baseScenario')
            .map(i => i.intervention);
        const isEdit = appliedMeasures.filter(applied => applied.id == suggestion.id).length > 0;
        const suggestionCopy = cloneDeep(suggestion);
        suggestionCopy.parameters = parameters;

        const measuresToRequest = isEdit
            ? appliedMeasures.map(m => (m.id == suggestion.id ? suggestionCopy : m))
            : [...appliedMeasures, suggestionCopy];

        this.improvementsWrapperService
            .getMeasureImpact(
                this.data.companyLocation.id,
                this.measureFormGroup.value.baseScenario || -1,
                measuresToRequest,
            )
            .pipe(
                takeUntil(this.cancelEstimateDetailedSuggestionRequest),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(impacts => {
                const impact = impacts.find(
                    i => i.interventionImpact.interventionId == suggestion.id,
                );
                if (impact) {
                    this.selectedSuggestionImpact.set(impact);
                }
            });
    }

    public findImpact(allImpacts: EstimatedImpact[], suggestion: MobilityIntervention) {
        return allImpacts.find(i => i.interventionImpact.interventionId == suggestion.id);
    }

    public loadImpact(appliedMeasures: InterventionData[], suggestions: InterventionData[] = []) {
        const newMeasures = appliedMeasures.filter(i => i.source !== 'baseScenario');
        const baseScenario = this.measureFormGroup.value.baseScenario || -1;
        if (suggestions.length > 0) {
            suggestions.forEach(sug => {
                const interventions = newMeasures.concat(sug).map(i => i.intervention);
                this.improvementsWrapperService
                    .getMeasureImpact(this.data.companyLocation.id, baseScenario, interventions)
                    .pipe(
                        takeUntil(this.cancelImprovementImpactRequest),
                        takeUntilDestroyed(this.destroyRef),
                    )
                    .subscribe(impacts =>
                        sug.impact.set(this.findImpact(impacts, sug.intervention)),
                    );
            });
        } else {
            this.improvementsWrapperService
                .getMeasureImpact(
                    this.data.companyLocation.id,
                    baseScenario,
                    newMeasures.map(i => i.intervention),
                )
                .pipe(
                    takeUntil(this.cancelImprovementImpactRequest),
                    takeUntilDestroyed(this.destroyRef),
                )
                .subscribe(impacts =>
                    appliedMeasures.forEach(measure =>
                        measure.impact.set(this.findImpact(impacts, measure.intervention)),
                    ),
                );
        }
    }

    public fillFormWithPackageData(package_: PackageDefinition): void {
        if (package_.baseScenario == null) {
            this.measureFormGroup.controls.type.setValue(
                package_.modeEstimationConfig?.modeAssignment != null
                    ? MeasureType.CUSTOM_MEASURE
                    : MeasureType.MODAL_SPLIT,
            );
            this.disableBackButton = true;
        } else {
            this.measureFormGroup.controls.baseScenario.setValue(package_.baseScenario);
            this.measureFormGroup.controls.title.setValue(package_.title);

            const baseInterventions = package_.baseScenario
                ? this.packagesDataService.definitions$.value.find(
                      p => p.packageId === package_.baseScenario,
                  )?.interventions ?? []
                : [];

            const diffInterventions = differenceWith(
                package_.interventions,
                baseInterventions ?? [],
                isEqual,
            );

            this.appliedMeasures.set(
                diffInterventions
                    .map(i => new InterventionData(i, 'currentlyAdded'))
                    .concat(baseInterventions.map(i => new InterventionData(i, 'baseScenario'))),
            );
        }
    }

    public ngOnInit(): void {
        this.getMeasureSuggestions();

        handleChanges(this.dialogRef, [this.measureFormGroup]);
    }

    public selectBaseScenario(baseScenario: number): void {
        if (this.packageToEdit != null && !this.data.duplicate) {
            this.toastService.showInfoEvent(
                $localize`:@@changingBaseScenario:Changing Base Scenario is not supported while editing`,
                false,
            );

            return;
        }

        this.measureFormGroup.controls.baseScenario.setValue(baseScenario);
        this.showAddMeasure = false;
        const package_ = this.packagesDataService.definitions$.value.find(
            p => p.packageId === baseScenario,
        );
        if (package_ && package_.interventions.length > 0) {
            this.appliedMeasures.set(
                package_.interventions.map(i => new InterventionData(i, 'baseScenario')),
            );
        } else {
            this.appliedMeasures.set([]);
        }
    }

    public getMeasureSuggestions(): void {
        this.suggestedMeasures.set([]);
        const appliedMeasures = this.appliedMeasures();
        const baseScenario = this.measureFormGroup.value.baseScenario || -1;
        this.improvementsWrapperService
            .getSuggestedMeasures(this.data.companyLocation.id, baseScenario)
            .subscribe(suggestions => {
                this.suggestedMeasures.set(
                    suggestions.map(
                        s =>
                            new InterventionData(
                                s,
                                appliedMeasures.find(i => i.intervention.type == s.type)
                                    ? 'other'
                                    : 'suggested',
                            ),
                    ),
                );
            });
    }

    public addMeasure() {
        const body = {
            tags: [],
            name: this.measureFormGroup.value.title || '',
            baseScenario: this.measureFormGroup.value.baseScenario || -1,
            appliedImprovements: [
                ...this.appliedMeasures()
                    .filter(i => i.source === 'currentlyAdded')
                    .map(i => i.intervention),
            ],
        };

        if (this.packageToEdit && !this.data.duplicate) {
            return this.packagesDataService.updateMeasure(
                this.data.companyLocation.id,
                this.packageToEdit.packageId,
                body,
            );
        } else {
            return this.packagesDataService.addMeasure(this.data.companyLocation.id, body);
        }
    }

    public addBaseScenario() {
        if (this.packageToEdit && !this.data.duplicate) {
            return this.packagesDataService.updateBaseScenario(
                this.data.companyLocation.id,
                this.packageToEdit.packageId,
                {
                    ...(this.measureFormGroup.value.measure as PostedPackage),
                    interventions: [],
                },
            );
        } else {
            return this.packagesDataService.addBaseScenario(this.data.companyLocation.id, {
                ...(this.measureFormGroup.value.measure as PostedPackage),
                modeEstimationConfig:
                    this.measureFormGroup.value.type == MeasureType.MODAL_SPLIT
                        ? undefined
                        : this.measureFormGroup.value.measure?.modeEstimationConfig,
                interventions: [],
            });
        }
    }

    public onStepsCompleted(): void {
        const companyLocationId = this.companyLocationDataService.companyLocation().id;

        if (companyLocationId == null) {
            return;
        }

        if (this.measureFormGroup.value.type == null) {
            // create measure and leave screen
            // ignore observable, new value only comes in after calculation is done
            // We want to close the dialog before that
            this.addMeasure().subscribe();
            this.dialogRef.close();
        } else {
            // create base scenario and dialog stays open?
            this.addBaseScenario().subscribe(packageId => {
                if (packageId) {
                    this.measureFormGroup.controls.baseScenario.setValue(packageId);
                }
                this.showAddBaseScenario = false;
                this.measureFormGroup.controls.type.setValue(null);
            });
        }
    }

    public cancelDialog() {
        this.dialogRef.close();
    }

    public selectMeasureType(type: MeasureType | null): void {
        this.measureFormGroup.controls.measure?.reset();
        this.measureFormGroup.controls.type.setValue(type);
        this.selectedSuggestion.set(undefined);
    }

    public selectMeasure(suggestion: InterventionData): void {
        this.measureFormGroup.controls.type.setValue(MeasureType.SUGGESTION);
        this.selectedSuggestion.set(suggestion.intervention);
        this.selectedSuggestionImpact.set(suggestion.impact());
    }

    public onAddSuggestion(
        originalSuggestion: MobilityIntervention,
        newSuggestion: MobilityIntervention,
    ): void {
        this.appliedMeasures.set([
            ...this.appliedMeasures().filter(sug => sug.intervention !== originalSuggestion),
            new InterventionData(newSuggestion, 'currentlyAdded'),
        ]);
        this.showAddMeasure = false;
        this.measureFormGroup.controls.type.setValue(null);
        this.selectedSuggestion.set(undefined);
    }

    public dialogInfoBaseScenario() {
        this.toastService.showInfoEvent(
            $localize`:@@baseMeasureScenarioInfo:Measures defined in another Base Scenario are not editable.`,
            false,
        );
    }

    public removeMeasure(measure: MobilityIntervention) {
        this.appliedMeasures.set(this.appliedMeasures().filter(s => s.intervention !== measure));
    }
}
