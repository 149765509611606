import { Component, Input } from '@angular/core';

import { NumberUnit } from '@shared/utils';

import { TopicTableComponentEntry } from '../../interfaces/topic-table-component-entry.interface';

@Component({
    selector: 'topic-table',
    templateUrl: './topic-table.component.html',
    styleUrls: ['./topic-table.component.scss'],
})
export class TopicTableComponent {
    @Input() public entries: TopicTableComponentEntry[];
    @Input() public useDailyPipe = false;
    public numberUnit: typeof NumberUnit = NumberUnit;
}
