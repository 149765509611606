<nav-bar-layout>
    <div class="flex row gap-[48px]" left-side>
        <img
            class="max-h-full w-auto cursor-pointer"
            alt="Mobility Score Logo"
            (click)="onLogoClick()"
            src="assets/images/upscore-brand/mobility-score-logo-transparent-small.png" />

        <ng-content select="[left-side]"></ng-content>
    </div>

    <div class="flex flex-row" right-side>
        <ng-content select="[right-side]"></ng-content>
        <div class="flex flex-row gap-m">
            <upscore-user-notifications
                [tasks]="
                    this.taskNotificationService.userTasks().length
                "></upscore-user-notifications>
            <user-details
                [user]="(userDataService.user$ | async)!!"
                [canShowSettings]="
                    ((userDataService.showFeature$(featureFlag.CALCULATION_PROPERTIES) | async) ??
                        false) &&
                    (userDataService.user$ | async)?.accountType !== 'VIEW_ONLY'
                "
                (showSettings)="onClickUserSettings()"></user-details>
        </div>
    </div>
</nav-bar-layout>
