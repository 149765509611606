import {
    AbstractAlgorithm,
    AlgorithmInput,
    AlgorithmOutput,
    Cluster,
    SuperClusterOptions,
} from '@googlemaps/markerclusterer';
import equal from 'fast-deep-equal/es6';
import { groupBy } from 'lodash-es';

import { Employee, ZipCodeUtilsService } from '@shared/map';

type Marker = google.maps.marker.AdvancedMarkerElement;

export interface ZipCodeAlgorithmInput extends Omit<AlgorithmInput, 'markers'> {
    markers: (Marker & { id?: string; employee?: Employee })[];
}

export class ZipCodeClusterAlgorithm extends AbstractAlgorithm {
    protected markers: (Marker & { id?: string; employee?: Employee })[];
    protected clusters: Cluster[];
    protected visibleCluters: Cluster[];
    protected state = { zoom: -1 };

    constructor({ maxZoom = 9, ...options }: SuperClusterOptions) {
        super({ maxZoom });
    }

    /**
     * Calculate the clusters that are in the map viewport
     * @param map
     */
    public calculateClustersInViewport(map: google.maps.Map): Cluster[] {
        return this.clusters.filter(cluster => map.getBounds()?.contains(cluster.position));
    }

    public calculate(input: ZipCodeAlgorithmInput): AlgorithmOutput {
        // TODO fix comparision as we are currently comparing the same instances (currently doesn't work)
        // wait till google maps markerclusterer provides a better way to compare markers
        if (equal(this.markers, input.markers)) {
            // dont render clusters that are not in the current viewport
            const clusters = this.calculateClustersInViewport(input.map);

            // no need to compare anything else than the position
            // as the markers are already the same as before
            if (
                equal(
                    this.visibleCluters.map(c => c.position),
                    clusters.map(c => c.position),
                )
            ) {
                return { clusters: this.visibleCluters, changed: false };
            }
            this.visibleCluters = clusters;

            return { clusters: clusters, changed: true };
        }

        this.markers = input.markers;

        this.clusters = this.cluster(input);

        // dont render clusters that are not in the current viewport
        this.visibleCluters = this.calculateClustersInViewport(input.map);

        return {
            clusters: this.visibleCluters,
            changed: true,
        };
    }

    public cluster({ markers, ...options }: ZipCodeAlgorithmInput): Cluster[] {
        const visibleMarkers = markers.filter(marker => marker.position != null);

        return Object.values(
            groupBy(visibleMarkers, marker => {
                if ('employee' in marker && marker.employee) {
                    return ZipCodeUtilsService.stripPreClustering(marker.employee.postalCode);
                } else {
                    return null;
                }
            }),
        ).map(markers => {
            const lat =
                markers.reduce(
                    (acc, marker) =>
                        acc + ((marker.position as google.maps.LatLngLiteral)?.lat ?? 0),
                    0,
                ) / markers.length;
            const lng =
                markers.reduce(
                    (acc, marker) =>
                        acc + ((marker.position as google.maps.LatLngLiteral)?.lng ?? 0),
                    0,
                ) / markers.length;

            return new Cluster({ markers, position: { lat: lat, lng: lng } });
        });
    }
}
