import { SliderColorClass } from '@ui-library/enums/slider-color-class.enum';
import { StatsPercentageChange } from '@upscore-mobility-audit/shared/interfaces/stats-percentage-change.interface';

import { DefaultMobilityStatsSliderOptionsTranslations } from '../translations/default-mobility-stats-slider-options-translations';

export const defaultMobilityStatsSliderOptions: StatsPercentageChange[] = [
    {
        identifier: 'walk',
        sliderOptions: {
            title: DefaultMobilityStatsSliderOptionsTranslations.walk,
            value: 8,
            min: 0,
            max: 100,
            format: '%',
            colorClass: SliderColorClass.WALKING,
        },
    },
    {
        identifier: 'bike',
        sliderOptions: {
            title: DefaultMobilityStatsSliderOptionsTranslations.bike,
            value: 7,
            min: 0,
            max: 100,
            format: '%',
            colorClass: SliderColorClass.BIKING,
        },
    },
    {
        identifier: 'transit',
        sliderOptions: {
            title: DefaultMobilityStatsSliderOptionsTranslations.pt,
            value: 20,
            min: 0,
            max: 100,
            format: '%',
            colorClass: SliderColorClass.PUBLIC_TRANSPORT,
        },
    },
    {
        identifier: 'carPassenger',
        sliderOptions: {
            title: DefaultMobilityStatsSliderOptionsTranslations.carPassenger,
            value: 5,
            min: 0,
            max: 100,
            format: '%',
            colorClass: SliderColorClass.CAR_PASSENGER,
        },
    },
    {
        identifier: 'carDriver',
        sliderOptions: {
            title: DefaultMobilityStatsSliderOptionsTranslations.carDriver,
            value: 60,
            min: 0,
            max: 100,
            format: '%',
            colorClass: SliderColorClass.CAR_DRIVER,
        },
    },
];
