import { CommonModule } from '@angular/common';
import { Component, Input, computed, input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';

import { NotificationLayoutComponent } from '@ui-library/components/notification-layout/notification-layout.component';
import {
    PackageTask,
    TaskDefinition,
    TaskProgress,
    TasksService,
} from '@upscore-mobility-audit/api';

import {
    TaskDefinitionHeaderPipe,
    TaskProgressBodyPipe,
    TaskProgressFooterPipe,
    TaskProgressPercentagePipe,
} from '../../pipes';
import { DialogService, TaskUtilsService, TasksWrapperService } from '../../services';
import { HiddenAuditTasksService } from '../../services/hidden-audit-tasks.service';
import { TaskProgressBarComponent } from '../task-progress-bar/task-progress-bar.component';

@Component({
    selector: 'upscore-task-notification',
    standalone: true,
    imports: [
        CommonModule,
        NotificationLayoutComponent,
        TaskProgressBarComponent,
        MatIconButton,
        MatIcon,
        MatMenuItem,
        MatMenu,
        MatMenuTrigger,
        TaskDefinitionHeaderPipe,
        TaskProgressBodyPipe,
        TaskProgressFooterPipe,
        TaskProgressPercentagePipe,
    ],
    templateUrl: './task-notification.component.html',
    styleUrl: './task-notification.component.scss',
})
export class TaskNotificationComponent {
    @Input() public task: TaskProgress;
    @Input() public taskId: string;
    @Input() public standalone = true;
    @Input() public companyLocationName: string;
    public definition = input.required<TaskDefinition>();

    public packageName = computed(() => {
        const def = this.definition();

        return def.type === 'PackageTask'
            ? $localize`:@@measure :Measure ` + (def as PackageTask).packageId
            : '';
    });

    constructor(
        private readonly tasksService: TasksService,
        private readonly router: Router,
        private readonly tasksWrapperService: TasksWrapperService,
        private readonly dialogService: DialogService,
        private readonly hiddenAuditTasksService: HiddenAuditTasksService,
        private taskUtilsService: TaskUtilsService,
    ) {}

    public deleteTask() {
        if (this.standalone && this.taskUtilsService.taskIsRunning(this.task)) {
            this.hiddenAuditTasksService.hideTask(this.taskId);
        } else {
            this.tasksWrapperService.deleteTask(this.taskId);
        }
    }

    public cancelTask() {
        this.tasksWrapperService.deleteTask(this.taskId);
    }

    public showError() {
        this.tasksService.getTaskResult({ taskId: this.taskId }).subscribe();
    }

    public viewTaskResult() {
        if (this.definition().type === 'PackageTask') {
            void this.router
                .navigate(['/audits', this.definition().locationId], {
                    queryParams: { scenario: (this.definition() as PackageTask).packageId },
                })
                .then(() => {
                    this.deleteTask();
                });
        } else {
            void this.router.navigate(['/audits', this.definition().locationId]).then(() => {
                this.deleteTask();
            });
        }
    }

    public openNotificationDialog() {
        this.dialogService.openNotifyUserDialog(this.taskId);
    }
}
