import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompanyLocationOwner, SharingService, UserInfo } from '@upscore-mobility-audit/api';

@Injectable({
    providedIn: 'root',
})
export class MobilitySharingWrapperService {
    constructor(private mobilitySharingService: SharingService) {}

    /**
     * Create New View Only User
     */
    public createNewUser(
        companyLocationId: number,
        username: string,
        name?: string,
        password?: string,
    ): Observable<CompanyLocationOwner> {
        return this.mobilitySharingService.newViewOnlyUser({
            companyLocationId,
            username,
            name,
            password,
        });
    }

    /*
    List all View Only Users for a particular location
    */
    public listViewOnlyUsers(companyLocationId: number): Observable<UserInfo[]> {
        return this.mobilitySharingService.listAllViewOnlyUsersOfCompanyLocation({
            companyLocationId,
        });
    }

    /* Delete a View Only User of a particular location */
    public deleteViewOnlyUser(
        viewOnlyUserId: number,
        companyLocationId: number,
    ): Observable<unknown> {
        return this.mobilitySharingService.deleteViewOnlyUser({
            viewOnlyUserId,
            companyLocationId,
        });
    }

    /**
     * Reset Password Of User
     */
    public resetNewUserPassword(
        viewOnlyUserId: number,
        companyLocationId: number,
        newPassword: string,
    ): Observable<CompanyLocationOwner> {
        return this.mobilitySharingService.resetViewOnlyUserPassword({
            viewOnlyUserId,
            companyLocationId,
            newPassword,
        });
    }
}
