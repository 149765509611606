<div class="popover">
    <div class="popover-content" [ngClass]="type === 'warn' ? 'popover-warn' : null">
        <div class="content-text">{{ content }}</div>
        @if (link) {
            <a (click)="onLinkClick()" class="learn-more" i18n="@@learnMoreLink">
                @if (linkText) {
                    {{ linkText }}
                } @else {
                    Learn more
                }
            </a>
        }
    </div>
</div>
