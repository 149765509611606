import { MatDialogRef } from '@angular/material/dialog';

export function handleChanges(dialogRef: MatDialogRef<any>, forms: any[]): void {
    dialogRef.disableClose = true;
    dialogRef.backdropClick().subscribe(() => {
        if (forms.some(f => f.dirty)) {
            const confirmMessage: string = $localize`:@@confirmDiscardChanges:You have unsaved changes. Do you want to discard them?`;
            const confirmed: boolean = confirm(confirmMessage);
            if (confirmed) {
                dialogRef.close();
            }
        } else {
            dialogRef.close();
        }
    });
}
