import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ExportCardWrapperTranslations } from '../../translations/export-card-wrapper-translations';

@Component({
    selector: 'export-card-wrapper',
    templateUrl: './export-card-wrapper.component.html',
    styleUrls: ['./export-card-wrapper.component.scss'],
})
export class ExportCardWrapperComponent {
    @Input() public position: number;
    @Input() public pageLength: number;
    @Input() public currentPage: number;
    @Input() public cardId: string;
    @Input() public deleteButton = false;

    @Output() public positionChanged: EventEmitter<MoveCardEvent> =
        new EventEmitter<MoveCardEvent>();
    @Output() public delete: EventEmitter<void> = new EventEmitter<void>();

    public moveDirection: typeof MoveDirection = MoveDirection;
    public translations: typeof ExportCardWrapperTranslations = ExportCardWrapperTranslations;

    public moveCard(direction: MoveDirection): void {
        this.positionChanged.emit({
            direction,
            position: this.position,
            id: this.cardId,
            page: this.currentPage,
        });
    }

    public disabled(direction: MoveDirection): boolean {
        switch (direction) {
            case MoveDirection.UP:
                return this.position === 0;
            case MoveDirection.DOWN:
                return this.position === this.pageLength - 1;
            case MoveDirection.LEFT:
                return this.currentPage === 0;
        }

        return false;
    }
}

export enum MoveDirection {
    UP,
    DOWN,
    LEFT,
    RIGHT,
}

export interface MoveCardEvent {
    id: string;
    direction: MoveDirection;
    position: number;
    page: number;
}
