import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MobilityStats } from '@upscore-mobility-audit/api';
import { KpiCardInfoTranslations } from '@upscore-mobility-audit/core/translations/kpi-card-info.translations';

import { BaseKpiCardComponent } from '../../models/base-kpi-card.model';

@Component({
    selector: 'modal-split-card',
    templateUrl: './modal-split-card.component.html',
    styleUrls: ['./modal-split-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSplitCardComponent extends BaseKpiCardComponent {
    @Input() public title: string;
    @Input() public employeeCount: number;
    @Input() public mobilityStats: MobilityStats;
    @Input() public baselineMobilityStats?: MobilityStats;
    @Input() public hasMenu = true;
    @Input() public showUnavailable: boolean;

    public kpiCardTranslations: typeof KpiCardInfoTranslations = KpiCardInfoTranslations;
}
