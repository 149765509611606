export const markerClusterIconStyleOptions: { url: string; width: number; height: number }[] = [
    {
        url: 'assets/images/map/map-cluster/1.png',
        width: 60,
        height: 60,
    },
    {
        url: 'assets/images/map/map-cluster/2.png',
        width: 63,
        height: 63,
    },
    {
        url: 'assets/images/map/map-cluster/3.png',
        width: 75,
        height: 75,
    },
    {
        url: 'assets/images/map/map-cluster/4.png',
        width: 88,
        height: 88,
    },
    {
        url: 'assets/images/map/map-cluster/5.png',
        width: 100,
        height: 100,
    },
];
