@if (
    (this.userDataService.showFeature$(FeatureFlag.GEOCODING_QUALITY) | async) &&
    !searchEmployeeInfo
) {
    <div class="row checkbox-container__content">
        <span i18n="@@showEmployeeDetailsTable" ngClass="labelBeforeSlide"
            >Employee Details Table</span
        >
        <mat-slide-toggle
            (toggleChange)="showEmployeeGeocodingCheckbox = !showEmployeeGeocodingCheckbox"
            class="mat-form-field-wrapper"
            i18n="@@showAggregatedGeocodingEstimates">
            Aggregated Geocoding Estimation Table
        </mat-slide-toggle>
    </div>
}

<div class="checkbox-container">
    @if (
        (this.userDataService.showFeature$(FeatureFlag.GEOCODING_QUALITY) | async) &&
        showEmployeeGeocodingCheckbox
    ) {
        <div class="checkbox-container__content">
            <input
                (change)="onCheckboxChange($event)"
                [(ngModel)]="employeeGeocodingCheckbox"
                type="checkbox" />
            <span class="left-margin-5" i18n="@@showGeocodingEstimation"
                >Show Employee Geocoding Estimation Quality</span
            >
        </div>
    }
</div>
@if (employeeData && showEmployeeGeocodingCheckbox) {
    <upscore-table
        [dataSource]="employeeData"
        [columns]="employeeTableColumns"
        [upscoreTableModel]="employeeTableModel"
        [locationEditRowIndex]="locationEditRowIndex"
        (rowDetails)="onEditRow($any($event))"
        (rowDelete)="onDeleteRow($any($event))"
        [searchEmployeeInfo]="searchEmployeeInfo"></upscore-table>
}
@if (aggregatedGeocodingInfoTableData && !showEmployeeGeocodingCheckbox) {
    <div class=".geocoding-table-container">
        <upscore-table
            [columns]="aggregatedGeocodingInfoDataTableColumns"
            [dataSource]="aggregatedGeocodingInfoTableData"
            [upscoreTableModel]="aggregatedGeocodingInfoTableModel"></upscore-table>
    </div>
}
