import { Injectable, signal } from '@angular/core';

import { LocalStorageKeys } from '@shared/utils';

@Injectable({
    providedIn: 'root',
})
export class HiddenAuditTasksService {
    // tasks that are hidden by the user
    public hiddenAuditTaskIds = signal<string[]>(
        JSON.parse(localStorage.getItem(LocalStorageKeys.HIDDEN_TASK_NOTIFICATIONS) || '[]'),
    );

    public hideTask(taskId: string) {
        this.hiddenAuditTaskIds.update(hiddenTasks => hiddenTasks.concat(taskId));
        localStorage.setItem(
            LocalStorageKeys.HIDDEN_TASK_NOTIFICATIONS,
            JSON.stringify(this.hiddenAuditTaskIds()),
        );
    }
}
