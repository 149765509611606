<div class="table-container">
    <div>
        @if (upscoreTableModel.enableTableSearches && !searchEmployeeInfo) {
            <mat-form-field appearance="outline" class="search-bar">
                <input
                    matInput
                    (keyup)="applyFilterFromInput($event)"
                    placeholder="Search"
                    style="width: 70%" />
                <mat-select
                    [(ngModel)]="searchDropdownVal"
                    style="width: 30%"
                    (selectionChange)="onSearchParameterChange()">
                    @for (item of searchDropdownOptions; track item) {
                        <mat-option [value]="item.field">{{ item.sectionHeader }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
    </div>
</div>
<div>
    <form
        [formGroup]="upscoreTableForm"
        autocomplete="off"
        (keydown.enter)="$event.preventDefault()">
        <ng-container formArrayName="upscoreTableRows">
            <div class="table-responsive">
                <mat-table
                    class="mat-elevation-z1 table row-border hover table-bordered table-hover table-striped"
                    (contentChanged)="onContentChange()"
                    [dataSource]="_dataSource">
                    @for (c of columns; track c) {
                        <ng-container [matColumnDef]="c.field" style="width: 80%">
                            <ng-container>
                                <th *matHeaderCellDef mat-header-cell>
                                    {{ c.sectionHeader }}
                                </th>
                            </ng-container>
                            <td
                                *matCellDef="let element; let i = index"
                                [formGroup]="element"
                                [matTooltip]="c.buttonPurpose ? '' : element.value[c.field]"
                                mat-cell>
                                <mat-form-field
                                    (keydown.enter)="
                                        saveRow(upscoreTableForm, startingIndexOfPage + i)
                                    "
                                    subscriptSizing="dynamic"
                                    [style.width]="c?.buttonPurpose ? '30%' : '80%'"
                                    [class.input-field]="
                                        upscoreTableForm.get('upscoreTableRows')?.value[
                                            startingIndexOfPage + i
                                        ].isEditable || !c.isEditable
                                    "
                                    [appearance]="
                                        upscoreTableForm.get('upscoreTableRows')?.value[
                                            startingIndexOfPage + i
                                        ].isEditable && !c.isEditable
                                            ? $any('none')
                                            : $any('fill')
                                    "
                                    [class.input-field]="
                                        upscoreTableForm.get('upscoreTableRows')?.value[
                                            startingIndexOfPage + i
                                        ].isEditable || !c.isEditable
                                    "
                                    [style.width]="c?.buttonPurpose === 'details' ? '30%' : '80%'">
                                    @switch (c.inputType) {
                                        @case ('textArea') {
                                            <textarea
                                                [readonly]="
                                                    upscoreTableForm.get('upscoreTableRows')?.value[
                                                        i
                                                    ].isEditable || !c.isEditable
                                                "
                                                formControlName="{{ c.field }}"
                                                matInput
                                                style="overflow: hidden"
                                                type="text"></textarea>
                                        }
                                        @case ('number') {
                                            <input
                                                [readonly]="
                                                    upscoreTableForm.get('upscoreTableRows')?.value[
                                                        startingIndexOfPage + i
                                                    ].isEditable || !c.isEditable
                                                "
                                                formControlName="{{ c.field }}"
                                                matInput
                                                max="{{ c.maxNumber }}"
                                                min="{{ c.minNumber }}"
                                                type="number" />
                                        }
                                        @case ('time') {
                                            <input
                                                [readonly]="
                                                    upscoreTableForm.get('upscoreTableRows')?.value[
                                                        startingIndexOfPage + i
                                                    ].isEditable || !c.isEditable
                                                "
                                                formControlName="{{ c.field }}"
                                                matInput
                                                placeholder="{{
                                                    upscoreTableForm.get('upscoreTableRows')?.value[
                                                        startingIndexOfPage + i
                                                    ].isEditable || !c.isEditable
                                                        ? ''
                                                        : c?.placeHolder
                                                }}"
                                                type="time" />
                                        }
                                        @case ('autocomplete') {
                                            <input
                                                (input)="
                                                    onAutocompleteChange(
                                                        $event,
                                                        c.field,
                                                        startingIndexOfPage + i
                                                    )
                                                "
                                                [matAutocomplete]="auto"
                                                [readonly]="
                                                    upscoreTableForm.get('upscoreTableRows')?.value[
                                                        startingIndexOfPage + i
                                                    ].isEditable || !c.isEditable
                                                "
                                                formControlName="{{ c.field }}"
                                                matInput
                                                type="text" />

                                            @if (
                                                !(
                                                    upscoreTableForm.get('upscoreTableRows')?.value[
                                                        startingIndexOfPage + i
                                                    ].isEditable || !c.isEditable
                                                )
                                            ) {
                                                <mat-icon
                                                    (click)="
                                                        onAddAutocompleteOption(
                                                            upscoreTableForm.get('upscoreTableRows')
                                                                ?.value[startingIndexOfPage + i][
                                                                c.field
                                                            ]
                                                        )
                                                    "
                                                    matSuffix
                                                    style="cursor: pointer"
                                                    >add_circle
                                                </mat-icon>
                                            }
                                        }
                                        @case ('dropdown') {
                                            @if (
                                                c.dropDownValueKey != null &&
                                                c.dropDownValueDisplayKey != null
                                            ) {
                                                <mat-select
                                                    [disabled]="
                                                        upscoreTableForm.get('upscoreTableRows')
                                                            ?.value[startingIndexOfPage + i]
                                                            .isEditable || !c.isEditable
                                                    "
                                                    formControlName="{{ c.field }}"
                                                    [multiple]="c.dropDownValuesMultiple ?? false">
                                                    @for (item of c.dropDownValues; track item) {
                                                        <mat-option
                                                            [value]="
                                                                item[c.dropDownValueKey] === null ||
                                                                item[c.dropDownValueKey] ===
                                                                    undefined
                                                                    ? item
                                                                    : item[c.dropDownValueKey]
                                                            ">
                                                            {{ item[c.dropDownValueDisplayKey] }}
                                                        </mat-option>
                                                    }
                                                </mat-select>
                                            }
                                        }
                                        @default {
                                            <!-- !c.inputType -->
                                            <input
                                                [readonly]="
                                                    upscoreTableForm.get('upscoreTableRows')?.value[
                                                        startingIndexOfPage + i
                                                    ].isEditable || !c.isEditable
                                                "
                                                formControlName="{{ c.field }}"
                                                matInput
                                                type="text" />
                                        }
                                    }
                                    <!-- <mat-icon matSuffix *ngIf="c.inputType=='autocomplete'">add_circle</mat-icon> -->
                                    <mat-autocomplete #auto="matAutocomplete">
                                        @for (option of autocompleteoptions | async; track option) {
                                            <mat-option [value]="option">
                                                {{ option }}
                                            </mat-option>
                                        }
                                    </mat-autocomplete>
                                    <!-- <mat-placeholder class="placeholder">
                  {{
                  upscoreTableForm.get('upscoreTableRows')?.value[
                  startingIndexOfPage + i
                  ].isEditable || !c.isEditable
                  ? ''
                  : c?.placeHolder
                  }}
                </mat-placeholder> -->
                                    @if (
                                        showUnsetBtn(c, startingIndexOfPage + i) &&
                                        !(
                                            upscoreTableForm.get('upscoreTableRows')?.value[
                                                startingIndexOfPage + i
                                            ].isEditable || !c.isEditable
                                        )
                                    ) {
                                        <button
                                            (click)="
                                                unsetField(startingIndexOfPage + i, c.field, $event)
                                            "
                                            mat-icon-button
                                            matSuffix
                                            type="button">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    }
                                </mat-form-field>
                                @if (
                                    (c.showButton &&
                                        !(
                                            upscoreTableForm.get('upscoreTableRows')?.value[
                                                startingIndexOfPage + i
                                            ].isEditable && c.showButton
                                        )) ||
                                    (c.showButton &&
                                        c.buttonPurpose &&
                                        upscoreTableForm.get('upscoreTableRows')?.value[
                                            startingIndexOfPage + i
                                        ]?.displayDetails?.length > 0)
                                ) {
                                    <button
                                        (click)="
                                            onRowButtonClick(
                                                upscoreTableForm.get('upscoreTableRows')?.value[
                                                    startingIndexOfPage + i
                                                ],
                                                startingIndexOfPage + i,
                                                c.buttonPurpose
                                            )
                                        "
                                        mat-icon-button>
                                        @if (c.buttonIcon) {
                                            <mat-icon
                                                [matTooltip]="
                                                    c?.buttonPurpose
                                                        ? upscoreTableForm.get('upscoreTableRows')
                                                              ?.value[startingIndexOfPage + i]
                                                              .displayDetails
                                                        : ''
                                                "
                                                >{{ c.buttonIcon }}</mat-icon
                                            >
                                        }
                                        {{ c?.buttonText }}
                                    </button>
                                }
                            </td>
                        </ng-container>
                    }
                    <ng-container matColumnDef="action">
                        <th *matHeaderCellDef mat-header-cell>Action</th>
                        <td *matCellDef="let element; let i = index" [formGroup]="element" mat-cell>
                            @if (
                                !upscoreTableForm.get('upscoreTableRows')?.value[
                                    startingIndexOfPage + i
                                ].isEditable
                            ) {
                                <button
                                    (click)="saveRow(upscoreTableForm, startingIndexOfPage + i)"
                                    [disabled]="!upscoreTableForm.valid"
                                    class="material-icons app-toolbar-menu save-button"
                                    color="primary"
                                    mat-icon-button
                                    matTooltip="Save Changes">
                                    <mat-icon>check_circle</mat-icon>
                                </button>
                            }
                            @if (
                                !upscoreTableForm.get('upscoreTableRows')?.value[
                                    startingIndexOfPage + i
                                ].isEditable
                            ) {
                                <button
                                    (click)="cancelRow(upscoreTableForm, startingIndexOfPage + i)"
                                    class="material-icons app-toolbar-menu cancel-button"
                                    color="warn"
                                    mat-icon-button
                                    matTooltip="Cancel Changes">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            } @else {
                                @if (upscoreTableModel.showEditButton) {
                                    <button
                                        (click)="
                                            editTableRow(upscoreTableForm, startingIndexOfPage + i)
                                        "
                                        class="material-icons app-toolbar-menu save-button"
                                        color="primary"
                                        mat-icon-button
                                        matTooltip="Edit">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                }
                                @if (upscoreTableModel.showDeleteButton) {
                                    <button
                                        class="material-icons app-toolbar-menu delete-button"
                                        color="warn"
                                        mat-icon-button
                                        [matMenuTriggerFor]="menu"
                                        matTooltip="Delete">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                }
                            }

                            <mat-menu #menu>
                                @if (
                                    upscoreTableForm.get('upscoreTableRows')?.value[
                                        startingIndexOfPage + i
                                    ].isEditable && upscoreTableModel.showDeleteButton
                                ) {
                                    <button
                                        (click)="
                                            onConfirmDeleteRow(
                                                upscoreTableForm,
                                                startingIndexOfPage + i
                                            )
                                        "
                                        class="material-icons app-toolbar-menu delete-button"
                                        color="warn"
                                        mat-button>
                                        Confirm Delete!
                                    </button>
                                }
                            </mat-menu>
                            @if (
                                upscoreTableForm.get('upscoreTableRows')?.value[
                                    startingIndexOfPage + i
                                ].isEditable && upscoreTableModel.showResetPasswordButton
                            ) {
                                <button
                                    (click)="
                                        onResetPassword(upscoreTableForm, startingIndexOfPage + i)
                                    "
                                    class="material-icons app-toolbar-menu save-button"
                                    color="primary"
                                    mat-icon-button
                                    matTooltip="Reset Password">
                                    <i class="material-icons">password</i>
                                </button>
                            }
                        </td>
                    </ng-container>
                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </mat-table>
            </div>
        </ng-container>
    </form>
    <mat-paginator
        #paginator
        [length]="_dataSource.data.length"
        [pageIndex]="0"
        [pageSize]="5"
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons></mat-paginator>
</div>
