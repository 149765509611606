import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';

import { CompanyLocation } from '@upscore-mobility-audit/api';
import { CompanyLocationsWrapperService } from '@upscore-mobility-audit/shared/api-services/company-locations-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class CompanyNameService {
    private companyNames: {
        [locationId: CompanyLocation['id']]: Observable<CompanyLocation['name']>;
    } = {};

    constructor(private companyLocationService: CompanyLocationsWrapperService) {}

    public getCompanyName(locationId: CompanyLocation['id']) {
        if (this.companyNames[locationId]) {
            return this.companyNames[locationId];
        }

        this.companyNames[locationId] = this.companyLocationService
            .getMinimalCompanyLocation(locationId)
            .pipe(
                map(location => location.name),
                shareReplay(1),
            );

        return this.companyNames[locationId];
    }
}
