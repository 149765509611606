<!---
when adding a new step dont forget to update: DialogSteps
--->

<upscore-stepper
    (stepsCompleted)="onStepsCompleted()"
    (stepsReset)="onStepsReset()"
    [allowReset]="newCompanyLocation"
    [linear]="true">
    <!-- company input -->
    <upscore-step
        [label]="translations.companyLocationText"
        [postCall]="uploadCompanyLocation.bind(this)"
        [stepControl]="locationFormGroup">
        <company-location-step
            [entrancesFormGroup]="entrancesFormGroup"
            [locationFormGroup]="locationFormGroup"
            [userCoverage]="(userDataService.user$ | async)?.coverage">
        </company-location-step>
    </upscore-step>

    @if (userDataService.showFeature$(featureFlag.CALCULATION_PROPERTIES) | async) {
        <upscore-step
            [label]="translations.calculationPropertiesText"
            [mandatory]="false"
            [optional]="true"
            [postCall]="createMobilityProperties.bind(this)"
            [preCall]="checkForCalculationPropertiesStep.bind(this)"
            [stepControl]="locationPropertiesForm">
            <mobility-properties-step
                (resetCalculationPropertiesForm)="resetCalculationPropertiesForm()"
                [enableModeEstimationConfig]="enableModeEstimationConfig"
                [mobilityPropertiesForm]="locationPropertiesForm"></mobility-properties-step>
        </upscore-step>
    }

    <!-- upload employee data  input -->
    <upscore-step
        [label]="translations.employeeUploadText"
        [nextButton]="
            companyLocation?.employeeCount !== 0 &&
            uploadFormGroup.value.file == null &&
            uploadFormGroup.value.employeeCount == null
                ? defaultSkipButtonString
                : defaultNextButtonString
        "
        [optional]="
            (companyLocation?.employeeCount !== 0 && uploadFormGroup.value.employeeCount == null) ||
            (uploadFormGroup.value.employeeCount != null &&
                companyLocation?.employeeCount !== 0 &&
                uploadFormGroup.value.employeeCount >= 10 &&
                uploadFormGroup.value.employeeCount <= 10000)
        "
        [postCall]="createEmployees.bind(this)"
        [stepControl]="uploadFormGroup">
        <employee-data-step
            (reUploadEmployees)="reUploadFailedEmployees($event, this.companyLocation!)"
            [employeeCount]="companyLocation?.employeeCount"
            [entrances]="companyLocation?.entrances"
            [uploadFormGroup]="uploadFormGroup"></employee-data-step>
    </upscore-step>

    <!-- location input -->
    <upscore-step
        [label]="translations.mobilityParametersText"
        [preCall]="resetUploadStepData.bind(this)"
        [stepControl]="mobilityParamsFormGroup">
        @if (upscoreStepper?.selected?.stepControl === mobilityParamsFormGroup) {
            <mobility-parameters-step
                [companyLocationId]="companyLocation?.id"
                [mobilityParamsFormGroup]="mobilityParamsFormGroup"
                [mobilityStats]="companyLocation?.mobilityStats"
                [vehicleStats]="companyLocation?.vehicleStats">
            </mobility-parameters-step>
        }
    </upscore-step>
</upscore-stepper>
